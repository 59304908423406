import axios from "axios";
import Vue from "vue";

const getDefaultState = () => {
    return {
        complain_type: '',
        complain_list: [],
        complain_primary_word: {},
        complain_translation_word: {},
        complain_translate_id: null,
        complain_sentence_translate_id: null,
        complain_task_id: null,
        complain_is_primary: null,
        complain_entity_details: null,
        complain_entity_comments: [],
        complain_entity_comments_all_loaded: false,

        complain_entity_data: null,
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async COMPLAIN_TO_ENTITY({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url}/complain`, payload.data)
                    .then(() => {
                        // если target = ENTITY || MATHERIAL, то пользователь пожаловался на сущность
                        // если target = RESOLUTION, то пользователь оспорил решение
                        dispatch("ADD_NEW_NEED_ACTION_TASK_COMMENT", {
                            type: "complain_added_log",
                            target: payload.data['target'],
                            comment: payload.data['comment']
                        });
                        resolve();
                    })
                    .catch((err) => reject(err))
            })
        },
        async LOAD_COMPLAIN_ENTITY_DETAILS({state, commit}) {
            await axios
                .get(`${Vue.prototype.$api_url}/dictionary/entity`, {
                    params: {
                        'entity_type_slug': state.complain_entity_details.entity_type_slug,
                        'entity_id': state.complain_entity_details.entity_id
                    }
                })
                .then(r => commit('UPDATE_COMPLAIN_ENTITY_ENTITY', r.data.data))
                .catch((e) => {
                    throw new Error(e)
                })
        },
        async ACTION_FEED_COMPLAIN({dispatch, commit}, payload) {
            let URL = `${Vue.prototype.$api_url}/complain`;
            let data = Object.assign({}, payload);

            if (payload.target_type === 'profile') {
                URL += '/user';
                delete data['target_type'];
            } else {
                URL += '/user-action';
            }

            await axios
                .post(URL, data)
                .then(() => {
                    commit("ALERT_SHOW", {
                        name: 'feed_complain',
                        content: 'complaint_sent',
                        duration: 300
                    })
                })
                .catch((err) => {
                    if (err.message === 'COMPLAIN_BEING_PROCESSED') dispatch('ERROR_SHOW', {name: 'system', content: 'complaint_already_sent'});
                    else if (err.message === 'ADMINS_CAN_NOT_BE_COMPLAINED') dispatch('ERROR_SHOW', {name: 'system', content: 'admins_cannot_be_complained_error'});
                    else dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'});
                    throw Error(err)
                })
        },
    },
    mutations: {
        SAVE_COMPLAIN_TYPE: (state, type) => state.complain_type = type,
        SAVE_COMPLAIN_LIST: (state, list) => state.complain_list = list,
        SAVE_COMPLAIN_PRIMARY_WORD: (state, word) => state.complain_primary_word = word,
        SAVE_COMPLAIN_TRANSLATION_WORD: (state, word) => state.complain_translation_word = word,
        SAVE_COMPLAIN_TRANSLATE_ID: (state, id) => state.complain_translate_id = id,
        SAVE_COMPLAIN_SENTENCE_TRANSLATE_ID: (state, id) => state.complain_sentence_translate_id = id,
        SAVE_COMPLAIN_TASK_ID: (state, id) => state.complain_task_id = id,
        SAVE_COMPLAIN_IS_PRIMARY: (state, boolean) => state.complain_is_primary = boolean,
        SAVE_COMPLAIN_ENTITY_DETAILS: (state, data) => {
            let duration = 0;
            if (state.complain_entity_details) {
                duration = 300;
                state.complain_entity_details = null;
                state.complain_entity_comments = [];
                state.complain_entity_comments_all_loaded = false;
            }
            setTimeout(() => state.complain_entity_details = data, duration)
        },
        CLEAR_COMPLAIN_ENTITY_DETAILS: (state) => {
            state.complain_entity_comments_all_loaded = false;
            state.complain_entity_comments = [];
            state.complain_entity_details = null;
        },
        UPDATE_COMPLAIN_ENTITY_ENTITY: (state, entity) => {
            if (state.complain_entity_details) state.complain_entity_details['entity'] = entity
        },
        CHANGE_COMPLAIN_ENTITY_COMMENTS_ALL_LOADED: (state, boolean) => state.complain_entity_comments_all_loaded = boolean,
        SAVE_COMPLAIN_ENTITY_COMMENTS: (state, comments) => state.complain_entity_comments = comments,
        EDIT_COMPLAIN_ENTITY_COMMENT: (state, comment) => {
            let index = state.complain_entity_comments.findIndex(c => c.task_comment_uuid === comment.task_comment_uuid);
            if (~index) state.complain_entity_comments.splice(index, 1, comment);
        },
        DELETE_COMPLAIN_ENTITY_COMMENT: (state, uuid) => {
            state.complain_entity_comments = state.complain_entity_comments.filter(c => c.task_comment_uuid !== uuid);
        },
        CONCAT_COMPLAIN_ENTITY_COMMENTS: (state, comments) => state.complain_entity_comments = state.complain_entity_comments.concat(comments),
        PUSH_COMPLAIN_ENTITY_COMMENT: (state, comment) => state.complain_entity_comments.unshift(comment),
        SAVE_COMPLAIN_ENTITY_DATA: (state, data) => state.complain_entity_data = data,
        CLEAR_COMPLAIN_CACHE: (state) => Object.assign(state, getDefaultState()),
    },
}
