import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '../localizations/en.json';

Vue.use(VueI18n, {
    ssrAttribute: 'renderer-ready',
})

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: {en}
})
