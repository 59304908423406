const getDefaultState = () => {
    return {
        chat_unsent_messages: [],
        chat_not_publish_messages: {},
        chats_languages_settings: {
            key: 1
        },
        selections_edit_map: {},
        active_users_setting: {},
        unread_news_status: false,
        autoplay_audio: true,
        show_audio_modal: true,
        google_translates_day: localStorage.getItem('google_translates_day') || String(new Date().getDate()),
        google_translates_count: 0,
        api: 'https://wola.dev/api/v1',
        api_v2: 'https://wola.dev/api/v2',
        api_chat: 'https://chat.wola.dev/api/v1'
    }
}

export default {
    state: () => getDefaultState(),
    mutations: {
        UPDATE_CHAT_NOT_PUBLISH_MESSAGES: (state, data) => {
            if (!state.chat_not_publish_messages[data.uuid]) state.chat_not_publish_messages[data.uuid] = {}
            if ("quote" in data) state.chat_not_publish_messages[data.uuid]["quote"] = data.quote
            if ("msg" in data) state.chat_not_publish_messages[data.uuid]["message"] = data.msg
            if ("correction" in data) state.chat_not_publish_messages[data.uuid]["correction"] = data.correction
        },
        SAVE_UNSENT_MESSAGE: (state, data) => {
            if (!data.type) {
                let index = state.chat_unsent_messages.findIndex(m => m.id === data.message.client_message_id);
                if (index >= 0) state.chat_unsent_messages.splice(index, 1);
            } else {
                let index = state.chat_unsent_messages.findIndex(m => m.id === data.message.id);

                if (data.type === 'save') {
                    if (index < 0) state.chat_unsent_messages.push(data.message);
                } else if (index >= 0) {
                    state.chat_unsent_messages.splice(index, 1);
                }
            }
        },
        ADD_CHAT_LANGUAGES_SETTINGS: (state, data) => {
            if (!(data.user_uuid in state.chats_languages_settings)) {
                state.chats_languages_settings[data.user_uuid] = {}
            }

            state.chats_languages_settings[data.user_uuid][data.setting_name] = data.value
            state.chats_languages_settings.key += 1
        },
        SAVE_API: (state, host) => state.api = host,
        SAVE_API_CHAT: (state, host) => state.api_chat = host,
        SAVE_UNREAD_NEWS_STATUS: (state, boolean) => state.unread_news_status = boolean,
        SAVE_ACTIVE_USERS_SETTING: (state, data) => state.active_users_setting = data,
        SAVE_AUTOPLAY_AUDIO: (state, boolean) => state.autoplay_audio = boolean,
        SAVE_SHOW_AUDIO_MODAL: (state, boolean) => state.show_audio_modal = boolean,
        SAVE_SELECTION_EDIT_MAP: (state, payload) => {
            if (payload.data) state.selections_edit_map[payload.uuid] = payload.data
            else delete state.selections_edit_map[payload.uuid]
        },
        SAVE_GOOGLE_TRANSLATES_DAY: (state) => {
            if (state.google_translates_day === String(new Date().getDate())) {
                state.google_translates_count++;
            } else {
                state.google_translates_day = String(new Date().getDate());
                localStorage.setItem('google_translates_day', state.google_translates_day);
            }
        },
        CLEAR_CACHE: (state) => {
            let api, api_chat;

            if (location.host !== 'wola.io') {
                api = state.api;
                api_chat = state.api_chat;
            } else {
                api = 'https://wola.io/api/v1';
                api_chat = 'https://chat.wola.io/api/v1';
            }

            Object.assign(state, getDefaultState(), {api, api_chat});
        }
    },
}
