import store from "../../store";

const LanguageDetect = require('languagedetect');
const lngDetector = new LanguageDetect();
const splitToWords = require('split-to-words');

lngDetector.setLanguageType('iso3');

export default {
    defineLanguage(text, lang, lang_t, array) {
        let has_lang_detect_1 = true,
            has_lang_detect_2 = true;

        if (lang) has_lang_detect_1 = store.state.languages.languages.find(l => l.code === lang)?.lang_detect;
        if (lang_t) has_lang_detect_2 = store.state.languages.languages.find(l => l.code === lang_t)?.lang_detect;

        if (!has_lang_detect_1 || !has_lang_detect_2) {
            if (array) return [];
            else return false;
        }

        let result = lngDetector.detect(text, 10),
            has_lang = false,
            has_lang_t = false,
            resp = [];

        if (array) {
            result.forEach(l => {
                let f = store.state.languages.languages.find(i => i.lang_detect === l[0])
                if (f) resp.push(f.code)
            })
            return resp;
        } else {
            for (let probability of result) {
                if (probability[0] === lang) has_lang = true;
                else if (lang_t && (probability[0] === lang_t)) has_lang_t = true;
            }

            if (!lang_t && !has_lang) return 1;
            else if (!has_lang && has_lang_t) return 1;
            else return 0;
        }
    },
    getSentenceBreakingStatus(code) {
        let lang = store.state.languages.languages.find(lang => lang.code === code);
        return lang ? lang.is_sentence_breaking : true;
    },
    getWordsArrayFromSentence(string, full, letter_by_letter) {
        let result = [],
            from_index = 0,
            wordsArray = letter_by_letter ? string.match(/(.)/) : splitToWords(string);

        if (full) {
            if (wordsArray?.length) {
                wordsArray.forEach((word, index) => {
                    if (word) {
                        let index_start = string.indexOf(word, from_index),
                            index_finish = index_start + word.length - 1;

                        from_index = index_finish + 1;

                        result.push({
                            id: index,
                            word: word,
                            is_selected: false,
                            position: [index_start, index_finish]
                        })
                    }
                })
            }
        } else {
            result = wordsArray;
        }

        return result;
    }
}
