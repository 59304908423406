import axios from "axios";
import router from "../../router";
import Vue from "vue";

let last_translation_id = null,
    last_sentences_load_params = null,
    do_not_clear = false;

const getDefaultState = () => {
    return {
        detail_primary_word: JSON.parse(sessionStorage.getItem('word_primary')) || {},
        detail_translate_word: JSON.parse(sessionStorage.getItem('word_translate')) || {},
        translate_info: JSON.parse(sessionStorage.getItem('translate_info')) || {},
        translate_details: JSON.parse(sessionStorage.getItem('translate_details')) || {},
        word_meanings: [[], []],
        word_sentences: [],
        word_synonyms: [[], []],
        word_antonyms: [[], []],
        word_sources: [],
        translation_id: sessionStorage.getItem('translation_id') || null,
        sentences: [],
        meanings_lang: '',
        synonyms_lang: '',
        antonyms_lang: '',
        meanings_list_open: false,
        translation_not_found: false,
        word_sentences_all_loaded: false,
        all_sentences_all_loaded: false,
        moderation_mode: false,
        detail_page_key: 1,
        update_sentences_key: 1,
        editable_vocabulary_ids: [],
        non_removable_element: {}
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_WORD_PANEL({commit}, data) {
            commit("SAVE_DETAIL_PRIMARY_WORD", data.primary_word);
            commit("SAVE_DETAIL_TRANSLATE_WORD", data.translation_word);
            commit("SAVE_TRANSLATION_ID", data.id);
            commit("SAVE_TRANSLATE_INFO", data);

            if (data.do_not_clear) do_not_clear = true
        },
        async OPEN_WORD_PANEL({state, commit, getters, dispatch}, data) {
            if (data) await dispatch("LOAD_WORD_PANEL", data);
            else {
                dispatch("GET_TRANSLATION", state.translation_id).then(resp => {
                    dispatch("LOAD_WORD_PANEL", resp)
                })
            }

            if (getters.MOBILE) {
                await router.push({
                    name: 'DetailWordPage',
                    params: {
                        locale: getters.LOCALE.route_code,
                        lang: sessionStorage.getItem('language_primary'),
                        lang_t: sessionStorage.getItem('language_translation'),
                        search: sessionStorage.getItem('search_text'),
                        id: sessionStorage.getItem('translation_id')
                    }
                })
            } else await commit("CHANGE_WORD_PANEL", true);
        },
        async OPEN_WORD_PANEL_2({state, commit, dispatch}, type) {
            await commit("CHANGE_LOADING_DETAILS_STATUS", true);
            await commit("SAVE_TRANSLATION_NOT_FOUND", false);

            if (type === 'favorite') await commit("CHANGE_FAVORITE_PANEL", true)
            else if (type !== 'info') await commit("CHANGE_WORD_PANEL", true)

            await dispatch("GET_TRANSLATION", state.translation_id)
                .then(resp => {
                    dispatch("LOAD_WORD_PANEL", resp);
                    dispatch("LOAD_WORD_INFO", type === 'info');
                })
                .catch(async () => {
                    await commit("LIGHT_CLEAR_DETAIL_WORD_PAGE");
                    await commit("CHANGE_LOADING_DETAILS_STATUS", false);
                })
        },
        LOAD_WORD_INFO({state, commit, dispatch}, dont_load_all) {
            if (dont_load_all || (last_translation_id !== state.translation_id)) {
                let a = 0,
                    stop = (count) => {
                        if (count === 5) {
                            dispatch('LOAD_MULTIPLE_EDIT_AVAILABLE').finally(() => {
                                commit('SAVE_TRANSLATE_INFO', state.translate_info)
                                setTimeout(() => commit("CHANGE_LOADING_DETAILS_STATUS", false), 200)
                            })
                        }
                    };

                last_translation_id = state.translation_id;
                commit("SAVE_SCROLL", {name: 'DetailPage', position: 0});
                commit("SAVE_TRANSLATION_NOT_FOUND", false);
                commit("CHANGE_LOADING_DETAILS_STATUS", true);
                commit("CLEAR_WORD_PAGE_FILTER", true);
                commit("SAVE_WORD_SOURCES", []);
                commit('SAVE_TRANSLATE_DETAILS', {});

                if (dont_load_all) {
                    commit("ENABLE_CAN_EDIT_FOR_TYPES", ['translation', 'attributes', 'vocabulary']);
                    setTimeout(() => commit("CHANGE_LOADING_DETAILS_STATUS", false), 200);
                } else {
                    dispatch("LOAD_WORD_MEANINGS").finally(() => stop(++a));
                    dispatch("LOAD_POSSIBLE_COMPLEMENTS").finally(() => stop(++a));
                    dispatch("LOAD_WORD_SENTENCES").finally(() => stop(++a));
                    dispatch("LOAD_WORD_SYNONYMS").finally(() => stop(++a));
                    dispatch("LOAD_WORD_ANTONYMS").finally(() => stop(++a));
                }
            } else commit("CHANGE_LOADING_DETAILS_STATUS", false);
        },
        async LOAD_POSSIBLE_COMPLEMENTS({state, commit}) {
            await commit('SAVE_TRANSLATE_DETAILS', {});
            await axios
                .get(`${Vue.prototype.$api_url}/translations/${state.translation_id}/possible-complements`)
                .then(resp => {
                    let translate_details = Object.assign(state.translate_details, resp.data.data)
                    translate_details['improve_btn'] = resp.data.data.is_improve_possible ? 'improve' : null
                    commit("SAVE_TRANSLATE_DETAILS", translate_details)
                })
        },
        async LOAD_MULTIPLE_EDIT_AVAILABLE({state, getters, dispatch}) {
            if (getters.isLoggedIn) {
                let params = {}

                state.editable_vocabulary_ids = [];

                if (state.translate_info.translation_union_id && state.translate_info.is_this_user) {
                    params['translation-union'] = [state.translate_info.translation_union_id]
                }
                if (state.translate_info.all_primary_attributes.length || state.translate_info.all_translation_attributes.length) {
                    let attrs = [], childs = [];

                    if (state.translate_info.all_primary_attributes[0]?.is_this_user) attrs.push(state.translate_info.all_primary_attributes[0].id);
                    if (state.translate_info.all_translation_attributes[0]?.is_this_user) attrs.push(state.translate_info.all_translation_attributes[0].id);

                    if (state.translate_info.all_primary_attributes[1] || state.translate_info.all_translation_attributes[1]) {
                        if (state.translate_info.all_primary_attributes[1]?.is_this_user) childs.push(state.translate_info.all_primary_attributes[1].id);
                        if (state.translate_info.all_translation_attributes[1]?.is_this_user) childs.push(state.translate_info.all_translation_attributes[1].id);
                    }

                    if (attrs.length) params['translation-attribute'] = attrs;
                    if (childs.length) params['translation-child-attribute'] = childs;
                }
                if (state.translate_info.all_vocabularies.length) {
                    let vs = [];
                    state.translate_info.all_vocabularies.forEach(v => v.is_this_user && vs.push(v.id));
                    if (vs.length) params['translation-vocabulary'] = vs
                }
                if (state.word_synonyms[0].length || state.word_synonyms[1].length) {
                    let ss = [];
                    if (state.word_synonyms[0].length) state.word_synonyms[0].forEach(s => s.is_this_user && ss.push(s.word_group_id));
                    if (state.word_synonyms[1].length) state.word_synonyms[1].forEach(s => s.is_this_user && ss.push(s.word_group_id));
                    if (ss.length) params['synonym'] = ss
                }
                if (state.word_antonyms[0].length || state.word_antonyms[1].length) {
                    let as = [];
                    if (state.word_antonyms[0].length) state.word_antonyms[0].forEach(a => a.is_this_user && as.push(a.word_group_id));
                    if (state.word_antonyms[1].length) state.word_antonyms[1].forEach(a => a.is_this_user && as.push(a.word_group_id));
                    if (as.length) params['antonym'] = as
                }
                if (state.word_meanings[0].length || state.word_meanings[1].length) {
                    let ms = [];
                    if (state.word_meanings[0].length) state.word_meanings[0].forEach(m => m.is_this_user && ms.push(m.id));
                    if (state.word_meanings[1].length) state.word_meanings[1].forEach(m => m.is_this_user && ms.push(m.id));
                    if (ms.length) params['translation-meaning'] = ms
                }

                if (Object.keys(params).length) {
                    await axios
                        .get(`${Vue.prototype.$api_url}/dictionary/multiple-edit-available`, {params})
                        .then(async (r) => {
                            await dispatch("UPDATE_TRANSLATE_EDITABLE", {
                                data: r.data.data,
                                types: ['all']
                            })
                        })
                        .catch(() => {
                            throw new Error()
                        })
                }
            }
        },
        async LOAD_MULTIPLE_EDIT_AVAILABLE_FOR_SOME_ENTITY({state, getters, dispatch}, payload) {
            if (getters.isLoggedIn) {
                let params = {};
                let dc = payload.disable_check;

                if (payload.types.includes('vocabulary')) {
                    state.editable_vocabulary_ids = [];

                    if (state.translate_info.all_vocabularies.length) {
                        let vs = [];
                        state.translate_info.all_vocabularies.forEach(v => (dc || v.is_this_user) && vs.push(v.id));
                        if (vs.length) params['translation-vocabulary'] = vs
                    }
                }
                if (payload.types.includes('translation')) {
                    params['translation-union'] = [state.translate_info.translation_union_id]
                }
                if (payload.types.includes('attributes')) {
                    if (state.translate_info.all_primary_attributes.length || state.translate_info.all_translation_attributes.length) {
                        let attrs = [], childs = [];

                        if (state.translate_info.all_primary_attributes[0] && (dc || state.translate_info.all_primary_attributes[0].is_this_user)) attrs.push(state.translate_info.all_primary_attributes[0].id);
                        if (state.translate_info.all_primary_attributes[1] && (dc || state.translate_info.all_primary_attributes[1].is_this_user)) childs.push(state.translate_info.all_primary_attributes[1].id);
                        if (state.translate_info.all_translation_attributes[0] && (dc || state.translate_info.all_translation_attributes[0].is_this_user)) attrs.push(state.translate_info.all_translation_attributes[0].id);
                        if (state.translate_info.all_translation_attributes[1] && (dc || state.translate_info.all_translation_attributes[1].is_this_user)) childs.push(state.translate_info.all_translation_attributes[1].id);
                        if (attrs.length) params['translation-attribute'] = attrs;
                        if (childs.length) params['translation-child-attribute'] = childs;
                    }
                }

                if (Object.keys(params).length) {
                    if (payload.types.includes('translation') || payload.types.includes('attributes')) {
                        await dispatch("LOAD_POSSIBLE_COMPLEMENTS")
                            .catch((err) => {
                                throw new Error(err)
                            })
                    }

                    await axios
                        .get(`${Vue.prototype.$api_url}/dictionary/multiple-edit-available`, {params})
                        .then((r) => {
                            dispatch("UPDATE_TRANSLATE_EDITABLE", {
                                data: r.data.data,
                                types: payload.types
                            })
                        })
                        .catch((err) => {
                            throw new Error(err)
                        })
                }
            }
        },
        async LOAD_MULTIPLE_EDIT_AVAILABLE_FOR_SENTENCES({getters, commit}, payload) {
            if (getters.isLoggedIn) {
                let params = {}, sns = [], sns_t = [];

                payload.data.forEach(s => {
                    if (s.sentence_translation_id) {
                        if (s.is_this_user) sns_t.push(s.sentence_translation_id)
                    } else {
                        if (s.word_sentence.is_this_user) sns.push(s.word_sentence.id)
                    }
                });
                if (sns.length) params['word-sentence'] = sns;
                if (sns_t.length) params['sentence-translation'] = sns_t;

                return new Promise((resolve, reject) => {
                    if (Object.keys(params).length) {
                        axios
                            .get(`${Vue.prototype.$api_url}/dictionary/multiple-edit-available`, {params})
                            .then((r) => {
                                if (payload.response) {
                                    resolve(r.data.data)
                                } else {
                                    if (r.data.data.word_sentence) {
                                        commit('UPDATE_CAN_EDIT_FOR_SENTENCES', {
                                            edit_available_arr: r.data.data.word_sentence,
                                            state_data_name: payload.state_name
                                        })
                                    }
                                    if (r.data.data.sentence_translation) {
                                        commit('UPDATE_CAN_EDIT_FOR_SENTENCES', {
                                            edit_available_arr: r.data.data.sentence_translation,
                                            state_data_name: payload.state_name,
                                            translate: true
                                        })
                                    }
                                    resolve(r.data.data)
                                }
                            })
                            .catch((err) => reject(err))
                    } else resolve()
                })
            }
        },
        GET_TRANSLATION({dispatch}, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/translations/${id}`)
                    .then(resp => {
                        dispatch('LOAD_WORD_PANEL', resp.data.data)
                        resolve(resp.data.data)
                    })
                    .catch(error => reject(error))
            })
        },
        LOAD_WORD_MEANINGS({state, commit}, id) {
            let meanings_all = [],
                primary_meanings = [],
                translation_meanings = [],
                a = 0, ID = id || state.translation_id

            axios
                .get(`${Vue.prototype.$api_url}/translations/${ID}/meaning-descriptions?lang_code=${state.detail_primary_word.lang_code}`)
                .then(word_meanings => primary_meanings = word_meanings.data.data)
                .finally(() => stop(++a))

            axios
                .get(`${Vue.prototype.$api_url}/translations/${ID}/meaning-descriptions?lang_code=${state.detail_translate_word.lang_code}`)
                .then(word_meanings_t => translation_meanings = word_meanings_t.data.data)
                .finally(() => stop(++a))

            let stop = function (count) {
                if (count === 2) {
                    meanings_all = primary_meanings.concat(translation_meanings)

                    if (meanings_all.length) {
                        let primary_meanings = [], translation_meanings = []
                        meanings_all.forEach(meaning => {
                            if (meaning.lang_code === state.detail_primary_word.lang_code) primary_meanings.push(meaning)
                            else translation_meanings.push(meaning)
                        })
                        commit("SAVE_WORD_MEANINGS", [primary_meanings, translation_meanings]);
                    } else commit("SAVE_WORD_MEANINGS", [[], []]);
                }
            }
        },
        async LOAD_WORD_SENTENCES({state, dispatch, commit, rootState}, payload) {
            let type = payload?.type,
                params = {
                    'word_name': state.detail_translate_word.name,
                    'word_name_t': state.detail_primary_word.name,
                    'lang': state.detail_translate_word.lang_code,
                    'lang_t': state.detail_primary_word.lang_code,
                    'limit': rootState.dictionary.sentences_limit_count,
                    'offset': (type !== 'load_up') ? 0 : state.word_sentences.length
                };

            if (type !== 'load_up') state.word_sentences_all_loaded = false;

            if (params.word_name && params.lang && params.lang_t) {
                await axios
                    .get(`${Vue.prototype.$api_url}/words/sentences2`, {params})
                    .then(async word_sentences => {
                        let data = word_sentences.data.data
                        state.word_sentences_all_loaded = data.length < params.limit;
                        await commit("SAVE_WORD_SENTENCES", {data, type});
                        await dispatch("LOAD_MULTIPLE_EDIT_AVAILABLE_FOR_SENTENCES", {data, state_name: 'word_sentences'})
                    })
                    .catch(err => {
                        throw new Error(err)
                    })
            }
        },
        async LOAD_WORD_SOURCES({state, commit}) {
            if (state.translation_id) {
                await axios
                    .get(`${Vue.prototype.$api_url}/translations/${state.translation_id}/data-sources`)
                    .then(word_sources => {
                        commit('SAVE_WORD_SOURCES', word_sources.data.data.filter(s => s.author || s.source).concat([{
                            author: null,
                            image_url: "https://wola.io/images/source_wola.png",
                            source: "wola.io",
                            source_url: "https://wola.io/",
                            user_avatar: null,
                            user_native_language: null
                        }]))
                    })
                    .catch(err => {
                        throw new Error(err)
                    })
            }
        },
        async LOAD_WORD_SYNONYMS({state, commit}, id) {
            let ID = id || state.translation_id
            await axios
                .get(`${Vue.prototype.$api_url}/translations/${ID}/synonyms`)
                .then(word_synonyms => {
                    if (word_synonyms.data.data.length) {
                        let primary_synonyms = [], translation_synonyms = []
                        word_synonyms.data.data.forEach(synonym => {
                            if (synonym.lang_code === state.detail_primary_word.lang_code) primary_synonyms.push(synonym)
                            else translation_synonyms.push(synonym)
                        })
                        commit("SAVE_WORD_SYNONYMS", [primary_synonyms, translation_synonyms]);
                    } else commit("SAVE_WORD_SYNONYMS", [[], []])
                })
        },
        async LOAD_WORD_ANTONYMS({state, commit}, id) {
            let ID = id || state.translation_id
            await axios
                .get(`${Vue.prototype.$api_url}/translations/${ID}/antonyms`)
                .then(word_antonyms => {
                    if (word_antonyms.data.data.length) {
                        let primary_antonyms = [], translation_antonyms = []
                        word_antonyms.data.data.forEach(antonym => {
                            if (antonym.lang_code === state.detail_primary_word.lang_code) primary_antonyms.push(antonym)
                            else translation_antonyms.push(antonym)
                        })
                        commit("SAVE_WORD_ANTONYMS", [primary_antonyms, translation_antonyms]);
                    } else commit("SAVE_WORD_ANTONYMS", [[], []])
                })
        },
        async LOAD_SENTENCES({state, getters, dispatch, commit, rootState}, request) {
            let params;

            if (request?.type === 'load_up') {
                params = Object.assign(last_sentences_load_params)
                params['offset'] = state.sentences.length
            } else if (!request?.word_name) {
                params = Object.assign(last_sentences_load_params)
                params['offset'] = 0
            } else {
                state.all_sentences_all_loaded = false;
                await commit("CHANGE_LOADING_DETAILS_STATUS", true);
                params = {
                    'word_name': request.word_name,
                    'lang': request.lang,
                    'lang_t': request.lang_t,
                    'limit': request.limit > 0 ? request.limit : rootState.dictionary.sentences_limit_count,
                    'offset': 0
                }
                last_sentences_load_params = params;
            }

            if (getters.MOBILE) await router.push({name: 'SentencesPage', params: {locale: getters.LOCALE.route_code}})
            else await commit("CHANGE_SENTENCES_PANEL", true)

            if (params.word_name && params.lang && params.lang_t) {
                await axios
                    .get(`${Vue.prototype.$api_url}/words/sentences2`, {params})
                    .then(async (r) => {
                        let data = r.data.data;
                        state.all_sentences_all_loaded = data.length < params.limit
                        await commit("SAVE_SENTENCES", {data, type: request.type})
                        await dispatch("LOAD_MULTIPLE_EDIT_AVAILABLE_FOR_SENTENCES", {data, state_name: 'sentences'})
                    })
                    .catch(() => {
                        commit("SAVE_SENTENCES", {data: [], type: request.type})
                        throw new Error()
                    })
                    .finally(() => {
                        commit('CHANGE_LOADING_DETAILS_STATUS', false)
                    })
            }
        },
        async UPDATE_SENTENCES({state, dispatch, rootState}) {
            if (rootState.panels.sentences_panel) {
                let word_name = rootState.dictionary.word.name,
                    lang = rootState.dictionary.primary_lang,
                    lang_t = rootState.dictionary.translation_lang,
                    limit = rootState.dictionary.sentences_limit_count;

                if (rootState.dictionary.sentences_total_count > rootState.dictionary.sentences_limit_count) {
                    if ((state.sentences.length + 5) <= 100) limit = state.sentences.length + 5;
                    else limit = rootState.dictionary.sentences_limit_count;
                }

                await dispatch('LOAD_SENTENCES', {word_name, lang, lang_t, limit});
            }

            if (rootState.panels.word_panel || state.word_sentences.length) {
                await dispatch('LOAD_WORD_SENTENCES')
            }
        },
        async UPDATE_TRANSLATE_EDITABLE({commit}, payload) {
            let data = payload.data;
            let can_attr = [], can_child_attr = [];

            if (data.translation_union) await commit('UPDATE_CAN_EDIT_FOR_TRANSLATION', data.translation_union[0])
            if (data.translation_vocabulary) await commit('UPDATE_CAN_EDIT_FOR_VOCABULARIES', data.translation_vocabulary)
            if (data.translation_meaning) await commit('UPDATE_CAN_EDIT_FOR_ITEMS', {
                data: data.translation_meaning,
                state_name: 'word_meanings'
            })
            if (data.synonym) await commit('UPDATE_CAN_EDIT_FOR_ITEMS', {
                data: data.synonym,
                state_name: 'word_synonyms'
            })
            if (data.antonym) await commit('UPDATE_CAN_EDIT_FOR_ITEMS', {
                data: data.antonym,
                state_name: 'word_antonyms'
            })
            if (data.translation_attribute) {
                can_attr = data.translation_attribute.filter(i => i.can_edit);
                if (can_attr.length) await commit('UPDATE_CAN_EDIT_FOR_ATTRIBUTES', {data: can_attr, index: 0});
            }
            if (data.translation_child_attribute) {
                can_child_attr = data.translation_child_attribute.filter(i => i.can_edit);
                if (can_child_attr.length) await commit('UPDATE_CAN_EDIT_FOR_ATTRIBUTES', {
                    data: can_child_attr,
                    index: 1
                });
            }
            if (data.translation_union?.[0].can_edit || can_attr.length || can_child_attr.length) {
                await commit('SAVE_TRANSLATE_DETAILS', {'improve_btn': 'edit'})
            }
        },
        async DELETE_SENTENCE_FROM_DETAIL_PAGE({state, commit, rootState}, id) {
            function clearSentence(state_data) {
                let wsi = state[state_data].findIndex(item => item.word_sentence.id === id)
                if (~wsi) {
                    state[state_data].splice(wsi, 1);
                    commit("UPDATE_SENTENCES_TOTAL_COUNT", -1);
                } else {
                    wsi = state[state_data].findIndex(item => item.translations[0]?.id === id)
                    if (~wsi) state[state_data][wsi].translations = []
                }
            }

            await clearSentence('word_sentences');
            await clearSentence('sentences');
            if (rootState.panels.sentences_panel && !state.sentences.length) await commit("CHANGE_SENTENCES_PANEL", false);
        },
        async DELETE_SENTENCE_TRANSLATIONS_FROM_DETAIL_PAGE({state, commit, rootState}, id) {
            let wsi = state.word_sentences.findIndex(item => item.sentence_translation_id === id),
                si = state.sentences.findIndex(item => item.sentence_translation_id === id);

            if (~wsi) state.word_sentences.splice(wsi, 1);
            if (~si) {
                state.sentences.splice(si, 1);
                await commit("UPDATE_SENTENCES_TOTAL_COUNT", -1);
            }

            if (rootState.panels.sentences_panel && !state.sentences.length) await commit("CHANGE_SENTENCES_PANEL", false);
        },
    },
    mutations: {
        SAVE_DETAIL_PRIMARY_WORD: (state, info) => {
            state.detail_primary_word = info
            sessionStorage.setItem('word_primary', JSON.stringify(info, ['attributes', 'id', 'lang_code', 'name']))
        },
        SAVE_DETAIL_TRANSLATE_WORD: (state, info) => {
            state.detail_translate_word = info
            sessionStorage.setItem('word_translate', JSON.stringify(info, ['attributes', 'id', 'lang_code', 'name']))
        },
        SAVE_TRANSLATE_INFO: (state, info) => {
            state.translate_info = info;
            sessionStorage.setItem('translate_info', JSON.stringify(
                info,
                [
                    'can_edit',
                    'favorite',
                    'comment',
                    'id',
                    'translation_union_id',
                    'vocabularies_is_locked',
                    'url_translate_detail',
                    'primary_word_explanation',
                    'translation_word_explanation',
                    'is_this_user',
                    'all_vocabularies',
                    'code',
                    'localized_value',
                    'value',
                    'all_primary_attributes',
                    'all_translation_attributes',
                    'attribute'
                ]
            ));
        },
        DELETE_AUDIO_FROM_DETAIL_WORD(state, id) {
            if (state.detail_primary_word.audios) {
                let p_a = state.detail_primary_word.audios?.findIndex(a => a.id === id);
                if (~p_a) state.detail_primary_word.audios.splice(p_a, 1);
            }
            if (state.detail_translate_word.audios) {
                let t_a = state.detail_translate_word.audios?.findIndex(a => a.id === id);
                if (~t_a) state.detail_translate_word.audios.splice(t_a, 1);
            }
        },
        SAVE_TRANSLATE_DETAILS: (state, details) => {
            state.translate_details = details
            sessionStorage.setItem('translate_details', JSON.stringify(details))
        },
        UPDATE_DETAIL_PAGE_KEY: (state) => state.detail_page_key += 1,
        SAVE_WORD_MEANINGS: (state, info) => state.word_meanings = info,
        SAVE_WORD_SENTENCES: (state, sentences) => {
            if (sentences.type === 'load_up') state.word_sentences = state.word_sentences.concat(sentences.data)
            else state.word_sentences = sentences.data
        },
        SAVE_WORD_SYNONYMS: (state, synonyms) => state.word_synonyms = synonyms,
        SAVE_WORD_ANTONYMS: (state, antonyms) => state.word_antonyms = antonyms,
        SAVE_WORD_SOURCES: (state, sources) => state.word_sources = sources,
        SAVE_TRANSLATION_NOT_FOUND: (state, boolean) => state.translation_not_found = boolean,
        SAVE_TRANSLATION_ID: (state, id) => {
            state.translation_id = id
            sessionStorage.setItem('translation_id', id)
        },
        SAVE_SENTENCES: (state, sentences) => {
            if (sentences.type === 'load_up') state.sentences = state.sentences.concat(sentences.data)
            else state.sentences = sentences.data
        },
        REMOVE_SENTENCE_FROM_FAVORITE: (state, id) => {
            let sentence_in_list = state.sentences.find(sent => sent.word_sentence.id === id),
                sentence_in_translate = state.word_sentences.find(sent => sent.word_sentence.id === id);
            if (sentence_in_list) sentence_in_list.word_sentence.favorite = null;
            if (sentence_in_translate) sentence_in_translate.word_sentence.favorite = null;
        },
        UPDATE_CAN_EDIT_FOR_TRANSLATION: (state, p) => {
            if (state.translate_info.translation_union_id === p.entity_id) state.translate_info.can_edit = p.can_edit;
        },
        ENABLE_CAN_EDIT_FOR_TYPES(state, types) {
            if (types.includes('translation')) {
                state.translate_info.can_edit = true;
            }
            if (types.includes('attributes')) {
                state.translate_info.all_primary_attributes?.forEach(a => a.can_edit = true)
                state.translate_info.all_translation_attributes?.forEach(a => a.can_edit = true)
            }
            if (types.includes('vocabulary')) {
                state.translate_info.all_vocabularies?.forEach(v => v.can_edit = true)
            }
        },
        UPDATE_CAN_EDIT_FOR_VOCABULARIES: (state, payload) => {
            state.editable_vocabulary_ids = payload.map(v => v.entity_id)
            payload.forEach(a => {
                if (a.can_edit) {
                    let b = state.translate_info.all_vocabularies.find(s => s.id === a.entity_id)
                    if (b) b.can_edit = true
                }
            })
        },
        UPDATE_CAN_EDIT_FOR_SENTENCES: (state, {edit_available_arr, state_data_name, translate}) => {
            edit_available_arr.forEach(scs => {
                if (scs.can_edit) {
                    let sentence;

                    if (translate) {
                        sentence = state[state_data_name].find(s => s.sentence_translation_id === scs.entity_id)
                        if (sentence) {
                            sentence.can_edit = true
                            sentence.word_sentence.can_edit = true
                            if (sentence.translations[0]) sentence.translations[0].can_edit = true
                        }
                    } else {
                        sentence = state[state_data_name].find(s => s.word_sentence.id === scs.entity_id)

                        if (sentence) sentence.word_sentence.can_edit = true
                        else {
                            sentence = state[state_data_name].find(s => s.translations[0]?.id === scs.entity_id)
                            if (sentence) sentence.translations[0].can_edit = true
                        }
                    }

                    state.update_sentences_key += 1;
                }
            })
        },
        UPDATE_CAN_EDIT_FOR_ATTRIBUTES: (state, payload) => {
            payload.data.forEach(a => {
                if (state.translate_info.all_primary_attributes[payload.index]?.id === a.entity_id) state.translate_info.all_primary_attributes[payload.index].can_edit = true
                else if (state.translate_info.all_translation_attributes[payload.index]?.id === a.entity_id) state.translate_info.all_translation_attributes[payload.index].can_edit = true
            })
        },
        UPDATE_CAN_EDIT_FOR_ITEMS: (state, payload) => {
            payload.data.forEach(a => {
                if (a.can_edit) {
                    let b = state[payload.state_name][0].find(s => {
                        if (s.word_group_id) return s.word_group_id === a.entity_id
                        else return s.id === a.entity_id
                    })
                    if (b) b.can_edit = true
                    else {
                        b = state[payload.state_name][1].find(s => {
                            if (s.word_group_id) return s.word_group_id === a.entity_id
                            else return s.id === a.entity_id
                        })
                        if (b) b.can_edit = true
                    }
                }
            })
        },
        DO_NOT_CLEAR_OFF: () => do_not_clear = false,
        SAVE_MEANINGS_LANG: (state, lang) => state.meanings_lang = lang,
        SAVE_SYNONYMS_LANG: (state, lang) => state.synonyms_lang = lang,
        SAVE_ANTONYMS_LANG: (state, lang) => state.antonyms_lang = lang,
        SAVE_NON_REMOVABLE_ELEMENT: (state, val) => state.non_removable_element = val,
        SAVE_MODERATION_MODE: (state, boolean) => state.moderation_mode = boolean,
        DELETE_SYNONYM_FROM_DETAIL_PAGE: (state, id) => {
            state.word_synonyms.forEach(items_half => {
                let i = items_half.findIndex(item => item.word_group_id === id)
                if (~i) items_half.splice(i, 1)
            })
        },
        DELETE_ANTONYM_FROM_DETAIL_PAGE: (state, id) => {
            state.word_antonyms.forEach(items_half => {
                let i = items_half.findIndex(item => item.word_group_id === id)
                if (~i) items_half.splice(i, 1)
            })
        },
        DELETE_MEANING_FROM_DETAIL_PAGE: (state, id) => {
            state.word_meanings.forEach(items_half => {
                let i = items_half.findIndex(item => item.id === id)
                if (~i) items_half.splice(i, 1)
            })
        },
        CHANGE_MEANINGS_LIST: (state, boolean) => state.meanings_list_open = boolean,
        CLEAR_WORD_PAGE_FILTER: (state) => state.meanings_lang = state.synonyms_lang = state.antonyms_lang = '',
        LIGHT_CLEAR_DETAIL_WORD_PAGE: (state) => {
            last_translation_id = null;
            last_sentences_load_params = null;
            ['word_primary', 'word_translate', 'translate_info', 'translate_details', 'translation_id']
                .forEach(storage => sessionStorage.removeItem(storage))

            state.detail_primary_word = JSON.parse(sessionStorage.getItem('word_primary')) || {}
            state.detail_translate_word = JSON.parse(sessionStorage.getItem('word_translate')) || {}
            state.translate_info = JSON.parse(sessionStorage.getItem('translate_info')) || {}
            state.translate_details = JSON.parse(sessionStorage.getItem('translate_details')) || {}
            state.word_meanings = [[], []]
            state.word_sentences = []
            state.word_synonyms = [[], []]
            state.word_antonyms = [[], []]
            state.word_sources = []
            state.sentences = []
            state.meanings_lang = ''
            state.synonyms_lang = ''
            state.antonyms_lang = ''
            state.meanings_list_open = false
            state.word_sentences_all_loaded = false
            state.all_sentences_all_loaded = false
            state.moderation_mode = false
            state.detail_page_key = 1
            state.update_sentences_key = 1
            state.editable_vocabulary_ids = []
            state.non_removable_element = {}
        },
        CLEAR_DETAIL_WORD_PAGE: (state) => {
            if (!do_not_clear) {
                last_translation_id = null;
                last_sentences_load_params = null;
                ['word_primary', 'word_translate', 'translate_info', 'translate_details', 'translation_id']
                    .forEach(storage => sessionStorage.removeItem(storage))
                Object.assign(state, getDefaultState());
            }
        }
    },
}
