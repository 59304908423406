export const firebase_config = {
    apiKey: "AIzaSyCA2s9uL6Pr_s6SIB6MlptMb5kcc4KK0XA",
    authDomain: "wola-push.firebaseapp.com",
    databaseURL: "https://wola-push.firebaseio.com",
    projectId: "wola-push",
    storageBucket: "wola-push.appspot.com",
    messagingSenderId: "12118144601",
    appId: "1:12118144601:web:de2f4650ea91a5db1d2348",
    measurementId: "G-T7VMLXT262"
};
