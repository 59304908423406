import axios from "axios";
import Vue from "vue";
import Cookies from "js-cookie";

const getDefaultState = () => {
    return {
        all_attributes: [],
        all_attributes_localize: '',
        set_attributes: {},
        set_genus: {},
        x_keys: {}
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_ALL_ATTRIBUTES({state, getters, commit}, localize) {
            let local = localize || getters.LOCALE.dictionary_code || 'eng',
                x_key = state.x_keys['all'],
                headers = {};

            if (!state.all_attributes.length || !Cookies.get('attr_x_key') || local !== state.all_attributes_localize) {
                if (x_key) {
                    if (state.all_attributes.length && (local === state.all_attributes_localize)) headers["X-modified-key"] = x_key;
                    else delete state.x_keys['all'];
                }

                await axios
                    .get(`${Vue.prototype.$api_url}/localization/attributes`, {headers})
                    .then(response => {
                        if (response) {
                            state.x_keys['all'] = response.data.modified_key;
                            x_key = response.data.modified_key;
                            commit('SAVE_ALL_ATTRIBUTES', response.data.data);
                        }
                        state.all_attributes_localize = local;
                        Cookies.set('attr_x_key', x_key, {
                            secure: true,
                            'max-age': '86400'
                        });
                    })
                    .catch(err => {
                        if (err.response) console.info("WOLA: Attributes not updated")
                        else if (err.request) console.info("WOLA: Attribute response not received")
                        else console.info("WOLA: Attributes error")
                    })
            }
        },
        async LOAD_ATTRIBUTES({commit, dispatch}, lang) {
            await dispatch('GET_ATTRIBUTES', lang).then(resp => {
                if (resp) {
                    commit("SAVE_ATTRIBUTES", {lang, attributes: resp.attributes});
                    commit("SAVE_GENUS", {lang, genus: resp.genus});
                }
            })
        },
        async GET_ATTRIBUTES({state, getters}, lang) {
            let local = getters.LOCALE.dictionary_code || 'eng',
                x_key = state.x_keys[lang],
                headers = {local};

            if (x_key) {
                if (!state.set_attributes[lang] || !state.set_genus[lang]) delete state.x_keys[lang];
                else headers["X-modified-key"] = x_key;
            }

            return axios
                .get(`${Vue.prototype.$api_url}/languages/${lang}/data`, {headers})
                .then(response => {
                    if (response) {
                        let attributes = [], genus = [];

                        response.data.data.attributes.forEach(attr => {
                            let item = {
                                    attribute: attr.attribute,
                                    lang_code: attr.lang_code
                                },
                                local_attr = state.all_attributes.find(attr => attr.code === item.attribute['code']);

                            if (local_attr) item.attribute['name'] = local_attr.value
                            if (attr.parent_id) {
                                item['parent_id'] = attr.parent_id
                                genus.push(item)
                            } else {
                                item['id'] = attr.id
                                attributes.push(item)
                            }
                        })
                        attributes.sort((a, b) => a.attribute.name.localeCompare(b.attribute.name, local));
                        genus.sort((a, b) => a.attribute.name.localeCompare(b.attribute.name, local));

                        state.x_keys[lang] = response.data.modified_key

                        return {attributes, genus}
                    }
                })
                .catch(err => {
                    if (err.response) console.info("WOLA: Attributes not updated")
                    else if (err.request) console.info("WOLA: Attribute response not received")
                    else console.info("WOLA: Attributes error")
                })
        },
    },
    mutations: {
        SAVE_ALL_ATTRIBUTES: (state, attributes) => state.all_attributes = attributes,
        SAVE_ATTRIBUTES: (state, data) => state.set_attributes[data.lang] = data.attributes,
        SAVE_GENUS: (state, data) => state.set_genus[data.lang] = data.genus,
        DELETE_X_KEY: (state, key) => delete state.x_keys[key],
        DELETE_SET_ATTRIBUTES: (state) => state.set_attributes = {},
        CLEAR_ATTRIBUTES: (state) => Object.assign(state, getDefaultState())
    },
}
