import Vue from "vue";
import router from "../../router";
import axios from "axios";

let can_refresh = true,
    can_refresh_timeout;

const getDefaultState = () => {
    return {
        token: '',
        token_type: '',
        expires_in: '',
        refresh_token: '',
        authorization_date: '',
        google_auth: false,
        facebook_auth: false,
        apple_auth: false,
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        isLoggedIn: state => !!state.token
    },
    actions: {
        async LOGIN({commit, dispatch, rootState}, user) {
            await commit("CHANGE_LOADING_AUTH_STATUS", true)
            await commit("CLOSE_ERROR", 'auth')
            await axios
                .post(Vue.prototype.$api_url + "/user/login", user)
                .then(async resp => {
                    await dispatch('FIX_AUTH_STATUS', resp.data).finally(async () => {
                        if (rootState.profile.native_lang) await dispatch('IDENTIFY_LANGUAGES');
                        else await router.replace({name: 'AuthAddNativeLang'});
                        await dispatch('FINISH_AUTH', 'old_user');
                    });
                })
                .catch(async (err) => {
                    await dispatch('FIX_LOGOUT_STATUS');
                    throw new Error(err)
                })
                .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
        },
        async REGISTRATION({commit, dispatch}, payload) {
            await commit("CHANGE_LOADING_AUTH_STATUS", true);

            const
                data = {
                    "email": payload.email,
                    "nickname": payload.nickname,
                    "password": payload.password,
                    "platform": "WEB",
                },
                user = {
                    "birthday": payload.birthday,
                    "gender": payload.gender,
                    "country": payload.native_lang_code
                };

            delete data['avatar']; // не удалять, пусть будет

            await axios
                .post(Vue.prototype.$api_url + "/user/registration", data)
                .then(async resp => {
                    await localStorage.setItem('verified_popup', Date.now());
                    await dispatch("FIX_AUTH_STATUS", resp.data);
                    await dispatch("EDIT_USER_PROFILE", user);
                    await dispatch("REGISTRATION_USER_LANGUAGES", payload);
                })
                .catch(async err => {
                    await dispatch('FIX_LOGOUT_STATUS');
                    throw new Error(err);
                })
                .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
        },
        async LOGOUT({state, dispatch}) {
            if (state.token) {
                await axios
                    .post(Vue.prototype.$api_url + "/user/logout")
                    .catch((err) => {
                        throw new Error(err);
                    })
                    .finally(() => dispatch("FIX_LOGOUT_STATUS"))
            } else await dispatch("FIX_LOGOUT_STATUS");
        },
        async DELETE_ACCOUNT({state, getters, dispatch, commit}) {
            if (state.token) {
                await commit("CHANGE_DELETED_ACCOUNT_LOADING", true)
                await axios
                    .post(Vue.prototype.$api_url + "/user/delete")
                    .then(async () => {
                        if (getters.USER.nickname) await commit("CLEAR_USER_IN_HISTORY", getters.USER.nickname);
                        await dispatch("FIX_LOGOUT_STATUS");
                    })
                    .catch((err) => {
                        throw new Error(err);
                    })
                    .finally(() => commit("CHANGE_DELETED_ACCOUNT_LOADING", false))
            } else await dispatch("FIX_LOGOUT_STATUS");
        },
        async UPDATE_SOCIAL_PROFILE({commit, dispatch, rootState}, payload) {
            commit("CHANGE_LOADING_AUTH_STATUS", true)
            const user = {
                "nickname": payload.nickname,
                "birthday": payload.birthday,
                "gender": payload.gender,
                "country": payload.native_lang_code
            }

            return new Promise((resolve, reject) => {
                if (rootState.profile.user_info.need_to_set_nickname) {
                    dispatch("EDIT_USER_PROFILE", user)
                        .then(() => {
                            dispatch("REGISTRATION_USER_LANGUAGES", payload)
                                .then((r) => resolve(r))
                                .catch((e) => reject(e))
                        })
                        .catch(err => reject(err))
                        .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
                } else {
                    dispatch("REGISTRATION_USER_LANGUAGES", payload)
                        .then((r) => resolve(r))
                        .catch((e) => reject(e))
                }
            })
        },
        async REGISTRATION_USER_LANGUAGES({commit, dispatch, rootState}, payload) {
            let languages = [{
                lang_code: payload.native_lang_code,
                level: "NATIVE"
            }]

            payload.learning_languages.forEach(lang => languages.push({
                lang_code: lang.lang.code,
                level: lang.level.value
            }))

            return new Promise((resolve, reject) => {
                axios
                    .post(Vue.prototype.$api_url + "/user/languages", {languages})
                    .then(async resp => {
                        await commit("SAVE_USER_LANG", resp.data.data)
                        if (rootState.profile.native_lang) await dispatch('IDENTIFY_LANGUAGES');
                        else await router.replace({name: 'AuthAddNativeLang'});
                        await resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        async FINISH_AUTH({getters, commit, rootState}, type) {
            await commit('SAVE_LAST_TRANSLATE_LINK', null)
            if (rootState.profile.user_info.need_to_set_nickname || !rootState.profile.user_info.nickname) {
                await router.push({name: 'AuthCompleteSocialProfile', params: {step: 'nickname', locale: getters.LOCALE.route_code}});
            } else if (!rootState.profile.native_lang) {
                await router.push({name: 'AuthAddNativeLang'});
            } else {
                await router.push(rootState.other.redirect_link || rootState.other.before_auth_link || (getters.LOCALE.base + '/feed'));
                await commit("SAVE_BEFORE_AUTH_LINK", null);
                await commit("SAVE_REDIRECT_LINK", null);
                if (type === 'old_user') setTimeout(() => commit("SAVE_USER_VERIFIED_POPUP", !rootState.profile.user_info.is_email_verified), 1000);
            }
        },
        async FIX_AUTH_STATUS({commit, getters, dispatch}, resp) {
            const token = resp['access_token'],
                token_type = resp['token_type'],
                expires_in = resp['expires_in'],
                refresh_token = resp['refresh_token'];

            axios.defaults.headers.common['authorization'] = token_type + ' ' + token;

            if (resp['google']) await commit('SAVE_GOOGLE_AUTH', true);
            if (resp['facebook']) await commit('SAVE_FACEBOOK_AUTH', true);
            if (resp['apple']) await commit('SAVE_APPLE_AUTH', true);

            await commit('SAVE_TOKEN', token);
            await commit('SAVE_TOKEN_TYPE', token_type);
            await commit('SAVE_EXPIRES_IN', expires_in);
            await commit('SAVE_REFRESH_TOKEN', refresh_token);
            await commit('SAVE_AUTHORIZATION_DATE');
            await commit('CLEAR_SCROLL');
            await commit('CLEAR_PANELS');
            await commit('CLEAR_TUTORIALS');
            await commit('CLEAR_CHAT');
            await commit('CLEAR_CACHE');
            await dispatch('LOAD_USER');
            await dispatch('LOAD_NEW_NOTIFICATIONS_COUNT');
            await dispatch('CHECK_USER_EMAIL_VERIFICATION');

            if (!getters.MOBILE) {
                await dispatch('SOCKET_PUSH_CONNECT');
                await dispatch('GET_UNREAD_MESSAGES_COUNT');
            }
        },
        async FIX_LOGOUT_STATUS({commit}) {
            let localKeys = ['sentFirebaseMessagingToken', 'verified_popup', 'hidden_avatar_banner', 'show_new_selection_banner', 'show_new_video_banner', 'show_article_tutorial_banner', 'feed_addition_languages'];
            localKeys.forEach((item) => localStorage.removeItem(item));
            sessionStorage.clear();
            delete axios.defaults.headers.common['authorization'];
            await commit('CLEAR_AUTH');
            await commit('CLEAR_USER');
            await commit('CLEAR_STATISTICS');
            await commit('CLEAR_RATINGS');
            await commit('CLEAR_USER_FAVORITES');
            await commit('CLEAR_NOTIFICATIONS');
            await commit('CLEAR_DICTIONARY');
            await commit('CLOSE_ALL_ALERTS');
            await commit('CLEAR_TASKS');
            await commit('CLEAR_LEARNING');
            await commit('CLEAR_NEED_ACTION_TASKS');
            await commit('CLEAR_MODERATION_STORE');
            await commit('CLEAR_CHAT');
            await commit('CLEAR_CACHE');
            await commit('CLEAR_FEED');
            await commit('CLEAR_DETAIL_WORD_PAGE');
            await commit('SAVE_REDIRECT_LINK', null);
        },
        async FORGET_PASSWORD({commit}, email) {
            commit("CHANGE_LOADING_AUTH_STATUS", true)
            await axios
                .post(Vue.prototype.$api_url + "/user/password-reset/reset", {email})
                .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
        },
        async RESET_PASSWORD({commit}, data) {
            await commit("CHANGE_LOADING_AUTH_STATUS", true)
            await axios
                .post(Vue.prototype.$api_url + "/user/password-reset/update", data)
                .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
        },
        async CHECK_TOKEN_LIFE({state, getters}) {
            if (getters.isLoggedIn) {
                let date_now = Number(Date.now().toString()),
                    date_auth = state.authorization_date,
                    expires_in = state.expires_in,
                    date_diff = Math.floor((date_now - date_auth) / 1000),
                    allowable_stock = Math.floor(expires_in * 0.99);

                return (expires_in - date_diff) < allowable_stock;
            } else return false
        },
        REFRESH_TOKEN({state, getters, commit, dispatch}) {
            return new Promise(resolve => {
                let new_token = ''
                if (!getters.isLoggedIn) {
                    resolve()
                } else if (can_refresh) {
                    can_refresh = false;
                    commit("CHANGE_LOADING_REFRESH_TOKEN_STATUS", true);

                    if (state.refresh_token) {
                        axios
                            .post(
                                Vue.prototype.$api_url + '/user/refresh-token',
                                {'refresh_token': state.refresh_token},
                            )
                            .then(async (resp) => {
                                const token = resp.data['access_token'],
                                    token_type = resp.data['token_type'],
                                    expires_in = resp.data['expires_in'],
                                    refresh_token = resp.data['refresh_token'];

                                axios.defaults.headers.common['authorization'] = token_type + ' ' + token;

                                new_token = token;
                                await commit('SAVE_TOKEN', token);
                                await commit('SAVE_TOKEN_TYPE', token_type);
                                await commit('SAVE_EXPIRES_IN', expires_in);
                                await commit('SAVE_REFRESH_TOKEN', refresh_token);
                                await commit('SAVE_AUTHORIZATION_DATE');
                                await dispatch('SOCKET_PUSH_CONNECT');
                                await dispatch('RETRY_FAILED_ACTIONS');
                            })
                            .catch(async () => {
                                await dispatch("FIX_LOGOUT_STATUS");
                                if (!location.pathname.includes('/auth')) {
                                    await dispatch("CLOSE_PANELS");
                                    await router.push(`${getters.LOCALE.base}/auth`);
                                }
                            })
                            .finally(async () => {
                                await commit("CHANGE_LOADING_REFRESH_TOKEN_STATUS", false);
                                await clearTimeout(can_refresh_timeout);
                                can_refresh_timeout = await setTimeout(() => can_refresh = true, 500);
                                resolve(new_token);
                            })
                    } else {
                        dispatch("FIX_LOGOUT_STATUS");
                        dispatch("CLOSE_PANELS");
                        router.push(`${getters.LOCALE.base}/auth`);
                        commit("CHANGE_LOADING_REFRESH_TOKEN_STATUS", false);
                        setTimeout(() => can_refresh = true, 500);
                        resolve();
                    }
                } else {
                    let interval = setInterval(() => {
                        if (can_refresh) {
                            clearInterval(interval);
                            resolve(state.token);
                        }
                    }, 150)
                }
            })
        }
    },
    mutations: {
        SAVE_TOKEN: (state, token) => state.token = token,
        SAVE_TOKEN_TYPE: (state, token_type) => state.token_type = token_type,
        SAVE_EXPIRES_IN: (state, expires_in) => state.expires_in = expires_in * 1000,
        SAVE_REFRESH_TOKEN: (state, refresh_token) => state.refresh_token = refresh_token,
        SAVE_AUTHORIZATION_DATE: (state) => state.authorization_date = Number(Date.now().toString()),
        SAVE_GOOGLE_AUTH: (state, boolean) => state.google_auth = boolean,
        SAVE_FACEBOOK_AUTH: (state, boolean) => state.facebook_auth = boolean,
        SAVE_APPLE_AUTH: (state, boolean) => state.apple_auth = boolean,
        CLEAR_AUTH: (state) => Object.assign(state, getDefaultState())
    }
}
