const getDefaultState = () => {
    return {
        word_panel: false,
        word_sentences_panel: false,
        sentences_panel: false,
        complaint_panel: false,
        favorite_panel: false,
        comments_panel: false,
        mobile_nav_panel: false,
        history_panel: false,
        wola_user_panel_nickname: null,
        friends_panel: false,
        blocked_panel: false,
        blocked_history_panel: false,
        friends_requests_panel: false,
        achievement_popup: null,
        accept_moderation_popup: null,
        localization_popup: false,
        news_popup: false,
        add_lang_popup: false,
        level_popup: null,
        image_popup: null,
        ban_popup: null,
        clear_history_popup: null,
        sentence_translations_popup: false,
        word_translations_popup: false,
        report_popup: false,
        wola_plus_popup: false,
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        CLOSE_WORD_PANEL({commit}, hard) {
            commit('CHANGE_WORD_PANEL', false)
            commit("CHANGE_FAVORITE_PANEL", false)
            commit('CHANGE_MEANINGS_LIST', false)

            if (hard) commit("CLEAR_DETAIL_WORD_PAGE")
        },
        CLOSE_PANELS({state, dispatch, commit, rootState}) {
            if (state.sentences_panel) commit("CHANGE_SENTENCES_PANEL", false);
            if (state.word_sentences_panel) commit("CHANGE_WORD_SENTENCES_PANEL", false);
            if (state.word_panel || state.favorite_panel) dispatch("CLOSE_WORD_PANEL");
            if (rootState.need_action.need_action_task_details) dispatch("CLEAR_NEED_ACTION_TASK_PANEL");
            if (rootState.statistics.statistics_detail_panel) commit('CHANGE_STATISTICS_DETAIL_PANEL', false);
            if (rootState.statistics.statistics_general_panel) commit('CHANGE_STATISTICS_GENERAL_PANEL', false);
            if (rootState.notifications.notifications_panel) commit("CLOSE_NOTIFICATIONS_PANEL");
        }
    },
    mutations: {
        CHANGE_WORD_PANEL: (state, boolean) => state.word_panel = boolean,
        CHANGE_WORD_SENTENCES_PANEL: (state, boolean) => state.word_sentences_panel = boolean,
        CHANGE_SENTENCES_PANEL: (state, boolean) => state.sentences_panel = boolean,
        CHANGE_COMPLAIN_PANEL: (state, boolean) => state.complaint_panel = boolean,
        CHANGE_FAVORITE_PANEL: (state, boolean) => state.favorite_panel = boolean,
        CHANGE_COMMENTS_PANEL: (state, boolean) => state.comments_panel = boolean,
        CHANGE_MOBILE_NAV_PANEL: (state, boolean) => state.mobile_nav_panel = boolean,
        CHANGE_LOCALIZATION_POPUP: (state, boolean) => state.localization_popup = boolean,
        CHANGE_HISTORY_PANEL: (state, boolean) => state.history_panel = boolean,
        CHANGE_BLOCKED_PANEL: (state, boolean) => state.blocked_panel = boolean,
        CHANGE_BLOCKED_HISTORY_PANEL: (state, data) => state.blocked_history_panel = data,
        CHANGE_CLEAR_HISTORY_POPUP: (state, boolean) => state.clear_history_popup = boolean,
        CHANGE_SENTENCE_TRANSLATIONS_POPUP: (state, boolean) => state.sentence_translations_popup = boolean,
        CHANGE_WORD_TRANSLATIONS_POPUP: (state, boolean) => state.word_translations_popup = boolean,
        CHANGE_NEWS_POPUP: (state, boolean) => state.news_popup = boolean,
        CHANGE_ADD_LANG_POPUP: (state, boolean) => state.add_lang_popup = boolean,
        CHANGE_WOLA_USER_PANEL_NICKNAME: (state, nickname) => state.wola_user_panel_nickname = nickname,
        CHANGE_ACHIEVEMENT_POPUP: (state, string) => state.achievement_popup = string,
        CHANGE_ACCEPT_MODERATION_POPUP: (state, string) => state.accept_moderation_popup = string,
        CHANGE_LEVEL_POPUP: (state, string) => state.level_popup = string,
        CHANGE_IMAGE_POPUP: (state, data) => state.image_popup = data,
        CHANGE_BAN_POPUP: (state, data) => state.ban_popup = data,
        CHANGE_REPORT_POPUP: (state, boolean) => state.report_popup = boolean,
        CHANGE_WOLA_PLUS_POPUP: (state, boolean) => state.wola_plus_popup = boolean,
        CHANGE_PANEL: (state, request) => state[request.panel] = request.boolean,
        CLEAR_PANELS: (state) => Object.assign(state, getDefaultState()),
    },
}
