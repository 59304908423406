const getDefaultState = () => {
    return {
        tutorial_screen: false,
        tutorial_screen_timeout: null,
        completed_tutorials: localStorage.getItem('completed_tutorials') ? JSON.parse(localStorage.getItem('completed_tutorials')) : {}
    }
}

export default {
    state: () => getDefaultState(),
    mutations: {
        CHANGE_TUTORIAL_SCREEN: (state, screen) => {
            if (state.tutorial_screen) {
                state.tutorial_screen = false
                clearTimeout(state.tutorial_screen_timeout)
                state.tutorial_screen_timeout = setTimeout(() => state.tutorial_screen = screen, 300)
            } else state.tutorial_screen = screen
        },
        CHECK_TUTORIAL_PASSED: (state, type) => {
            state.completed_tutorials['tutorial_' + type] = 'passed'
            localStorage.setItem('completed_tutorials', JSON.stringify(state.completed_tutorials))
        },
        CLEAR_TUTORIALS: (state) => {
            clearTimeout(state.tutorial_screen_timeout)
            state.tutorial_screen = false
        },
    },
}
