<template>
    <transition-group
            name="panel-transition"
            tag="div"
            style="z-index: 500"
            tabindex="0"
            @keydown.native.esc="closePanel"
    >
        <notifications
                v-if="NOTIFICATIONS_PANEL"
                :need_action_panel="!!NEED_ACTION_TASK_DETAILS"
                :user_panel="!!WOLA_USER_PANEL_NICKNAME || BLOCKED_PANEL || BLOCKED_HISTORY_PANEL"
                :detail_panel="!!POST_DETAILS || !!QUESTION_DETAILS"
                :comments_panel="!!SELECTION_COMMENTS_DETAIL || !!SELECTION_COMMENTS_DETAIL"
                :settings_panel="NOTIFICATIONS_SETTINGS_PANEL"
                key="notifications"
                class="panel-transition-item"
        />
        <notifications_settings
                v-if="NOTIFICATIONS_SETTINGS_PANEL"
                key="notifications_settings"
                class="panel-transition-item"
        />
        <panel_statistics_general
                v-if="STATISTICS_GENERAL_PANEL"
                key="statistics_general"
                class="panel-transition-item"
        />
        <panel_statistics_detail
                v-if="STATISTICS_DETAIL_PANEL"
                key="statistics_detail"
                class="panel-transition-item"
        />
        <panel_need_action
                v-if="!!NEED_ACTION_TASK_DETAILS"
                ref="panel_need_action"
                key="need_action_details"
                class="panel-transition-item"
                :hidden="!!POST_DETAILS || !!QUESTION_DETAILS || !!WOLA_USER_PANEL_NICKNAME"
                @close="closePanel"
        />
        <panel_post
                v-if="!!POST_DETAILS"
                ref="panel_post"
                :key="POST_DETAILS ? POST_DETAILS.uuid : 'panel_post'"
                class="panel-transition-item"
                @close="closePanel"
        />
        <panel_question
                v-else-if="!!QUESTION_DETAILS"
                ref="panel_question"
                :key="QUESTION_DETAILS ? QUESTION_DETAILS.uuid : 'panel_question'"
                class="panel-transition-item"
                @close="closePanel"
        />
        <panel_selection_comments
                v-if="!!SELECTION_COMMENTS_DETAIL"
                key="panel_selection_comments"
                class="panel-transition-item"
                @close="closePanel"
        />
        <panel_translation_sentences
                v-if="!WORD_PANEL && SENTENCES_PANEL"
                key="sentences_panel"
                class="panel-transition-item"
        />
        <panel_translation_word
                v-if="WORD_PANEL || FAVORITE_PANEL"
                key="word_panel"
                class="panel-transition-item"
                @close="closePanel"
        />
        <panel_translation_sentences
                v-if="WORD_SENTENCES_PANEL"
                key="word_sentences"
                word_sentences
                class="panel-transition-item"
                @closePanel="closePanel"
        />
        <panel_complain_entity
                v-if="COMPLAIN_PANEL"
                key="complain_panel"
                class="panel-transition-item"
                @closePanel="closePanel"
        />
        <panel_user_profile
                v-if="!!WOLA_USER_PANEL_NICKNAME"
                key="user_panel"
                :panels="!!BLOCKED_HISTORY_PANEL"
                class="panel-transition-item"
                @close="closePanel"
        />
        <panel_blocked_info
            v-if="BLOCKED_PANEL"
            key="blocked_info"
            class="panel-transition-item"
        />
        <panel_blocked_history
            v-else-if="BLOCKED_HISTORY_PANEL"
            key="blocked_history_info"
            class="panel-transition-item"
        />
    </transition-group>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "appPanels",
        components: {
            notifications: () => import("../../../views/notifications/Notifications"),
            notifications_settings: () => import("../../../views/notifications/NotificationsSettings.vue"),
            panel_user_profile: () => import("../../panels/panel_user_profile"),
            panel_need_action: () => import("../../panels/panel_need_action"),
            panel_translation_word: () => import("../../panels/panel_translation_word"),
            panel_translation_sentences: () => import("../../panels/panel_translation_sentences"),
            panel_complain_entity: () => import("../../panels/panel_complain_entity"),
            panel_statistics_general: () => import("../../panels/panel_statistics_general"),
            panel_statistics_detail: () => import("../../panels/panel_statistics_detail"),
            panel_selection_comments: () => import("../../panels/panel_selection_comments"),
            panel_post: () => import("../../panels/panel_post"),
            panel_question: () => import("../../panels/panel_question.vue"),
            panel_blocked_info: () => import("../../panels/panel_blocked_info.vue"),
            panel_blocked_history: () => import("../../panels/panel_blocked_history.vue"),
        },
        computed: {
            ...mapState({
                'NOTIFICATIONS_PANEL': state => state.notifications.notifications_panel,
                'NOTIFICATIONS_SETTINGS_PANEL': state => state.notifications.notifications_settings_panel,
                'WORD_PANEL': state => state.panels.word_panel,
                'WORD_SENTENCES_PANEL': state => state.panels.word_sentences_panel,
                'SENTENCES_PANEL': state => state.panels.sentences_panel,
                'COMPLAIN_PANEL': state => state.panels.complaint_panel,
                'FAVORITE_PANEL': state => state.panels.favorite_panel,
                'WOLA_USER_PANEL_NICKNAME': state => state.panels.wola_user_panel_nickname,
                'NEED_ACTION_TASK_DETAILS': state => state.need_action.need_action_task_details,
                'NEED_ACTION_AUDIO_DATA': state => state.need_action.need_action_audio_data,
                'STATISTICS_GENERAL_PANEL': state => state.statistics.statistics_general_panel,
                'STATISTICS_DETAIL_PANEL': state => state.statistics.statistics_detail_panel,
                'SELECTION_COMMENTS_DETAIL': state => state.learning.selection_comments_detail,
                'POST_DETAILS': state => state.feed.post_details,
                'QUESTION_DETAILS': state => state.feed.question_details,
                'BLOCKED_PANEL': state => state.panels.blocked_panel,
                'BLOCKED_HISTORY_PANEL': state => state.panels.blocked_history_panel,
            })
        },
        methods: {
            closePanel() {
                this.$emit('closePanel');
            }
        }
    }
</script>
