import Axios from 'axios';
import store from './store';
import axiosRetry from 'axios-retry';
// import UNFULFILLED_REQUESTS from "./assets/js/unfulfilled_requests";
import CANCEL_TOKENS from "./assets/js/cancel_tokens";
import {SUPPORTED_LOCALES} from "./assets/js/locales";

const
    token = () => store.state.auth.token,
    token_type = store.state.auth.token_type,
    ADDITION_ERRORS = new Map([
        ['VERIFICATION_IS_EXIST', 'error_complain_exists'],
        ['SENTENCE_EXIST', 'error_sample_exists'],
        ['WORD_SENTENCE_EXIST', 'error_sample_exists'],
        ['SENTENCE_TRANSLATION_EXIST', 'error_sample_exists'],
        ['EXIST_WORD_SENTENCE_TO_WS', 'error_sample_exists'],
        ['WORD_EXIST', 'Word exist'],
        ['FAVORITE_GROUP_UNIQUE_EXCEPTION', 'error_folder_exists'],
    ]),
    retryDelay = (retryNumber = 0) => {
        const seconds = Math.pow(2, retryNumber) * 1000;
        const randomMs = 1000 * Math.random();
        return seconds + randomMs;
    },
    getLocaleRegex = () => {
        let reg = [];
        SUPPORTED_LOCALES.forEach(locale => reg.push(locale.code));
        return reg;
    };

let update_moder_profile = false,
    has_error = false,
    internet_connection = true;

Axios.baseURL = store.state.other.dev_host ? store.state.cache.api : 'https://wola.io/api/v1';
Axios.timeout = 5000;

if (token()) Axios.defaults.headers.common['authorization'] = token_type + ' ' + token();

Axios.interceptors.request.use(async function (config) {
    let url = config.url;

    if (!url.includes('/api/v1/user/refresh-token')) {
        let need_update_token = await store.dispatch("CHECK_TOKEN_LIFE")
        if (need_update_token) {
            await store.dispatch('REFRESH_TOKEN').then(t => config.headers.authorization = 'Bearer ' + t)
        }

        if (!config.signal) {
            let controller = CANCEL_TOKENS.get(url);

            if (controller && (!config.data || (controller.data === JSON.stringify(config.data)))) controller.ab_control?.abort();

            controller = {
                "ab_control": new AbortController(),
                "data": JSON.stringify(config.data)
            }

            CANCEL_TOKENS.set(url, controller)

            config.signal = controller.ab_control.signal
        }
    }

    if (has_error) store.commit("CLOSE_ALL_ERRORS")
    if (!internet_connection) {
        internet_connection = true
        store.commit("SAVE_INTERNET_CONNECTION", true)
    }

    if (!config.headers['local'] && !url.includes('/api/v1/chat') && !url.includes('/api/v1/messages')) {
        let local = store.getters.LOCALE.dictionary_code
        if (!local) {
            let params = location.pathname.split('/')
            if (getLocaleRegex().includes(params[1])) {
                let l = store.state.languages.languages.find(l => l.code_two_signs === params[1])
                local = l ? l.code : 'eng'
            }
            else local = 'eng'
        }
        config.headers['local'] = local
    }

    return config;
});

Axios.interceptors.response.use(
    response => {
        has_error = false
        CANCEL_TOKENS.delete(response.config.url)
        return response
    },
    error => {
        has_error = true
        if (error.code === "ERR_CANCELED") {
            CANCEL_TOKENS.delete(error.config.url)
            // UNFULFILLED_REQUESTS.delete(error.message)
            throw new Error(error)
        } else if (error.message.includes('Request canceled')) {
            throw new Error(error)
        } else if (error.message === 'Network Error') {
            // UNFULFILLED_REQUESTS.set(error.config.url, error.config)
            if (navigator.onLine) {
                store.commit("CHANGE_ERROR_STATUS", {name: 'server', content: 'Network Error'})
            } else {
                internet_connection = false;
                store.commit("SAVE_INTERNET_CONNECTION", false)
            }
            throw new Error('Network Error')
        } else if (error.response) {
            if (error.response.status === 304) {
                return
            } else if (error.response.status === 401) {
                if (error.response.config.url.includes('/user/logout')) console.info('WOLA: logout');
                else store.dispatch("REFRESH_TOKEN");
            } else if (error.response.config.url.includes('/api/v1/education')) {
                throw new Error(error.response.data?.errors?.[0].code || 'Axios error')
            } else if (error.response.config.url.includes('/api/v1/questions')) {
                throw new Error(error.response.data?.errors?.[0].code || 'Axios error')
            } else if (error.response.config.url.includes('/api/v1/posts')) {
                throw new Error(error.response.data?.errors?.[0].code || 'Axios error')
            } else if (error.response.status === 400) {
                if (error.response.data.error?.code === 'FREE_CHAT_LIMIT_EXCEEDED') {
                    throw new Error(error.response.data.error.code || 'Axios error')
                } else if (error.response.data.errors[0].code === 'INVALID_LOGIN_OR_PASSWORD') {
                    store.dispatch('ERROR_SHOW', {name: 'auth', content: 'incorrect_login_or_password'})
                } else if (error.response.data.errors[0].code === 'USER_LOGIN_BLOCKED_EXCEPTION') {
                    store.dispatch('ERROR_SHOW', {name: 'auth', content: 'blocked_access_account'})
                } else if (error.response.data.errors[0].code === 'FAVORITE_GROUP_UNIQUE_EXCEPTION') {
                    store.dispatch("ERROR_SHOW", {name: 'system', content: 'error_folder_exists'})
                } else if (error.response.data.errors[0].code === 'ENTITY_IS_VERIFIED') {
                    return;
                } else if (error.response.data.errors[0].code === 'WORD_AUDIO_EXIST') {
                    store.dispatch("ERROR_SHOW", {name: 'system', content: 'error_audio_exists'})
                } else if (error.response.data.errors[0].code === 'ENTITY_HAS_UNVERIFIED_PARENTS') {
                    store.commit("SAVE_DISPUTE_PARENT_MATERIAL_POPUP", error.response.data.errors[0].payload)
                } else if ((error.response.data.errors[0].code === 'TRANSLATION_NOT_FOUND') && (error.response.config.method === 'post')) {
                    store.dispatch('ERROR_SHOW', {name: "addition", content: "error_change_remote_translation"})
                } else if ((error.response.data.errors[0].code === 'USER_NEW_LOCATION_EQUAL_WITH_OLD_LOCATION') && (error.response.config.method === 'patch')) {
                    return;
                } else {
                    let key = ADDITION_ERRORS.get(error.response.data.errors[0].code)
                    store.dispatch('ERROR_SHOW', {name: "addition", content: key || "server_error_title", url: error.response.config.url})
                }
            } else if (error.response.status === 403) {
                if (error.response.data?.errors?.[0].code === 'FORBIDDEN') {
                    if (!update_moder_profile) {
                        update_moder_profile = true;
                        if (error.response.config.url.includes('/api/v1/user/logout')) {
                            store.dispatch("GO_TO_PAGE", "Dictionary");
                        } else {
                            store.dispatch("LOAD_USER_INFO").then(() => {
                                store.dispatch("GO_TO_PAGE", "Dictionary");
                                setTimeout(() => update_moder_profile = false, 200);
                            })
                        }
                    }
                    return;
                } else if (!error.config.dont_show_error &&  store.state.other.dev_host) {
                    store.dispatch("ERROR_SHOW", {name: 'server', content: error.response.status, url: error.response.config.url})
                }
            } else if (error.response.status === 404) {
                if (error.response.data?.errors?.[0].code === 'TRANSLATION_NOT_FOUND') store.commit("SAVE_TRANSLATION_NOT_FOUND", true)
            } else if ([500, 502, 503, 504, 700].includes(error.response.status)) {
                if ([500, 502].includes(error.response.status)) console.error('WOLA: Server error')
                else if ([503].includes(error.response.status)) console.error('WOLA: Service Temporarily Unavailable')
                else if ([504, 700].includes(error.response.status)) console.error('WOLA: Server time out')

                // UNFULFILLED_REQUESTS.set(error.config.url, error.config)

                if (!error.config.dont_show_error) store.dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title', url: error.response.config.url});
            }
            throw new Error(error.response.data?.errors?.[0].code || 'Axios error')
        } else {
            console.info('WOLA: Axios error: ' + error)
        }

        return Promise.reject(error);
    }
);

axiosRetry(Axios, {
    retries: 2,
    retryDelay,
    retryCondition: axiosRetry.isRetryableError
})

export default Axios
