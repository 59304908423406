import Vue from 'vue';
import Vuex from 'vuex';
import {createLogger} from 'vuex'
import createMutationsSharer from "vuex-shared-mutations";
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
import UNFULFILLED_ACTIONS from "../assets/js/unfulfilled_actions";

import addition from './modules/addition';
import alerts from './modules/alerts';
import auth from './modules/auth';
import cache from './modules/cache';
import chat from './modules/chat';
import mini_dictionary from './modules/mini_dictionary';
import complaints from './modules/complaints';
import attributes from "./modules/attributes";
import detail_word_page from "./modules/detail_word_page";
import dictionary from "./modules/dictionary";
import errors from "./modules/errors";
import favorites from "./modules/favorites";
import feed from "./modules/feed";
import history from "./modules/history";
import languages from "./modules/languages";
import learning from "./modules/learning";
import lesson from "./modules/lesson";
import loadings from "./modules/loadings";
import moderation from "./modules/moderation";
import need_action from "./modules/need_action";
import news from "./modules/news";
import notifications from "./modules/notifications";
import other from "./modules/other";
import panels from "./modules/panels";
import profile from "./modules/profile";
import ratings from "./modules/ratings";
import statistics from "./modules/statistics";
import tasks from "./modules/tasks";
import tutorials from "./modules/tutorials";

const ls = new SecureLS({isCompression: false});

let plugins = [
    (store) => {
        store.subscribeAction({
            // before(action, state) {
            //     if (state.loadings.loading_refresh_token) UNFULFILLED_ACTIONS.set(action.type, action.payload)
            // },
            // after(action) {
            //     UNFULFILLED_ACTIONS.delete(action.type)
            // },
            error(action) {
                if (['SKIP_LESSON_WORDS', 'SEND_LESSON_WORDS'].includes(action.type)) {
                    UNFULFILLED_ACTIONS.set(action.type, action.payload)
                }
            }
        })
    },
    createPersistedState({
        key: 'briefcase',
        paths: [
            'auth',
            'attributes',
            'languages',
            'profile'
        ],
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        },
    }),
    createPersistedState({
        key: 'history',
        paths: [
            'history',
        ]
    }),
    createPersistedState({
        key: 'cache',
        paths: [
            'cache',
        ],
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        },
    }),
    createMutationsSharer({
        predicate: [
            "SAVE_TOKEN",
            "SAVE_TOKEN_TYPE",
            "SAVE_EXPIRES_IN",
            "SAVE_REFRESH_TOKEN",
            "SAVE_AUTHORIZATION_DATE",
            "SAVE_USER_LANG",
            "SAVE_USER",
            "SAVE_USER_PREMIUM",
            "CHANGE_UPDATED_PROFILE_DATA",
            "SAVE_UNREAD_NEWS_STATUS",
            "CHANGE_USER_MODERATION_STATUS",
            "CHANGE_SITE_THEME_PREFERS",
            "SAVE_NOTIFICATIONS_NEW_COUNT",
            "UPDATE_UNREAD_MESSAGES_STATUS",
            "CLEAR_NOTIFICATIONS",
            "CLEAR_CHAT",
        ]
    })
];

if (process.env.NODE_ENV !== 'production') {
    plugins.push(
        createLogger({
            collapsed: true, // автоматически раскрывать залогированные мутации
            // eslint-disable-next-line no-unused-vars
            filter(mutation, stateBefore, stateAfter) {
                // возвращает `true`, если мутация должна быть залогирована
                // `mutation` — это объект `{ type, payload }`
                return mutation.type !== 'aBlocklistedMutation';
            },
            // eslint-disable-next-line no-unused-vars
            actionFilter(action, state) {
                // аналогично `filter`, но для действий
                // `action` будет объектом `{ type, payload }`
                return action.type !== 'aBlocklistedAction'
            },
            transformer(state) {
                // обработать состояние перед логированием
                // например, позволяет рассматривать только конкретное поддерево
                return state.subTree;
            },
            mutationTransformer(mutation) {
                // мутации логируются в формате `{ type, payload }`,
                // но это можно изменить
                return mutation.type;
            },
            actionTransformer(action) {
                // аналогично `mutationTransformer`, но для действий
                return action.type
            },
            logActions: false, // логирование действий
            logMutations: false, // логирование мутаций
            logger: console // реализация API `console`, по умолчанию `console`
        })
    )
}

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        addition,
        alerts,
        auth,
        cache,
        chat,
        complaints,
        attributes,
        detail_word_page,
        dictionary,
        errors,
        favorites,
        feed,
        history,
        languages,
        learning,
        lesson,
        loadings,
        mini_dictionary,
        moderation,
        need_action,
        news,
        notifications,
        other,
        panels,
        profile,
        ratings,
        statistics,
        tasks,
        tutorials
    },
    plugins
})
