import axios from "axios";
import Vue from "vue";
import router from "../../router";

const getDefaultState = () => {
    return {
        tasks: [],
        tasks_ids: [],
        previews_task: null,
        previews_task_answer: null,
        previews_task_timeout: null,
        tasks_type: 'all',
        tasks_all_loaded: false,
        update_setting_languages: true,
        task_skip_id: null,
        task_continue_id: null
    }
}

let task_load_source = false;

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_TASKS({state, getters, commit}, type) {
            if (!task_load_source) {
                task_load_source = true;
                commit('CHANGE_LOADING_TASKS_STATUS', true);

                let filter = [],
                    exclude_task_ids = [],
                    // URL = `${Vue.prototype.$api_url_v2}/tasks?dev=1`,
                    URL = `${Vue.prototype.$api_url_v2}/tasks`,
                    types = getters.USER_HISTORY['tasks_setting_types'];

                if (state.tasks_type === 'all' && types && types.length < 17 && types.length > 0) filter = types;
                else if (state.tasks_type === 'audio') filter = ['add_word_audio', 'add_sentence_audio'];
                else if (state.tasks_type === 'translation') filter = ['chk_translation_union', 'chk_sentence_translation'];

                if (type === "load_up" && state.tasks_ids.length) exclude_task_ids = state.tasks_ids;

                await axios
                    .get(URL, {params: {filter, exclude_task_ids}})
                    .then(async response => {
                        let data = response.data.data;

                        await data.forEach(task => {
                            task['date_of_receipt'] = +new Date()
                            task['expired'] = false
                        })
                        await commit(type === "load_up" ? "LOAD_UP_TASKS" : "SAVE_TASKS", data)
                        if (response.data.data.length < 3) state.tasks_all_loaded = true
                    })
                    .finally(() => {
                        commit('CHANGE_LOADING_TASKS_STATUS', false)
                        task_load_source = false
                    })
            }
        },
        async POST_TASK_ACTION({state, commit}, request) {
            let post_task = (task_request) => {
                let data;

                if (task_request.translate) {
                    data = {word_name: task_request.translate}
                    if (task_request.attributes) data['attributes'] = task_request.attributes
                }
                else if (task_request.sentence) data = task_request.sentence
                else if (task_request.attributes) data = {
                    primary_word_attributes: task_request.attributes
                }
                else if (task_request.audio) {
                    data = new FormData();
                    data.append('audio', task_request.audio, 'audio.mp3');
                }

                axios
                    .post(`${Vue.prototype.$api_url}/tasks/${task_request.taskId}/${task_request.action_type}`, data)
                    .then(() => commit('REMOVE_TASK_ID', task_request.taskId))
            }

            if (state.previews_task_answer) {
                await clearTimeout(state.previews_task_timeout)
                await post_task(state.previews_task_answer);
                await commit('SAVE_PREVIEWS_TASK_ANSWER', null);
            }

            if (request.skip_after_adding) {
                await commit("SAVE_PREVIEWS_TASK_ANSWER", null);
                await post_task(request);
            } else if (request.action_type === 'add') {
                await commit("SAVE_PREVIEWS_TASK_ANSWER", null);
                await post_task(request);
            } else if (['yes', 'no', 'skip'].includes(request.action_type)) {
                await commit("SAVE_PREVIEWS_TASK_ANSWER", request);
                state.previews_task_timeout = setTimeout(async () => {
                    await post_task(state.previews_task_answer);
                    await commit('SAVE_PREVIEWS_TASK_ANSWER', null);
                }, 5000);
            }
        },
        async TASK_UPDATE_SETTING_LANGUAGES({state, commit, dispatch, rootState}) {
            if (state.update_setting_languages) {
                return new Promise((resolve, reject) => {
                    axios
                        .get(Vue.prototype.$api_url + '/tasks/lang-filter')
                        .then(async response => {
                            let array = []
                            if (response.data.data.length) {
                                await response.data.data.forEach(item => {
                                    if (item.lang.code !== rootState.profile.native_lang) array.push(item.lang.code)
                                })
                            }
                            await dispatch('UPDATE_USER_CONFIG', {
                                key: 'tasks_setting_languages',
                                value: array
                            })
                            await commit('CHANGE_UPDATE_SETTING_LANGUAGES', false)
                            await resolve(array)
                        })
                        .catch(err => reject(err))
                })
            }
        },
        async FILTER_TASKS_BY_RELEVANCE({state, commit}) {
            let actual_tasks,
                date_now = +new Date(), validity_period = 480000; // 8 minutes

            await state.tasks.forEach(task => {
                if ((date_now - task.date_of_receipt) > validity_period) task['expired'] = true
            })

            actual_tasks = await state.tasks.filter((t, i) => (t['expired'] === false) || (i === 0))

            await commit('SAVE_TASKS', actual_tasks)
            return Promise.resolve(actual_tasks.length)
        },
        LOAD_TASK_COMMENTS({commit}, data) {
            let params = {limit: 20};

            if (data.pagination_id) params['pagination_id'] = data.pagination_id;
            else if (data.complain) commit("CHANGE_COMPLAIN_ENTITY_COMMENTS_ALL_LOADED", false)
            else commit("CHANGE_NEED_ACTION_TASK_COMMENTS_ALL_LOADED", false)

            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/tasks/${data.type}/${data.id}/comment-logs`, {params})
                    .then(response => {
                        if (response.data.data.length < 20) commit("CHANGE_NEED_ACTION_TASK_COMMENTS_ALL_LOADED", true);
                        resolve(response.data.data)
                    })
                    .catch(err => {
                        if (err.message === 'TASK_NOT_FOUND') commit('SHOW_OVERDUE_TASK', data.id)
                        reject(err)
                    })
            })
        },
        POST_TASK_COMMENT({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url}/tasks/${payload.type}/${payload.id}/comment-logs`, payload.data)
                    .then((resp) => {
                        if (payload.resolve) {
                            // пользователь оставил комментарий
                            dispatch("ADD_NEW_NEED_ACTION_TASK_COMMENT", {
                                type: "task_comment",
                                comment: payload.data['comment'],
                                task_comment_uuid: resp.data.data['task_comment_uuid'],
                                resolve: true
                            }).then(comment => resolve(comment));
                        } else {
                            // пользователь оставил комментарий
                            dispatch("ADD_NEW_NEED_ACTION_TASK_COMMENT", {
                                type: "task_comment",
                                comment: payload.data['comment'],
                                task_comment_uuid: resp.data.data['task_comment_uuid'],
                            });
                            resolve();
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        EDIT_TASK_COMMENT({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${Vue.prototype.$api_url}/tasks/${payload.type}/${payload.id}/comment-logs/${payload.comment.task_comment_uuid}`, {comment: payload.new_comment})
                    .then(() => resolve())
                    .catch(() => reject())
                    .finally(() => commit)
            })
        },
        async DELETE_TASK_COMMENT({commit}, payload) {
            await axios.delete(`${Vue.prototype.$api_url}/tasks/${payload.type}/${payload.id}/comment-logs/${payload.comment_uuid}`)
            await commit("DELETE_TASK_COMMENT")
        },
        async UPDATE_TASKS_FILTER({getters, dispatch}, payload) {
            if (payload.languages) {
                await axios.post(Vue.prototype.$api_url + '/tasks/lang-filter', {'languages': payload.languages})
                await dispatch('UPDATE_USER_CONFIG', {key: 'tasks_setting_languages', value: payload.languages})
            }

            if (payload.types) {
                await dispatch('UPDATE_USER_CONFIG', {key: 'tasks_setting_types', value: payload.types})
            }

            await dispatch("LOAD_TASKS").then(() => router.push({name: 'TasksCards', params: {locale: getters.LOCALE.route_code}}))
        }
    },
    mutations: {
        SAVE_TASKS(state, tasks) {
            state.tasks = tasks
            state.tasks_ids = []
            let ids = []
            tasks.forEach(task => ids.push(task.task_id))
            state.tasks_ids = ids
        },
        LOAD_UP_TASKS(state, tasks) {
            state.tasks = state.tasks.concat(tasks)
            tasks.forEach(task => {
                if (!state.tasks_ids.includes(task.task_id)) state.tasks_ids.push(task.task_id)
            })
        },
        CHANGE_TASKS_TYPE(state, type) {
            state.tasks_type = type
        },
        SAVE_PREVIEWS_TASK_ANSWER(state, answer) {
            state.previews_task_answer = answer
        },
        CHANGE_UPDATE_SETTING_LANGUAGES(state, boolean) {
            state.update_setting_languages = boolean
        },
        ADD_TASK_COMMENTS(state) {
            state.tasks[0].comment_count += 1
            state.tasks[0].actions.push('continue')
        },
        DELETE_TASK_COMMENT(state) {
            state.tasks[0].comment_count -= 1
            state.tasks[0].actions = state.tasks[0].actions.filter(a => a !== 'continue')
        },
        REMOVE_TASK(state) {
            state.previews_task = state.tasks[0];
            state.tasks.shift();
        },
        REMOVE_TASK_ID(state, task_id) {
            let id_index = state.tasks_ids.findIndex(id => id === task_id)
            if (~id_index) state.tasks_ids.splice(id_index, 1)
        },
        SHOW_OVERDUE_TASK(state, id) {
            let taskIndex = state.tasks.findIndex(task => task.task_id === id)
            if (~taskIndex) state.tasks[taskIndex]['expired'] = true
        },
        SAVE_TASK_SKIP_ID(state, id) {
            state.task_skip_id = id
        },
        SAVE_TASK_CONTINUE_ID(state, id) {
            state.task_continue_id = id
        },
        RETURN_PREVIEWS_TASK(state) {
            clearTimeout(state.previews_task_timeout);
            if (state.previews_task && state.previews_task_answer) state.tasks.unshift(state.previews_task);
            state.previews_task = null;
            state.previews_task_answer = null;
        },
        REMOVE_OVERDUE_TASK(state, id) {
            let taskIndex = state.tasks.findIndex(task => task.task_id === id)
            if (~taskIndex) state.tasks.splice(taskIndex, 1)
        },
        UPDATE_TASKS_LOADED(state) {
            state.tasks_all_loaded = false
        },
        CLEAR_TASKS(state) {
            Object.assign(state, getDefaultState())
        }
    },
}
