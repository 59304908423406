import store from "../../store";
import i18n from "../../plugins/i18n";
import Vue from "vue";
import platform from './platform';

// const path = require('path');

let EmojiConvertor = require('emoji-js');
let emoji = new EmojiConvertor();
let tooltip_timeout;
let geo_loading = false;

emoji.img_sets.apple.path = '/images/apple-emoji/';
emoji.replace_mode = 'img';
emoji.supports_css = false;
emoji.include_alt = true;

export default {
    addToFavourites(id, type, additional_id) {
        return new Promise((resolve, reject) => {
            store.dispatch('ADD_FAVORITES', {id, type, additional_id})
                .then((resp) => {
                    resolve(resp)
                    if (resp) store.commit("ALERT_SHOW", {name: 'favorite'})
                })
                .catch(err => reject(err))
        })
    },
    chooseLang(lang, type) {
        let language
        if (lang?.code) language = lang
        else language = store.state.languages.languages.find(language => language.code === lang)
        store.dispatch(type === 'p' ? 'CHANGE_LANGUAGE' : 'CHANGE_LANGUAGE_T', language)
    },
    copyLink(text) {
        return new Promise(resolve => {
            let input = document.createElement("input");
            input.value = text

            if (navigator.clipboard) {
                navigator.clipboard
                    .writeText(input.value)
                    .then(() => share(input.value))
                    .catch(err => console.info('WOLA: Something went wrong', err));
            } else {
                input.style.display = 'block'
                input.select()
                document.execCommand("copy")
                input.style.display = 'none'
                share(input.value)
            }

            let share = (str) => {
                if (store.getters.MOBILE && !!navigator.share) {
                    navigator.share({url: str})
                }
                resolve()
            }
        })
    },
    deleteFavorite(id, ids) {
        return new Promise((resolve) => {
            store.dispatch('DELETE_FAVORITES', {id, ids}).then(() => resolve())
        })
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        function deg2rad(deg) {
            return deg * (Math.PI / 180)
        }

        let R = 6371; // Radius of the earth in km
        let dLat = deg2rad(lat2 - lat1);  // deg2rad below
        let dLon = deg2rad(lon2 - lon1);
        let a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c; // Distance in km
        return d;
    },
    detectGeoPosition() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                position => {
                    let do_it = true;

                    if (store.getters.USER_HISTORY.geolocation) {
                        let distance = this.getDistanceFromLatLonInKm(
                            position.coords.latitude,
                            position.coords.longitude,
                            store.getters.USER_HISTORY.geolocation.latitude,
                            store.getters.USER_HISTORY.geolocation.longitude
                        )

                        do_it = distance > 100
                    }

                    if (do_it && !geo_loading) {
                        geo_loading = true;

                        store
                            .dispatch("CHANGE_USER_GEOLOCATION", {
                                "latitude": position.coords.latitude,
                                "longitude": position.coords.longitude
                            })
                            .then(() => resolve())
                            .catch((error) => {
                                geo_loading = false
                                reject(error)
                            })
                    } else return resolve('stopped')
                },
                (error) => {
                    console.error(error)
                    if (error.PERMISSION_DENIED) {
                        geo_loading = false;
                        console.error("Location access denied!");
                        reject('PERMISSION_DENIED')
                    } else reject(error)
                })
        })
    },
    getAttribute(attributes, type, lit) {
        let attr,
            genus,
            attr_val,
            genus_val,
            val,
            attr_list = store.state.attributes.all_attributes;

        if (attributes) {
            if (Array.isArray(attributes)) {
                if (!attributes.length) return '';

                if (typeof attributes[0] === 'object') {
                    attr = attributes[0].attribute
                    if (attributes[1]) genus = attributes[1].attribute
                } else {
                    attr = attributes[0]
                    if (attributes[1]) genus = attributes[1]
                }

                attr_val = attr_list.find(a => a.code === attr)
                if (attributes[1]) genus_val = attr_list.find(a => a.code === genus)
            } else attr_val = attr_list.find(a => a.code === attributes)

            if (type === 'object') {
                if (typeof attr_val === 'object') val = attr_val
                else val = attr_list.find(a => a.code === attr_val)
            } else if (attr_val) {
                val = attr_val.value
                if (genus) val += `${lit ? ('<small>' + lit + '</small>') : ', '}${genus_val.value}`.toLowerCase()
            }

            if (attr_list.length) return val
            else return attr
        }
    },
    getNumericalFormat(n, type) {
        let f = [];

        if (type === 'meanings') f = ['meanings_format_1', 'meanings_format_2', 'meanings_format_3', 'meanings_format_4'];
        else if (type === 'age') f = ['age_format_1', 'age_format_2', 'age_format_3', 'age_format_4'];
        else if (type === 'sample') f = ['sample_format_1', 'sample_format_2', 'sample_format_3', 'sample_format_4'];
        else if (type === 'folder') f = ['folder_format_1', 'folder_format_2', 'folder_format_3', 'folder_format_4'];
        else if (type === 'word') f = ['word_format_1', 'word_format_2', 'word_format_3', 'word_format_4'];
        else if (type === 'sentence') f = ['sentence_format_1', 'sentence_format_2', 'sentence_format_3', 'sentence_format_4'];
        else if (type === 'more_requests') f = ['more_requests_format_1', 'more_requests_format_2', 'more_requests_format_3', 'more_requests_format_4'];
        else if (type === 'review') f = ['review_format_1', 'review_format_2', 'review_format_3', 'review_format_4'];

        return f[(n > 20 && /1$/.test(n)) ? 3 : (n % 100 > 4 && n % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(n % 10 < 5) ? n % 10 : 5]];
    },
    getLanguage(lang_code, key) {
        if (!lang_code) return '';

        let language;
        try {
            language = store.state.languages.languages.find(lang => lang.code === lang_code || lang.code_two_signs === lang_code);
        } catch (e) {
            language = lang_code;
        }

        if (language) {
            if (key) return language[key];
            else return language;
        } else return null;
    },
    getMsg(text, parse, no_link, icon) {
        let message = ''

        if (text) {
            if (!parse) message = text.replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt;");
            else message = text;
            message = platform.platform.isApple ? message : emoji.replace_unified(message);
            // if (icon) message = message.slice(0, -1) + ` alt="${text}" ` + message.slice(-1)
            if (!no_link && !icon) message = this.urlify(message);
        }

        return message
    },
    shuffleArray(array) {
        let currentIndex = array.length, randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }

        return array;
    },
    selectNicknames(text) {
        let message = ''
        if (text) {
            let array = text.split(" ")
            array = array.map(word => {
                if (word.includes('@')) return `<span class="semi-bold">${word}</span>`
                else return word
            })
            message = array.join(' ')
        }
        return message
    },
    urlify(text) {
        let urlRegex = /(https?:\/\/[^\s]+)/g;

        return text.replace(urlRegex, href => {
            let new_url = new URL(href),
                decoded = decodeURI(new_url.href);

            if (location.host === new_url.host) {
                let has_id = new_url.searchParams.get('translation_id')

                if (has_id && has_id.length) {
                    has_id = has_id.replace(/\D+/g, "")

                    let new_href = href.replace(/[\s.,]/g, ''),
                        word = new_url.searchParams.get('search');

                    return `<a href="${new_href}" target="_blank" class="word" data-id="${has_id}">${decodeURI(word)}</a>`
                }
                return `<a href="${href}" target="_blank">${decodeURI(decoded)}</a>`
            } else {
                return `<a href="${href}" target="_blank">${decodeURI(decoded)}</a>`
            }
        })
    },
    getSentences(word_name, lang, lang_t) {
        store.dispatch('LOAD_SENTENCES', {word_name, lang, lang_t})
    },
    getLocalString(path, slots, class_list) {
        let localMsg = i18n.t(path);
        if (slots.length) slots.forEach(word => {
            let w = String(word).replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt;");
            if (localMsg.includes('{word}')) localMsg = localMsg.replace('{word}', `<span class="${class_list}">${w}</span>`);
            else if (localMsg.includes('{number}')) localMsg = localMsg.replace('{number}', `<span class="${class_list}">${w}</span>`);
        });
        return localMsg;
    },
    getText(string, wordArray) {
        function sortMessageWordsArray(array) {
            array.forEach((item, index) => array[index] = item.toString())
            let uniqueArr = new Set(array);
            let decodeArray = Array.from(uniqueArr)

            decodeArray.forEach((position, index) => {
                let pos = position.split(',')
                decodeArray[index] = [Number(pos[0]), Number(pos[1])]
            })

            let done = false;
            while (!done) {
                done = true;
                for (let i = 1; i < decodeArray.length; i += 1) {
                    if (decodeArray[i - 1][0] > decodeArray[i][0]) {
                        done = false;
                        let tmp = decodeArray[i - 1];
                        decodeArray[i - 1] = decodeArray[i];
                        decodeArray[i] = tmp;
                    }
                }
            }

            return decodeArray;
        }

        if (wordArray.length) {
            let newString = '',
                newArr = sortMessageWordsArray(wordArray);

            newArr.forEach((position, i) => {
                if (i === 0) newString += string.slice(0, position[0])
                let word = `<span class="sentence-origin">${string.slice(position[0], position[1] + 1)}</span>`;
                newString += word
                if (i === newArr.length - 1) newString += string.slice(position[1] + 1)
                else newString += string.slice(position[1] + 1, newArr[i + 1][0])
            })
            return this.getMsg(newString, true);
        } else return string;
    },
    getVocabulary(code, properties) {
        let val = store.state.languages.vocabulary.find(attr => attr.code === code);

        if (val) {
            if (properties === 'all') return val;
            else if (properties) return val[properties];
            else return val.localized_value;
        } else return code;
    },
    async handleKeypress(e, text) {
        if (!e.shiftKey) {
            e.preventDefault()
            e.target.blur()
            await this.tooltipTranslate(text, store.state.languages.language.code)
        }
    },
    async langChange(text) {
        await store.commit('CHANGE_LANGUAGES')
        if (text) await this.translate(text, store.state.languages.language.code, store.state.languages.language_t.code)
    },
    async queryFromHistory(word, lang, lang_t) {
        await this.saveText(word)
        if (store.state.languages.language.code !== lang) await this.chooseLang(lang, 'p')
        if (store.state.languages.language_t.code !== lang_t) await this.chooseLang(lang_t, 't')
        await this.translate(word, lang, lang_t)
    },
    pageEventForAnalytic(name) {
        if (Vue.prototype.$analytics) {
            Vue.prototype.$analytics.logEvent(name);
        }
    },
    saveText(text) {
        sessionStorage.setItem('search_text', text)
    },
    tooltips(search, lang, lang_t) {
        this.saveText(search);
        clearTimeout(tooltip_timeout);
        tooltip_timeout = setTimeout(() => store.dispatch('LOAD_TOOLTIPS', {search, lang, lang_t}), 300);
    },
    tooltipTranslate(tooltip, lang) {
        let gt = store.state.languages
        if (gt.language.code !== lang) this.langChange(tooltip)
        else this.translate(tooltip, gt.language.code, gt.language_t.code)
    },
    translate(search, lang, lang_t) {
        if (search) return new Promise(resolve => {
            store.dispatch('LOAD_TRANSLATES', {search, lang, lang_t}).then(() => resolve())
        })
    }
}
