import axios from "axios";
import router from "../../router";
import Vue from "vue";
import { v4 as uuidv4 } from 'uuid';

let sourceTooltip, sourceTranslate;

const getDefaultState = () => {
    return {
        translates: [],
        filter_attr: [],
        tooltips: [],
        word: {},
        not_found: false,
        sentences_total_count: 0,
        sentences_limit_count: 15,
        translates_limit_count: 10,
        google_translate: '',
        google_translates_cache: [],
        primary_lang: sessionStorage.getItem('language_primary') || '',
        translation_lang: sessionStorage.getItem('language_translation') || '',
        last_translate_link: sessionStorage.getItem('last_translate_link') || '',
        link_copy_status: false
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_TRANSLATES({state, getters, commit, dispatch}, request) {
            if (sourceTranslate) sourceTranslate?.abort('translate')
            sourceTranslate = new AbortController();

            if (request.search && request.lang && request.lang_t) {
                commit("CHANGE_LOADING_DICTIONARY_STATUS", true);
                sessionStorage.setItem('dictionary_filter', 'all')
                sessionStorage.setItem('search_text', request.search)

                return new Promise((resolve, reject) => {
                    axios
                        .get(Vue.prototype.$api_url + "/translations", {
                            signal: sourceTranslate.signal,
                            params: {
                                "lang": request.lang,
                                "lang_t": request.lang_t,
                                "search": request.search
                            }
                        })
                        .then(async response => {
                            let data = response.data.data.length ? response.data.data : [];
                            commit("CLEAR_DETAIL_WORD_PAGE");
                            commit("SAVE_PRIMARY_LANG", request.lang);
                            commit("SAVE_TRANSLATION_LANG", request.lang_t);
                            commit("SAVE_SENTENCES_TOTAL_COUNT", response?.data.sentences_total_count);
                            sessionStorage.setItem('language_primary', request.lang);
                            sessionStorage.setItem('language_translation', request.lang_t);

                            // NOT FOUND
                            commit("SAVE_NOT_FOUND", data.length < 1);

                            // TRANSLATES
                            dispatch('UPDATE_SEARCH_WORD', {data, canonical: response.data.canonical})

                            if (!data.length) {
                                // LOAD TOOLTIPS
                                if (!state.tooltips.length) {
                                    await dispatch("LOAD_TOOLTIPS", {
                                        search: request.search,
                                        lang: request.lang,
                                        lang_t: request.lang_t
                                    })
                                }
                            } else commit('SAVE_TOOLTIPS', [])

                            // FINISHED LOADING
                            await resolve(data.length > 0)
                        })
                        .catch(async (err) => {
                            await commit("SAVE_NOT_FOUND", true);
                            await commit("SAVE_TRANSLATES", [])
                            dispatch("LOAD_GOOGLE_TRANSLATE", {
                                lang: request.lang,
                                lang_t: request.lang_t,
                                search: decodeURI(request.search),
                                dictionary: true
                            })
                            await reject(err)
                        })
                        .finally(async () => {
                            // HISTORY
                            let query = {
                                text: request.search,
                                lang: request.lang,
                                lang_t: request.lang_t,
                                id: uuidv4(request.search)
                            }
                            await dispatch("UPDATE_USER_QUERY_HISTORY", query)
                            await commit('SAVE_TRANSLATES_LIMIT_COUNT')

                            if (router.app._route.name !== 'Chat') {
                                let link = `/dictionary/${request.lang}/${request.lang_t}/${request.search}`
                                if ((getters.LOCALE.base + encodeURI(state.last_translate_link)) !== encodeURI(link) || !router.app._route.params.search) {
                                    await commit('SAVE_LAST_TRANSLATE_LINK', link)
                                    await router.push({
                                        name: 'DictionaryPage',
                                        params: {
                                            locale: getters.LOCALE.route_code,
                                            lang: request.lang,
                                            lang_t: request.lang_t,
                                            search: request.search
                                        }
                                    })

                                    if (!getters.MOBILE) {
                                        let input = document.querySelector('.dictionary-select-class');
                                        if (input) input.select();
                                    }
                                }
                            }
                            await commit("CHANGE_LOADING_DICTIONARY_STATUS", false)
                        })
                })
            }
        },
        async GET_ONE_TRANSLATE({commit}, id) {
            commit
            return new Promise((resolve, reject) => {
                if (id) {
                    axios
                        .get(Vue.prototype.$api_url + "/translations/" + id)
                        .then((response) => resolve(response.data.data))
                        .catch((err) => reject(err))
                } else reject()
            })
        },
        async UPDATE_SEARCH_WORD({commit}, payload) {
            let data = payload.data,
                canonical = payload.canonical;

            if (data.length) {
                let audios = [], attributes = [], transcriptions = [], filter = [], url = data[0].url_translate_detail;

                await data
                    .sort((a, b) => {
                        if (!a.is_this_user && b.is_this_user) return 1
                        else if (a.is_this_user && !b.is_this_user) return -1
                        else return 0
                    })
                    .sort((a, b) => {
                        if (!a.favorite && b.favorite) return 1
                        else if (a.favorite && !b.favorite) return -1
                        else return 0
                    })

                for (let word of data) {
                    let attributes_p = word.all_primary_attributes.map(a => a.attribute),
                        attributes_t = word.all_translation_attributes.map(a => a.attribute);

                    if (attributes_t[0]) {
                        if (!filter.includes(attributes_t[0])) filter.push(attributes_t[0])
                    } else if (!filter.includes('all')) filter.unshift('all')

                    for (let audio of word.primary_word.audios) {
                        if (!audios.find(word_audio => word_audio.id === audio.id)) audios.push(audio)
                    }
                    if (attributes_p.length && !attributes.find(r => r[0] === attributes_p[0] && r[1] === attributes_p[1])) {
                        attributes.push(attributes_p)
                    }
                    if (!transcriptions.length && word.primary_word.transcriptions[0]) transcriptions = word.primary_word.transcriptions[0]
                }

                let index_finish = url.lastIndexOf('/')
                url = url.slice(0, index_finish)

                await commit("SAVE_WORD", {
                    id: data[0].primary_word.id,
                    name: data[0].primary_word.name,
                    lang_code: data[0].primary_word.lang_code,
                    audios,
                    attributes,
                    transcriptions,
                    is_this_user: data[0].primary_word.is_this_user,
                    url_translate_page: url,
                    canonical: canonical
                });

                // FILTER ATTRIBUTES
                if (filter.length === 1) filter = []
                else if (!filter.includes('all')) filter.unshift('all')
                await commit("SAVE_FILTER", filter);
            } else {
                await commit("SAVE_WORD", {});
                await commit("SAVE_FILTER", []);
            }

            await commit("SAVE_TRANSLATES", data);
        },
        async LOAD_TOOLTIPS({commit}, request) {
            if (sourceTooltip) sourceTooltip?.abort('tooltips');
            sourceTooltip = new AbortController();

            if (request.search) {
                await axios
                    .get(Vue.prototype.$api_url + "/words/tooltips", {
                        signal: sourceTooltip.signal,
                        params: {
                            "lang": request.lang,
                            "lang_t": request.lang_t,
                            "search": request.search,
                            "limit": 6
                        }
                    })
                    .then(response => {
                        let data = response.data.data.filter(tooltip => !!tooltip.name)
                        commit("SAVE_TOOLTIPS", data || [])
                    })
                    .catch(err => err)
            } else await sessionStorage.removeItem('search_text')
        },
        async LOAD_GOOGLE_TRANSLATE({state, getters, commit, rootState}, request) {
            let tl, sl = 'auto', client = 'gtx', dt = 't', q = request.search,
                lang = await rootState.languages.languages.find(l => l.code === request.lang),
                lang_t = await rootState.languages.languages.find(l => l.code === request.lang_t);

            if (lang) sl = lang.code_two_signs;
            if (lang_t) tl = lang_t.code_two_signs;
            else tl = 'en';

            return new Promise((resolve, reject) => {
                let saved_gt = state.google_translates_cache.find(gt => (gt.source_text === q) && (gt.translate_lang === tl));

                if (saved_gt) {
                    if (request.dictionary) {
                        commit("SAVE_GOOGLE_TRANSLATE", saved_gt.translate)
                        resolve()
                    } else if (request.mini_dictionary) {
                        commit("SAVE_MINI_GOOGLE_TRANSLATE", saved_gt.translate)
                        resolve()
                    } else resolve({translate: saved_gt.translate, sl})
                } else if (lang_t) {
                    if (!getters.USER.premium && !request.important && !request.dictionary && !request.mini_dictionary) {
                        if (rootState.cache.google_translates_count >= 10) {
                            reject('WOLA_PLUS_LIMIT')
                            return;
                        }
                    }

                    commit("CHANGE_LOADING_GOOGLE_TRANSLATE_STATUS", true)

                    fetch('https://translate.googleapis.com/translate_a/single?' + new URLSearchParams({client, dt, sl, tl, q}))
                        .then(r => r.json())
                        .then(async resp => {
                            let translate = '',
                                sl = await rootState.languages.languages.find(l => l.code_two_signs === resp[2])?.code;

                            await resp[0]?.forEach(p => translate += p[0])

                            await commit("SAVE_GOOGLE_TRANSLATE_IN_CACHE", {source_text: q, translate_lang: tl, translate})
                            if (!getters.USER.premium) await commit("SAVE_GOOGLE_TRANSLATES_DAY")

                            if (request.dictionary) {
                                await commit("SAVE_GOOGLE_TRANSLATE", translate)
                                resolve()
                            } else if (request.mini_dictionary) {
                                await commit("SAVE_MINI_GOOGLE_TRANSLATE", translate)
                                resolve()
                            } else await resolve({translate, sl})
                        })
                        .catch((err) => reject(err))
                        .finally(() => commit("CHANGE_LOADING_GOOGLE_TRANSLATE_STATUS", false))
                } else resolve({translate: request.search, sl: request.lang_t})
            })
        },
        async TOGGLE_LIKE_IN_TRANSLATE({state, rootState}, request) {
            let likes_count,
                liked_by_user,
                translateIndex = state.translates.findIndex(item => item.id === request.id),
                updateTranslate = ({id, count, type}) => {
                    let findIndex = state.translates.findIndex(item => item.id === id)
                    if (~findIndex) {
                        state.translates[findIndex].likes_count = count
                        state.translates[findIndex].liked_by_user = (type === 'like')
                    }
                    if (rootState.detail_word_page.translate_info.id === id) {
                        rootState.detail_word_page.translate_info.likes_count = count
                        rootState.detail_word_page.translate_info.liked_by_user = (type === 'like')
                    }
                }

            if (~translateIndex) {
                likes_count = state.translates[translateIndex].likes_count
                liked_by_user = state.translates[translateIndex].liked_by_user
            }

            updateTranslate({
                "id": request.id,
                "count": request.type === 'like' ? (likes_count + 1) : (likes_count - 1),
                "type": request.type
            })

            await axios
                .post(Vue.prototype.$api_url + '/translations/' + request.id + '/' + request.type)
                .catch((err) => {
                    updateTranslate({
                        "id": request.id,
                        "count": likes_count,
                        "type": liked_by_user ? 'like' : 'dislike'
                    })
                    throw new Error(err)
                })
        },
        async UPDATE_SENTENCES_COUNT({state, commit}) {
            if (sourceTranslate) sourceTranslate?.abort('translate')
            sourceTranslate = new AbortController();

            if (state.primary_lang && state.translation_lang && sessionStorage.getItem('search_text')) {
                await axios
                    .get(Vue.prototype.$api_url + "/translations", {
                        signal: sourceTranslate.signal,
                        params: {
                            "lang": state.primary_lang,
                            "lang_t": state.translation_lang,
                            "search": sessionStorage.getItem('search_text')
                        }
                    })
                    .then(async response => commit("SAVE_SENTENCES_TOTAL_COUNT", response.data.sentences_total_count))
            }
        }
    },
    mutations: {
        SAVE_TRANSLATES: (state, translates) => state.translates = translates,
        SAVE_FILTER: (state, filters) => state.filter_attr = filters,
        SAVE_TOOLTIPS: (state, tooltips) => state.tooltips = tooltips,
        SAVE_WORD: (state, word) => state.word = word,
        SAVE_NOT_FOUND: (state, boolean) => state.not_found = boolean,
        SAVE_LINK_COPY_STATUS: (state, boolean) => state.link_copy_status = boolean,
        SAVE_LAST_TRANSLATE_LINK: (state, link) => {
            if (link) {
                sessionStorage.setItem('last_translate_link', encodeURI(link))
                state.last_translate_link = encodeURI(link)
            } else {
                sessionStorage.removeItem('last_translate_link')
                state.last_translate_link = ''
            }
        },
        SAVE_SENTENCES_TOTAL_COUNT: (state, count) => state.sentences_total_count = count,
        UPDATE_SENTENCES_TOTAL_COUNT: (state, count) => state.sentences_total_count += count,
        SAVE_TRANSLATES_LIMIT_COUNT: (state, count) => count ? state.translates_limit_count += count : state.translates_limit_count = 10,
        SAVE_GOOGLE_TRANSLATE: (state, translate) => state.google_translate = translate,
        SAVE_GOOGLE_TRANSLATE_IN_CACHE: (state, payload) => {
            state.google_translates_cache.push({
                id: new Date().toLocaleString(),
                source_text: payload.source_text,
                translate_lang: payload.translate_lang,
                translate: payload.translate,
            });
        },
        SAVE_PRIMARY_LANG: (state, lang) => state.primary_lang = lang,
        SAVE_TRANSLATION_LANG: (state, lang) => state.translation_lang = lang,
        REMOVE_NEW_TRANSLATE_TAG: (state, id) => {
            let translate = state.translates.find(item => item.id === id)
            if (translate && 'new_translate' in translate) delete translate['new_translate']
        },
        DELETE_DICTIONARY_TRANSLATE: (state, id) => {
            let ti = state.translates.findIndex(t => t.translation_union_id === id);
            if (~ti) state.translates.splice(ti, 1);
        },
        DELETE_AUDIO_FROM_TRANSLATIONS(state, id) {
            state.translates.forEach(t => {
                if (t.primary_word.audios) {
                    let p_a = t.primary_word.audios.findIndex(a => a.id === id);
                    if (~p_a) t.primary_word.audios.splice(p_a, 1);
                }
                if (t.translation_word.audios) {
                    let t_a = t.translation_word.audios.findIndex(a => a.id === id);
                    if (~t_a) t.translation_word.audios.splice(t_a, 1);
                }
            })
            if (state.word.audios) {
                let w_a = state.word.audios.findIndex(a => a.id === id);
                if (~w_a) state.word.audios.splice(w_a, 1);
            }
        },
        DELETE_MEANING_FROM_TRANSLATION: (state, id) => {
            state.translates.some(tr => {
                let pmi = tr.primary_meanings_descriptions.findIndex(m => m.id === id),
                    tmi = tr.translation_meanings_descriptions.findIndex(m => m.id === id);

                if (~pmi) {
                    tr.primary_meanings_descriptions.splice(pmi, 1);
                    tr.primary_meanings_descriptions_total_count -= 1;
                    return pmi;
                } else if (~tmi) {
                    tr.translation_meanings_descriptions.splice(tmi, 1);
                    tr.translation_meanings_descriptions_total_count -= 1;
                    return tmi;
                }
            })
        },
        REMOVE_TRANSLATE_FROM_FAVORITE: (state, id) => {
            let translate = state.translates.find(transl => transl.id === id);
            if (translate) translate.favorite = null;
        },
        CLEAR_DICTIONARY: (state) => Object.assign(state, getDefaultState()),
    },
}
