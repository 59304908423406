import Vue from 'vue';
import router from './router';
import App from './App.vue';
import store from './store';
import axios from './axios';
import i18n from './plugins/i18n';
import Vuelidate from 'vuelidate';
import * as Sentry from "@sentry/vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import {firebase_config} from './assets/js/firebase-config';
import {Integrations} from "@sentry/tracing";
import ClickOutside from './directives/ClickOutside';
import platform from './assets/js/platform';
import Cookies from 'js-cookie';
import Vlf from 'vlf';
import localforage from 'localforage';
import smoothscroll from 'smoothscroll-polyfill';
import {SUPPORTED_LOCALES} from "./assets/js/locales";
import AudioRecorder from "audio-recorder-polyfill";
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import './registerServiceWorker';
import package_json from '../package.json';
import w_popup from '@/components/by_wola/w_popup.vue';
import w_button from '@/components/by_wola/w_button.vue';

smoothscroll.polyfill();

const BRIEFCASE_VERSION = package_json.version;

if (store.state.other.dev_host) {
    Vue.prototype.$api_url = store.state.cache.api;
    Vue.prototype.$api_url_v2 = store.state.cache.api_v2;
    Vue.prototype.$api_url_chat = store.state.cache.api_chat;
    Vue.prototype.$chat_ai_uuid = 'be503a9c-1754-4c9c-9cf9-a03f1d577a34';
    Vue.prototype.$stripe_key = 'pk_test_51O4ddbCY06bZZxtX7H0M05iGy0vbbg2cVTmWHXAdbERAoAop0Yq9tcwctpcGWoFU6rGUeIFktn7YXSRxeo0Y9ypm00DTNbKjUT';
} else {
    Vue.prototype.$api_url = 'https://wola.io/api/v1';
    Vue.prototype.$api_url_v2 = 'https://wola.io/api/v2';
    Vue.prototype.$api_url_chat = 'https://chat.wola.io/api/v1';
    Vue.prototype.$chat_ai_uuid = 'f6dcc981-fa8c-4eb4-b800-bd6784564185';
    Vue.prototype.$stripe_key = 'pk_live_51O4ddbCY06bZZxtXLZ8GF2UqllJQbyNqExh8kCGlTVfYKdL591PJH8EKJjz3oWLYpJmm59XXaDcJMPnMdCp6bX3600YhR1NqMJ';
}

localforage.config({
    driver      : localforage.INDEXEDDB,
    name        : 'chatDB',
    version     : 1.0,
    storeName   : 'messages',
    description : 'local indexedDB chat store'
});

Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.use(Vuelidate);
Vue.use(Vlf, localforage);

Vue.prototype.$name = 'wola';
Vue.prototype.$web_version = BRIEFCASE_VERSION;
Vue.prototype.$chat_images = new Map();
Vue.prototype.$chat_voices = new Map();
Vue.prototype.$platform = platform.platform;
Vue.prototype.$random = platform.random;
Vue.prototype.$is_robot = platform.is_robot;
Vue.prototype.$is_webview = platform.is_webview;
Vue.prototype.$page_transition = {
    name: 'bounce',
    mode: '',
    setParams(n, m) {
        this.name = n;
        this.mode = m;
    }
};

if (!Vue.prototype.$platform.isApple) {
    AudioRecorder.encoder = mpegEncoder;
    AudioRecorder.prototype.mimeType = 'audio/mpeg';
    window.MediaRecorder = AudioRecorder;
}

// &#9203; - захардкоженный текст

Vue.component('w-button', w_button);
Vue.component('w-popup', w_popup);
Vue.component('w-audios', (resolve) => import('./components/by_wola/w_audios').then((w_audios) => resolve(w_audios.default)));
Vue.component('w-input', (resolve) => import('./components/by_wola/w_input').then((w_input) => resolve(w_input.default)));
Vue.component('w-textarea', (resolve) => import('./components/by_wola/w_textarea').then((w_textarea) => resolve(w_textarea.default)));
Vue.component('w-select', (resolve) => import('./components/by_wola/w_select').then((w_select) => resolve(w_select.default)));
Vue.component('w-languages-select', (resolve) => import('./components/by_wola/w_languages_select').then((w_languages_select) => resolve(w_languages_select.default)));
Vue.component('w-audio-button', (resolve) => import('./components/by_wola/w_audio_button').then((w_audio_button) => resolve(w_audio_button.default)));
Vue.component('w-blackout', (resolve) => import('./components/by_wola/w_blackout').then((w_blackout) => resolve(w_blackout.default)));
Vue.component('w-alert', (resolve) => import('./components/by_wola/w_alert').then((w_alert) => resolve(w_alert.default)));
Vue.component('w-flag', (resolve) => import('./components/by_wola/w_flag').then((w_flag) => resolve(w_flag.default)));
Vue.component('w-svg-icon', (resolve) => import('./components/by_wola/w_svg_icon').then((w_svg_icon) => resolve(w_svg_icon.default)));
Vue.component('w-img', (resolve) => import('./components/by_wola/w_img').then((w_img) => resolve(w_img.default)));
Vue.component('w-loading', (resolve) => import('./components/by_wola/w_loading').then((w_loading) => resolve(w_loading.default)));
Vue.component('w-loading-min', (resolve) => import('./components/by_wola/w_loading_min').then((w_loading_min) => resolve(w_loading_min.default)));
Vue.component('w-loading-list', (resolve) => import('./components/by_wola/w_loading_list').then((w_loading_list) => resolve(w_loading_list.default)));
Vue.component('w-observer', (resolve) => import('./components/by_wola/w_observer').then((w_observer) => resolve(w_observer.default)));
Vue.component('upload-error-server', (resolve) => import('./components/elements/upload_error_server').then((upload_error_server) => resolve(upload_error_server.default)));
Vue.directive('click-outside', ClickOutside);

store.commit("SAVE_WINDOW_WIDTH", document.documentElement.clientWidth)
store.dispatch("UPDATE_BRIEFCASE_VERSION", BRIEFCASE_VERSION)

document.addEventListener('renderer-ready', function handler() {
    setTimeout(() => store.commit("LOAD_SITE", false), 500)
    this.removeEventListener('renderer-ready', handler)
})

let local,
    cookieLocale = Cookies.get('cookie_localize'),
    storageLocale = localStorage.getItem('storage_localize'),
    getLocaleRegex = () => {
        let reg = [];
        SUPPORTED_LOCALES.forEach(locale => reg.push(locale.code));
        return reg;
    };

if (Vue.prototype.$is_webview) {
    let params = location.pathname.split('/');
    if (getLocaleRegex().includes(params[1])) {
        local = SUPPORTED_LOCALES.find(loc => loc.code === params[1]);
    }
} else if (cookieLocale) {
    local = SUPPORTED_LOCALES.find(loc => loc.code === cookieLocale);
} else if (storageLocale) {
    local = SUPPORTED_LOCALES.find(loc => loc.code === storageLocale)
} else if (!Vue.prototype.$is_robot) {
    let navigatorLanguage = window.navigator.language, index = navigatorLanguage.indexOf('-');
    if (~index) navigatorLanguage = navigatorLanguage.substr(0, index);
    local = SUPPORTED_LOCALES.find(loc => loc.code === navigatorLanguage.toLowerCase());
} else {
    let params = location.pathname.split('/');
    if (getLocaleRegex().includes(params[1])) {
        local = SUPPORTED_LOCALES.find(loc => loc.code === params[1]);
    }
}

if (!local) local = {code: 'en', base: '', route_code: undefined, dictionary_code: 'eng'};
store.commit("SAVE_LOCALE", local);

import(`/src/localizations/${local.code}.json`)
    .then((messages) => {
        i18n.setLocaleMessage(local.code, messages || {})
        i18n.locale = local.code
    })
    .catch((err) => console.error('WOLA:', err))

if (!store.getters.MOBILE) {
    let prefers_color_scheme = localStorage.getItem('user_theme');
    if (!prefers_color_scheme) prefers_color_scheme = getComputedStyle(document.documentElement).getPropertyValue('--w_wola_theme');
    if (prefers_color_scheme) store.commit("CHANGE_SITE_THEME_PREFERS", {theme: prefers_color_scheme});
} else if (Vue.prototype.$is_webview) {
    let theme_params = location.search.split(/\?|&/).find(p => p.includes('theme'))
    if (theme_params) document.documentElement.setAttribute('user-theme', theme_params.split('=')[1]);
}

if (process.env.NODE_ENV === 'production') {
    if (!store.getters.MOBILE) {
        document.body.oncontextmenu = () => {
            if (window.getSelection()) {
                let select = window.getSelection();
                return !!select.toString().length;
            } else return false;
        };
    }

    if (!store.state.other.dev_host) {
        firebase.initializeApp(firebase_config);
        firebase.analytics();

        Vue.prototype.$firebase = firebase;
        Vue.prototype.$analytics = firebase.analytics();
        Vue.prototype.$analytics.logEvent(store.getters.MOBILE ? "web_mobile" : "web_desktop");

        Sentry.init({
            Vue: Vue,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                })
            ],
            tracesSampleRate: 1.0,
        });
    }
}

new Vue({
    router,
    axios,
    store,
    i18n,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('renderer-ready'))
    }
}).$mount('#wola');
