import axios from "axios";
import Vue from "vue";

let sourceLightTranslate;

const getDefaultState = () => {
    return {
        mini_translates: [],
        mini_not_found: false,
        mini_primary_lang: sessionStorage.getItem('language_primary') || '',
        mini_translation_lang: sessionStorage.getItem('language_translation') || '',
        mini_google_translate: '',
        mini_search_text: '',
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_MINI_TRANSLATES({commit, dispatch, rootState}, request) {
            if (sourceLightTranslate) sourceLightTranslate?.abort('translate')
            sourceLightTranslate = new AbortController();

            if (request.search) {
                commit("CHANGE_LOADING_DICTIONARY_STATUS", true);

                return new Promise((resolve, reject) => {
                    axios
                        .get(Vue.prototype.$api_url + "/translations/light", {
                            signal: sourceLightTranslate.signal,
                            params: {
                                "lang": request.lang,
                                "lang_t": request.lang_t,
                                "search": request.search
                            }
                        })
                        .then(async response => {
                            let data = response.data.data?.length ? response.data.data : [];

                            await commit("SAVE_MINI_TRANSLATES", data);

                            // NOT FOUND
                            commit("SAVE_MINI_NOT_FOUND", data.length < 1);

                            if (!data.length) {
                                // LOAD TOOLTIPS
                                if (!rootState.dictionary.tooltips.length) {
                                    await dispatch("LOAD_TOOLTIPS", {
                                        search: request.search,
                                        lang: request.lang,
                                        lang_t: request.lang_t
                                    })
                                }
                            } else commit('SAVE_TOOLTIPS', [])

                            // FINISHED LOADING
                            await resolve(data.length > 0);
                            await commit("CHANGE_LOADING_DICTIONARY_STATUS", false);
                        })
                        .catch(async (err) => {
                            if (err.message.includes('CanceledError')) {
                                await reject(err);
                            } else {
                                await commit("SAVE_MINI_NOT_FOUND", true);
                                await commit("SAVE_MINI_TRANSLATES", []);
                                dispatch("LOAD_GOOGLE_TRANSLATE", {
                                    lang: request.lang,
                                    lang_t: request.lang_t,
                                    search: decodeURI(request.search),
                                    mini_dictionary: true
                                });
                                await reject(err);
                                await commit("CHANGE_LOADING_DICTIONARY_STATUS", false);
                            }
                        })
                })
            }
        },
    },
    mutations: {
        SAVE_MINI_TRANSLATES: (state, translates) => state.mini_translates = translates,
        SAVE_MINI_NOT_FOUND: (state, boolean) => state.mini_not_found = boolean,
        SAVE_MINI_GOOGLE_TRANSLATE: (state, translate) => state.mini_google_translate = translate,
        SAVE_MINI_PRIMARY_LANG: (state, lang) => state.mini_primary_lang = lang,
        SAVE_MINI_TRANSLATION_LANG: (state, lang) => state.mini_translation_lang = lang,
        SAVE_MINI_SEARCH_TEXT: (state, text) => state.mini_search_text = text,
        SAVE_NEW_MINI_TRANSLATE: (state, translate) => {
            state.mini_translates.push({
                all_primary_attributes: translate.all_primary_attributes,
                all_translation_attributes: translate.all_translation_attributes,
                favorite: translate.favorite,
                id: translate.id,
                is_verified: translate.is_verified,
                primary_word_audio: translate.primary_word.audios,
                primary_word_explanation: translate.primary_word_explanation,
                primary_word_name: translate.primary_word.name,
                translation_lang_code: translate.translation_lang_code,
                translation_union_id: translate.translation_union_id,
                translation_word_audio: translate.translation_word.audios,
                translation_word_explanation: translate.translation_word_explanation,
                translation_word_name: translate.translation_word.name,
                new_translate: true
            })
        },
        UPDATE_TRANSLATE_IN_MINI_DICTIONARY: (state, translate) => {
            let tri = state.mini_translates.findIndex(t => t.id === translate.id);

            if (~tri) {
                state.mini_translates.splice(tri, 1, {
                    all_primary_attributes: translate.all_primary_attributes,
                    all_translation_attributes: translate.all_translation_attributes,
                    favorite: translate.favorite,
                    id: translate.id,
                    is_verified: translate.is_verified,
                    primary_word_audio: translate.primary_word.audios,
                    primary_word_explanation: translate.primary_word_explanation,
                    primary_word_name: translate.primary_word.name,
                    translation_lang_code: translate.translation_lang_code,
                    translation_union_id: translate.translation_union_id,
                    translation_word_audio: translate.translation_word.audios,
                    translation_word_explanation: translate.translation_word_explanation,
                    translation_word_name: translate.translation_word.name,
                    is_this_user: true,
                    new_translate: true
                })
            }
        },
        REMOVE_NEW_MINI_TRANSLATE_TAG: (state, id) => {
            let translate = state.mini_translates.find(item => item.id === id)
            if (translate && 'new_translate' in translate) delete translate['new_translate']
        },
        REMOVE_MINI_TRANSLATE_FROM_FAVORITE: (state, id) => {
            let translate = state.mini_translates.find(transl => transl.id === id);
            if (translate) translate.favorite = null;
        },
        CLEAR_MINI_DICTIONARY_LIGHT: (state) => {
            state.mini_translates = []
            state.mini_not_found = false
            state.mini_google_translate = ''
            state.mini_search_text = ''
            sessionStorage.removeItem('search_text')
        },
        CLEAR_MINI_DICTIONARY: (state) => Object.assign(state, getDefaultState())
    },
}
