import i18n from "../../plugins/i18n";
import { v4 as uuidv4 } from 'uuid';

const getDefaultState = () => {
    return {
        query_history: [],
        users_history: {},
        mini_dictionary: false,
        history_update_key: 1
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        USER_HISTORY: (state, getters) => {
            state.history_update_key;
            return state.users_history[getters.USER.nickname] || {};
        },
        USER_LEARNING_LANG: (state, getters) => {
            return getters.USER_HISTORY.learning_lang || null;
        }
    },
    actions: {
        async UPDATE_USER_CONFIG({commit, rootState}, request) {
            await commit('SAVE_USER_CONFIG', {
                nickname: rootState.profile.user_info.nickname,
                key: request.key,
                value: request.value
            })
        },
        SAVE_USER_COMMENT_TEMPLATE({commit, rootState}, request) {
            commit('UPDATE_USER_COMMENT_TEMPLATES', {
                nickname: rootState.profile.user_info.nickname,
                template: request.template,
                type: request.type,
                index: request.index
            })
        },
        UPDATE_USER_QUERY_HISTORY: ({commit, rootState}, query) => {
            commit('UPDATE_HISTORY', {
                nickname: rootState.profile.user_info.nickname || '',
                query: query
            })
        },
        CLEAR_QUERY_HISTORY({getters, commit}) {
            if (getters.isLoggedIn) commit('CLEAR_USER_HISTORY', getters.USER.nickname)
            else commit('CLEAR_COMMON_HISTORY')
        },
        DELETE_HISTORY_QUERY({state, getters}, id) {
            let list = [];
            if (getters.isLoggedIn && ('query_history' in getters.USER_HISTORY)) list = state.users_history[getters.USER.nickname].query_history
            else list = state.query_history

            let index = list.findIndex(q => q.id === id)
            if (~index) list.splice(index, 1)

            state.history_update_key += 1;
        },
    },
    mutations: {
        SAVE_USER_IN_HISTORY: (state, nickname) => {
            if (!(nickname in state.users_history)) {
                state.users_history[nickname] = {
                    'tasks_tutorial': true,
                    'task_wola_comment': true,
                    'permission_not_verified': true,
                    'set_favorites_folder': false,
                    'query_history': []
                }
            }
        },
        SAVE_USER_CONFIG: (state, request) => {
            if (!('value' in request)) {
                delete state.users_history[request.nickname][request.key]
            } else {
                state.users_history[request.nickname][request.key] = request.value
            }

            state.history_update_key += 1;
        },
        async UPDATE_HISTORY(state, request) {
            let user = state.users_history[request.nickname],
                history = user ? user : state;

            history['query_history'] = await history['query_history'].filter(query => {
                return request.query.lang !== query.lang || request.query.lang_t !== query.lang_t || request.query.text !== query.text
            })

            history['query_history'].unshift(request.query)

            if (history['query_history'].length > 200) history['query_history'].length = 200

            state.history_update_key += 1;
        },
        async UPDATE_USER_COMMENT_TEMPLATES(state, request) {
            let user = state.users_history[request.nickname];

            if (request.type === 'start') {
                let id = 0,
                    def_temps = [],
                    keys = ['template_example_1', 'template_example_2', 'template_example_3', 'template_example_4', 'template_example_5', 'template_example_6', 'template_example_7'];

                for (let temp of keys) def_temps.push({
                    'id': ++id,
                    'value': i18n.t(temp)
                });
                if (def_temps.length) user['templates'] = def_temps;
            } else if (request.type === 'add') {
                user['templates'].push({
                    'id': uuidv4(request.template),
                    'value': request.template
                });
            } else {
                let index = user['templates'].findIndex(t => t.id === request.index);
                if (~index) user['templates'].splice(index, 1);
            }

            state.history_update_key += 1;
        },
        CLEAR_COMMON_HISTORY: (state) => state.query_history = [],
        CLEAR_USER_HISTORY: (state, nickname) => {
            state.users_history[nickname]['query_history'] = [];
            state.history_update_key += 1;
        },
        CLEAR_ONE_USER_SETTING: (state, setting_name) => {
            for (let config in state.users_history) {
                if (setting_name === 'tasks_setting_types') {
                    let condition1 = state.users_history[config][setting_name]?.includes('add_translation_sentence'),
                        condition2 = state.users_history[config][setting_name]?.includes('chk_translation_sentence');
                    if (condition1 || condition2) delete state.users_history[config][setting_name]
                } else delete state.users_history[config][setting_name]
            }
            state.history_update_key += 1;
        },
        CLEAR_USER_IN_HISTORY: (state, nickname) => delete state.users_history[nickname],
        CHANGE_MINI_DICTIONARY: (state, boolean) => {
            if (boolean == null) state.mini_dictionary = !state.mini_dictionary
            else state.mini_dictionary = Boolean(boolean)
        },
        CLEAR_HISTORY: (state) => Object.assign(state, getDefaultState())
    },
}
