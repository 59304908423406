import Vue from "vue";
import axios from "axios";
import router from "@/router";

const getDefaultState = () => {
    return {
        edited_selection: null,
        selection: null,
        selection_unions_limit: 20,
        selection_unions_limit_max: 500,
        selection_unions_all_loaded: false,
        learning_daily_activity: {},
        learning_statistics: {},
        selection_edit_status: false,
        deleted_selection_words_ids: [],
        added_selection_words: [],
        selections_list: [],
        videos_list: [],
        selections_list_limit: 18,
        selections_list_all_loaded: false,
        videos_list_all_loaded: false,
        learning_know_lang: null,

        search_selections_list: [],
        search_selections_list_limit: 18,
        search_selections_list_all_loaded: false,
        search_selections_list_text: '',
        search_selections_list_filter: {
            'complexity': 'all',
            'words_range_from': 6,
            'words_range_to': 500,
            'subject': {},
        },
        search_videos_list: [],
        search_videos_list_all_loaded: false,

        selection_all_ids: [],

        deleted_selection_data: null,

        video: null,
        selection_comments_detail: null,
        selection_comments_list: [],
        selection_comments_list_all_loaded: false,

        learning_materials_source: 'all',
        learning_materials_type: 'selections',
        learning_materials_filter: {
            'complexity': 'all',
            'words_range_from': 6,
            'words_range_to': 500,
            'subject': {},
        },
        learning_materials_selections_count: null,
        learning_materials_videos_count: null,
        learning_materials_list: [],
        learning_materials_list_all_loaded: false
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        LEARNING_KNOW_LEARNING_LANG: state => state.learning_know_lang
    },
    actions: {
        async GET_LEARNING_KNOW_LANGUAGE({commit}) {
            await axios
                .get(`${Vue.prototype.$api_url}/education/user-language`)
                .then(resp => commit("SAVE_LEARNING_KNOW_LANGUAGE", resp.data.data.lang_code))
        },
        async POST_LEARNING_KNOW_LANGUAGE({dispatch, commit}, lang) {
            await axios
                .post(`${Vue.prototype.$api_url}/education/user-language`, {lang_code: lang.code})
                .then(async resp => {
                    await commit("SAVE_LEARNING_KNOW_LANGUAGE", resp.data.data.lang_code);
                    await dispatch("LOAD_USER_LANG");
                    commit("CHANGE_UPDATE_SETTING_LANGUAGES", true);
                    commit("CLEAR_TASKS");
                    commit("CLEAR_MODERATION_TASKS");
                })
        },
        async CREATE_SELECTION({state, commit}, payload) {
            await commit("CHANGE_LOADING_ADDITION_STATUS", true);
            await axios
                .post(`${Vue.prototype.$api_url}/education/selections`, payload)
                .then(async (resp) => {
                    let selection = resp.data.data;
                    await commit("SAVE_SELECTION", selection);
                    await commit("SAVE_SELECTION_TO_FEED", selection);
                    await commit("MATERIALS_UPDATE");
                    if (state.learning_materials_list.length) {
                        await commit("SAVE_LEARNING_MATERIALS_LIST", []);
                        await commit("CHANGE_LEARNING_MATERIALS_LIST_ALL_LOADED", false);
                    }
                    await router.replace({name: 'TrainingSelection', query: {uuid: selection.uuid}});
                })
                .finally(() => {
                    commit("CHANGE_LOADING_ADDITION_STATUS", false);
                })
        },
        async EDIT_SELECTION({state, dispatch, commit}, payload) {
            await commit("CHANGE_LOADING_ADDITION_STATUS", true);
            await axios
                .put(`${Vue.prototype.$api_url}/education/selections/${state.edited_selection.uuid}`, payload)
                .then((resp) => {
                    let sel = resp.data.data;
                    if (sel.unions) delete sel.unions;
                    commit("UPDATE_SELECTION", sel);
                    dispatch("CLOSE_PAGE_BACK");
                    commit("ALERT_SHOW", {name: 'learning', content: 'compilation_saved', duration: 500});
                })
                .finally(() => {
                    commit("CHANGE_LOADING_ADDITION_STATUS", false);
                })
        },
        async DELETE_SELECTION({dispatch, commit}, payload) {
            await axios
                .delete(`${Vue.prototype.$api_url}/education/${payload.type}/${payload.uuid}`)
                .then(async () => {
                    await commit("DELETE_SELECTION_FROM_LEARNING_MATERIALS", payload.uuid);
                    await commit("DELETE_POSITION_FROM_FEED", payload.uuid);
                    await commit("SAVE_DELETED_FEED_ITEMS", [payload.uuid]);

                    if (payload.type === 'selections') {
                        await commit("DELETE_SELECTION_FROM_LIST", payload.uuid);
                        await commit("ALERT_SHOW", {
                            name: 'learning',
                            content: 'selection_is_deleted',
                            duration: 500
                        });
                    } else {
                        await commit("DELETE_VIDEO_FROM_LIST", payload.uuid);
                        await commit("ALERT_SHOW", {
                            name: 'learning',
                            content: 'video_is_deleted',
                            duration: 500
                        });
                    }
                })
                .catch(() => {
                    dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'});
                })
        },
        GET_SELECTION({state, dispatch, commit, rootState}, uuid) {
            return new Promise((resolve, reject) => {
                let params = {'limit': state.selection_unions_limit};

                if (uuid) {
                    if (uuid === state.selection?.uuid) {
                        if (uuid in rootState.cache.selections_edit_map) dispatch("CHANGE_SELECTION_EDIT_STATUS", true);
                        resolve(state.selection);
                        return;
                    }
                    commit("CHANGE_LOADING_SELECTION", true);
                    commit("SAVE_SELECTION", null);
                    commit("CHANGE_SELECTION_UNIONS_ALL_LOADED", false);
                } else {
                    params['pagination_id'] = state.selection?.unions[state.selection.unions.length - 1].pagination_id
                }

                let section_uuid = uuid || state.selection?.uuid;

                if (section_uuid) {
                    axios
                        .get(`${Vue.prototype.$api_url}/education/selections/${section_uuid}`, {params})
                        .then((resp) => {
                            let s = resp.data.data;

                            if (!s.unions) s['unions'] = []

                            if (!s.unions.length || (s.unions.length < state.selection_unions_limit) || (s.unions.length >= state.selection_unions_limit_max)) {
                                commit("CHANGE_SELECTION_UNIONS_ALL_LOADED", true);
                            }

                            if (uuid) {
                                commit("SAVE_SELECTION", s);
                            } else if (s.unions.length) {
                                commit("SAVE_SELECTION_UNIONS", {unions: s.unions});
                            }

                            if (section_uuid in rootState.cache.selections_edit_map) {
                                dispatch("CHANGE_SELECTION_EDIT_STATUS", true)
                            }

                            resolve(s)
                        })
                        .catch(err => {
                            console.error(err);
                            reject(err);
                        })
                        .finally(() => {
                            commit("CHANGE_LOADING_SELECTION", false);
                        })
                } else reject('Not found selection.uuid');
            })
        },
        async CHANGE_SELECTION_STUDY({state, commit}, timeout) {
            const
                method = state.selection.additional.selected_for_learning ? 'delete' : 'post',
                bool = method === 'post',
                uuid = state.selection.uuid;

            await axios({
                method: method,
                url: `${Vue.prototype.$api_url}/education/selections/${uuid}/study`
            }).then(async () => {
                setTimeout(() => {
                    commit("UPDATE_SELECTION_STUDY", {uuid: uuid, value: bool});
                    if (bool) commit("ALERT_SHOW", {name: 'learning', content: 'compilation_selected_for_learning'});
                }, timeout)
            }).catch(e => {
                throw Error(e)
            })
        },
        async GET_SELECTION_ALL_IDS({state, dispatch, commit}) {
            return new Promise((resolve, reject) => {
                if (state.selection.unions.length) {
                    axios
                        .get(`${Vue.prototype.$api_url}/education/selections/${state.selection.uuid}/contents/enumeration`)
                        .then(async (resp) => {
                            await commit("SAVE_SELECTION_ALL_IDS", resp.data.data);
                            await commit("UPDATE_EDITED_LISTS");
                            resolve();
                        })
                        .catch(err => {
                            console.error(err);
                            if (state.selection_edit_status) dispatch("CHANGE_SELECTION_EDIT_STATUS", false);
                            reject(err);
                        })
                } else {
                    commit("SAVE_SELECTION_ALL_IDS", []);
                    resolve();
                }
            })
        },
        async RESET_SELECTION({state, dispatch, commit}) {
            const d_uuid = state.selection.uuid
            await axios
                .delete(`${Vue.prototype.$api_url}/education/statistics/selection/${d_uuid}`, {
                    'dont_show_error': true
                })
                .then(() => commit("SAVE_RESET_SELECTION"))
                .catch((err) => {
                    dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'});
                    throw new Error(err)
                })
        },

        async SAVE_SELECTION_CHANGES({state, dispatch, commit}) {
            await commit("UPDATE_EDITED_LISTS");

            if (!state.added_selection_words.length && !state.deleted_selection_words_ids.length) return;
            else {
                await commit("CHANGE_LOADING_SELECTION_EDIT", true);

                let payload = {}

                if (state.added_selection_words.length) payload['add_union_ids'] = state.added_selection_words.map(w => w.union.id);
                if (state.deleted_selection_words_ids.length) payload['delete_union_ids'] = state.deleted_selection_words_ids;

                await axios
                    .put(`${Vue.prototype.$api_url}/education/selections/${state.selection.uuid}/contents`, payload)
                    .then(async () => {
                        const selection_edited_list = state.added_selection_words
                            .map(w => w)
                            .concat(state.selection.unions)
                            .filter(w => !state.deleted_selection_words_ids.includes(w.union.id))

                        await commit("UPDATE_SELECTION_WORDS_COUNT", state.added_selection_words.length - state.deleted_selection_words_ids.length);
                        await dispatch("CHANGE_SELECTION_EDIT_STATUS", false);
                        await commit("SAVE_SELECTION_UNIONS", {unions: selection_edited_list, type: 'update'});
                        await commit("ALERT_SHOW", {name: 'learning', content: 'compilation_saved', duration: 300});
                        await commit("SAVE_SELECTION_EDIT_MAP", {uuid: state.selection.uuid})
                    })
                    .catch(err => console.error(err))
                    .finally(() => commit("CHANGE_LOADING_SELECTION_EDIT", false))
            }
        },
        async GET_SELECTIONS_LIST({state, getters, commit}, payload) {
            return new Promise((resolve, reject) => {
                let do_action = false,
                    selection_type = payload.type === 'selections',
                    list = state[selection_type ? 'selections_list' : 'videos_list'],
                    params = {limit: state.selections_list_limit};

                if (selection_type) {
                    params['lang_from'] = getters.LEARNING_KNOW_LANGUAGE;
                    params['lang_to'] = getters.USER_LEARNING_LANG;
                } else {
                    params['lang_code'] = getters.USER_LEARNING_LANG;
                }

                if (payload.update) {
                    do_action = true;

                    if (selection_type) {
                        commit("CHANGE_SELECTIONS_LIST_ALL_LOADED", false);
                        commit("SAVE_SELECTIONS_LIST", []);
                    } else {
                        commit("CHANGE_VIDEOS_LIST_ALL_LOADED", false);
                        commit("SAVE_VIDEOS_LIST", []);
                    }
                } else if (list.length > 0) {
                    do_action = true;

                    let p_uuid, n_uuid, o_uuid, last = list[list.length - 1], last_pop, last_new, penult = list[list.length - 2], penult_pop, penult_new;

                    if (selection_type) {
                        last_pop = last.additional.labels.includes("popular");
                        last_new = last.additional.labels.includes("new");
                        penult_pop = penult.additional.labels.includes("popular");
                        penult_new = penult.additional.labels.includes("new");
                    } else {
                        last_pop = last.labels.includes("popular");
                        last_new = last.labels.includes("new");
                        penult_pop = penult.labels.includes("popular");
                        penult_new = penult.labels.includes("new");
                    }

                    if (last_pop && last_new) {
                        p_uuid = last.uuid;
                        n_uuid = last.uuid;
                    } else if (last_new && penult_pop) {
                        p_uuid = penult.uuid;
                        n_uuid = last.uuid;
                    } else if (last_pop && penult_new) {
                        p_uuid = last.uuid;
                        n_uuid = penult.uuid;
                    } else if (last_new && !last_pop && !penult_pop) {
                        n_uuid = last.uuid;
                    } else if (last_pop && !last_new && !penult_new) {
                        p_uuid = last.uuid;
                    } else {
                        o_uuid = last.uuid;
                    }

                    if (p_uuid) params['popular_pagination_uuid'] = p_uuid;
                    if (n_uuid) params['new_pagination_uuid'] = n_uuid;
                    if (o_uuid) params['old_unpopular_pagination_uuid'] = o_uuid;
                }

                if (do_action) {
                    axios
                        .get(`${Vue.prototype.$api_url}/education/${payload.type}/feed`, {params})
                        .then((resp) => {
                            let data = resp.data.data;

                            if (selection_type) {
                                if (data.length < state.selections_list_limit) commit("CHANGE_SELECTIONS_LIST_ALL_LOADED", true);
                                if (payload.update || !list.length) commit("SAVE_SELECTIONS_LIST", data);
                                else commit("SAVE_SELECTIONS_LIST", list.concat(data));
                            } else {
                                if (data.length < state.selections_list_limit) commit("CHANGE_VIDEOS_LIST_ALL_LOADED", true);
                                if (payload.update || !state.videos_list.length) commit("SAVE_VIDEOS_LIST", data);
                                else commit("SAVE_VIDEOS_LIST", state.videos_list.concat(data));
                            }

                            resolve();
                        })
                        .catch(err => {
                            console.error(err);
                            reject(err);
                        })
                } else resolve();
            })
        },
        async SEARCH_SELECTIONS({state, getters, commit}, payload) {
            let selection_type = payload.type === 'selections';
            let list = selection_type ? state.search_selections_list : state.search_videos_list;

            let params = {limit: state.selections_list_limit}

            if (selection_type) {
                params['lang_from'] = getters.LEARNING_KNOW_LANGUAGE;
                params['lang_to'] = getters.USER_LEARNING_LANG;
                params['offset'] = payload.update ? 0 : list.length;

                if (payload.word_names) params['word_names'] = payload.word_names;
                else if (payload.selection_name) params['selection_name'] = payload.selection_name;

                if (payload.update) await commit("CHANGE_SEARCH_SELECTIONS_LIST_ALL_LOADED", false);
            } else {
                params['lang_code'] = getters.USER_LEARNING_LANG;
                if (!payload.update && list.length) params['previous_uuid'] = list[list.length - 1].uuid;

                if (payload.selection_name) params['video_name'] = payload.selection_name;

                if (payload.update) await commit("CHANGE_SEARCH_VIDEOS_LIST_ALL_LOADED", false);
            }

            if (state.search_selections_list_filter.complexity !== 'all') params['complexity'] = state.search_selections_list_filter.complexity
            if (state.search_selections_list_filter.words_range_from > 6) params['words_range_from'] = state.search_selections_list_filter.words_range_from
            if (state.search_selections_list_filter.words_range_to < 500) params['words_range_to'] = state.search_selections_list_filter.words_range_to
            if (state.search_selections_list_filter.subject.code) params['subjects'] = [state.search_selections_list_filter.subject.code]

            await axios
                .get(`${Vue.prototype.$api_url}/education/${payload.type}/search`, {params})
                .then((resp) => {
                    let data = resp.data.data.rows;

                    if (selection_type) {
                        if (data.length < state.selections_list_limit) commit("CHANGE_SEARCH_SELECTIONS_LIST_ALL_LOADED", true);
                        if (payload.update || !list.length) commit("SAVE_SEARCH_SELECTIONS_LIST", data);
                        else commit("SAVE_SEARCH_SELECTIONS_LIST", list.concat(data));
                    } else {
                        if (data.length < state.selections_list_limit) commit("CHANGE_SEARCH_VIDEOS_LIST_ALL_LOADED", true);
                        if (payload.update || !list.length) commit("SAVE_SEARCH_VIDEOS_LIST", data);
                        else commit("SAVE_SEARCH_VIDEOS_LIST", list.concat(data));
                    }
                })
                .catch(err => console.error(err))
        },
        async GET_MATERIALS_SELECTIONS_LIST({state, commit}, payload) {
            return new Promise((resolve, reject) => {
                let params = {
                    limit: state.selections_list_limit,
                    offset: 0
                };

                params["source"] = state.learning_materials_source

                if (!payload.for_info) {
                    if (payload.update) {
                        commit("SAVE_LEARNING_MATERIALS_LIST", []);
                        commit("CHANGE_LEARNING_MATERIALS_LIST_ALL_LOADED", false);
                    } else if (state.learning_materials_list.length > 0) {
                        if (state.learning_materials_type === 'selections') {
                            params['offset'] = state.learning_materials_list.length;
                        } else {
                            params['previous_uuid'] = state.learning_materials_list[state.learning_materials_list.length - 1].uuid;
                        }
                    }
                }

                if (state.learning_materials_filter.complexity !== 'all') params['complexity'] = state.learning_materials_filter.complexity
                if (state.learning_materials_filter.subject.code) params['subjects'] = [state.learning_materials_filter.subject.code]

                if (state.learning_materials_type === 'selections') {
                    if (state.learning_materials_filter.words_range_from > 6) params['words_range_from'] = state.learning_materials_filter.words_range_from
                    if (state.learning_materials_filter.words_range_to < 500) params['words_range_to'] = state.learning_materials_filter.words_range_to
                }

                axios
                    .get(`${Vue.prototype.$api_url}/education/${payload.for_info || state.learning_materials_type}/search/users`, {params})
                    .then((resp) => {
                        if (payload.for_info) {
                            if (payload.for_info === 'selections') {
                                commit("SAVE_LEARNING_MATERIALS_SELECTIONS_COUNT", resp.data.data.count);
                            } else {
                                commit("SAVE_LEARNING_MATERIALS_VIDEOS_COUNT", resp.data.data.count);
                            }
                        } else {
                            if (state.learning_materials_type === 'selections') {
                                commit("SAVE_LEARNING_MATERIALS_SELECTIONS_COUNT", resp.data.data.count);
                            } else {
                                commit("SAVE_LEARNING_MATERIALS_VIDEOS_COUNT", resp.data.data.count);
                            }

                            let selections = resp.data.data.rows;

                            if (selections.length < state.selections_list_limit) {
                                commit("CHANGE_LEARNING_MATERIALS_LIST_ALL_LOADED", true);
                            }

                            if (payload.update || !state.learning_materials_list.length) commit("SAVE_LEARNING_MATERIALS_LIST", selections);
                            else commit("SAVE_LEARNING_MATERIALS_LIST", state.learning_materials_list.concat(selections));
                        }

                        resolve();
                    })
                    .catch(err => {
                        console.error(err);
                        reject(err);
                    })
            })
        },
        async GET_SELECTION_COMMENTS({state, commit}, load_up) {
            let params = {limit: state.selections_list_limit}
            let type = 'duration' in state.selection_comments_detail ? 'videos' : 'selections';

            if (load_up) {
                if (state.selection_comments_list.length > 0) {
                    let last_c = state.selection_comments_list[state.selection_comments_list.length - 1];
                    params['pagination_id'] = last_c.id;
                }
            } else {
                commit("CHANGE_SELECTION_COMMENTS_LIST_ALL_LOADED", false);
            }

            await axios
                .get(`${Vue.prototype.$api_url}/education/${type}/${state.selection_comments_detail.uuid}/comments`, {params})
                .then((resp) => {
                    let selections = resp.data.data;

                    if (selections.length < state.selections_list_limit) {
                        commit("CHANGE_SELECTION_COMMENTS_LIST_ALL_LOADED", true);
                    }

                    if (!state.selection_comments_list.length) commit("SAVE_SELECTION_COMMENTS_LIST", selections);
                    else commit("SAVE_SELECTION_COMMENTS_LIST", state.selection_comments_list.concat(selections));
                })
                .catch(err => {
                    console.error(err);
                })
        },
        async POST_SELECTION_COMMENT({state}, payload) {
            let type = 'duration' in state.selection_comments_detail ? 'videos' : 'selections';
            await axios
                .post(`${Vue.prototype.$api_url}/education/${type}/${state.selection_comments_detail.uuid}/comments`, payload)
                .then(resp => {
                    if (type === 'selections') {
                        if (state.selection?.uuid === state.selection_comments_detail.uuid) {
                            state.selection.additional.comments_total += 1;
                        }
                    } else {
                        if (state.video?.uuid === state.selection_comments_detail.uuid) {
                            state.video.comments_count += 1;
                        }
                    }

                    state.selection_comments_list.unshift(resp.data.data);
                })
        },
        async EDIT_SELECTION_COMMENT({state, commit}, payload) {
            const old_comment = payload.comment.comment;
            await commit("EDIT_SELECTION_COMMENT_FOR_LIST", payload);
            await axios
                .put(`${Vue.prototype.$api_url}/education/${payload.type}/${state.selection_comments_detail.uuid}/comments/${payload.comment.uuid}`, {comment: payload.new_comment})
                .catch(() => {
                    payload['new_comment'] = old_comment;
                    commit("EDIT_SELECTION_COMMENT_FOR_LIST", payload);
                })
        },
        DELETE_SELECTION_COMMENT({state, commit}, comment) {
            let type = 'duration' in state.selection_comments_detail ? 'videos' : 'selections';
            axios.delete(`${Vue.prototype.$api_url}/education/${type}/${state.selection_comments_detail.uuid}/comments/${comment.uuid}`)
            commit("DELETE_SELECTION_COMMENT_FROM_LIST", comment)
        },
        async TOGGLE_LIKE_SELECTION_COMMENT({state, commit}, payload) {
            let uuid = state.selection.uuid,
                like = payload.liked_by_user,
                likes_total = payload.likes_total;

            await commit("SAVE_SELECTION_COMMENT_LIKE_STATUS", {
                uuid: uuid,
                comment_id: payload.comment_id,
                liked_by_user: !like,
                likes_total: like ? likes_total - 1 : likes_total + 1
            })

            await axios
                .post(`${Vue.prototype.$api_url}/education/selections/${uuid}/comments/${payload.comment_id}/${like ? 'unlike' : 'like'}`)
                .then((resp) => {
                    commit("SAVE_SELECTION_COMMENT_LIKE_STATUS", {
                        uuid: uuid,
                        comment_id: payload.comment_id,
                        liked_by_user: resp.data.data.liked_by_user,
                        likes_total: resp.data.data.count
                    });
                })
                .catch(err => {
                    commit("SAVE_SELECTION_COMMENT_LIKE_STATUS", {
                        uuid: uuid,
                        comment_id: payload.comment_id,
                        liked_by_user: like,
                        likes_total: likes_total
                    });
                    console.error(err);
                })
        },
        async TOGGLE_LIKE_VIDEO_COMMENT({state, commit}, payload) {
            let uuid = state.video.uuid,
                like = payload.liked_by_user,
                likes_total = payload.likes_total;

            await commit("SAVE_VIDEO_COMMENT_LIKE_STATUS", {
                uuid: uuid,
                comment_id: payload.comment_id,
                liked_by_user: !like,
                likes_total: like ? likes_total - 1 : likes_total + 1
            })

            await axios
                .post(`${Vue.prototype.$api_url}/education/videos/${uuid}/comments/${payload.comment_id}/${like ? 'unlike' : 'like'}`)
                .then((resp) => {
                    commit("SAVE_VIDEO_COMMENT_LIKE_STATUS", {
                        uuid: uuid,
                        comment_id: payload.comment_id,
                        liked_by_user: resp.data.data.liked_by_user,
                        likes_total: resp.data.data.count
                    });
                })
                .catch(err => {
                    commit("SAVE_VIDEO_COMMENT_LIKE_STATUS", {
                        uuid: uuid,
                        comment_id: payload.comment_id,
                        liked_by_user: like,
                        likes_total: likes_total
                    });
                    console.error(err);
                })
        },
        async TOGGLE_LIKE_SELECTION({state, dispatch}, payload) {
            let uuid, like, likes_total;

            if (payload) {
                uuid = payload.uuid;
                like = payload.is_liked;
                likes_total = payload.likes_total;
            } else {
                uuid = state.selection.uuid;
                like = state.selection.additional.is_liked_by_current_user;
                likes_total = state.selection.additional.likes_total;
            }

            await dispatch("SAVE_SELECTION_LIKE_STATUS", {
                uuid: uuid,
                liked_by_user: !like,
                likes_total: like ? likes_total - 1 : likes_total + 1
            })

            await axios
                .post(`${Vue.prototype.$api_url}/education/selections/${uuid}/${like ? 'dislike' : 'like'}`)
                .then((resp) => {
                    dispatch("SAVE_SELECTION_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: resp.data.data.liked_by_user,
                        likes_total: resp.data.data.count
                    });
                })
                .catch(err => {
                    dispatch("SAVE_SELECTION_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: like,
                        likes_total: likes_total
                    });
                    console.error(err);
                })
        },
        SAVE_SELECTION_LIKE_STATUS({state, rootState}, payload) {
            if (state.selection?.uuid === payload.uuid) {
                state.selection.additional.is_liked_by_current_user = payload.liked_by_user;
                state.selection.additional.likes_total = payload.likes_total;
            }
            let selection = state.selections_list.find(s => s.uuid === payload.uuid);
            if (selection) {
                selection.additional.is_liked_by_current_user = payload.liked_by_user;
                selection.additional.likes_total = payload.likes_total;
            }
            let search_selection = state.search_selections_list.find(s => s.uuid === payload.uuid);
            if (search_selection) {
                search_selection.additional.is_liked_by_current_user = payload.liked_by_user;
                search_selection.additional.likes_total = payload.likes_total;
            }
            let selection_in_feed = rootState.feed.feed.find(p => p.position.uuid === payload.uuid);
            if (selection_in_feed) {
                selection_in_feed.position.additional.is_liked_by_current_user = payload.liked_by_user;
                selection_in_feed.position.additional.likes_total = payload.likes_total;
            }
        },
        CHANGE_SELECTION_EDIT_STATUS({state, rootState}, bool) {
            if (bool) {
                let cacheList = rootState.cache.selections_edit_map[state.selection.uuid]
                if (cacheList) {
                    state.deleted_selection_words_ids = cacheList.deleted_selection_words_ids || [];
                    state.added_selection_words = cacheList.added_selection_words || [];
                }
            } else {
                state.deleted_selection_words_ids = [];
                state.added_selection_words = [];
            }

            state.selection_edit_status = bool;
        },

        async GET_LEARNING_DAILY_ACTIVITY({commit}) {
            await axios
                .get(`${Vue.prototype.$api_url}/education/daily-activity`)
                .then((resp) => {
                    commit("SAVE_LEARNING_DAILY_ACTIVITY", resp.data.data);
                })
                .catch(err => {
                    console.error(err);
                })
        },
        async GET_LEARNING_STATISTICS({commit}, period) {
            await axios
                .get(`${Vue.prototype.$api_url}/education/statistics?period=${period}`)
                .then((resp) => {
                    let data = resp.data.data;
                    data.detailed_by_period.reverse();
                    commit("SAVE_LEARNING_STATISTICS", data);
                })
                .catch(err => {
                    console.error(err);
                })
        },

        SEARCH_VIDEOS({commit}, payload) {
            return new Promise((resolve, reject) => {
                let params = {lang_code: payload.lang};
                if (payload.search_string) params["search_string"] = payload.search_string;
                if (payload.pagination_token) params["pagination_token"] = payload.pagination_token;

                axios
                    .get(`${Vue.prototype.$api_url}/education/video-sources/youtube`, {params})
                    .then((resp) => resolve(resp.data.data))
                    .catch(err => reject(err))
                    .finally(() => commit)
            })
        },
        GET_VIDEO({state, commit}, uuid) {
            return new Promise((resolve, reject) => {
                if (uuid || state.video?.uuid) {
                    let id;

                    if (uuid && (uuid === state.video?.uuid)) {
                        resolve(state.video);
                        return;
                    } else if (uuid) {
                        id = uuid;
                        commit("CHANGE_LOADING_SELECTION", true);
                        commit("SAVE_VIDEO", null);
                    } else {
                        id = state.video?.uuid;
                    }

                    if (id) {
                        axios
                            .get(`${Vue.prototype.$api_url}/education/videos/${id}`)
                            .then((resp) => {
                                commit("SAVE_VIDEO", resp.data.data);
                                resolve(resp.data.data);
                            })
                            .catch(err => {
                                console.error(err);
                                reject(err);
                            })
                            .finally(() => {
                                if (uuid) commit("CHANGE_LOADING_SELECTION", false);
                            })
                    } else reject('Not found video.uuid');
                } else reject('Not found video.uuid');
            })
        },
        async CREATE_VIDEO({state, commit}, payload) {
            await commit("CHANGE_LOADING_ADDITION_STATUS", true);
            await axios
                .post(`${Vue.prototype.$api_url}/education/videos`, payload)
                .then(async (resp) => {
                    let video = resp.data.data;
                    await commit("SAVE_VIDEO", video);
                    await commit("SAVE_VIDEO_TO_FEED", video);
                    await commit("MATERIALS_UPDATE");
                    if (state.learning_materials_list.length) {
                        await commit("SAVE_LEARNING_MATERIALS_LIST", []);
                        await commit("CHANGE_LEARNING_MATERIALS_LIST_ALL_LOADED", false);
                    }
                    await router.replace({name: 'TrainingVideo', query: {uuid: video.uuid}});
                })
                .catch(err => {
                    throw Error(err)
                })
                .finally(() => {
                    commit("CHANGE_LOADING_ADDITION_STATUS", false);
                })
        },
        async EDIT_VIDEO({state, dispatch, commit}, payload) {
            await commit("CHANGE_LOADING_ADDITION_STATUS", true);
            await axios
                .put(`${Vue.prototype.$api_url}/education/videos/${state.edited_selection.uuid}`, payload)
                .then((resp) => {
                    commit("UPDATE_VIDEO", resp.data.data);
                    dispatch("CLOSE_PAGE_BACK");
                    commit("ALERT_SHOW", {name: 'learning', content: 'video_saved', duration: 500});
                })
                .finally(() => {
                    commit("CHANGE_LOADING_ADDITION_STATUS", false);
                })
        },
        async CHANGE_VIDEO_STUDY({state, commit}, timeout) {
            const
                method = state.video.selected_for_learning ? 'delete' : 'post',
                bool = method === 'post',
                uuid = state.video.uuid;

            await axios({
                method: method,
                url: `${Vue.prototype.$api_url}/education/videos/${uuid}/study`
            }).then(async () => {
                setTimeout(() => {
                    commit("UPDATE_VIDEO_STUDY", {uuid: uuid, value: bool});
                    if (bool) commit("ALERT_SHOW", {name: 'learning', content: 'video_selected_for_learning'});
                }, timeout)
            }).catch(e => {
                throw Error(e)
            })
        },
        async SAVE_VIDEO_WATCHING_POSITION({state, commit}, progress) {
            await axios
                .post(`${Vue.prototype.$api_url}/education/videos/${state.video.uuid}/progress`, {progress})
                .then(() => commit("UPDATE_VIDEO_WATCHING_POSITION", progress))
        },
        async TOGGLE_LIKE_VIDEO({state, dispatch}, payload) {
            let uuid, like, likes_total;

            if (payload) {
                uuid = payload.uuid;
                like = payload.is_liked;
                likes_total = payload.likes_total;
            } else {
                uuid = state.video.uuid;
                like = state.video.is_liked_by_current_user;
                likes_total = state.video.users_liked_video_count;
            }

            await dispatch("SAVE_VIDEO_LIKE_STATUS", {
                uuid: uuid,
                liked_by_user: !like,
                count: like ? likes_total - 1 : likes_total + 1
            })

            await axios
                .post(`${Vue.prototype.$api_url}/education/videos/${uuid}/${like ? 'dislike' : 'like'}`)
                .then((resp) => {
                    dispatch("SAVE_VIDEO_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: resp.data.data.liked_by_user,
                        count: resp.data.data.count
                    });
                })
                .catch(err => {
                    dispatch("SAVE_VIDEO_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: like,
                        count: likes_total
                    });
                    console.error(err);
                })
        },
        SAVE_VIDEO_LIKE_STATUS({state, rootState}, payload) {
            if (state.video?.uuid === payload.uuid) {
                state.video.is_liked_by_current_user = payload.liked_by_user;
                state.video.users_liked_video_count = payload.count;
            }
            let video = state.videos_list.find(s => s.uuid === payload.uuid);
            if (video) {
                video.is_liked_by_current_user = payload.liked_by_user;
                video.users_liked_video_count = payload.count;
            }
            let search_video = state.search_videos_list.find(s => s.uuid === payload.uuid);
            if (search_video) {
                search_video.is_liked_by_current_user = payload.liked_by_user;
                search_video.users_liked_video_count = payload.count;
            }
            let video_in_feed = rootState.feed.feed.find(p => p.position.uuid === payload.uuid);
            if (video_in_feed) {
                video_in_feed.position.is_liked_by_current_user = payload.liked_by_user;
                video_in_feed.position.users_liked_video_count = payload.count;
            }
        },
    },
    mutations: {
        SAVE_SELECTION: (state, selection) => {
            if (selection && !selection.unions) selection['unions'] = [];
            state.selection = selection;
        },
        SAVE_EDITED_SELECTION: (state, selection) => state.edited_selection = selection,
        UPDATE_SELECTION: (state, selection) => Object.assign(state.selection, selection),
        UPDATE_SELECTION_LEARNED_WORDS_COUNT: (state, count) => {
            if (state.selection) {
                state.selection.additional.words_total_learned += count;
                if (state.selection.additional.words_total_learned > state.selection.additional.words_total) {
                    state.selection.additional.words_total_learned = state.selection.additional.words_total;
                }
            }
        },
        SAVE_SELECTION_UNIONS: (state, payload) => {
            if (payload.type === 'update') state.selection['unions'] = payload.unions
            else state.selection.unions = state.selection.unions.concat(payload.unions)
        },
        SAVE_LEARNING_KNOW_LANGUAGE: (state, language) => state.learning_know_lang = language,
        CHANGE_SELECTION_UNIONS_ALL_LOADED: (state, boolean) => state.selection_unions_all_loaded = boolean,
        UPDATE_UNION_IN_SELECTION_UNIONS: (state, translation) => {
            let union = {
                "union": {
                    "id": translation.translation_union_id,
                    "is_verified": translation.is_verified,
                    "is_removed": false,
                    "translation_id": translation.id,
                    "words": [
                        {
                            'id': translation.translation_word.id,
                            'name': translation.translation_word.name,
                            'lang_code': translation.translation_word.lang_code,
                            'audios': translation.translation_word.audios
                        },
                        {
                            'id': translation.primary_word.id,
                            'name': translation.primary_word.name,
                            'lang_code': translation.primary_word.lang_code,
                            'audios': translation.primary_word.audios
                        }
                    ]
                }
            }

            let union_in_list = state.selection.unions.find(u => u.union.id === union.union.id);
            if (union_in_list) Object.assign(union_in_list, union);
        },
        DELETE_SELECTION_FROM_LIST: (state, uuid) => {
            let s_in_list = state.selections_list.findIndex(s => s.uuid === uuid);
            if (~s_in_list) state.selections_list.splice(s_in_list, 1);
        },
        DELETE_VIDEO_FROM_LIST: (state, uuid) => {
            let v_in_list = state.videos_list.findIndex(v => v.uuid === uuid);
            if (~v_in_list) state.videos_list.splice(v_in_list, 1);
        },
        SAVE_RESET_SELECTION: (state) => {
            state.selection.additional.words_total_learned = 0;
        },
        UPDATE_SELECTION_STUDY: (state, payload) => {
            if (state.selection) state.selection.additional.selected_for_learning = payload.value;

            let selection_l = state.selections_list.find(s => s.uuid === payload.uuid);
            if (selection_l) selection_l.additional.selected_for_learning = payload.value;

            let selection_lm_i = state.learning_materials_list.findIndex(s => s.uuid === payload.uuid);
            if (~selection_lm_i) {
                if (payload.value || ['all', 'authored_by_me'].includes(state.learning_materials_source)) {
                    state.learning_materials_list[selection_lm_i].additional.selected_for_learning = payload.value;
                } else {
                    state.learning_materials_list.splice(selection_lm_i, 1);
                    state.learning_materials_selections_count -= 1;
                }
            }
        },

        SAVE_SELECTIONS_LIST: (state, list) => state.selections_list = list,
        SAVE_VIDEOS_LIST: (state, list) => state.videos_list = list,
        CHANGE_SELECTIONS_LIST_ALL_LOADED: (state, boolean) => state.selections_list_all_loaded = boolean,
        CHANGE_VIDEOS_LIST_ALL_LOADED: (state, boolean) => state.videos_list_all_loaded = boolean,
        SAVE_LEARNING_DAILY_ACTIVITY: (state, daily_activity) => state.learning_daily_activity = daily_activity,
        SAVE_LEARNING_STATISTICS: (state, statistics) => state.learning_statistics = statistics,

        SAVE_SEARCH_SELECTIONS_LIST: (state, list) => state.search_selections_list = list,
        SAVE_SEARCH_VIDEOS_LIST: (state, list) => state.search_videos_list = list,
        CHANGE_SEARCH_SELECTIONS_LIST_ALL_LOADED: (state, boolean) => state.search_selections_list_all_loaded = boolean,
        CHANGE_SEARCH_VIDEOS_LIST_ALL_LOADED: (state, boolean) => state.search_videos_list_all_loaded = boolean,
        CHANGE_SEARCH_SELECTIONS_LIST_TEXT: (state, text) => state.search_selections_list_text = text,
        SAVE_SEARCH_SELECTIONS_LIST_FILTER: (state, filter) => {
            if (filter) state.search_selections_list_filter = filter
            else state.search_selections_list_filter = getDefaultState().search_selections_list_filter
        },

        SAVE_DELETED_SELECTION_WORDS_IDS: (state, id) => {
            let i = state.added_selection_words.findIndex(w => w.union.id === id);
            if (~i) state.added_selection_words.splice(i, 1);

            if (!state.selection_all_ids.length || (state.selection_all_ids.includes(id) && !state.deleted_selection_words_ids.includes(id))) {
                state.deleted_selection_words_ids.push(id);
            }
        },
        UPDATE_ADDED_SELECTION_WORD: (state, word) => {
            let words = [
                {
                    'id': word.translation_word.id,
                    'name': word.translation_word.name,
                    'lang_code': word.translation_word.lang_code,
                    'audios': word.translation_word.audios
                },
                {
                    'id': word.primary_word.id,
                    'name': word.primary_word.name,
                    'lang_code': word.primary_word.lang_code,
                    'audios': word.primary_word.audios
                }
            ]

            let w = state.added_selection_words.find(u => u.union.id === word.translation_union_id)
            if (w) w.union["words"] = words

            let sw = state.selection.unions.find(u => u.union.id === word.translation_union_id)
            if (sw) sw.union["words"] = words
        },
        SAVE_ADDED_SELECTION_WORDS: (state, word) => {
            let i = state.deleted_selection_words_ids.indexOf(word.union.id);
            if (~i) state.deleted_selection_words_ids.splice(i, 1);

            if (!state.selection_all_ids.includes(word.union.id) && !state.added_selection_words.find(w => w.union.id === word.union.id)) {
                state.added_selection_words.unshift(word);
            }
        },
        SAVE_SELECTION_ALL_IDS: (state, ids) => state.selection_all_ids = ids,
        UPDATE_SELECTION_WORDS_COUNT: (state, count) => state.selection.additional.words_total += count,
        UPDATE_EDITED_LISTS: (state) => {
            state.deleted_selection_words_ids = state.deleted_selection_words_ids.filter(id => state.selection_all_ids.includes(id));
            state.added_selection_words = state.added_selection_words.filter(word => !state.selection_all_ids.includes(word.union.id));
        },

        SAVE_SELECTION_COMMENTS_DETAIL: (state, detail) => state.selection_comments_detail = detail,
        CHANGE_SELECTION_COMMENTS_LIST_ALL_LOADED: (state, boolean) => state.selection_comments_list_all_loaded = boolean,
        SAVE_SELECTION_COMMENTS_LIST: (state, list) => state.selection_comments_list = list,
        EDIT_SELECTION_COMMENT_FOR_LIST: (state, payload) => {
            if (state.selection_comments_list.length) {
                let comment = state.selection_comments_list.find(c => c.uuid === payload.comment.uuid);
                if (comment) comment.comment = payload.new_comment;
            }
        },
        DELETE_SELECTION_COMMENT_FROM_LIST: (state, comment) => {
            if (state.selection_comments_list.length) {
                let index = state.selection_comments_list.findIndex(с => с.uuid === comment.uuid);
                if (~index) {
                    state.selection_comments_list.splice(index, 1);
                    if (state.selection) state.selection.additional.comments_total -= 1;
                    if (state.video) state.video.comments_count -= 1;
                }
            }
        },
        CLEAR_LEARNING_COMMENTS: (state) => {
            state.selection_comments_detail = null
            state.selection_comments_list = []
            state.selection_comments_list_all_loaded = false
        },
        SAVE_SELECTION_COMMENT_LIKE_STATUS(state, payload) {
            if (state.selection?.uuid === payload.uuid) {
                let comment = state.selection_comments_list.find(c => c.id === payload.comment_id);
                if (comment) {
                    comment.additional.is_liked_by_current_user = payload.liked_by_user;
                    comment.additional.likes_total = payload.likes_total;
                }
            }
        },

        SAVE_LEARNING_MATERIALS_LIST: (state, list) => state.learning_materials_list = list,
        CHANGE_LEARNING_MATERIALS_LIST_ALL_LOADED: (state, boolean) => state.learning_materials_list_all_loaded = boolean,
        SAVE_LEARNING_MATERIALS_SOURCE: (state, source) => {
            if (state.learning_materials_source !== source) {
                state.learning_materials_source = source;
                state.learning_materials_selections_count = null;
                state.learning_materials_videos_count = null;
            }
        },
        SAVE_LEARNING_MATERIALS_TYPE: (state, type) => {
            if (state.learning_materials_type !== type) {
                state.learning_materials_type = type;
                state.learning_materials_list = [];
                state.learning_materials_list_all_loaded = false;
            }
        },
        SAVE_LEARNING_MATERIALS_FILTER: (state, filter) => {
            if (filter) state.learning_materials_filter = filter
            else state.learning_materials_filter = getDefaultState().learning_materials_filter
        },
        DELETE_SELECTION_FROM_LEARNING_MATERIALS: (state, uuid) => {
            let s_in_materials_list = state.learning_materials_list.findIndex(s => s.uuid === uuid);
            if (~s_in_materials_list) {
                if ('watching_time' in state.learning_materials_list[s_in_materials_list]) {
                    state.learning_materials_videos_count -= 1;
                } else {
                    state.learning_materials_selections_count -= 1;
                }
                state.learning_materials_list.splice(s_in_materials_list, 1);
            }
        },
        SAVE_LEARNING_MATERIALS_SELECTIONS_COUNT: (state, count) => state.learning_materials_selections_count = count,
        SAVE_LEARNING_MATERIALS_VIDEOS_COUNT: (state, count) => state.learning_materials_videos_count = count,
        SAVE_DELETED_SELECTION_DATA: (state, data) => state.deleted_selection_data = data,
        UPDATE_LEARNING_DAILY_ACTIVITY_DAYS: (state) => {
            state.learning_daily_activity.days_activity_span += 1;
            state.learning_daily_activity.has_activity_today = true;
            state.learning_daily_activity.need_action_until = null;
        },

        SAVE_VIDEO: (state, video) => state.video = video,
        UPDATE_VIDEO: (state, video) => Object.assign(state.video, video),
        UPDATE_VIDEO_STUDY: (state, payload) => {
            if (state.video) state.video.selected_for_learning = payload.value;

            let video_l = state.videos_list.find(v => v.uuid === payload.uuid);
            if (video_l) video_l.selected_for_learning = payload.value;

            let video_lm_i = state.learning_materials_list.findIndex(v => v.uuid === payload.uuid);
            if (~video_lm_i) {
                if (payload.value || ['all', 'authored_by_me'].includes(state.learning_materials_source)) {
                    state.learning_materials_list[video_lm_i].selected_for_learning = payload.value;
                } else {
                    state.learning_materials_list.splice(video_lm_i, 1);
                    state.learning_materials_selections_count -= 1;
                }
            }
        },
        UPDATE_VIDEO_WATCHING_POSITION: (state, position) => {
            if (state.video) state.video.watching_time = position;

            let video_l = state.videos_list.find(v => v.uuid === state.video.uuid);
            if (video_l) video_l.watching_time = position;

            let video_lm_i = state.learning_materials_list.findIndex(s => s.uuid === state.video.uuid);
            if (~video_lm_i) state.learning_materials_list[video_lm_i].watching_time = position;
        },
        SAVE_VIDEO_COMMENT_LIKE_STATUS(state, payload) {
            if (state.video?.uuid === payload.uuid) {
                let comment = state.selection_comments_list.find(c => c.id === payload.comment_id);
                if (comment) {
                    comment.additional.is_liked_by_current_user = payload.liked_by_user;
                    comment.additional.likes_total = payload.likes_total;
                }
            }
        },

        CLEAR_LEARNING: (state) => {
            Object.assign(state, getDefaultState());
        }
    }
}