import axios from "axios";
import router from "../../router";
import Vue from "vue";
import {v4 as uuidv4} from "uuid";

let example_lang, example_lang_t

export default {
    state: {
        addition_new_points: 0,
        example_sentence: null,
        addition_params: {},
        editable_sentence: JSON.parse(sessionStorage.getItem('editable_sentence')) || {},
        new_translates: [],
        other_sentence_translations: {},
        other_sentence_translations_action: {},
        other_word_translations: {}
    },
    actions: {
        POST_NEW_WORD({state, commit, dispatch, rootState}, request) {
            return new Promise((resolve, reject) => {
                commit("CHANGE_LOADING_ADDITION_STATUS", true)

                let translateContent_ID,
                    count = 0,
                    success_status = 0,
                    translates_request_statuses = []

                function step(index, id, explanation, vocabs, meaning) {
                    let new_translate,
                        data = {
                            primary_word_id: translateContent_ID,
                            translation_word_id: id,
                            translation_lang_code: request.translates[0].word.lang_code,
                        }

                    if (request.explanation) data['primary_word_explanation'] = request.explanation
                    if (explanation) data['translation_word_explanation'] = explanation
                    if (vocabs) data['vocabulary_codes'] = vocabs

                    axios
                        .post(Vue.prototype.$api_url + '/translations', data)
                        .then(async (response) => {
                            ++success_status;

                            let points = response.data.rating.points;

                            translates_request_statuses.push({index, status: 'success'});
                            new_translate = response.data.data;
                            new_translate['new_translate'] = true;
                            new_translate['can_edit'] = true;
                            await commit('SAVE_NOT_FOUND', false);

                            if (request.out_of_place === 'mini_dictionary') {
                                await commit("SAVE_NEW_MINI_TRANSLATE", new_translate);

                                if (rootState.learning.selection && rootState.learning.selection_edit_status) {
                                    await commit("SAVE_ADDED_SELECTION_WORDS", {
                                        "pagination_id": uuidv4(),
                                        "union": {
                                            "id": new_translate.translation_union_id,
                                            "is_verified": new_translate.is_verified,
                                            "is_removed": false,
                                            "translation_id": new_translate.id,
                                            "words": [
                                                {
                                                    'id': 1,
                                                    'name': new_translate.translation_word.name,
                                                    'lang_code': new_translate.translation_word.lang_code,
                                                    'audios': new_translate.translation_word.audios
                                                },
                                                {
                                                    'id': 2,
                                                    'name': new_translate.primary_word.name,
                                                    'lang_code': new_translate.primary_word.lang_code,
                                                    'audios': new_translate.primary_word.audios
                                                }
                                            ]
                                        }
                                    });
                                    await commit("CLEAR_MINI_DICTIONARY_LIGHT");
                                }
                            } else {
                                await dispatch("SAVE_NEW_TRANSLATE", new_translate);
                            }

                            if (request.meaning) {
                                await axios
                                    .post(`${Vue.prototype.$api_url}/translations/${response.data.data.id}/meaning-descriptions`, request.meaning)
                                    .then(meaning_resp => points += meaning_resp.data.rating.points)
                            }

                            if (meaning) {
                                await axios
                                    .post(`${Vue.prototype.$api_url}/translations/${response.data.data.id}/meaning-descriptions`, meaning)
                                    .then(meaning_resp => points += meaning_resp.data.rating.points)
                            }

                            await dispatch('SAVE_ADDITION_NEW_POINTS', points)
                        })
                        .catch((err) => {
                            if (err.message === 'TRANSLATION_EXIST_TRANSLATION') {
                                translates_request_statuses.push({index, status: 'failed'})
                            } else {
                                translates_request_statuses.push({index, status: ''})
                                reject()
                            }
                        })
                        .finally(() => {
                            ++count;

                            if (count === request.translates.length) {
                                let link, regExp = new RegExp('/dictionary/(.*)/(.*)/(.*)/');

                                if (new_translate) {
                                    link = new_translate.url_translate_detail;

                                    if (link.match(regExp)) {
                                        link = link.match(regExp)[0];
                                        link = link.slice(0, link.length - 1);
                                    }
                                }

                                finish(link);
                            }
                        })
                }

                async function finish(link) {
                    if (count === request.translates.length) {
                        if (link) await commit("SAVE_LAST_TRANSLATE_LINK", link)
                        if (success_status === request.translates.length) await dispatch('ADDITION_FINISHED_ACTION', state.addition_new_points);
                        else {
                            commit("DICTIONARY_RATING_UPDATE");
                            commit("ACHIEVEMENTS_UPDATE");
                            commit("STATISTICS_UPDATE");
                        }
                        await commit("CHANGE_LOADING_ADDITION_STATUS", false)
                        resolve(translates_request_statuses)
                    }
                }

                dispatch('ADD_OR_GET_WORD', request.word)
                    .then(resp => translateContent_ID = resp.data.data.id)
                    .then(() => {
                        let cache_words = [];

                        request.translates.forEach((transl, index) => {
                            if (transl.status === 'success') {
                                ++count;
                                translates_request_statuses.push({index, status: 'success'});

                                finish()
                            } else if ((transl.status === 'failed') || cache_words.includes(transl.word.name.trim())) {
                                ++count;
                                translates_request_statuses.push({index, status: 'failed'});

                                finish()
                            } else {
                                cache_words.push(transl.word.name.trim())
                                dispatch('ADD_OR_GET_WORD', transl.word)
                                    .then(resp => step(index, resp.data.data.id, transl.explanation, transl.vocabulary_codes, transl.meaning))
                                    .catch(err => {
                                        commit("CHANGE_LOADING_ADDITION_STATUS", false);
                                        return err;
                                    })
                            }
                        })
                    })
                    .catch(err => {
                        commit("CHANGE_LOADING_ADDITION_STATUS", false);
                        return err;
                    })
            })
        },
        async PUT_TRANSLATE({commit, dispatch, rootState}, request) {
            commit("CHANGE_LOADING_ADDITION_STATUS", true)

            let translateContent_ID, translateContentT_ID,
                id = rootState.detail_word_page.translation_id,
                finish = async (resp) => {
                    let c1 = !sessionStorage.getItem('need_action_task_id'),
                        c2 = !sessionStorage.getItem('moderation_task_id'),
                        c3 = !rootState.statistics.statistics_detail_panel,
                        c4 = rootState.panels.word_panel;

                    if ((resp && c1 && c2 && c3) || c4) {
                        let data = resp.data;
                        data['vocabulary_update'] = true;
                        await dispatch('SAVE_NEW_TRANSLATE', data);
                        if (!router.app._route.query.out_of_place) {
                            await dispatch('LOAD_MULTIPLE_EDIT_AVAILABLE_FOR_SOME_ENTITY', {types: ['translation', 'attributes', 'vocabulary']});
                        } else if (router.app._route.query.out_of_place === 'mini_dictionary') {
                            await commit("UPDATE_UNION_IN_SELECTION_UNIONS", data)
                            await commit("UPDATE_TRANSLATE_IN_MINI_DICTIONARY", data)
                            if (rootState.learning.added_selection_words.length || rootState.learning.selection.unions.length) {
                                await commit("UPDATE_ADDED_SELECTION_WORD", data)
                            }
                        } else if (router.app._route.query.out_of_place === 'selection') {
                            await commit("UPDATE_UNION_IN_SELECTION_UNIONS", data);
                            await commit("UPDATE_UNION_IN_SELECTION_LESSON_WORDS", data);
                        }
                    }
                    await dispatch('ADDITION_FINISHED_ACTION');
                    await commit("UPDATE_DETAIL_PAGE_KEY");
                },
                failed = (err) => {
                    dispatch('ADDITION_ERROR_ACTION', err);
                    return err;
                };

            if (request.word) {
                await dispatch('ADD_OR_GET_WORD', request.word)
                    .then(resp => translateContent_ID = resp.data.data.id)
                    .catch(err => failed(err))
            } else translateContent_ID = rootState.detail_word_page.detail_primary_word.id

            if (request.word_t) {
                await dispatch('ADD_OR_GET_WORD', request.word_t)
                    .then(resp => translateContentT_ID = resp.data.data.id)
                    .catch(err => failed(err))
            } else translateContentT_ID = rootState.detail_word_page.detail_translate_word.id

            let data = {
                primary_word_id: translateContent_ID,
                translation_word_id: translateContentT_ID
            }

            if (request.moderation_task_id) data['moderation_task_id'] = request.moderation_task_id
            if ('primary_word_explanation' in request) data['primary_word_explanation'] = request.primary_word_explanation
            if ('translation_word_explanation' in request) data['translation_word_explanation'] = request.translation_word_explanation

            let put_tr_request = request.word || request.word_t || ('primary_word_explanation' in data) || ('translation_word_explanation' in data);

            if (request.vocabulary_codes || request.attributes || put_tr_request) {
                if (request.vocabulary_codes) {
                    await dispatch('PUT_VOCABULARY', {data: {'vocabulary_codes': request.vocabulary_codes}})
                        .then(r => !put_tr_request && !request.attributes && finish(r))
                        .catch(err => failed(err))
                }

                if (request.attributes) {
                    await axios
                        .post(Vue.prototype.$api_url + '/translations/' + id + '/attributes', request.attributes)
                        .then((r) => !put_tr_request && finish(r.data))
                        .catch(() => commit("CHANGE_LOADING_ADDITION_STATUS", false))
                }

                if (put_tr_request) {
                    await axios
                        .put(Vue.prototype.$api_url + '/translations/' + id, data)
                        .then(response => finish(response.data))
                        .catch(err => failed(err))
                }
            } else await finish()
        },
        async IMPROVE_TRANSLATE({dispatch, commit, rootState}, request) {
            commit("CHANGE_LOADING_ADDITION_STATUS", true)

            let finish = async (response) => {
                    if (response) {
                        let data = response.data;
                        data['vocabulary_update'] = true;
                        await dispatch('SAVE_NEW_TRANSLATE', data);
                        await dispatch('LOAD_MULTIPLE_EDIT_AVAILABLE_FOR_SOME_ENTITY', {types: ['translation', 'attributes', 'vocabulary']});
                    }
                    await dispatch('ADDITION_FINISHED_ACTION', response.rating?.points);
                    await commit("UPDATE_DETAIL_PAGE_KEY");
                },
                failed = (err) => {
                    dispatch('ADDITION_ERROR_ACTION', err);
                    return err;
                };

            if (request.vocabulary_codes) {
                await dispatch('PUT_VOCABULARY', {data: {'vocabulary_codes': request.vocabulary_codes}})
                    .then(data => {
                        if (!request.primary_word_attributes && !request.translation_word_attributes) finish(data)
                    })
            }

            if (request.attributes) {
                await axios
                    .post(Vue.prototype.$api_url + '/translations/' + rootState.detail_word_page.translation_id + '/attributes', request.attributes)
                    .then(response => finish(response.data))
                    .catch((err) => failed(err))
            }
        },
        PUT_VOCABULARY({commit, rootState}, payload) {
            return new Promise((resolve, reject) => {
                let request_payload = payload.data;
                request_payload['vocabulary_codes'] = request_payload['vocabulary_codes'].slice(0, 5);

                axios
                    .post(`${Vue.prototype.$api_url}/translations/${rootState.detail_word_page.translation_id}/vocabulary-codes`, request_payload)
                    .then(async resp => {
                        if ((payload.out_of_place === 'tasks_list') && rootState.complaints.complain_task_id) {
                            if (rootState.tasks.tasks[0]) {
                                await commit("SAVE_TASK_CONTINUE_ID", rootState.complaints.complain_task_id);
                                await commit("SAVE_COMPLAIN_TASK_ID", null);
                            }
                        } else if (rootState.statistics.statistics_detail_panel && rootState.statistics.statistics_edited_id) {
                            await commit("SAVE_STATISTICS_EDITED_DATA", {entity_type_slug: 'translation-vocabulary'})
                        }

                        if (payload.clear_popup) {
                            await commit("CHANGE_NEED_ACTION_VOCABULARY_PANEL", false);
                            await commit("UPDATE_NEED_ACTION_TASK_INDEX", "edit");
                            await commit("ALERT_SHOW", {
                                name: 'need_action',
                                content: 'notice_material_sent_for_review',
                                duration: 500
                            });
                        }

                        await resolve(resp.data)
                    })
                    .catch(err => reject(err))
            })
        },
        POST_MEANING({commit, dispatch, rootState}, request) {
            commit("CHANGE_LOADING_ADDITION_STATUS", true)
            let id = rootState.detail_word_page.translation_id,
                new_meaning = request.type === 'new',
                method = new_meaning ? 'POST' : 'PUT',
                url = `${Vue.prototype.$api_url}/translations/${id}/meaning-descriptions`;

            if (!new_meaning) url += `/${request.id}`

            return new Promise((resolve, reject) => {
                axios({url, method, data: request.meaning})
                    .then(async (resp) => {
                        let meaning = Object.assign(resp.data['meaning-description'], {can_edit: true}),
                            meanings = rootState.detail_word_page.word_meanings,
                            array = (meaning.lang_code === rootState.dictionary.primary_lang) ? meanings[0] : meanings[1],
                            translations = rootState.dictionary.translates,
                            tr = translations.find(el => el.id === id),
                            func = () => {
                                if (new_meaning) array.unshift(meaning)
                                else {
                                    let m = array.find(item => item.id === request.id)
                                    if (m) Object.assign(m, meaning)
                                }
                                if (tr) Object.assign(tr, resp.data.data)
                                commit("SAVE_MEANINGS_LANG", meaning.lang_code)
                            }

                        if (!sessionStorage.getItem('need_action_task_id') && !sessionStorage.getItem('moderation_task_id')) {
                            await func()
                            await commit("SAVE_WORD_MEANINGS", meanings)
                        }

                        await dispatch('ADDITION_FINISHED_ACTION', new_meaning ? resp.data.rating.points : null)
                        await resolve()
                    })
                    .catch(error => {
                        dispatch('ADDITION_ERROR_ACTION', error)
                        reject(error.message)
                    })
            })
        },
        GET_POSSIBLE_MEANINGS({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/translations/words/all-meanings?keyword=${payload.keyword}&lang=${payload.lang}`)
                    .then(resp => resolve(resp.data.data))
                    .catch(e => reject(e))
                    .finally(() => commit)
            })
        },
        async POST_SENTENCES({getters, commit, dispatch, rootState}, request) {
            let id, URL;

            commit("CHANGE_LOADING_ADDITION_STATUS", true);

            if (!request.data['sentence']) {
                id = request.sentence_id;
                URL = `${Vue.prototype.$api_url}/words/sentence/${id}/translation`; // translate sentence
            } else {
                id = request.id;
                URL = `${Vue.prototype.$api_url}/words/${id}/sentence`; // one sentence, both sentence
            }

            await axios
                .post(URL, request.data)
                .then(async resp => {
                    if (!getters.MOBILE) {
                        if (!rootState.panels.sentences_panel && !rootState.panels.word_panel) {
                            await commit("CHANGE_SENTENCES_PANEL", true)
                        } else if (rootState.panels.word_panel && rootState.detail_word_page.word_sentences.length >= 2) {
                            await commit("CHANGE_WORD_SENTENCES_PANEL", true)
                        }
                    }

                    if (!rootState.panels.word_panel) {
                        let search = rootState.dictionary.word.name,
                            lang = rootState.dictionary.primary_lang,
                            lang_t = rootState.dictionary.translation_lang;

                        await dispatch('LOAD_TRANSLATES', {search, lang, lang_t})
                    }

                    await dispatch('UPDATE_SENTENCES');
                    await dispatch('ADDITION_FINISHED_ACTION', resp.data.rating.points);
                })
                .catch(error => {
                    dispatch('ADDITION_ERROR_ACTION', error);
                    throw new Error(error);
                })
        },
        async EDIT_SENTENCES({commit, dispatch}, request) {
            commit("CHANGE_LOADING_ADDITION_STATUS", true);

            let moderation_task_id = request.moderation_task_id;

            let finish = async () => {
                    if (!sessionStorage.getItem('need_action_task_id') && !sessionStorage.getItem('moderation_task_id')) {
                        await dispatch('UPDATE_SENTENCES');
                    }

                    await dispatch('ADDITION_FINISHED_ACTION');
                },
                stop = async (err) => {
                    await dispatch('ADDITION_ERROR_ACTION', err);
                    throw new Error(err);
                };

            if (request.data.sentence_translation_id && request.data.sentence && request.data.sentence_translation) {
                let data = {
                    "sentence": {
                        "sentence_text": request.data.sentence.sentence_text,
                        "selected_positions": request.data.sentence.selected_positions,
                        "lang_code": request.data.sentence.lang_code
                    },
                    "sentence_translation": {
                        "sentence_text": request.data.sentence_translation.sentence_text,
                        "selected_positions": request.data.sentence_translation.selected_positions,
                        "lang_code": request.data.sentence_translation.lang_code
                    }
                }
                if (moderation_task_id) data["moderation_task_id"] = moderation_task_id;
                if ("changed_word_form" in request.data.sentence) data["sentence"]["changed_word_form"] = request.data.sentence["changed_word_form"];
                if ("changed_word_form" in request.data.sentence_translation) data["sentence_translation"]["changed_word_form"] = request.data.sentence_translation["changed_word_form"];

                await axios
                    .put(`${Vue.prototype.$api_url}/words/sentence-to-sentence/${request.data.sentence_translation_id}`, data)
                    .catch(error => stop(error))

                await finish()
            } else {
                if (request.data.sentence) {
                    let data = {
                        "sentence_text": request.data.sentence.sentence_text,
                        "sentence_translation_id": request.data.sentence_translation_id,
                        "selected_positions": request.data.sentence.selected_positions
                    }
                    if (moderation_task_id) data["moderation_task_id"] = moderation_task_id;
                    if (request["changed_word_form"]) data["changed_word_form"] = request["changed_word_form"];
                    await axios
                        .put(`${Vue.prototype.$api_url}/words/sentence/${request.sentence_id}`, data)
                        .catch(error => stop(error))
                }

                if (request.data.sentence_translation) {
                    await axios
                        .post(`${Vue.prototype.$api_url}/words/sentence/${request.sentence_id}/translation`, {'sentence_translation': request.data.sentence_translation})
                        .catch(error => stop(error))
                }

                await finish()
            }
        },
        async POST_SYNONYM_OR_ANTONYM({state, commit, dispatch, rootState}, request) {
            return new Promise((resolve) => {
                commit("CHANGE_LOADING_ADDITION_STATUS", true)
                let type = request[0].synonym ? 'synonym' : 'antonym',
                    id = rootState.detail_word_page.translation_id,
                    count = 0,
                    success_status = 0,
                    synonyms_request_statuses = [],
                    url = `${Vue.prototype.$api_url}/translations/${id}/${type}`;

                async function finish() {
                    if (count === request.length) {
                        if (success_status === request.length) await dispatch('ADDITION_FINISHED_ACTION', state.addition_new_points);
                        await commit("CHANGE_LOADING_ADDITION_STATUS", false)
                        resolve(synonyms_request_statuses)
                    }
                }

                let cache_words = [];

                request.forEach((item, index) => {
                    if (item.status === 'success') {
                        ++count;
                        ++success_status;
                        synonyms_request_statuses.push({index, status: 'success'});

                        finish()
                    } else if ((item.status === 'failed') || cache_words.includes(item[type].name)) {
                        ++count;
                        synonyms_request_statuses.push({
                            index,
                            status: 'failed',
                            error_message: type === 'synonym' ? 'error_synonym_exists' : 'error_antonym_exists'
                        });

                        finish()
                    } else {
                        cache_words.push(item[type].name)
                        let data = item[type]
                        axios.post(url, data)
                            .then(async resp => {
                                ++success_status;
                                synonyms_request_statuses.push({index, status: 'success'});
                                let new_item = Object.assign(resp.data.data, {can_edit: true}),
                                    items = (type === 'synonym') ? rootState.detail_word_page.word_synonyms : rootState.detail_word_page.word_antonyms,
                                    array = (new_item.lang_code === rootState.dictionary.primary_lang) ? items[0] : items[1]

                                await array.push(new_item)
                                await dispatch('SAVE_ADDITION_NEW_POINTS', resp.data.rating.points)
                            })
                            .catch(err => {
                                let error_message = '';

                                if (err.message === 'SYNONYM_UNIQUE') error_message = 'error_synonym_exists'
                                else if (err.message === 'ANTONYM_UNIQUE') error_message = 'error_antonym_exists'
                                else if (err.message === 'SYNONYM_CANNOT_BE_ADDED_EXCEPTION') error_message = 'error_synonym_cannot_be_added'
                                else if (err.message === 'ANTONYM_CANNOT_BE_ADDED_EXCEPTION') error_message = 'error_antonym_cannot_be_added'

                                synonyms_request_statuses.push({index, status: 'failed', error_message})
                            })
                            .finally(() => {
                                ++count;
                                if (count === request.length) finish();
                            })
                    }
                })

                commit(type === 'synonym' ? "SAVE_SYNONYMS_LANG" : "SAVE_ANTONYMS_LANG", request[0][type].lang_code)
            })
        },
        async PUT_SYNONYM_OR_ANTONYM({commit, dispatch, rootState}, request) {
            await commit("CHANGE_LOADING_ADDITION_STATUS", true)
            let synonym_type = !!request.synonym,
                id = rootState.detail_word_page.translation_id,
                data = synonym_type ? request.synonym : request.antonym,
                url = `${Vue.prototype.$api_url}/translations/${id}` + (synonym_type ? '/synonym' : '/antonym') + `/${request.id}`;

            if (request.moderation_task_id) data['moderation_task_id'] = request.moderation_task_id;

            await axios.put(url, data)
                .then(async resp => {
                    let new_item = Object.assign(resp.data.data, {can_edit: true}),
                        items = synonym_type ? rootState.detail_word_page.word_synonyms : rootState.detail_word_page.word_antonyms,
                        array = (new_item.lang_code === rootState.dictionary.primary_lang) ? items[0] : items[1],
                        func = () => {
                            let object = array.find(item => item.id === request.id);
                            if (object) Object.assign(object, new_item);
                        }

                    if (!sessionStorage.getItem('need_action_task_id') && !sessionStorage.getItem('moderation_task_id')) await func()
                    await dispatch('ADDITION_FINISHED_ACTION')
                })
                .catch(err => {
                    let error_message = '';

                    if (err.message === 'SYNONYM_UNIQUE') error_message = 'error_synonym_exists'
                    else if (err.message === 'ANTONYM_UNIQUE') error_message = 'error_antonym_exists'
                    else if (err.message === 'SYNONYM_CANNOT_BE_ADDED_EXCEPTION') error_message = 'error_synonym_cannot_be_added'
                    else if (err.message === 'ANTONYM_CANNOT_BE_ADDED_EXCEPTION') error_message = 'error_antonym_cannot_be_added'

                    dispatch('ADDITION_ERROR_ACTION', err)
                    throw new Error(error_message)
                })
        },
        POST_TRANSLATE_AUDIO({commit, dispatch, rootState}, request) {
            return new Promise((resolve, reject) => {
                if (request.type === 'dictionary') commit("CHANGE_LOADING_ADDITION_STATUS", true)

                axios
                    .post(
                        `${Vue.prototype.$api_url}/words/${request.word_id}/audios`,
                        request.audio,
                        {headers: {'Content-Type': 'multipart/form-data'}}
                    )
                    .then(async response => {
                        let new_audio = response.data.data

                        if (request.type === 'favorite') {
                            let favorite = rootState.favorites.favorites_in_group.find(favorite => favorite.id === request.id);

                            if (favorite) favorite.entity.primary_word.audios.push(new_audio)
                        } else if (request.id) {
                            let translate = rootState.dictionary.translates.find(translate => translate.id === request.id),
                                translate_id = rootState.dictionary.translates.findIndex(translate => translate.id === request.id);

                            if (translate && ~translate_id) {
                                if (translate.translation_word.audios?.length) translate.translation_word.audios.push(new_audio)
                                else translate.translation_word.audios = [new_audio]

                                rootState.dictionary.translates.splice(translate_id, 1, translate)
                            }
                        } else {
                            if (rootState.dictionary.word.audios?.length) rootState.dictionary.word.audios.push(new_audio)
                            else rootState.dictionary.word.audios = [new_audio]
                            if (rootState.detail_word_page.detail_primary_word.audios) rootState.detail_word_page.detail_primary_word.audios.push(new_audio)
                            else rootState.detail_word_page.detail_primary_word.audios = [new_audio]
                        }
                        await dispatch('SAVE_ADDITION_NEW_POINTS', response.data.rating.points)
                        await resolve()
                    })
                    .catch(err => reject(err))
                    .finally(() => commit("CHANGE_LOADING_ADDITION_STATUS", false))
            })
        },
        POST_SENTENCE_AUDIO({commit, dispatch, rootState}, request) {
            return new Promise((resolve, reject) => {
                commit("CHANGE_LOADING_ADDITION_STATUS", true)
                axios
                    .post(
                        `${Vue.prototype.$api_url}/sentences/${request.sentence_id}/audios`,
                        request.audio,
                        {headers: {'Content-Type': 'multipart/form-data'}}
                    )
                    .then(async response => {
                        let new_audio = response.data.data,
                            updateSentence = async (name) => {
                                let sentence = await rootState.detail_word_page[name].find(sentence => sentence.word_sentence.id === request.id),
                                    sentence_id = await rootState.detail_word_page[name].findIndex(sentence => sentence.word_sentence.id === request.id);

                                if (sentence && ~sentence_id) {
                                    if (request.type === 'sentence_primary') sentence.word_sentence.sentence.audio = new_audio
                                    else if (sentence.translations[0]) sentence.translations[0].sentence.audio = new_audio

                                    await rootState.detail_word_page[name].splice(sentence_id, 1, sentence);
                                }
                            };

                        if (rootState.detail_word_page.sentences.length) await updateSentence('sentences')
                        if (rootState.detail_word_page.word_sentences.length) await updateSentence('word_sentences')

                        await dispatch('SAVE_ADDITION_NEW_POINTS', response.data.rating.points)
                        await resolve()
                    })
                    .catch(err => reject(err))
                    .finally(() => commit("CHANGE_LOADING_ADDITION_STATUS", false))
            })
        },
        async PUT_AUDIO({commit, dispatch, rootState}, request) {
            await commit("CHANGE_LOADING_ADDITION_STATUS", true);

            await axios
                .post(
                    Vue.prototype.$api_url + `/${request.type}/${request.word_id}/audios/${request.id}`,
                    request.audio,
                    {headers: {'Content-Type': 'multipart/form-data'}}
                )
                .then(async (resp) => {
                    if ((request.out_of_place === 'tasks_list') && rootState.complaints.complain_task_id) {
                        if (rootState.tasks.tasks[0]) {
                            await commit("SAVE_TASK_CONTINUE_ID", rootState.complaints.complain_task_id);
                            await commit("SAVE_COMPLAIN_TASK_ID", null);
                        }
                    } else if (request.out_of_place === 'dictionary') {
                        let search = rootState.dictionary.word.name,
                            lang = rootState.dictionary.primary_lang,
                            lang_t = rootState.dictionary.translation_lang;

                        await dispatch('LOAD_TRANSLATES', {search, lang, lang_t})
                    } else if (request.out_of_place === 'translation_panel') {
                        let search = rootState.dictionary.word.name,
                            lang = rootState.dictionary.primary_lang,
                            lang_t = rootState.dictionary.translation_lang;

                        await dispatch('LOAD_TRANSLATES', {search, lang, lang_t})

                        let translation = await rootState.dictionary.translates.find(t => ~t.primary_word.audios.findIndex(a => a.id === request.id))
                        if (!translation) translation = await rootState.dictionary.translates.find(t => ~(t.translation_word.audios.findIndex(a => a.id === request.id)))

                        if (translation) await dispatch("LOAD_WORD_PANEL", translation)
                    } else if (request.out_of_place === 'selection') {
                        let audios = rootState.lesson.lesson_words[0]?.union.words[0].audios;
                        if (audios && audios[0]) audios.splice(0, 1, resp.data.data);
                        if (rootState.learning.selection?.unions) {
                            let card = rootState.learning.selection.unions.find(u => u.union.id === rootState.lesson.lesson_words[0].union.id);
                            if (card) card.union.words[0].audios.splice(0, 1, resp.data.data);
                        }
                    } else if (rootState.statistics.statistics_detail_panel && rootState.statistics.statistics_edited_id) {
                        await dispatch("GET_ONE_STATISTICS_ITEM", rootState.statistics.statistics_edited_id)
                            .then(data => commit("SAVE_STATISTICS_EDITED_DATA", data))
                    }
                    await commit("CHANGE_NEED_ACTION_AUDIO_DATA", null);
                    await commit("UPDATE_NEED_ACTION_TASK_INDEX", "edit");
                    await commit("ALERT_SHOW", {
                        name: 'need_action',
                        content: 'notice_material_sent_for_review',
                        duration: 500
                    });
                    await commit("CHANGE_LOADING_ADDITION_STATUS", false);
                })
                .catch(err => {
                    dispatch('ADDITION_ERROR_ACTION', err)
                    throw new Error(err)
                })
        },
        async ADD_OR_GET_WORD({commit}, word) {
            return new Promise((resolve, reject) => {
                axios
                    .post(Vue.prototype.$api_url + "/words/add-or-get", word)
                    .then(resp => resolve(resp))
                    .catch(err => reject(err))
                    .finally(() => commit)
            })
        },
        async SAVE_NEW_TRANSLATE({dispatch, rootState}, data) {
            let i, translates = rootState.dictionary.translates, canonical = rootState.dictionary.word.canonical;

            if (data.new_translate) {
                i = translates.findIndex(transl => transl.is_this_user === false);
            } else {
                let id = rootState.detail_word_page.translation_id;
                i = translates.findIndex(el => el.id === id);
            }

            let start_index = ~i ? i : translates.length;

            data['required'] = true
            await dispatch('LOAD_WORD_PANEL', data);
            await translates.splice(start_index, data.new_translate ? 0 : 1, data);
            await dispatch('UPDATE_SEARCH_WORD', {data: translates, canonical});
        },
        async SAVE_NEW_VOCABULARIES({rootState}, vocabularies) {
            let translate = rootState.dictionary.translates.find(el => el.id === rootState.detail_word_page.translation_id);

            if (translate) {
                let new_vocabs = vocabularies
                new_vocabs.forEach(v => {
                    if (rootState.detail_word_page.editable_vocabulary_ids.includes(v.id)) v['can_edit'] = true
                })
                translate.all_vocabularies = new_vocabs
            }
        },
        async LOAD_SENTENCES_EXAMPLE({commit}, request) {
            return new Promise(resolve => {
                if (example_lang !== request.lang || example_lang_t !== request.lang_t) {
                    example_lang = request.lang;
                    example_lang_t = request.lang_t;
                    if (example_lang && example_lang_t) {
                        axios
                            .get(`${Vue.prototype.$api_url}/translations/sentences/example?lang=${example_lang}&lang_t=${example_lang_t}`)
                            .then(resp => commit('SAVE_EXAMPLE_SENTENCE', resp.data.data))
                            .then(() => resolve())
                            .catch(error => error)
                    } else resolve()
                } else resolve()
            })
        },
        async ADDITION_FINISHED_ACTION({state, getters, dispatch, commit, rootState}, points) {
            let duration = 500,
                show_alert = true,
                needActionTaskId = sessionStorage.getItem('need_action_task_id'),
                moderationTaskId = sessionStorage.getItem('moderation_task_id');

            if (rootState.need_action.need_action_task_details) await commit("UPDATE_NEED_ACTION_TASK_INDEX", "edit");

            if ((router.app._route.query.out_of_place === 'tasks_list') && rootState.complaints.complain_task_id) {
                if (rootState.tasks.tasks[0]) {
                    await commit("SAVE_TASK_CONTINUE_ID", rootState.complaints.complain_task_id);
                    await commit("SAVE_COMPLAIN_TASK_ID", null);
                }
            }

            if (rootState.statistics.statistics_detail_panel && rootState.statistics.statistics_edited_id) {
                duration = 200;
                await dispatch("GET_ONE_STATISTICS_ITEM", rootState.statistics.statistics_edited_id)
                    .then(data => commit("SAVE_STATISTICS_EDITED_DATA", data))
                    .catch(e => {
                        commit("SAVE_STATISTICS_EDITED_DATA", {entity_type_slug: 'translation-attribute'})
                        console.error('WOLA:', e)
                    })

                if (rootState.other.before_edit_link) {
                    await router.replace(rootState.other.before_edit_link);
                    await commit("SAVE_BEFORE_EDIT_LINK", null);
                } else await dispatch("CLOSE_PAGE_BACK");
            } else if (['mini_dictionary', 'selection'].includes(router.app._route.query.out_of_place)) {
                duration = 200;
                if (rootState.other.before_edit_link) await commit("SAVE_BEFORE_EDIT_LINK", null);
                await dispatch("CLOSE_ADDITION_PAGE_BACK");
            } else if (moderationTaskId) {
                duration = 200;
                if (rootState.other.before_edit_link) {
                    await router.replace(rootState.other.before_edit_link);
                    await commit("SAVE_BEFORE_EDIT_LINK", null);
                } else await dispatch("CLOSE_PAGE_BACK", 'Moderation');
                await sessionStorage.removeItem('moderation_task_id');
            } else if (needActionTaskId) {
                duration = 200;
                if (rootState.other.before_edit_link) {
                    await router.replace(rootState.other.before_edit_link);
                    await commit("SAVE_BEFORE_EDIT_LINK", null);
                } else await dispatch("CLOSE_PAGE_BACK", 'TasksNeedAction');
                await sessionStorage.removeItem('need_action_task_id');
            } else {
                if (points) await dispatch('SAVE_ADDITION_NEW_POINTS', points)
                if (router.app._route.name !== 'DictionaryPage') {
                    if (router.app._route.query.task_id) {
                        show_alert = false;
                        state.addition_params['done'] = true;
                        await router.replace({name: 'TasksCards'});
                    } else await router.replace(getters.LOCALE.base + (rootState.dictionary.last_translate_link || '/'))
                }
            }
            await commit("STATISTICS_UPDATE")

            if (show_alert) {
                await commit("ALERT_SHOW", {
                    name: 'need_action',
                    content: 'notice_material_sent_for_review',
                    duration: duration + 100
                });
            }
            setTimeout(() => commit("CHANGE_LOADING_ADDITION_STATUS", false), duration)
        },
        async ADDITION_ERROR_ACTION({commit}, error) {
            await console.error('WOLA:', error)
            await commit("CHANGE_LOADING_ADDITION_STATUS", false)
            await commit("CHANGE_LOADING_DELETE_STATUS", false)
        },
        SAVE_ADDITION_NEW_POINTS({state, commit}, points) {
            state.addition_new_points += points;
            commit("DICTIONARY_RATING_UPDATE");
            commit("ACHIEVEMENTS_UPDATE");
            commit("STATISTICS_UPDATE");
        },
        async GET_OTHER_SENTENCE_TRANSLATIONS({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/word-sentences/${payload.sentence.id}/translations`)
                    .then(resp => {
                        if (resp.data.data.length > 0) {
                            commit("SAVE_OTHER_SENTENCE_TRANSLATIONS", {
                                type: payload.type,
                                source: payload.sentence,
                                translations: resp.data.data
                            });
                            commit("CHANGE_SENTENCE_TRANSLATIONS_POPUP", true);
                        }
                        resolve(resp.data.data.length > 0)
                    })
                    .catch(e => reject(e))
            })
        },
        async GET_OTHER_WORD_TRANSLATIONS({commit}, word) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/translations/words/${word.id}`)
                    .then(resp => {
                        if (resp.data.data.length > 0) {
                            commit("SAVE_OTHER_WORD_TRANSLATIONS", {
                                source: word,
                                translations: resp.data.data.map(t => t.translation_word)
                            });
                            commit("CHANGE_WORD_TRANSLATIONS_POPUP", true);
                        }
                        resolve(resp.data.data.length > 0)
                    })
                    .catch(e => reject(e))
            })
        }
    },
    mutations: {
        CLEAR_ADDITION_NEW_POINTS: (state) => state.addition_new_points = 0,
        SAVE_EDITABLE_SENTENCE: (state, data) => {
            if (!data) {
                state.editable_sentence = {}
                sessionStorage.removeItem('editable_sentence')
            } else {
                state.editable_sentence = data
                sessionStorage.setItem('editable_sentence', JSON.stringify(data))
            }
        },
        SAVE_EXAMPLE_SENTENCE: (state, sentence) => {
            let ex_sentence = Object.assign({}, sentence)
            if (sentence.translations[0]?.word_sentence) {
                ex_sentence.translations[0] = sentence.translations[0].word_sentence
            }
            state.example_sentence = ex_sentence
        },
        SAVE_NEW_TRANSLATES: (state, new_translates) => state.new_translates = new_translates,
        SAVE_OTHER_SENTENCE_TRANSLATIONS: (state, data) => state.other_sentence_translations = data,
        SAVE_OTHER_SENTENCE_TRANSLATIONS_ACTION: (state, data) => state.other_sentence_translations_action = data,
        SAVE_OTHER_WORD_TRANSLATIONS: (state, data) => state.other_word_translations = data,
        SAVE_NEW_ADDITION_PARAMS: (state, new_params) => state.addition_params = new_params,
    }
}
