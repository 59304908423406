<template>
    <w-alert v-if="showAlert"
             class="dictionary_alerts"
             :addition_alert="!!ALERT_NEW_POINTS"
             :points="ADDITION_NEW_POINTS"
             detail_page
             @mouseover="closeAlert"
    >
        <template v-if="LINK_COPY_STATUS">{{ $t('link_copied') }}</template>
        <template v-else-if="ALERT_NEW_POINTS">{{ $t(ALERT_NEW_POINTS) }}</template>
        <template v-else-if="ALERT_DETAIL">{{ $t(ALERT_DETAIL) }}</template>
        <template v-else-if="ALERT_COMPLAIN">{{ $t(ALERT_COMPLAIN) }}</template>
    </w-alert>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "dictionaryAlerts",
        computed: {
            showAlert() {
                if (this.WORD_PANEL || this.FAVORITE_PANEL || this.SENTENCES_PANEL || this.WORD_SENTENCES_PANEL || this.COMPLAIN_PANEL) {
                    return this.ALERT_DETAIL || this.ALERT_COMPLAIN || this.LINK_COPY_STATUS || (this.ALERT_NEW_POINTS && this.ADDITION_NEW_POINTS > 0)
                } else return false
            },
            ...mapState({
                'WORD_PANEL': state => state.panels.word_panel,
                'FAVORITE_PANEL': state => state.panels.favorite_panel,
                'WORD_SENTENCES_PANEL': state => state.panels.word_sentences_panel,
                'SENTENCES_PANEL': state => state.panels.sentences_panel,
                'COMPLAIN_PANEL': state => state.panels.complaint_panel,
                'ADDITION_NEW_POINTS': state => state.addition.addition_new_points,
                'ALERT_NEW_POINTS': state => state.alerts.alert_new_points,
                'LINK_COPY_STATUS': state => state.dictionary.link_copy_status,
                'ALERT_DETAIL': state => state.alerts.alert_detail,
                'ALERT_COMPLAIN': state => state.alerts.alert_complain,
            })
        },
        methods: {
            closeAlert() {
                this.$store.commit("SAVE_LINK_COPY_STATUS", false);
                this.$store.commit("CLOSE_ALERT", 'new_points');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dictionary_alerts {
        right: 0;
        padding: 0 40px;
        width: var(--panel_width1) !important;
    }

    @media screen and (max-width: 980px) {
        .dictionary_alerts {
            padding: 0;
            width: 100% !important;
        }
    }
</style>
