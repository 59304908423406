<template>
    <w-popup close_in
             @closePopup="closePopup"
    >
        <transition name="bounce" mode="out-in">
            <div v-if="unsubscribe_popup" key="unsubscribe_popup" class="wola_plus_unsubscribe none-select">
                <arrow_back class="wola_plus_unsubscribe-back" custom :show_text="false" @click="unsubscribe_popup = false"/>
                <div class="wola_plus_unsubscribe-patterns"/>
                <div class="wola_plus_unsubscribe-header bold">
                    {{ $t('confirm_unsubscribe_wola_plus_title') }}
                </div>
                <div v-if="plusCancelDate" class="wola_plus_unsubscribe-desc">
                    {{ $t('confirm_unsubscribe_wola_plus_desc', {word: plusCancelDate}) }}
                </div>
                <div class="wola_plus_unsubscribe-btns">
                    <w-button class="wola_plus_unsubscribe-btns--item"
                              red shadow
                              :loading="payment_loading"
                              @click="cancelWolaPlus"
                    >
                        {{ $t('cancel_subscription') }}
                    </w-button>
                    <w-button class="wola_plus_unsubscribe-btns--item"
                              grey
                              @click="closePopup"
                    >
                        {{ $t('leave_from_moderation') }}
                    </w-button>
                </div>
            </div>
            <div v-else-if="success_popup" key="success_popup" class="wola_plus_success none-select">
                <div class="wola_plus_success-patterns"/>
                <w-img folder-name="profile"
                       image-name="imageWolaPlusSuccess.png"
                       alt="imageWolaPlusSuccess"
                       always
                       class="wola_plus_success-image"
                />
                <div class="wola_plus_success-header semi-bold">
                    {{ $t('subscription_completed_title') }}
                </div>
                <div class="wola_plus_success-desc">
                    {{ $t('subscription_completed_desc') }}
                </div>
                <w-button class="wola_plus_success-btn"
                          premium
                          @click="closePopup"
                >
                    {{ $t('action_continue') }}
                </w-button>
            </div>
            <div v-else-if="canceled_popup" key="canceled_popup" class="wola_plus_success none-select">
                <div class="wola_plus_success-patterns"/>
                <w-img folder-name="profile"
                       image-name="imageWolaPlusCanceled.png"
                       alt="imageWolaPlusCanceled"
                       always
                       class="wola_plus_success-image"
                />
                <div class="wola_plus_success-header semi-bold">
                    {{ $t('subscription_canceled_title') }}
                </div>
                <div class="wola_plus_success-desc">
                    {{ $t('subscription_canceled_desc') }}
                </div>
                <w-button class="wola_plus_success-btn"
                          premium
                          @click="closePopup"
                >
                    {{ $t('action_continue') }}
                </w-button>
            </div>
            <div v-else-if="cant_cancel_popup" key="cant_cancel_popup" class="wola_plus_success no_ground none-select">
                <div class="wola_plus_success-header small semi-bold">
                    {{ $t('subscription_cant_cancel_title') }}
                </div>
                <div class="wola_plus_success-desc small">
                    {{ $t(cant_cancel_popup_free ? 'subscription_cant_cancel_free' : 'subscription_cant_cancel_desc') }}
                </div>
                <w-button class="wola_plus_success-btn"
                          shadow
                          @click="closePopup"
                >
                    {{ $t('action_continue') }}
                </w-button>
            </div>
            <div v-else-if="processing_popup" key="processing_popup" class="wola_plus_success none-select">
                <div class="wola_plus_success-patterns"/>
                <w-img folder-name="profile"
                       image-name="imageWolaPlusProcessing.png"
                       alt="imageWolaPlusProcessing"
                       always
                       class="wola_plus_success-image"
                />
                <div class="wola_plus_success-header semi-bold">
                    {{ $t('subscription_processing_title') }}
                </div>
                <div class="wola_plus_success-desc">
                    {{ $t('subscription_processing_desc') }}
                </div>
                <w-button class="wola_plus_success-btn"
                          shadow
                          @click="closePopup"
                >
                    {{ $t('action_continue') }}
                </w-button>
            </div>
            <div v-else key="plus_popup" class="wola_plus_popup none-select">
                <div class="wola_plus_popup__header">
                    <div class="wola_plus_popup__header_patterns"/>
                    <w-img folder-name="profile"
                           image-name="imageWolaPlus.png"
                           alt="imageWolaPlus"
                           always
                           class="wola_plus_popup__header_image"
                    />
                    <div v-if="USER.premium" class="wola_plus_popup__header_title bold">
                        Wola<span>Plus</span>
                        <w-svg-icon folder-name="main" icon-name="iconOkCircle"/>
                    </div>
                    <i18n v-else tag="div" path="plus_title" class="wola_plus_popup__header_title bold">
                        <template #word>
                            Wola<span>Plus</span>
                        </template>
                    </i18n>
                    <template v-if="!USER.premium">
                        <div class="wola_plus_popup__header_desc">
                            {{ $t('plus_desc') }}
                        </div>
                        <div class="wola_plus_popup__header_buttons">
                            <div class="wola_plus_popup__header_buttons-item pointer year"
                                 :class="{'active': yearActive}"
                                 @click="active_period = 'year'"
                            >
                                <div class="wola_plus_popup__header_buttons-item--period bold">
                                    {{ $t('month_format_3', {number: 12}) }}
                                </div>
                                <div class="wola_plus_popup__header_buttons-item--price">
                                    <div class="old-price">59,88 $</div>
                                    <div class="new-price">&nbsp;&nbsp;{{ year_price }} $</div>
                                    <div class="price">{{ $t('per_month', {word: (year_price / 12).toFixed(2) + ' $'}) }}</div>
                                </div>
                                <div class="wola_plus_popup__header_buttons-item--benefit bold none-events">
                                    {{ $t('plus_benefit_annual_price', {word: (((1 - (year_price / full_year_price)) * 100).toFixed(0)) + '%'}) }}
                                </div>
                            </div>
                            <div class="wola_plus_popup__header_buttons-item pointer"
                                 :class="{'active': !yearActive}"
                                 @click="active_period = 'month'"
                            >
                                <div class="wola_plus_popup__header_buttons-item--period bold">
                                    {{ $t('month_format_1', {number: 1}) }}
                                </div>
                                <div class="wola_plus_popup__header_buttons-item--price">
                                    <div class="price">{{ $t('per_month', {word: month_price + ' $'}) }}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="wola_plus_popup__header_info">
                            <template v-if="subscription_info">
                                <template v-if="subscription_info.source === 'PLAY_STORE'">{{ $t('subscription_issued_on_google') }}</template>
                                <template v-else-if="subscription_info.source === 'APP_STORE'">{{ $t('subscription_issued_on_apple') }}</template>
                                <template v-else-if="subscription_info.source === 'STRIPE'">{{ $t('subscription_issued_on_web') }}</template>
                                <template v-else-if="(subscription_info.source === 'MANUAL') && (manual_minutes > 10)">{{ $t('subscription_connected_by_admin') }}</template>
                            </template>
                        </div>
                        <div class="wola_plus_popup__header_subtitle semi-bold">
                            {{ $t('subscription_includes') }}
                        </div>
                    </template>
                </div>
                <div class="wola_plus_popup__benefits scrollable" @scroll="closeBenefitInfo">
                    <div v-for="b in benefits"
                         :key="b.id"
                         class="wola_plus_popup__benefits_item"
                         :class="USER.premium ? 'none-events': 'pointer'"
                         @click="openBenefitInfo($event, '.wola_plus_popup__benefits_item', b.id)"
                    >
                        <w-svg-icon class="wola_plus_popup__benefits_item-icon"
                                    folder-name="plus"
                                    :icon-name="b.icon"
                        />
                        <div class="wola_plus_popup__benefits_item-info">
                            <div class="wola_plus_popup__benefits_item-info--title semi-bold">{{ $t(b.title) }}</div>
                            <div class="wola_plus_popup__benefits_item-info--desc">{{ $t(b.description) }}</div>
                        </div>
                        <w-svg-icon v-if="!USER.premium"
                                    class="wola_plus_popup__benefits_item-arrow"
                                    folder-name="dictionary"
                                    icon-name="iconArrowTo"
                        />
                    </div>
                    <div class="wola_plus_popup__benefits_links">
                        <router-link :to="{name: 'TermsAndConditions'}" target="_blank">{{ $t('terms_conditions') }}</router-link>
                        &nbsp;•&nbsp;
                        <router-link :to="{name: 'PrivacyPolicy'}" target="_blank">{{ $t('privacy_policy') }}</router-link>
                    </div>
                </div>

                <transition name="bounce">
                    <w-loading v-if="payment_loading" blur :circle="false" class="wola_plus_popup__loading"/>
                </transition>

                <div v-if="USER.premium" class="wola_plus_popup__footer cancel">
                    <w-button class="wola_plus_popup__footer-btn"
                              grey
                              :loading="payment_loading"
                              @click="actionCancelSubscription"
                    >
                        {{ $t('cancel_subscription') }}
                    </w-button>
                </div>
                <div v-else class="wola_plus_popup__footer">
                    <w-button class="wola_plus_popup__footer-btn"
                              premium
                              :loading="payment_loading"
                              @click="purchaseAction"
                    >
                        {{ $t(yearActive ? 'plus_connect_for_year' : 'plus_connect_for_month', {number: actualPrice, word: '$'}) }}
                    </w-button>
                </div>
            </div>
        </transition>
    </w-popup>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import moment from "moment";
import arrow_back from "@/components/elements/arrow_back.vue";

export default {
    name: "popupWolaPlus",
    components: {arrow_back},
    data() {
        return {
            active_period: "year",
            full_year_price: '59.99',
            year_price: '39.99',
            month_price: '4.99',
            can_cancel: false,
            payment_loading: false,
            unsubscribe_popup: false,
            success_popup: false,
            canceled_popup: false,
            cant_cancel_popup: false,
            cant_cancel_popup_free: false,
            processing_popup: false,
            subscription_info: null,
            timer: null,
            paymentWindow: null,
            plusCancelDate: null,
            manual_minutes: null,
            benefits: [
                {id: 1, title: 'plus_benefit_1_title', description: 'plus_benefit_1_desc', icon: 'iconBenefit1'},
                {id: 2, title: 'plus_benefit_2_title', description: 'plus_benefit_2_desc', icon: 'iconBenefit2'},
                {id: 3, title: 'plus_benefit_3_title', description: 'plus_benefit_3_desc', icon: 'iconBenefit3'},
                {id: 4, title: 'plus_benefit_4_title', description: 'plus_benefit_4_desc', icon: 'iconBenefit4'},
                {id: 5, title: 'plus_benefit_5_title', description: 'plus_benefit_5_desc', icon: 'iconBenefit5'},
                {id: 6, title: 'plus_benefit_6_title', description: 'plus_benefit_6_desc', icon: 'iconBenefit6'},
                {id: 7, title: 'plus_benefit_7_title', description: 'plus_benefit_7_desc', icon: 'iconBenefit7'},
            ]
        }
    },
    computed: {
        ...mapGetters(['isLoggedIn', 'USER']),
        ...mapState({
            'WOLA_PLUS_POPUP': state => state.panels.wola_plus_popup,
            'PLUS_BENEFIT_DATA': state => state.other.plus_benefit_data,
        }),
        actualPrice() {
            if (this.yearActive) return this.year_price
            else return this.month_price
        },
        yearActive() {
            return this.active_period === 'year'
        }
    },
    watch: {
        $route: 'closePopup'
    },
    created() {
        if (this.isLoggedIn && this.USER.premium) {
            this.payment_loading = true;

            this.$store
                .dispatch("GET_WOLA_PLUS_SUBSCRIPTION_INFO")
                .then(info => {
                    this.subscription_info = info;
                    let e_date = new Date(this.subscription_info.expires_at), n_date = new Date();
                    this.manual_minutes = ((e_date - n_date) / 60000).toFixed(0);
                })
                .catch(() => this.can_cancel = false)
                .finally(() => this.payment_loading = false)
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        async actionCancelSubscription() {
            if (this.isLoggedIn && this.USER.premium && this.subscription_info) {
                if (this.subscription_info.source === 'STRIPE') {
                    this.plusCancelDate = moment(this.subscription_info.expires_at).format('D MMMM');
                    this.can_cancel = true;
                } else if (this.subscription_info.source === 'MANUAL') {
                    if (this.manual_minutes < 10) {
                        this.processing_popup = true;
                    } else {
                        this.cant_cancel_popup = this.cant_cancel_popup_free = true;
                    }
                }

                if (this.can_cancel) {
                    this.unsubscribe_popup = true;
                } else if (!this.processing_popup) {
                    this.cant_cancel_popup = true;
                }
            }
        },
        async cancelWolaPlus() {
            this.payment_loading = true;

            await this.$store
                .dispatch("CANCEL_WOLA_PLUS_SUBSCRIPTION")
                .then(() => {
                    this.canceled_popup = true;
                    this.unsubscribe_popup = false;
                })
                .finally(() => this.payment_loading = false)
        },
        closeBenefitInfo() {
            this.$store.commit("CHANGE_PLUS_BENEFIT_DATA", null)
        },
        closePopup() {
            let duration = 0;

            if (!this.payment_loading || !this.paymentWindow) {
                if (this.PLUS_BENEFIT_DATA) {
                    duration = 300;
                    this.closeBenefitInfo();
                }
                setTimeout(() => this.$store.commit("CHANGE_WOLA_PLUS_POPUP", false), duration);
            } else if (this.paymentWindow) {
                let popup = this.$el.querySelector('.wola_plus_popup')

                if (popup) {
                    duration = 300;
                    popup.parentElement.classList.add('close_payment');
                    setTimeout(() => popup.parentElement.classList.remove('close_payment'), 300);
                }

                setTimeout(() => this.paymentWindow.focus(), duration);
            }
        },
        openBenefitInfo(e, el_class, benefit_id) {
            if (!this.USER.premium) {
                this.$store.commit("CHANGE_PLUS_BENEFIT_DATA", {
                    benefit_id: benefit_id,
                    parent_class: el_class,
                    parent_event_target: e.target,
                    popup_type: true
                })
            }
        },
        purchaseAction() {
            if (!this.isLoggedIn) this.$router.push({name: 'Auth'})
            else {
                this.payment_loading = true;

                setTimeout(() => {
                    this.paymentWindow = window.open(`${this.$store.getters.LOCALE.base}/wola-plus-payment/${this.active_period}`, "_blanc");

                    this.timer = setInterval(() => {
                        if (!this.paymentWindow) {
                            clearInterval(this.timer);
                            this.payment_loading = false;
                        } else if (this.paymentWindow.closed || !this.paymentWindow.location.pathname.includes('/wola-plus-payment/')) {
                            clearInterval(this.timer);
                            this.payment_loading = false;

                            if (this.USER.premium && this.paymentWindow.document.URL.includes("/wola-plus-payment/verification")) this.success_popup = true;
                            else this.paymentWindow = null;
                        }
                    }, 500);
                }, 300)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .wola_plus_popup {
        width: 640px;
        max-height: 90vh;
        padding: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: inherit;

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 100%;
            border-radius: inherit;
            padding: 20px 60px 0;
            z-index: 5;

            &::after, &::before {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;
            }

            &::before {
                background-image: -webkit-linear-gradient(88deg, #F1E0E7 6.31%, #D9D7F0 97.07%);
                background-image: linear-gradient(88deg, #F1E0E7 6.31%, #D9D7F0 97.07%);
            }

            &::after {
                z-index: 2;
                background-image: -webkit-linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
                background-image: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
            }

            &_patterns {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: 100%;
                z-index: 1;
                background-image: url("~@/assets/images/profile/imageWolaPlusPatterns.png");
            }

            &_image {
                width: 120px;
                aspect-ratio: 1;
                z-index: 3;
            }

            &_title {
                display: flex;
                align-items: center;
                font-size: 24px;
                line-height: 32px;
                margin-top: 12px;
                margin-bottom: 4px;
                z-index: 3;

                span {
                    background-image: -webkit-linear-gradient(103deg, #402EC2 5.54%, #916B96 96.8%);
                    background-image: linear-gradient(103deg, #402EC2 5.54%, #916B96 96.8%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                svg {
                    width: 24px;
                    height: 24px;
                    margin-left: 4px;
                }
            }

            &_desc {
                font-size: 16px;
                line-height: 24px;
                padding: 0 50px;
                z-index: 3;
            }

            &_buttons {
                display: flex;
                align-items: center;
                z-index: 3;
                width: 100%;
                margin-top: 24px;
                box-shadow: 0 14px 12px 3px var(--w_color_white);

                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    padding: 16px;
                    height: 88px;
                    width: 48%;
                    white-space: nowrap;
                    border-radius: 20px;
                    border: 1px solid rgba(0, 0, 0, 0.10);
                    background: transparent;

                    &.year {
                        flex-direction: column;
                        align-items: flex-start;
                        width: 55%;

                        .wola_plus_popup__header_buttons-item--price {
                            width: 100%;
                            margin: 8px 0 0;
                        }
                    }

                    &.active {
                        border: 1px solid #D6B5C4 !important;
                        background-image: -webkit-linear-gradient(93deg, #FCEEF4 2.12%, #EAE8FB 97.73%) !important;
                        background-image: linear-gradient(93deg, #FCEEF4 2.12%, #EAE8FB 97.73%) !important;
                    }

                    &:first-of-type {
                        margin-right: 8px;
                    }

                    &--period {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    &--price {
                        font-size: 14px;
                        line-height: 16px;
                        margin-left: auto;
                        display: inline-flex;

                        .price {
                            margin-left: auto;
                        }

                        .old-price {
                            text-decoration: line-through;
                        }

                        .old-price, .new-price {
                            opacity: 0.5;
                        }
                    }

                    &--benefit {
                        position: absolute;
                        top: -1px;
                        right: -1px;
                        padding: 9px 14px;
                        font-size: 12px;
                        line-height: 14px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        border-radius: 0 16px;
                        color: var(--w_color_white);
                        background-image: -webkit-linear-gradient(94deg, #6451DB 0%, #8C699A 100%);
                        background-image: linear-gradient(94deg, #6451DB 0%, #8C699A 100%);
                    }
                }
            }

            &_subtitle {
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 1px;
                text-transform: uppercase;
                text-align: left;
                width: 100%;
                margin-top: 28px;
                padding: 0 50px;
                z-index: 3;
                color: var(--w_color_grey6);
                box-shadow: 0 10px 10px 10px var(--w_color_white);
            }

            &_info {
                font-size: 14px;
                line-height: 16px;
                min-height: 16px;
                z-index: 3;
                color: var(--w_color_grey6);
            }
        }

        &__benefits {
            display: flex;
            flex-direction: column;
            width: calc(100% - 6px);
            padding: 20px 95px 50px;
            margin: 0 3px;
            max-height: 44vh;

            &_item {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
                padding: 12px;
                border-radius: 12px;

                &.pointer:hover {
                    background: var(--w_color_grey2);
                }

                &-icon {
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                }

                &-info {
                    line-height: 20px;
                    width: 100%;
                    text-align: left;
                    margin: 0 12px;

                    &--title {
                        font-size: 16px;
                    }

                    &--desc {
                        font-size: 14px;
                        color: var(--w_color_grey6);
                    }
                }

                &-arrow {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                }
            }

            &_links {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                line-height: 20px;
                margin-top: 8px;
                padding-top: 12px;
                color: var(--w_color_grey4);
                border-top: 1px solid var(--w_color_grey7);

                a {
                    color: var(--w_color_grey6);

                    &:hover {
                        color: var(--w_color_dark) !important;
                    }
                }
            }
        }

        &__loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 30px);
            border-radius: 40px;
            overflow: hidden;
            z-index: 9;
        }

        &__footer {
            z-index: 10;
            width: 100%;
            padding: 12px 60px;
            border-top: 1px solid var(--w_color_grey7);

            &.cancel {
                padding: 12px 95px;
            }

            &-btn {
                width: 100%;
            }
        }
    }

    .wola_plus_unsubscribe {
        width: 640px;
        height: 600px;
        padding: 70px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: inherit;
        position: relative;

        &::after, &::before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 62%;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }

        &::before {
            background-image: -webkit-linear-gradient(88deg, #F1E0E7 6.31%, #D9D7F0 97.07%);
            background-image: linear-gradient(88deg, #F1E0E7 6.31%, #D9D7F0 97.07%);
        }

        &::after {
            z-index: 2;
            background-image: -webkit-linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
            background-image: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
        }

        &-patterns {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 62%;
            background-size: 100%;
            z-index: 1;
            background-image: url("~@/assets/images/profile/imageWolaPlusPatterns.png");
        }

        &-back {
            position: absolute !important;
            top: 28px;
            left: 28px;
        }

        &-header, &-desc, &-btns {
            z-index: 3;
        }

        &-header, &-desc {
            padding: 0 50px;
        }

        &-header {
            font-size: 28px;
            line-height: 32px;
        }

        &-desc {
            font-size: 16px;
            line-height: 24px;
            margin-top: 8px;
            color: var(--w_color_grey6);
        }

        &-btns {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 24px;

            &--item {
                width: 100%;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }

    .wola_plus_success {
        width: 640px;
        padding: 70px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: inherit;
        position: relative;

        &.no_ground {
            &::after, &::before {
                content: none !important;
            }
        }

        &::after, &::before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 62%;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }

        &::before {
            background-image: -webkit-linear-gradient(88deg, #F1E0E7 6.31%, #D9D7F0 97.07%);
            background-image: linear-gradient(88deg, #F1E0E7 6.31%, #D9D7F0 97.07%);
        }

        &::after {
            z-index: 2;
            background-image: -webkit-linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
            background-image: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
        }

        &-patterns {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 62%;
            background-size: 80%;
            z-index: 1;
            background-image: url("~@/assets/images/profile/imageWolaPlusPatterns.png");
        }

        &-header, &-desc, &-btn, &-image {
            z-index: 3;
        }

        &-image {
            width: 260px;
            height: 260px;
            margin-bottom: 20px;
        }

        &-header, &-desc {
            padding: 0 30px;

            &.small {
                padding: 0 45px;
            }
        }

        &-header {
            font-size: 28px;
            line-height: 32px;
        }

        &-desc {
            font-size: 20px;
            line-height: 28px;
            margin-top: 8px;
            color: var(--w_color_grey6);

            &.small {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &-btn {
            margin-top: 12px;
        }
    }

    @media (max-width: 1440px) and (min-width: 981px) {
        .wola_plus_popup {
            width: 428px;

            &__header {
                padding: 14px 40px 0;

                &_image {
                    width: 80px;
                }

                &_title {
                    font-size: 16px;
                    line-height: 22px;
                    margin-top: 8px;
                    margin-bottom: 3px;

                    svg {
                        width: 16px;
                        height: 16px;
                        margin-left: 3px;
                    }
                }

                &_desc {
                    font-size: 11px;
                    line-height: 16px;
                    padding: 0 34px;
                }

                &_buttons {
                    margin-top: 16px;

                    &-item {
                        padding: 11px;
                        height: 58px;
                        border-radius: 14px;

                        &.year .wola_plus_popup__header_buttons-item--price {
                            margin: 5px 0 0;
                        }

                        &:first-of-type {
                            margin-right: 5px;
                        }

                        &--period {
                            font-size: 14px;
                            line-height: 16px;
                        }

                        &--price {
                            font-size: 9px;
                            line-height: 11px;
                        }

                        &--benefit {
                            padding: 6px 9px;
                            font-size: 8px;
                            line-height: 9px;
                            border-radius: 0 11px;
                        }
                    }
                }

                &_subtitle {
                    font-size: 8px;
                    line-height: 11px;
                    margin-top: 19px;
                    padding: 0 34px;
                }

                &_info {
                    font-size: 9px;
                    line-height: 11px;
                    min-height: 11px;
                }
            }

            &__benefits {
                padding: 14px 62px 33px;
                margin: 0 2px;

                &_item {
                    padding: 8px;
                    border-radius: 8px;

                    &-icon {
                        width: 16px;
                        height: 16px;
                        min-width: 16px;
                    }

                    &-info {
                        line-height: 14px;
                        margin: 0 8px;

                        &--title {
                            font-size: 11px;
                        }

                        &--desc {
                            font-size: 9px;
                        }
                    }

                    &-arrow {
                        width: 14px;
                        height: 14px;
                        min-width: 14px;
                    }
                }

                &_links {
                    font-size: 9px;
                    line-height: 14px;
                    margin-top: 5px;
                    padding-top: 8px;
                }
            }

            &__loading {
                height: calc(100% - 20px);
                border-radius: 28px;
            }

            &__footer {
                padding: 8px 40px;

                &.cancel {
                    padding: 8px 62px;
                }
            }
        }

        .wola_plus_unsubscribe {
            width: 428px;
            height: 400px;
            padding: 46px;

            &-back {
                top: 20px;
                left: 20px;
            }

            &-header, &-desc {
                padding: 0 33px;
            }

            &-header {
                font-size: 19px;
                line-height: 22px;

            }

            &-desc {
                font-size: 11px;
                line-height: 16px;
                margin-top: 5px;
            }

            &-btns {
                margin-top: 16px;

                &--item:first-child {
                    margin-right: 14px;
                }
            }
        }

        .wola_plus_success {
            width: 428px;
            padding: 46px;

            &-image {
                width: 174px;
                height: 174px;
                margin-bottom: 14px;
            }

            &-header, &-desc {
                padding: 0 20px;
            }

            &-header {
                font-size: 19px;
                line-height: 22px;
            }

            &-desc {
                font-size: 14px;
                line-height: 18px;
                margin-top: 5px;
            }

            &-btn {
                margin-top: 8px;
            }
        }
    }

    [data-theme='dark'] {
        .wola_plus_popup {
            background-color: var(--w_color_grey2);

            &__header {
                &::before {
                    background-image: -webkit-linear-gradient(88deg, #60434F 6.31%, #4E498A 97.07%);
                    background-image: linear-gradient(88deg, #60434F 6.31%, #4E498A 97.07%);
                }

                &::after {
                    background-image: -webkit-linear-gradient(0deg, var(--w_color_grey2) 0%, rgba(255, 255, 255, 0.00) 100%);
                    background-image: linear-gradient(0deg, var(--w_color_grey2) 0%, rgba(255, 255, 255, 0.00) 100%);
                }

                &_patterns {
                    mix-blend-mode: screen;
                }

                &_buttons {
                    box-shadow: 0 14px 12px 3px var(--w_color_grey2);

                    &-item {
                        border: 1px solid rgba(255, 255, 255, 0.20);

                        &.active {
                            color: var(--w_color_dark_only);
                        }
                    }
                }

                &_subtitle {
                    color: var(--w_color_grey4);
                    box-shadow: 0 10px 10px 10px var(--w_color_grey2);
                }

                &_info {
                    color: var(--w_color_grey4);
                }
            }

            &__benefits {
                &_item {
                    &.pointer:hover {
                        background: var(--w_color_grey7);
                    }

                    &-info--desc {
                        color: var(--w_color_grey4);
                    }
                }

                &_links {
                    color: var(--w_color_grey5);
                    border-top: 1px solid var(--w_color_grey3);

                    a {
                        color: var(--w_color_grey4);
                    }
                }
            }

            &__footer {
                border-top: 1px solid var(--w_color_grey3);

                &-btn.grey {
                    background-color: var(--w_color_grey7);
                }
            }
        }

        .wola_plus_unsubscribe, .wola_plus_success {
            background-color: var(--w_color_grey2);

            &::before {
                background-image: -webkit-linear-gradient(88deg, #60434F 6.31%, #4E498A 97.07%);
                background-image: linear-gradient(88deg, #60434F 6.31%, #4E498A 97.07%);
            }

            &::after {
                background-image: -webkit-linear-gradient(0deg, var(--w_color_grey2) 0%, rgba(255, 255, 255, 0.00) 100%);
                background-image: linear-gradient(0deg, var(--w_color_grey2) 0%, rgba(255, 255, 255, 0.00) 100%);
            }

            &-patterns {
                mix-blend-mode: screen;
            }

            &-desc {
                color: var(--w_color_grey4);
            }

            &-btns--item.grey {
                background-color: var(--w_color_grey7);

                &:hover {
                    background-color: var(--w_color_grey3);
                }
            }
        }
    }
</style>
