import axios from "axios";
import Vue from "vue";
import {v4 as uuidv4} from 'uuid';
import router from "@/router";

const getDefaultState = () => {
    return {
        feed: [],
        feed_limit: 20,
        feed_all_loaded: false,
        feed_active_tab: 'all',

        deleted_post_data: null,
        deleted_feed_items: [],

        post_details: null,
        post_comments: [],
        post_comments_all_loaded: false,

        question_details: null,
        question_answers: [],
        question_answers_all_loaded: false,

        article_details: null,
        article_edited_details: null,
        article_comments: [],
        article_comments_all_loaded: false,
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        GET_FEED({state, commit}, payload = {}) {
            return new Promise((resolve, reject) => {
                let URL,
                    params = {
                        limit: state.feed_limit,
                        type: payload.type,
                    };

                if (payload.pagination_uuid) params['pagination_uuid'] = payload.pagination_uuid;

                if (payload.tab === 'all') {
                    URL = Vue.prototype.$api_url + '/feed/public';
                    if (payload.lang_codes) params['lang_codes'] = payload.lang_codes;
                    if (payload.fallback) params['fallback'] = payload.fallback;
                } else if (payload.tab === 'subscriptions') {
                    URL = Vue.prototype.$api_url + '/feed/subscriptions';
                } else if (payload.tab === 'user') {
                    URL = Vue.prototype.$api_url + '/feed/users/' + payload.author_uuid;
                }

                if (!params.pagination_uuid && !payload.author_uuid) {
                    commit("SAVE_FEED");
                    commit("SAVE_FEED_ALL_LOADED", false);
                }
                if (!payload.author_uuid && params.lang_codes) params['lang_codes'] = payload.lang_codes.map(l => l);

                if (URL) {
                    axios
                        .get(URL, {params})
                        .then((response) => {
                            let resp = response.data.data;
                            if (resp.length < state.feed_limit) commit("SAVE_FEED_ALL_LOADED", true);
                            if (!payload.author_uuid) commit("SAVE_FEED", resp);
                            resolve(resp);
                        })
                        .catch((err) => {
                            reject(err)
                            throw Error(err)
                        })
                }
            })
        },

        async CREATE_FEED_POST({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(Vue.prototype.$api_url + "/posts", payload)
                    .then(resp => resolve(resp.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        async EDIT_FEED_POST({commit}, payload) {
            await axios
                .put(Vue.prototype.$api_url + "/posts/" + payload.uuid, payload.data)
                .then((response) => {
                    commit("UPDATE_POST", response.data.data);
                })
                .catch((err) => {
                    throw Error(err);
                })
        },
        SEND_FEED_POST_IMAGE({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url}/posts/${payload.uuid}/images`, payload.image)
                    .then((resp) => {
                        commit("UPDATE_POST_IMAGES", {type: 'add', post_uuid: payload.uuid, image: resp.data.data});
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
        DELETE_FEED_POST_IMAGE({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${Vue.prototype.$api_url}/posts/${payload.post_uuid}/images/${payload.image_uuid}`)
                    .then(() => {
                        commit("UPDATE_POST_IMAGES", {
                            type: 'delete',
                            post_uuid: payload.post_uuid,
                            image_uuid: payload.image_uuid
                        });
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
        async UPDATE_POST_STATUS({state}, payload) {
            axios
                .patch(`${Vue.prototype.$api_url}/posts/${payload.uuid}/status`, {status: payload.status})
                .then(() => {
                    state;
                })
                .catch((err) => {
                    throw Error(err)
                })
        },
        GET_ONE_POST({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/posts/${payload.uuid}`)
                    .then(resp => {
                        if (payload.resolve) resolve(resp.data.data);
                        else {
                            commit("SAVE_POST_DETAILS", resp.data.data);
                            resolve();
                        }
                    })
                    .catch((err) => {
                        reject(err);
                        throw Error(err);
                    })
            })
        },
        async GET_POST_COMMENTS({state, commit}, uuid) {
            let params = {"limit": state.feed_limit};

            if (state.post_comments.length) params["pagination_id"] = state.post_comments[state.post_comments.length - 1].id;

            await axios
                .get(`${Vue.prototype.$api_url}/posts/${uuid}/comments`, {params})
                .then(resp => {
                    let comments = resp.data.data;
                    commit("SAVE_POST_COMMENTS", comments);
                    if (comments.length < state.feed_limit) commit("SAVE_POST_COMMENTS_ALL_LOADED", true);
                })
                .catch((err) => {
                    throw Error(err);
                })
        },
        SEND_POST_COMMENT({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url}/posts/${payload.uuid}/comments`, payload.data)
                    .then(resp => {
                        let comment = resp.data.data;
                        commit("SAVE_POST_COMMENTS", comment);
                        resolve();
                    })
                    .catch((err) => reject(err))
            })
        },
        async TOGGLE_LIKE_POST({commit}, payload) {
            let uuid = payload.uuid,
                is_liked = payload.is_liked,
                type = is_liked ? "unlike" : "like",
                likes_total = payload.likes_total;

            await commit("SAVE_POST_LIKE_STATUS", {
                uuid: uuid,
                liked_by_user: !is_liked,
                likes_total: is_liked ? likes_total - 1 : likes_total + 1
            })

            await axios
                .post(`${Vue.prototype.$api_url}/posts/${uuid}/${type}`)
                .then(resp => {
                    commit("SAVE_POST_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: resp.data.data.liked_by_user,
                        likes_total: resp.data.data.count
                    });
                })
                .catch((err) => {
                    commit("SAVE_POST_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: is_liked,
                        likes_total: likes_total
                    });
                    console.error(err);
                })
        },
        async DELETE_FEED_ITEM({state, dispatch, commit}, payload) {
            let data = payload || state.deleted_post_data;

            if (data) {
                await commit("CHANGE_LOADING_DELETE_STATUS", true);

                let query_type = ''

                if (data.type === 'post') query_type = 'posts'
                else if (data.type === 'article') query_type = 'articles'
                else if (data.type === 'question') query_type = 'questions'

                if (query_type) {
                    await axios
                        .delete(`${Vue.prototype.$api_url}/${query_type}/${data.uuid}`)
                        .then(async () => {
                            if (query_type === 'posts') {
                                await commit("DELETE_POST_FROM_FEED", data.uuid);
                            } else if (query_type === 'articles') {
                                await commit("DELETE_ARTICLE_FROM_FEED", data.uuid);
                                if (router.app._route.name === 'ArticlePage') await dispatch("CLOSE_PAGE_BACK");
                            } else if (query_type === 'questions') {
                                await commit("DELETE_QUESTION_FROM_FEED", data.uuid);
                            }

                            await commit("SAVE_DELETED_FEED_ITEMS", [data.uuid]);
                            await commit("DELETE_POSITION_FROM_FEED", data.uuid);
                            await commit("SAVE_DELETED_FEED_ITEM_DATA", null);
                        })
                        .catch((err) => {
                            dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'});
                            throw new Error(err);
                        })
                        .finally(() => {
                            commit("CHANGE_LOADING_DELETE_STATUS", false);
                        })
                }
            }
        },
        async TOGGLE_LIKE_POST_COMMENT({state, commit}, payload) {
            let uuid = state.post_details.uuid,
                like = payload.liked_by_user,
                likes_total = payload.likes_total;

            await commit("SAVE_POST_COMMENT_LIKE_STATUS", {
                uuid: uuid,
                comment_id: payload.comment_id,
                liked_by_user: !like,
                likes_total: like ? likes_total - 1 : likes_total + 1
            })

            await axios
                .post(`${Vue.prototype.$api_url}/posts/${uuid}/comments/${payload.comment_id}/${like ? 'unlike' : 'like'}`)
                .then((resp) => {
                    commit("SAVE_POST_COMMENT_LIKE_STATUS", {
                        uuid: uuid,
                        comment_id: payload.comment_id,
                        liked_by_user: resp.data.data.liked_by_user,
                        likes_total: resp.data.data.count
                    });
                })
                .catch(err => {
                    commit("SAVE_POST_COMMENT_LIKE_STATUS", {
                        uuid: uuid,
                        comment_id: payload.comment_id,
                        liked_by_user: like,
                        likes_total: likes_total
                    });
                    console.error(err);
                })
        },
        async EDIT_POST_COMMENT({state, commit}, payload) {
            const old_comment = payload.comment.comment;
            await commit("EDIT_POST_COMMENT", payload);
            await axios
                .put(`${Vue.prototype.$api_url}/posts/${state.post_details.uuid}/comments/${payload.comment.uuid}`, {comment: payload.new_comment})
                .catch(() => {
                    payload['new_comment'] = old_comment;
                    commit("EDIT_POST_COMMENT", payload);
                })
        },
        DELETE_POST_COMMENT({state, commit}, comment) {
            axios.delete(`${Vue.prototype.$api_url}/posts/${state.post_details.uuid}/comments/${comment.uuid}`)
            commit("DELETE_POST_COMMENT_FROM_LIST", comment)
        },

        async CREATE_FEED_QUESTION({state}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(Vue.prototype.$api_url + "/questions", payload)
                    .then((response) => {
                        state.feed.unshift(response.data.data);
                        resolve(response.data.data);
                    })
                    .catch((err) => reject(err))
            })
        },
        async EDIT_FEED_QUESTION({commit}, payload) {
            await axios
                .put(Vue.prototype.$api_url + "/questions/" + payload.uuid, payload.data)
                .then((response) => {
                    commit("UPDATE_QUESTION", response.data.data);
                })
                .catch((err) => {
                    throw Error(err);
                })
        },
        GET_ONE_QUESTION({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/questions/${payload.uuid}`)
                    .then(resp => {
                        if (payload.resolve) resolve(resp.data.data);
                        else {
                            commit("SAVE_QUESTION_DETAILS", resp.data.data);
                            resolve();
                        }
                    })
                    .catch((err) => {
                        reject(err);
                        throw Error(err);
                    })
            })
        },
        async GET_QUESTION_ANSWERS({state, commit}, payload) {
            let params = {"limit": state.feed_limit};

            if (payload.order_by) params["order_by"] = payload.order_by;
            if (payload.update) state.question_answers = [];
            else if (state.question_answers.length) params["pagination_uuid"] = state.question_answers[state.question_answers.length - 1].uuid;

            await axios
                .get(`${Vue.prototype.$api_url}/questions/${payload.uuid}/answers`, {params})
                .then(resp => {
                    let answers = resp.data.data;
                    commit("SAVE_QUESTION_ANSWERS", {answers});
                    if (answers.length < state.feed_limit) commit("SAVE_QUESTION_ANSWERS_ALL_LOADED", true);
                })
                .catch((err) => {
                    throw Error(err);
                })
        },
        async GET_QUESTION_ANSWERS_REPLIES({state, commit}, payload) {
            let params = {"limit": state.feed_limit};

            if (payload.pagination_uuid) params["pagination_uuid"] = payload.pagination_uuid;

            await axios
                .get(`${Vue.prototype.$api_url}/questions/${state.question_details.uuid}/answers/${payload.answer_uuid}/replies`, {params})
                .then(resp => {
                    let replies = resp.data.data.replies;
                    commit("SAVE_QUESTION_ANSWERS_REPLIES", {
                        replies,
                        answer_uuid: payload.answer_uuid,
                        more_replies_to_load: resp.data.data.more_replies_to_load
                    });
                })
                .catch((err) => {
                    throw Error(err);
                })
        },
        SEND_QUESTION_ANSWER({state, commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url}/questions/${state.question_details.uuid}/answers`, data)
                    .then(resp => {
                        let answers = resp.data.data;
                        commit("SAVE_QUESTION_ANSWERS", {answers, data});
                        resolve();
                    })
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    })
            })
        },
        async TOGGLE_LIKE_QUESTION({commit}, payload) {
            let uuid = payload.uuid,
                is_liked = payload.is_liked,
                type = is_liked ? "unlike" : "like",
                likes_total = payload.likes_total;

            await commit("SAVE_QUESTION_LIKE_STATUS", {
                uuid: uuid,
                liked_by_user: !is_liked,
                likes_total: is_liked ? likes_total - 1 : likes_total + 1
            })

            await axios
                .post(`${Vue.prototype.$api_url}/questions/${uuid}/${type}`)
                .then(resp => {
                    commit("SAVE_QUESTION_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: resp.data.data.liked_by_user,
                        likes_total: resp.data.data.count
                    });
                })
                .catch((err) => {
                    commit("SAVE_QUESTION_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: is_liked,
                        likes_total: likes_total
                    });
                    console.error(err);
                })
        },
        async EDIT_USER_ANSWER({state, commit}, payload) {
            const old_answer = payload.answer.answer;
            await commit("EDIT_USER_ANSWER_FROM_LIST", payload);
            await axios
                .put(`${Vue.prototype.$api_url}/questions/${state.question_details.uuid}/answers/${payload.answer.uuid}`, {answer: payload.new_answer})
                .catch(() => {
                    payload['new_answer'] = old_answer;
                    commit("EDIT_USER_ANSWER_FROM_LIST", payload);
                })
        },
        DELETE_USER_ANSWER({state, commit}, payload) {
            axios.delete(`${Vue.prototype.$api_url}/questions/${state.question_details.uuid}/answers/${payload.answer.uuid}`)
            commit("DELETE_USER_ANSWER_FROM_LIST", payload)
        },
        async SEND_USER_ANSWER_VOTE({commit}, payload) {
            let add_count = payload.voted_by_user ? 2 : 1;

            await commit("SAVE_QUESTION_VOTE_STATUS", {
                "uuid": payload.uuid,
                "answer_uuid": payload.answer_uuid,
                "data": {
                    "count": (payload.type === 'plus') ? (payload.count + add_count) : (payload.count - add_count),
                    "voted_by_user": true,
                    "users_vote_type": payload.type,
                }
            })

            await axios
                .post(`${Vue.prototype.$api_url}/questions/${payload.uuid}/answers/${payload.answer_uuid}/vote/${payload.type}`)
                .then(resp => {
                    commit("SAVE_QUESTION_VOTE_STATUS", {
                        "uuid": payload.uuid,
                        "answer_uuid": payload.answer_uuid,
                        "data": {
                            "count": resp.data.data.count,
                            "voted_by_user": resp.data.data.voted_by_user,
                            "users_vote_type": resp.data.data.users_vote_type,
                        }
                    })
                })
                .catch((err) => {
                    commit("SAVE_QUESTION_VOTE_STATUS", {
                        "uuid": payload.uuid,
                        "answer_uuid": payload.answer_uuid,
                        "data": {
                            "count": payload.count,
                            "voted_by_user": payload.voted_by_user,
                            "users_vote_type": payload.users_vote_type,
                        }
                    })
                    console.error(err);
                })
        },
        async PIN_FEED_ITEM({state, dispatch}, payload) {
            let data,
                method,
                pin = payload.type === 'pin',
                url = `${Vue.prototype.$api_url}/feed/${payload.uuid}/${payload.type}`;

            if (pin) {
                method = 'POST';
                data = {'pin_type': payload.pin_type};
            } else {
                method = 'DELETE';
                url += '?pin_type=' + payload.pin_type;
            }

            await axios({method, url, data})
                .then(() => {
                    let feed_index = state.feed.findIndex(u => u.pagination_uuid === payload.uuid);

                    if (~feed_index) {
                        state.feed[feed_index].is_pinned = pin;
                        if (pin) {
                            const union = state.feed[feed_index];
                            state.feed.splice(feed_index, 1);
                            state.feed.unshift(union);
                        }
                    }
                })
                .catch(e => {
                    if (e.message === 'FEED_POSITION_ALREADY_PINNED') {
                        dispatch('ERROR_SHOW', {name: 'system', content: 'feed_position_already_pinned_error'});
                    } else {
                        dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'});
                    }
                })
        },

        async CREATE_FEED_ARTICLE({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(Vue.prototype.$api_url + "/articles", payload.data)
                    .then(resp => resolve(resp.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        async CHANGE_ARTICLE_PUBLISH_STATUS({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(Vue.prototype.$api_url + "/articles/" + payload.uuid + "/status", {status: payload.status})
                    .then(resp => {
                        commit("UPDATE_ARTICLE_PUBLISH_STATUS_FROM_FEED", payload);
                        if (payload.status === 'published') commit("ALERT_SHOW", {
                            name: 'feed_complain',
                            content: 'article_published'
                        });
                        resolve(resp.data.data);
                    })
                    .catch((err) => {
                        dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'});
                        reject(err);
                        throw new Error(err);
                    })
            })
        },
        async EDIT_FEED_ARTICLE({commit}, payload) {
            await axios
                .put(Vue.prototype.$api_url + "/articles/" + payload.uuid, payload.data)
                .then((response) => {
                    commit("UPDATE_ARTICLE", response.data.data);
                })
                .catch((err) => {
                    throw Error(err);
                })
        },
        GET_ONE_ARTICLE({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/articles/${payload.uuid}`)
                    .then(resp => {
                        if (payload.resolve) resolve(resp.data.data);
                        else {
                            commit("SAVE_ARTICLE_DETAILS", resp.data.data);
                            resolve();
                        }
                    })
                    .catch((err) => {
                        reject(err);
                        throw Error(err);
                    })
            })
        },
        async TOGGLE_LIKE_ARTICLE({commit}, payload) {
            let uuid = payload.uuid,
                is_liked = payload.is_liked,
                type = is_liked ? "unlike" : "like",
                likes_total = payload.likes_total;

            await commit("SAVE_ARTICLE_LIKE_STATUS", {
                uuid: uuid,
                liked_by_user: !is_liked,
                likes_total: is_liked ? likes_total - 1 : likes_total + 1
            })

            await axios
                .post(`${Vue.prototype.$api_url}/articles/${uuid}/${type}`)
                .then(resp => {
                    commit("SAVE_ARTICLE_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: resp.data.data.liked_by_user,
                        likes_total: resp.data.data.count
                    });
                })
                .catch((err) => {
                    commit("SAVE_ARTICLE_LIKE_STATUS", {
                        uuid: uuid,
                        liked_by_user: is_liked,
                        likes_total: likes_total
                    });
                    console.error(err);
                })
        },
        ADD_ARTICLE_IMAGE({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url}/articles/${payload.uuid}/images`, payload.image)
                    .then(resp => {
                        commit
                        resolve(resp.data.data);
                    })
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    })
            })
        },
        async DELETE_ARTICLE_IMAGE({commit}, payload) {
            await axios
                .delete(`${Vue.prototype.$api_url}/articles/${payload.uuid}/images/${payload.image_uuid}`)
                .finally(() => commit)
        },
        async GET_ARTICLE_COMMENTS({state, commit}, uuid) {
            let params = {"limit": state.feed_limit};

            if (state.article_comments.length) params["pagination_id"] = state.article_comments[state.article_comments.length - 1].id;

            await axios
                .get(`${Vue.prototype.$api_url}/articles/${uuid}/comments`, {params})
                .then(resp => {
                    let comments = resp.data.data;
                    commit("SAVE_ARTICLE_COMMENTS", comments);
                    if (comments.length < state.feed_limit) commit("SAVE_ARTICLE_COMMENTS_ALL_LOADED", true);
                })
        },
        async SEND_ARTICLE_COMMENT({state, commit}, payload) {
            await axios
                .post(`${Vue.prototype.$api_url}/articles/${state.article_details.uuid}/comments`, payload)
                .then(resp => {
                    let comment = resp.data.data;
                    commit("SAVE_ARTICLE_COMMENTS", comment);
                })
        },
        async EDIT_ARTICLE_COMMENT({state, commit}, payload) {
            const old_comment = payload.comment.comment;
            await commit("EDIT_ARTICLE_COMMENT", payload);
            await axios
                .put(`${Vue.prototype.$api_url}/articles/${state.article_details.uuid}/comments/${payload.comment.uuid}`, {comment: payload.new_comment})
                .catch(() => {
                    payload['new_comment'] = old_comment;
                    commit("EDIT_ARTICLE_COMMENT", payload);
                })
        },
        DELETE_ARTICLE_COMMENT({state, commit}, comment) {
            axios.delete(`${Vue.prototype.$api_url}/articles/${state.article_details.uuid}/comments/${comment.uuid}`)
            commit("DELETE_ARTICLE_COMMENT_FROM_LIST", comment)
        },
        async TOGGLE_LIKE_ARTICLE_COMMENT({state, commit}, payload) {
            let uuid = state.article_details.uuid,
                like = payload.liked_by_user,
                likes_total = payload.likes_total;

            await commit("SAVE_ARTICLE_COMMENT_LIKE_STATUS", {
                uuid: uuid,
                comment_id: payload.comment_id,
                liked_by_user: !like,
                likes_total: like ? likes_total - 1 : likes_total + 1
            })

            await axios
                .post(`${Vue.prototype.$api_url}/articles/${uuid}/comments/${payload.comment_id}/${like ? 'unlike' : 'like'}`)
                .then((resp) => {
                    commit("SAVE_ARTICLE_COMMENT_LIKE_STATUS", {
                        uuid: uuid,
                        comment_id: payload.comment_id,
                        liked_by_user: resp.data.data.liked_by_user,
                        likes_total: resp.data.data.count
                    });
                })
                .catch(err => {
                    commit("SAVE_ARTICLE_COMMENT_LIKE_STATUS", {
                        uuid: uuid,
                        comment_id: payload.comment_id,
                        liked_by_user: like,
                        likes_total: likes_total
                    });
                    console.error(err);
                })
        },
    },
    mutations: {
        SAVE_FEED: (state, feed) => {
            if (feed) state.feed = state.feed.concat(feed)
            else state.feed = []
        },
        SAVE_NEW_POST(state, post) {
            if (['all', 'post'].includes(state.feed_active_tab)) {
                let index = state.feed[0].is_pinned ? 1 : 0;
                state.feed.splice(index, 0, {
                    fallback: false,
                    pagination_uuid: uuidv4(),
                    payload: null,
                    position: post,
                    type: "post",
                });
            }
        },
        SAVE_FEED_ALL_LOADED: (state, boolean) => state.feed_all_loaded = boolean,
        SAVE_FEED_ACTIVE_TAB: (state, tab) => state.feed_active_tab = tab,
        SAVE_POST_DETAILS: (state, details) => {
            if (details && details.author && state.post_details?.author) {
                state.post_details = {uuid: details.uuid};
                setTimeout(() => state.post_details = details, 500);
            } else {
                state.post_details = details;
            }
        },
        UPDATE_POST: (state, data) => {
            if (state.post_details?.uuid === data.uuid) state.post_details = data;
            let index = state.feed.findIndex(p => (p.type === 'post') && (p.position.uuid === data.uuid));
            if (~index) state.feed[index].position = data;
        },
        UPDATE_POST_IMAGES: (state, data) => {
            if (state.post_details) {
                if (data.type === 'delete') {
                    if (state.post_details.uuid === data.post_uuid) {
                        let index = state.post_details.additional.images.findIndex(i => i.uuid === data.image_uuid);
                        if (~index) state.post_details.additional.images.splice(index, 1);
                    }
                } else if (data.type === 'add') {
                    if (state.post_details.uuid === data.post_uuid) {
                        state.post_details.additional.images.push(data.image)
                    }
                }
            }
        },
        SAVE_POST_COMMENTS: (state, comments) => {
            if (Array.isArray(comments)) {
                let unique_ids = [];

                state.post_comments = Array
                    .from(state.post_comments)
                    .concat(comments)
                    .filter(c => {
                        if (!unique_ids.includes(c.id)) {
                            unique_ids.push(c.id);
                            return true
                        }
                        return false;
                    })
            } else {
                state.post_details.additional.comments_total += 1;
                state.post_comments.unshift(comments);
            }

            state.post_comments.forEach(c => c['type'] = 'task_comment');
        },
        EDIT_POST_COMMENT: (state, payload) => {
            if (state.post_comments.length) {
                let comment = state.post_comments.find(c => c.uuid === payload.comment.uuid);
                if (comment) comment.comment = payload.new_comment;
            }
        },
        DELETE_POST_COMMENT_FROM_LIST: (state, comment) => {
            if (state.post_comments.length) {
                let index = state.post_comments.findIndex(с => с.uuid === comment.uuid);
                if (~index) state.post_comments.splice(index, 1);
            }
        },
        SAVE_POST_COMMENTS_ALL_LOADED: (state, bool) => state.post_comments_all_loaded = bool,
        SAVE_POST_LIKE_STATUS: (state, payload) => {
            if (state.post_details?.uuid === payload.uuid) {
                state.post_details.additional.is_liked_by_current_user = payload.liked_by_user;
                state.post_details.additional.likes_total = payload.likes_total;
            }
            let post = state.feed.find(p => p.position.uuid === payload.uuid);
            if (post) {
                post.position.additional.is_liked_by_current_user = payload.liked_by_user;
                post.position.additional.likes_total = payload.likes_total;
            }
        },
        SAVE_DELETED_FEED_ITEM_DATA: (state, data) => state.deleted_post_data = data,
        SAVE_DELETED_FEED_ITEMS: (state, ids) => state.deleted_feed_items = ids,
        DELETE_POST_FROM_FEED(state, uuid) {
            if ((state.post_details?.uuid === uuid) || (uuid === 'require')) {
                state.post_details = null;
                state.post_comments = [];
                state.post_comments_all_loaded = false;
            }
        },
        SAVE_POST_COMMENT_LIKE_STATUS(state, payload) {
            if (state.post_details?.uuid === payload.uuid) {
                let comment = state.post_comments.find(c => c.id === payload.comment_id);
                if (comment) {
                    comment.additional.is_liked_by_current_user = payload.liked_by_user;
                    comment.additional.likes_total = payload.likes_total;
                }
            }
        },

        SAVE_ARTICLE_DETAILS: (state, details) => {
            state.article_details = details;
            state.article_comments = [];
            state.article_comments_all_loaded = false;
        },
        SAVE_ARTICLE_EDITED_DETAILS: (state, details) => {
            state.article_edited_details = details;
        },
        UPDATE_ARTICLE: (state, data) => {
            if (state.article_details?.uuid === data.uuid) state.article_details = data;
            let index = state.feed.findIndex(p => (p.type === 'article') && (p.position.uuid === data.uuid));
            if (~index) state.feed[index].position = data;
        },
        DELETE_ARTICLE_FROM_FEED(state, uuid) {
            if ((state.article_details?.uuid === uuid) || (uuid === 'require')) {
                state.article_details = null;
                state.article_comments = [];
                state.article_comments_all_loaded = false;
            }
        },
        UPDATE_ARTICLE_PUBLISH_STATUS_FROM_FEED(state, data) {
            if (state.article_details?.uuid === data.uuid) state.article_details.status = data.status;
            let index = state.feed.findIndex(p => (p.type === 'article') && (p.position.uuid === data.uuid));
            if (~index) state.feed[index].position.status = data.status;
        },
        SAVE_ARTICLE_LIKE_STATUS: (state, payload) => {
            if (state.article_details?.uuid === payload.uuid) {
                state.article_details.additional.is_liked_by_current_user = payload.liked_by_user;
                state.article_details.additional.likes_total = payload.likes_total;
            }
            let article = state.feed.find(p => p.position.uuid === payload.uuid);
            if (article) {
                article.position.additional.is_liked_by_current_user = payload.liked_by_user;
                article.position.additional.likes_total = payload.likes_total;
            }
        },
        SAVE_ARTICLE_COMMENTS: (state, comments) => {
            if (Array.isArray(comments)) {
                let unique_ids = [];

                state.article_comments = Array
                    .from(state.article_comments)
                    .concat(comments)
                    .filter(c => {
                        if (!unique_ids.includes(c.id)) {
                            unique_ids.push(c.id);
                            return true
                        }
                        return false;
                    })
            } else {
                state.article_details.additional.comments_total += 1;
                state.article_comments.unshift(comments);
            }

            state.article_comments.forEach(c => c['type'] = 'task_comment');
        },
        SAVE_ARTICLE_COMMENTS_ALL_LOADED: (state, bool) => state.article_comments_all_loaded = bool,
        EDIT_ARTICLE_COMMENT: (state, payload) => {
            if (state.article_comments.length) {
                let comment = state.article_comments.find(c => c.uuid === payload.comment.uuid);
                if (comment) comment.comment = payload.new_comment;
            }
        },
        DELETE_ARTICLE_COMMENT_FROM_LIST: (state, comment) => {
            if (state.article_comments.length) {
                let index = state.article_comments.findIndex(с => с.uuid === comment.uuid);
                if (~index) state.article_comments.splice(index, 1);
            }
        },
        SAVE_ARTICLE_COMMENT_LIKE_STATUS(state, payload) {
            if (state.article_details?.uuid === payload.uuid) {
                let comment = state.article_comments.find(c => c.id === payload.comment_id);
                if (comment) {
                    comment.additional.is_liked_by_current_user = payload.liked_by_user;
                    comment.additional.likes_total = payload.likes_total;
                }
            }
        },

        SAVE_QUESTION_DETAILS: (state, details) => {
            if (details && details.author && state.question_details?.author) {
                state.question_details = {uuid: details.uuid};
                setTimeout(() => state.question_details = details, 500);
            } else {
                state.question_details = details;
            }
        },
        SAVE_NEW_QUESTION(state, question) {
            if (['all', 'question'].includes(state.feed_active_tab)) {
                state.feed.unshift({
                    fallback: false,
                    pagination_uuid: uuidv4(),
                    payload: null,
                    position: question,
                    type: "question",
                });
            }
        },
        UPDATE_QUESTION: (state, data) => {
            if (state.question_details?.uuid === data.uuid) state.question_details = data;
            let index = state.feed.findIndex(p => (p.type === 'question') && (p.position.uuid === data.uuid));
            if (~index) state.feed[index].position = data;
        },
        DELETE_QUESTION_FROM_FEED(state, uuid) {
            if ((state.question_details?.uuid === uuid) || (uuid === 'require')) {
                state.question_details = null;
                state.question_answers = [];
                state.question_answers_all_loaded = false;
            }
        },
        SAVE_QUESTION_ANSWERS: (state, payload) => {
            if (Array.isArray(payload.answers)) {
                let unique_uuids = [];

                state.question_answers = Array
                    .from(state.question_answers)
                    .concat(payload.answers)
                    .filter(a => {
                        if (!unique_uuids.includes(a.uuid)) {
                            unique_uuids.push(a.uuid);
                            return true
                        }
                        return false;
                    });
            } else {
                if (payload.data?.reply_to_uuid) {
                    let answer = state.question_answers.find(a => a.uuid === payload.data.reply_to_uuid);
                    if (answer) answer.replies.unshift(payload.answers);
                } else {
                    state.question_details.additional.answers_total += 1;
                    state.question_answers.unshift(payload.answers);
                }
            }

            state.question_answers.forEach(a => a['type'] = 'task_comment');
        },
        SAVE_QUESTION_ANSWERS_REPLIES: (state, payload) => {
            let answer = state.question_answers.find(a => a.uuid === payload.answer_uuid);

            if (answer) {
                let unique_uuids = [];

                answer.additional.more_replies_to_load = payload.more_replies_to_load;

                answer.replies = answer.replies
                    .concat(payload.replies)
                    .filter(r => {
                        if (!unique_uuids.includes(r.uuid)) {
                            unique_uuids.push(r.uuid);
                            return true;
                        }
                        return false;
                    });

                answer.replies.forEach(a => a['type'] = 'task_comment');
            }
        },
        SAVE_QUESTION_ANSWERS_ALL_LOADED: (state, bool) => state.question_answers_all_loaded = bool,
        SAVE_QUESTION_LIKE_STATUS: (state, payload) => {
            if (state.question_details?.uuid === payload.uuid) {
                state.question_details.additional.is_liked_by_current_user = payload.liked_by_user;
                state.question_details.additional.likes_total = payload.likes_total;
            }
            let question = state.feed.find(p => p.position.uuid === payload.uuid);
            if (question) {
                question.position.additional.is_liked_by_current_user = payload.liked_by_user;
                question.position.additional.likes_total = payload.likes_total;
            }
        },
        EDIT_USER_ANSWER_FROM_LIST: (state, payload) => {
            if (state.question_answers.length) {
                if (payload.reply_to_uuid) {
                    let answer = state.question_answers.find(a => a.uuid === payload.reply_to_uuid);
                    if (answer?.replies) {
                        let comment = answer.replies.find(a => a.uuid === payload.answer.uuid);
                        if (comment) comment.answer = payload.new_answer;
                    }
                } else {
                    let answer = state.question_answers.find(a => a.uuid === payload.answer.uuid);
                    if (answer) answer.answer = payload.new_answer;
                }
            }
        },
        DELETE_USER_ANSWER_FROM_LIST: (state, payload) => {
            if (state.question_answers.length) {
                if (payload.reply_to_uuid) {
                    let answer = state.question_answers.find(a => a.uuid === payload.reply_to_uuid);
                    if (answer?.replies) {
                        let index = answer.replies.findIndex(a => a.uuid === payload.answer.uuid);
                        if (~index) answer.replies.splice(index, 1);
                    }
                } else {
                    let index = state.question_answers.findIndex(a => a.uuid === payload.answer.uuid);
                    if (~index) state.question_answers.splice(index, 1);
                }
            }
        },
        SAVE_QUESTION_VOTE_STATUS: (state, payload) => {
            if (state.question_details?.uuid === payload.uuid) {
                let answer = state.question_answers.find(a => a.uuid === payload.answer_uuid);
                if (answer) answer.additional.counter = payload.data;
            }
        },

        SAVE_SELECTION_TO_FEED(state, selection) {
            if (['all', 'selection'].includes(state.feed_active_tab)) {
                state.feed.unshift({
                    fallback: false,
                    pagination_uuid: uuidv4(),
                    payload: null,
                    position: selection,
                    type: "selection",
                });
            }
        },
        SAVE_VIDEO_TO_FEED(state, video) {
            if (['all', 'video'].includes(state.feed_active_tab)) {
                state.feed.unshift({
                    fallback: false,
                    pagination_uuid: uuidv4(),
                    payload: null,
                    position: video,
                    type: "video",
                });
            }
        },
        DELETE_POSITION_FROM_FEED(state, uuid) {
            let index = state.feed.findIndex(f => f.position?.uuid === uuid);
            if (~index) state.feed.splice(index, 1);
        },

        CLEAR_FEED: (state) => Object.assign(state, getDefaultState()),
    },
}
