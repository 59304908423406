import axios from "axios";
import Vue from "vue";

const getDefaultState = () => {
    return {
        statistics_general: [],
        statistics_general_someone: [],
        statistics_section: '',
        statistics_filters: [],
        entities_counter: {},
        statistics_general_panel: false,
        statistics_detail_panel: false,
        statistics_update: true,
        entities_update: true,
        statistics_deleted_entity: null,
        statistics_edited_data: null,
        statistics_edited_id: null,
        statistics_for_need_action: [],
        statistics_for_need_action_all_loaded: false,
        title_map: new Map([
            ['ADD_TRANSLATION_UNION', 'stat_add_translation'],
            ['VERIFY_TRANSLATION_UNION', 'stat_verify_translation'],
            ['COMMENT_TRANSLATION_UNION', 'stat_verify_translation'],
            ['COMMENT_EDIT_TRANSLATION_UNION', 'stat_edit_translation'],
            ['MODERATE_TRANSLATION_UNION', 'stat_moderated_translation'],
            ['MODERATE_DELETE_TRANSLATION_UNION', 'stat_moderated_translation'],
            ['MODERATE_EDIT_TRANSLATION_UNION', 'stat_moderated_translation'],
            ['COMPLAIN_TRANSLATION_UNION', 'stat_complained_translation'],

            ['ADD_TRANSLATION_ATTRIBUTE', 'stat_add_translation_attribute'],
            ['VERIFY_TRANSLATION_ATTRIBUTE', 'stat_verify_translation_attribute'],
            ['COMMENT_TRANSLATION_ATTRIBUTE', 'stat_verify_translation_attribute'],
            ['COMMENT_EDIT_TRANSLATION_ATTRIBUTE', 'stat_edit_translation_attribute'],
            ['MODERATE_TRANSLATION_ATTRIBUTE', 'stat_moderated_translation_attribute'],
            ['MODERATE_DELETE_TRANSLATION_ATTRIBUTE', 'stat_moderated_translation_attribute'],
            ['MODERATE_EDIT_TRANSLATION_ATTRIBUTE', 'stat_moderated_translation_attribute'],
            ['COMPLAIN_TRANSLATION_ATTRIBUTE', 'stat_complained_translation_attribute'],

            ['ADD_TRANSLATION_CHILD_ATTRIBUTE', 'stat_add_translation_child_attribute'],
            ['VERIFY_TRANSLATION_CHILD_ATTRIBUTE', 'stat_verify_translation_child_attribute'],
            ['COMMENT_TRANSLATION_CHILD_ATTRIBUTE', 'stat_verify_translation_child_attribute'],
            ['COMMENT_EDIT_TRANSLATION_CHILD_ATTRIBUTE', 'stat_edit_translation_child_attribute'],
            ['MODERATE_TRANSLATION_CHILD_ATTRIBUTE', 'stat_moderated_translation_child_attribute'],
            ['MODERATE_DELETE_TRANSLATION_CHILD_ATTRIBUTE', 'stat_moderated_translation_child_attribute'],
            ['MODERATE_EDIT_TRANSLATION_CHILD_ATTRIBUTE', 'stat_moderated_translation_child_attribute'],
            ['COMPLAIN_TRANSLATION_CHILD_ATTRIBUTE', 'stat_complained_translation_child_attribute'],

            ['ADD_WORD_AUDIO', 'stat_add_audio'],
            ['VERIFY_WORD_AUDIO', 'stat_verify_audio'],
            ['COMMENT_WORD_AUDIO', 'stat_verify_audio'],
            ['COMMENT_EDIT_WORD_AUDIO', 'stat_edit_audio'],
            ['MODERATE_WORD_AUDIO', 'stat_moderated_audio'],
            ['MODERATE_DELETE_WORD_AUDIO', 'stat_moderated_audio'],
            ['MODERATE_EDIT_WORD_AUDIO', 'stat_moderated_audio'],
            ['COMPLAIN_WORD_AUDIO', 'stat_complained_audio'],

            ['ADD_SENTENCE_AUDIO', 'stat_add_audio'],
            ['VERIFY_SENTENCE_AUDIO', 'stat_verify_audio'],
            ['COMMENT_SENTENCE_AUDIO', 'stat_verify_audio'],
            ['COMMENT_EDIT_SENTENCE_AUDIO', 'stat_edit_audio'],
            ['MODERATE_SENTENCE_AUDIO', 'stat_moderated_audio'],
            ['MODERATE_DELETE_SENTENCE_AUDIO', 'stat_moderated_audio'],
            ['MODERATE_EDIT_SENTENCE_AUDIO', 'stat_moderated_audio'],
            ['COMPLAIN_SENTENCE_AUDIO', 'stat_complained_audio'],

            ['ADD_TRANSLATION_MEANING', 'stat_add_meaning'],
            ['VERIFY_TRANSLATION_MEANING', 'stat_verify_meaning'],
            ['COMMENT_TRANSLATION_MEANING', 'stat_verify_meaning'],
            ['COMMENT_EDIT_TRANSLATION_MEANING', 'stat_edit_meaning'],
            ['MODERATE_TRANSLATION_MEANING', 'stat_moderated_meaning'],
            ['MODERATE_DELETE_TRANSLATION_MEANING', 'stat_moderated_meaning'],
            ['MODERATE_EDIT_TRANSLATION_MEANING', 'stat_moderated_meaning'],
            ['COMPLAIN_TRANSLATION_MEANING', 'stat_complained_meaning'],

            ['ADD_SYNONYM', 'stat_add_synonym'],
            ['VERIFY_SYNONYM', 'stat_verify_synonym'],
            ['COMMENT_SYNONYM', 'stat_verify_synonym'],
            ['COMMENT_EDIT_SYNONYM', 'stat_edit_synonym'],
            ['MODERATE_SYNONYM', 'stat_moderated_synonym'],
            ['MODERATE_DELETE_SYNONYM', 'stat_moderated_synonym'],
            ['MODERATE_EDIT_SYNONYM', 'stat_moderated_synonym'],
            ['COMPLAIN_SYNONYM', 'stat_complained_synonym'],

            ['ADD_ANTONYM', 'stat_add_antonym'],
            ['VERIFY_ANTONYM', 'stat_verify_antonym'],
            ['COMMENT_ANTONYM', 'stat_verify_antonym'],
            ['COMMENT_EDIT_ANTONYM', 'stat_edit_antonym'],
            ['MODERATE_ANTONYM', 'stat_moderated_antonym'],
            ['MODERATE_DELETE_ANTONYM', 'stat_moderated_antonym'],
            ['MODERATE_EDIT_ANTONYM', 'stat_moderated_antonym'],
            ['COMPLAIN_ANTONYM', 'stat_complained_antonym'],

            ['ADD_TRANSLATION_VOCABULARY', 'stat_add_vocabulary'],
            ['VERIFY_TRANSLATION_VOCABULARY', 'stat_verify_vocabulary'],
            ['COMMENT_TRANSLATION_VOCABULARY', 'stat_verify_vocabulary'],
            ['COMMENT_EDIT_TRANSLATION_VOCABULARY', 'stat_edit_vocabulary'],
            ['MODERATE_TRANSLATION_VOCABULARY', 'stat_moderated_vocabulary'],
            ['MODERATE_DELETE_TRANSLATION_VOCABULARY', 'stat_moderated_vocabulary'],
            ['MODERATE_EDIT_TRANSLATION_VOCABULARY', 'stat_moderated_vocabulary'],
            ['COMPLAIN_TRANSLATION_VOCABULARY', 'stat_complained_vocabulary'],

            ['ADD_WORD_SENTENCE', 'stat_add_word_sentence'],
            ['VERIFY_WORD_SENTENCE', 'stat_verify_word_sentence'],
            ['COMMENT_WORD_SENTENCE', 'stat_verify_word_sentence'],
            ['COMMENT_EDIT_WORD_SENTENCE', 'stat_edit_word_sentence'],
            ['MODERATE_WORD_SENTENCE', 'stat_moderated_word_sentence'],
            ['MODERATE_DELETE_WORD_SENTENCE', 'stat_moderated_word_sentence'],
            ['MODERATE_EDIT_WORD_SENTENCE', 'stat_moderated_word_sentence'],
            ['COMPLAIN_WORD_SENTENCE', 'stat_complained_word_sentence'],

            ['ADD_SENTENCE_TRANSLATION', 'stat_add_sentence_translation'],
            ['VERIFY_SENTENCE_TRANSLATION', 'stat_verify_sentence_translation'],
            ['COMMENT_SENTENCE_TRANSLATION', 'stat_verify_sentence_translation'],
            ['COMMENT_EDIT_SENTENCE_TRANSLATION', 'stat_edit_sentence_translation'],
            ['MODERATE_SENTENCE_TRANSLATION', 'stat_moderated_sentence_translation'],
            ['MODERATE_DELETE_SENTENCE_TRANSLATION', 'stat_moderated_sentence_translation'],
            ['MODERATE_EDIT_SENTENCE_TRANSLATION', 'stat_moderated_sentence_translation'],
            ['COMPLAIN_SENTENCE_TRANSLATION', 'stat_complained_sentence_translation'],
        ])
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        LOAD_STATISTICS_GENERAL({state, getters, commit, rootState}, user_nickname) {
            return new Promise(resolve => {
                const nickname = user_nickname || getters.USER.nickname,
                    this_user = nickname === getters.USER.nickname,
                    URL = Vue.prototype.$api_url + `/actions/` + (this_user ? 'general' : `${nickname}/general`),
                    commit_type = this_user ? 'SAVE_STATISTICS_GENERAL' : 'SAVE_STATISTICS_GENERAL_SOMEONE',
                    loadUser = () => {
                        commit('CHANGE_LOADING_STATISTICS_STATUS', true)
                        setTimeout(() => {
                            axios
                                .get(URL)
                                .then(resp => {
                                    commit(commit_type, resp.data.data)
                                    resolve(resp.data.data)
                                })
                                .catch(() => {
                                    if (this_user) state.statistics_update = true
                                })
                                .finally(() => commit('CHANGE_LOADING_STATISTICS_STATUS', false))
                        }, rootState.profile.profile_update_timeout)
                    }
                if (nickname === getters.USER.nickname) {
                    if (state.statistics_update) {
                        state.statistics_update = false
                        loadUser()
                    } else resolve(state.statistics_general)
                } else loadUser()
            })
        },
        LOAD_STATISTICS_SECTION({state}, data) {
            let url = Vue.prototype.$api_url + '/actions/' + (data.this_user ? 'list' : `${data.nickname}/list`) + '?limit=20';

            if (state.statistics_section === 'translations') url += '&type=TRANSLATION_UNION'
            else if (state.statistics_section === 'audios') url += '&type=AUDIO'
            else if (state.statistics_section === 'examples') url += '&type=SENTENCE'
            else if (state.statistics_section === 'attributes') url += '&type=TRANSLATION_ATTRIBUTE'
            else if (state.statistics_section === 'child_attribute') url += '&type=TRANSLATION_CHILD_ATTRIBUTE'
            else if (state.statistics_section === 'meanings') url += '&type=TRANSLATION_MEANING'
            else if (state.statistics_section === 'synonyms') url += '&type=SYNONYM'
            else if (state.statistics_section === 'antonyms') url += '&type=ANTONYM'
            else if (state.statistics_section === 'vocabularies') url += '&type=TRANSLATION_VOCABULARY'

            const filters = state.statistics_filters;

            if (filters.length) {
                let has_types = filters.includes('ADDED') || filters.includes('VERIFIED'),
                    has_status = filters.includes('ACCEPTED') || filters.includes('ON_MODIFY') || filters.includes('REJECTED');

                if (has_types && has_status) for (let filter of filters) url += `&filters[]=${filter}`
                else return []
            }
            if (data.pagination_id) url += '&pagination_id=' + data.pagination_id

            return new Promise((resolve, reject) => {
                axios
                    .get(url)
                    .then(resp => resolve(resp.data.data))
                    .catch(err => reject(err))
            })
        },
        LOAD_ENTITIES_COUNTER({state, commit, rootState}) {
            return new Promise(resolve => {
                if (state.entities_update) {
                    state.entities_update = false

                    setTimeout(() => {
                        axios
                            .get(Vue.prototype.$api_url + '/user/entities-counter')
                            .then(resp => {
                                commit("SAVE_ENTITIES_COUNTER", resp.data.data)
                                resolve(resp.data.data)
                            })
                            .catch(() => state.entities_update = true)
                    }, rootState.profile.profile_update_timeout)
                } else resolve(state.entities_counter)
            })
        },
        GET_ONE_STATISTICS_ITEM({commit}, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(Vue.prototype.$api_url + '/actions/' + id)
                    .then(resp => resolve(resp.data.data))
                    .catch(err => reject(err))
                    .finally(() => commit)
            })
        },
        OPEN_NEXT_NEED_ACTION_TASK_FROM_STATISTICS({state, commit, rootState}) {
            let task_index = state.statistics_for_need_action.findIndex(t => t.statistics_id === rootState.need_action.need_action_task_details.statistics_id);

            if (~task_index) task_index += 1;
            let task = state.statistics_for_need_action[task_index];
            if (task) {
                task['type'] = 'dictionary';
                commit("CHANGE_NEED_ACTION_TASK_DETAILS", task);
            } else {
                commit("CHANGE_NEED_ACTION_TASK_DETAILS", null);
            }
        },
    },
    mutations: {
        SAVE_STATISTICS_GENERAL: (state, statistics) => state.statistics_general = statistics,
        SAVE_STATISTICS_GENERAL_SOMEONE: (state, statistics) => state.statistics_general_someone = statistics,
        CHANGE_STATISTICS_GENERAL_PANEL: (state, boolean) => state.statistics_general_panel = boolean,
        CHANGE_STATISTICS_DETAIL_PANEL: (state, boolean) => state.statistics_detail_panel = boolean,
        SAVE_STATISTICS_SECTION: (state, section) => state.statistics_section = section,
        SAVE_STATISTICS_FILTER: (state, filters) => state.statistics_filters = filters,
        SAVE_STATISTICS_DELETED_ENTITY: (state, data) => state.statistics_deleted_entity = data,
        SAVE_STATISTICS_EDITED_DATA: (state, data) => state.statistics_edited_data = data,
        SAVE_STATISTICS_EDITED_ID: (state, data) => state.statistics_edited_id = data,
        SAVE_STATISTICS_FOR_NEED_ACTION: (state, data) => state.statistics_for_need_action = data,
        SAVE_STATISTICS_FOR_NEED_ACTION_LOADED: (state, boolean) => state.statistics_for_need_action_all_loaded = boolean,
        REFRESH_STATISTICS_SETTINGS: (state) => {
            state.statistics_section = '';
            state.statistics_filters = [];
        },
        SAVE_ENTITIES_COUNTER: (state, counter) => state.entities_counter = counter,
        STATISTICS_UPDATE: (state) => {
            state.statistics_update = true;
            state.entities_update = true;
        },
        CLEAR_STATISTICS: (state) => Object.assign(state, getDefaultState())
    }
}
