import axios from "axios";
import Vue from "vue";
import Cookies from "js-cookie";

const getDefaultState = () => {
    return {
        languages: [],
        language: {},
        language_t: {},
        last_languages: [],
        languages_x_key: "",
        languages_localize: "",
        vocabulary: [],
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_LANGUAGES({commit, state, getters, dispatch}) {
            let local = getters.LOCALE.dictionary_code || 'eng',
                headers = {};

            if (!state.languages.length || !state.vocabulary.length || !Cookies.get('langs_x_key') || local !== state.languages_localize) {
                if (state.languages_x_key && state.languages.length && state.vocabulary.length && local === state.languages_localize) headers["X-modified-key"] = state.languages_x_key

                await axios
                    .get(`${Vue.prototype.$api_url}/languages/get/all-data?platform=WEB`, {headers})
                    .then(async response => {
                        if (response) {
                            let languages = response.data.data.languages.sort((a, b) => a['name'].localeCompare(b['name'], local)),
                                vocabulary = response.data.data.vocabulary.sort((a, b) => a['localized_value'].localeCompare(b['localized_value'], local));

                            await commit("SAVE_LANGUAGES", languages);
                            await commit("SAVE_VOCABULARY", vocabulary);
                            await commit("SAVE_LANGUAGES_X_KEY", response.data.modified_key);

                            if (state.last_languages.length) {
                                for (let i = 0; i < state.last_languages.length; i++) {
                                    state.last_languages[i] = languages.find(lang => lang.code === state.last_languages[i]?.code)
                                }
                            }

                            state.languages_localize = local;
                        }

                        Cookies.set('langs_x_key', state.languages_x_key, {
                            secure: true,
                            'max-age': '86400'
                        });
                    })
                    .finally(() => dispatch("SET_TRANSLATE_LANGUAGES", local))
            } else await dispatch("SET_TRANSLATE_LANGUAGES", local)
        },
        CHANGE_LANGUAGE({commit, state}, lang) {
            if (lang?.code === state.language_t?.code) commit("CHANGE_LANGUAGES");
            else commit("SAVE_LANGUAGE", lang);
            commit("SAVE_LAST_LANGUAGES", lang);
        },
        CHANGE_LANGUAGE_T({commit, state}, lang) {
            if (lang?.code === state.language?.code) commit("CHANGE_LANGUAGES");
            else commit("SAVE_LANGUAGE_T", lang);
            commit("SAVE_LAST_LANGUAGES", lang);
        },
        SET_TRANSLATE_LANGUAGES({state, commit}, local) {
            let lang_local = state.languages.find(lang => lang.code === local) || {},
                lang_eng = state.languages.find(lang => lang.code === 'eng') || {},
                lang_esp = state.languages.find(lang => lang.code === 'spa') || {};

            if (!state.language.name) {
                if (lang_local) {
                    if (local !== 'eng') commit("SAVE_LANGUAGE", lang_local)
                    else commit("SAVE_LANGUAGE", lang_eng)
                } else commit("SAVE_LANGUAGE", lang_eng)
            } else commit("SAVE_LANGUAGE", state.languages.find(lang => lang.code_two_signs === state.language.code_two_signs))

            if (!state.language_t.name) {
                if (lang_local) {
                    if (local !== 'eng') commit("SAVE_LANGUAGE_T", lang_eng)
                    else commit("SAVE_LANGUAGE_T", lang_esp)
                } else commit("SAVE_LANGUAGE_T", lang_esp)
            } else commit("SAVE_LANGUAGE_T", state.languages.find(lang => lang.code_two_signs === state.language_t.code_two_signs))
        }
    },
    mutations: {
        SAVE_LANGUAGES: (state, languages) => state.languages = languages,
        SAVE_LANGUAGE: (state, lang) => state.language = lang,
        SAVE_LANGUAGE_T: (state, lang) => state.language_t = lang,
        SAVE_LANGUAGES_X_KEY: (state, key) => state.languages_x_key = key,
        SAVE_VOCABULARY: (state, vocabulary) => state.vocabulary = vocabulary,
        async SAVE_LAST_LANGUAGES(state, lang) {
            let index = await state.last_languages.findIndex(item => item.code === lang.code)
            if (~index) state.last_languages = await state.last_languages.filter(item => item.code !== lang.code)
            await state.last_languages.unshift(lang)
            if (state.last_languages.length > 4) state.last_languages.length = 5
        },
        CHANGE_LANGUAGES: (state) => {
            const buffer = state.language
            state.language = state.language_t
            state.language_t = buffer
        },
        CLEAR_LANGUAGES: (state) => Object.assign(state, getDefaultState())
    }
}
