import Vue from "vue";
import axios from "axios";
import addition_bus from "@/assets/js/addition_helper";

const getDefaultState = () => {
    return {
        lesson_words: [],
        lesson_words_cache: [],
        lesson_words_limit: 20,
        lesson_words_all_loaded: false,
        lesson_learn_words: [],
        lesson_skips_word_ids: [],
        lesson_points_earned: 0,
        lesson_lang: null,
        lesson_type: 'selection',
        can_listen: true,
        hard_lesson_words: [],
        hard_lesson_status: false,
        new_words_offset: 0,

        video_captions: [],
        video_captions_all_loaded: false
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async GET_LESSON_WORDS({state, getters, commit, rootState}, payload = {}) {
            let params, URL = Vue.prototype.$api_url;

            const lang = state.lesson_lang;

            if ((state.lesson_type !== 'repeat_words') && (state.lesson_words_limit !== 20)) {
                commit("CHANGE_LESSON_WORDS_LIMIT", 20);
            }

            if (state.lesson_type === 'repeat_words') {
                commit("CHANGE_LESSON_WORDS_LIMIT", 30);

                URL += '/education/lesson/words/repeat';
                params = {
                    "lang_from": getters.LEARNING_KNOW_LANGUAGE,
                    "lang_to": getters.USER_LEARNING_LANG,
                    "limit": state.lesson_words_limit
                };

                if (payload.repeat_pagination_id) {
                    params["pagination_id"] = payload.repeat_pagination_id;
                } else if (state.lesson_words.length) {
                    params["pagination_id"] = state.lesson_words[state.lesson_words.length - 1].union.id;
                }
            } else if (state.lesson_type === 'new_words') {
                URL += '/education/lesson/words/new';
                params = {
                    "lang_from": getters.LEARNING_KNOW_LANGUAGE,
                    "lang_to": getters.USER_LEARNING_LANG,
                    "limit": state.lesson_words_limit,
                    "offset": state.new_words_offset
                };
            } else if (state.lesson_type === 'favorite_words') {
                URL += '/education/lesson/words/favorite';
                params = {
                    "lang_from": getters.LEARNING_KNOW_LANGUAGE,
                    "lang_to": getters.USER_LEARNING_LANG,
                    "limit": state.lesson_words_limit
                };

                if (state.lesson_words.length) {
                    params["pagination_id"] = state.lesson_words[state.lesson_words.length - 1].union.id;
                }
            } else {
                URL += '/education/lesson/words/selection';
                params = {
                    "selection_uuid": rootState.learning.selection?.uuid || payload.uuid,
                    "limit": state.lesson_words_limit
                };

                if (payload.type === "update") {
                    await commit("CLEAR_LESSON");
                } else {
                    params["pagination_id"] = state.lesson_words[state.lesson_words.length - 1].union.id;
                }
            }

            await commit("CHANGE_LESSON_LANG", lang);

            await axios
                .get(URL, {params})
                .then((resp) => {
                    let words = resp.data.data.unions.map(u => {
                        if (u.union.words[1].lang_code === state.lesson_lang) {
                            u.union.words.reverse();
                        }
                        if (addition_bus.getSentenceBreakingStatus(u.union.words[0].lang_code) && (u.union.words[0].name.trim().split(' ').length > 2)) {
                            u.union['type'] = 'sentence'
                        } else {
                            u.union['type'] = 'word'
                        }
                        return u;
                    });

                    commit("SAVE_LESSON_WORDS", words);

                    if (state.lesson_type === 'new_words') {
                        state.new_words_offset += state.lesson_words_limit;
                    }

                    if (words.length < state.lesson_words_limit) {
                        commit("CHANGE_LESSON_WORDS_ALL_LOADED", true);
                    }
                })
                .catch(err => {
                    throw new Error(err);
                })
        },
        async POST_SKIP_LESSON_WORDS({dispatch, commit, rootState}, payload) {
            const hard = payload.hard;

            delete payload['hard'];

            await axios
                .post(`${Vue.prototype.$api_url}/education/lesson/skip`, payload, {
                    'dont_show_error': rootState.other.dev_host
                })
                .then(() => {
                    if (!hard) commit("UPDATE_SELECTION_LEARNED_WORDS_COUNT", payload.unions.length);
                })
                .catch(err => {
                    dispatch("SAVE_LESSON_SKIPS_WORDS", payload.unions);
                    dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    throw new Error(err);
                })
        },
        async POST_REPEATED_LESSON_WORDS({dispatch, commit, rootState}, payload) {
            await axios
                .post(`${Vue.prototype.$api_url}/education/lesson/repeat`, payload, {
                    'dont_show_error': rootState.other.dev_host
                })
                .then(() => {
                    dispatch("SAVE_LESSON_SKIPS_WORDS", []);
                    dispatch("SAVE_LESSON_LEARN_WORDS", []);
                    commit("SAVE_LESSON_POINTS_EARNED");
                })
                .catch(err => {
                    dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    throw new Error(err);
                })
        },
        async SKIP_LESSON_WORDS({state, dispatch, rootState}) {
            const skip_ids = state.lesson_skips_word_ids;

            if (skip_ids.length && (state.lesson_type !== 'repeat_words')) {
                dispatch("SAVE_LESSON_SKIPS_WORDS", []);

                let payload = {
                    "unions": skip_ids,
                    "hard": state.hard_lesson_status
                };

                if ((state.lesson_type === 'selection') && rootState.learning.selection) {
                    payload['selection_uuid'] = rootState.learning.selection.uuid
                }

                dispatch("POST_SKIP_LESSON_WORDS", payload);
            }
        },
        async SEND_LESSON_WORDS({dispatch, commit, rootState}, payload) {
            const hard = payload.hard;

            delete payload['hard'];

            await axios
                .post(`${Vue.prototype.$api_url}/education/lesson/complete`, payload, {
                    'dont_show_error': rootState.other.dev_host
                })
                .then(() => {
                    dispatch("SAVE_LESSON_LEARN_WORDS", []);
                    commit("SAVE_LESSON_POINTS_EARNED");
                    if (!hard) commit("UPDATE_SELECTION_LEARNED_WORDS_COUNT", payload.unions.length);
                })
                .catch(err => {
                    dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    throw new Error(err);
                })
        },
        async SAVE_LESSON_SKIPS_WORDS({state, dispatch, commit}, array_or_id) {
            if (Array.isArray(array_or_id)) {
                if (array_or_id.length) state.lesson_skips_word_ids = state.lesson_skips_word_ids.concat(array_or_id)
                else state.lesson_skips_word_ids = []
            } else {
                state.lesson_skips_word_ids.push(array_or_id)
                await commit("CLOSE_LESSON_WORDS")

                if ((state.lesson_type !== 'repeat_words') && !state.lesson_words_all_loaded && (state.lesson_learn_words.length < 6) && (state.lesson_words.length < 3)) {
                    await dispatch("SKIP_LESSON_WORDS");
                    await dispatch("GET_LESSON_WORDS");
                }
            }
        },
        async SAVE_LESSON_LEARN_WORDS({state, dispatch, commit}, array_or_object) {
            if (Array.isArray(array_or_object)) state.lesson_learn_words = array_or_object
            else {
                let has_not = !state.lesson_learn_words.find(w => w.union.id === array_or_object.union.id);
                if (has_not) {
                    let maxId = 0;

                    state.lesson_learn_words.forEach(e => {
                        if (e.id > maxId) maxId = e.id
                    });

                    let index = state.lesson_learn_words.findIndex(e => e.id === maxId);

                    array_or_object['id'] = maxId + 1;

                    if (state.lesson_type === 'repeat_words') {
                        array_or_object['exercises'] = ["choose_translation"];
                    } else {
                        array_or_object['exercises'] = ["choose_translation", "make_translation"];

                        if (array_or_object.union.words[0].audios[0]) {
                            array_or_object['exercises'].push("choose_translation_from_audio");

                            let a = document.createElement('audio');
                            a.src = array_or_object.union.words[0].audios[0].url;
                            a.id = 'lesson-audio-' + array_or_object.union.words[0].id;
                            a.preload = 'auto';
                            document.querySelector('.training_lesson').appendChild(a);
                        }
                    }

                    if (~index) state.lesson_learn_words.splice(index + 1, 0, array_or_object);
                    else state.lesson_learn_words.push(array_or_object);
                }

                if (state.lesson_type === 'repeat_words') {
                    setTimeout(() => commit("CLOSE_LESSON_WORDS"), 10);
                } else {
                    if (state.lesson_learn_words.length === 6) await commit("SAVE_LESSON_WORDS", []);
                    else {
                        setTimeout(() => commit("CLOSE_LESSON_WORDS"), 10);

                        if (!state.lesson_words_all_loaded && (state.lesson_learn_words.length < 6) && (state.lesson_words.length < 3)) {
                            await dispatch("SKIP_LESSON_WORDS");
                            await dispatch("GET_LESSON_WORDS");
                        }
                    }
                }
            }
        },
        async CREATE_HARD_EXERCISES_LESSON({state, commit}) {
            state.hard_lesson_words.forEach(e => {
                let hard_exercises = ["write_translation_from_text"];
                if (state.can_listen && e.union.words[0].audios[0]) {
                    hard_exercises.push("write_translation_from_audio", "make_translation_from_audio");
                }
                e.exercises.push(hard_exercises[Math.floor(Math.random() * hard_exercises.length)]);
            });

            state.lesson_learn_words = state.hard_lesson_words;

            commit("CHANGE_HARD_LESSON_STATUS", true);
            commit("SAVE_HARD_LESSON_WORDS", []);
        },
        CLEAR_ALL_AUDIO_EXERCISE({state, commit}) {
            return new Promise(resolve => {
                commit("CHANGE_CAN_LISTEN", false);

                if (state.hard_lesson_status) {
                    resolve(0);
                    state.lesson_learn_words.forEach((w) => {
                        if (["write_translation_from_audio", "make_translation_from_audio"].includes(w.exercises[0])) {
                            w.exercises[0] = "write_translation_from_text";
                        }
                    });
                } else {
                    let c = 0;
                    state.lesson_learn_words.forEach((w) => {
                        let c1 = w.exercises.includes('choose_translation_from_audio');
                        let c2 = w.exercises.includes('write_translation_from_audio');
                        let c3 = w.exercises.includes('make_translation_from_audio');
                        if (c1 || c2 || c3) c += 1;
                    });
                    resolve(c);
                    state.lesson_learn_words.forEach((w) => w.exercises = w.exercises.filter(t => {
                        return !["choose_translation_from_audio", "write_translation_from_audio", "make_translation_from_audio"].includes(t)
                    }));
                }
            });
        },
        FINISH_LESSON({state, dispatch, commit, rootState}) {
            dispatch("SKIP_LESSON_WORDS");

            const send_ids = state.lesson_learn_words.map(w => w.union.id);

            if (send_ids.length) {
                let points = state.lesson_points_earned;
                if (rootState.learning.learning_daily_activity.days_activity_span >= 7) points *= 2;

                commit("SAVE_HARD_LESSON_WORDS", state.lesson_learn_words.filter(w => w.union.type === 'word'));

                let payload = {
                    "points_earned": points,
                    "unions": send_ids,
                    "lang_code": state.lesson_lang,
                    "hard": state.hard_lesson_status,
                };

                if ((state.lesson_type === 'selection') && rootState.learning.selection) {
                    payload['selection_uuid'] = rootState.learning.selection.uuid
                }

                dispatch("SEND_LESSON_WORDS", payload);
            }
        },
        async GET_VIDEO_CAPTIONS({state, commit, rootState}, time) {
            let limit = 40,
                params = {limit};

            if (time) {
                if (Number(Math.floor(time)) > 0) params['current_time'] = Number(Math.floor(time)) * 1000
            } else if (state.video_captions.length) {
                let d_time = state.video_captions[state.video_captions.length - 1].segment_end_time
                if (Number(Math.floor(d_time)) > 0) params['current_time'] = Number(Math.floor(d_time)) + 100
            }

            await axios
                .get(`${Vue.prototype.$api_url}/education/videos/${rootState.learning.video.uuid}/captions`, {params})
                .then((resp) => {
                    let data = resp.data.data.filter(c => (c.segment_end_time / 1000) < rootState.learning.video.duration);

                    if (time) commit("SAVE_VIDEO_CAPTIONS");

                    commit("SAVE_VIDEO_CAPTIONS", data);

                    if (data.length < limit) {
                        commit("CHANGE_VIDEO_CAPTIONS_ALL_LOADED", true);
                    }
                })
        },
        VIDEO_HAS_CAPTIONS({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/education/video-sources/youtube/${payload.id}/has-captions`, {
                        params: {
                            'lang_code': payload.lang_code,
                        }
                    })
                    .then((resp) => resolve(resp.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
    },
    mutations: {
        SAVE_LESSON_WORDS: (state, data) => {
            if (data.length) {
                state.lesson_words = state.lesson_words.concat(data);
                state.lesson_words_cache = state.lesson_words_cache.concat(data);
            } else {
                state.lesson_words = [];
            }
        },
        CHANGE_LESSON_WORDS_ALL_LOADED: (state, boolean) => state.lesson_words_all_loaded = boolean,
        CHANGE_LESSON_WORDS_LIMIT: (state, limit) => state.lesson_words_limit = limit,
        CHANGE_LESSON_LANG: (state, lang) => state.lesson_lang = lang,
        CHANGE_LESSON_TYPE: (state, type) => state.lesson_type = type || 'selection',
        CHANGE_HARD_LESSON_STATUS: (state, bool) => state.hard_lesson_status = bool,
        CHANGE_CAN_LISTEN: (state, bool) => state.can_listen = bool,
        SAVE_LESSON_POINTS_EARNED: (state, points) => {
            if (points) state.lesson_points_earned += points
            else state.lesson_points_earned = 0
        },
        CLOSE_LESSON_WORDS: (state, type) => {
            if (type === 'word-audio') {
                let audios = state.lesson_words[0]?.union.words[0].audios.length > 0;
                if (audios) state.lesson_words[0].union.words[0].audios.shift();
            } else {
                state.lesson_words.shift();
            }
        },
        UPDATE_UNION_IN_SELECTION_LESSON_WORDS: (state, translation) => {
            let index = state.lesson_words.findIndex(u => u.union.id === translation.translation_union_id);
            if (~index) {
                state.lesson_words[index].union = {
                    "id": translation.translation_union_id,
                    "is_verified": translation.is_verified,
                    "is_removed": false,
                    "translation_id": translation.id,
                    "type": state.lesson_words[index].union.type,
                    "words": [
                        {
                            'id': translation.translation_word.id,
                            'name': translation.translation_word.name,
                            'lang_code': translation.translation_word.lang_code,
                            'audios': translation.translation_word.audios
                        },
                        {
                            'id': translation.primary_word.id,
                            'name': translation.primary_word.name,
                            'lang_code': translation.primary_word.lang_code,
                            'audios': translation.primary_word.audios
                        }
                    ]
                };
            }
        },
        SAVE_HARD_LESSON_WORDS: (state, array) => {
            state.hard_lesson_words = array;
        },
        SWIPE_LESSON_EXERCISE: (state, done) => {
            let done_exercise_id = state.lesson_learn_words[0].union.id,
                many_tasks = state.lesson_learn_words.length > 1;

            if (many_tasks) {
                if (state.lesson_learn_words[1].exercises.length) {
                    let first_exercise = state.lesson_learn_words.shift();
                    state.lesson_learn_words.push(first_exercise);
                } else {
                    let index_have_ex = state.lesson_learn_words.findIndex((e, i) => {
                        return (i !== 0) && (e.exercises.length > 0)
                    })
                    if (~index_have_ex) {
                        let arr1 = state.lesson_learn_words.slice(0, index_have_ex);
                        let arr2 = state.lesson_learn_words.slice(index_have_ex);
                        state.lesson_learn_words = [...arr2, ...arr1];
                    } else {
                        many_tasks = false;
                    }
                }
            }

            if (done) {
                state.lesson_learn_words.find(e => e.union.id === done_exercise_id).exercises.shift();
            }

            if (!many_tasks) {
                let first_exercise = state.lesson_learn_words.shift();
                first_exercise.id += 10;
                state.lesson_learn_words.unshift(first_exercise);
            }
        },
        SAVE_VIDEO_CAPTIONS(state, captions) {
            if (!captions) {
                state.video_captions = []
            } else if (!state.video_captions.length) {
                state.video_captions = captions
            } else {
                captions = captions.filter(c => !state.video_captions.find(sc => {
                    let c1 = sc.segment_start_time === c.segment_start_time,
                        c2 = sc.segment_end_time === c.segment_end_time,
                        c3 = sc.segment_strings === c.segment_strings;

                    return c1 && c2 && c3
                }))
                state.video_captions = state.video_captions.concat(captions)
            }
        },
        CHANGE_VIDEO_CAPTIONS_ALL_LOADED:(state, boolean) => state.video_captions_all_loaded = boolean,
        CLEAR_LESSON: (state) => {
            Object.assign(state, getDefaultState());
        }
    }
}