export default {
    priority: 700,
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) vnode.context[binding.expression](event);
        };
        document.body.addEventListener('mousedown', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('mousedown', el.clickOutsideEvent)
    },
    stopProp(event) {
        event.stopPropagation()
    }
};
