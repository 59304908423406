import router from "../../router";
import axios from "axios";
import Vue from "vue";

const getDefaultState = () => {
    return {
        favorites_in_group_all_loaded: false,
        favorite_folder: {},
        favorite_folder_main: {},
        favorites_groups: [],
        favorites_in_group: [],
        favorites_translations_total_count: null,
        favorites_sentences_total_count: null,
        favorites_folders_total_count: null,
        favorites_tooltips: [],
        favorites_groups_tooltips: [],
        favorites_found_items: [],
        favorites_checked_status: false,
        favorites_copying_status: false,
        favorites_moving_status: false,
        last_favorite: null,
        favorite_popup: false,
        favorite_selected_sort: {name: 'sort_by_date_new_old', value: 'NewOld', sort_by: 'date', sort_order: 'desc'},
        favorite_found_folder: {},
        favorite_found_word: {}
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        // FAVORITES ITEMS
        async SEARCH_FAVORITES({commit}, search) {
            if (search) {
                await axios
                    .get(`${Vue.prototype.$api_url}/favorites?search=${search}`)
                    .then(response => commit("SAVE_FAVORITES_TOOLTIPS", response.data.data))
                    .catch(err => err)
            } else await commit("SAVE_FAVORITES_TOOLTIPS", [])
        },
        async SEARCH_FAVORITES_IN_GROUP({state, commit}, search) {
            if (search) {
                await axios
                    .get(`${Vue.prototype.$api_url}/favorites/group/${state.favorite_folder.id}/favorites?search=${search}`)
                    .then(response => commit("SAVE_FAVORITES_FOUND_ITEMS", response.data.data))
                    .catch(err => err)
            } else await commit("SAVE_FAVORITES_FOUND_ITEMS", [])
        },
        async LOAD_FAVORITES_IN_GROUP({commit, state}, data) {
            state.favorites_in_group_all_loaded = false
            await commit("CHANGE_LOADING_FAVORITES_STATUS", true, {root: true})
            let sort = state.favorite_selected_sort
            await axios
                .get(`${Vue.prototype.$api_url}/favorites/group/${data.id}/favorites?limit=${data.limit}&offset=${data.offset}&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`)
                .then(response => {
                    commit("SAVE_FAVORITES_IN_GROUP", response.data.data)
                    commit("CHANGE_LOADING_FAVORITES_STATUS", false, {root: true})
                    if (response.data.data.length < 10) state.favorites_in_group_all_loaded = true
                });
        },
        async LOAD_FAVORITES_IN_GROUP_SORT({commit, state}, data) {
            state.favorites_in_group_all_loaded = false
            await commit("SAVE_FAVORITE_SELECTED_SORT", data.sort)
            await axios
                .get(`${Vue.prototype.$api_url}/favorites/group/${data.id}/favorites?limit=${data.limit}&offset=${data.offset}&sort_by=${data.sort.sort_by}&sort_order=${data.sort.sort_order}`)
                .then(response => commit("SAVE_FAVORITES_IN_GROUP", response.data.data));
        },
        async LOAD_FAVORITES_IN_GROUP_MORE({commit, state}) {
            if (!state.favorites_in_group_all_loaded) {
                let sort = state.favorite_selected_sort
                await axios
                    .get(`${Vue.prototype.$api_url}/favorites/group/${state.favorite_folder.id}/favorites?limit=10&offset=${state.favorites_in_group.length}&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`)
                    .then(response => {
                        commit("SAVE_FAVORITES_IN_GROUP_MORE", response.data.data)
                        if (response.data.data.length < 10) state.favorites_in_group_all_loaded = true
                    })
                    .catch(err => {
                        throw new Error(err)
                    })
            }
        },
        async ADD_FAVORITES({state, getters, dispatch, commit, rootState}, data) {
            if (getters.isLoggedIn) {
                if (!state.favorite_folder_main.id) await dispatch("LOAD_FAVORITES_GROUPS", 'update')
                return new Promise((resolve, reject) => {
                    axios
                        .post(Vue.prototype.$api_url + "/favorites", {
                            "entity_type": data.type,
                            "entity_id": data.id,
                            "additional_id": data.additional_id,
                            "comment": data.comment,
                            "favorite_group_id": data.groupId || state.favorite_folder_main.id
                        })
                        .then(resp => {
                            commit("SAVE_LAST_FAVORITE", resp.data.data);
                            const favorite = {comment: resp.data.data.comment, id: resp.data.data.id};

                            if (data.type === 'translation') {
                                let translate = rootState.dictionary.translates.find(transl => transl.id === data.id);
                                if (translate) translate.favorite = favorite;
                                rootState.detail_word_page.translate_info.favorite = favorite;

                                let mini_translate = rootState.mini_dictionary.mini_translates.find(transl => transl.id === data.id);
                                if (mini_translate) mini_translate.favorite = favorite;
                            } else {
                                let sentence = rootState.detail_word_page.sentences.find(sentence => sentence.word_sentence.id === data.id),
                                    word_sentence = rootState.detail_word_page.word_sentences.find(sentence => sentence.word_sentence.id === data.id);
                                if (sentence) sentence.word_sentence.favorite = favorite;
                                if (word_sentence) word_sentence.word_sentence.favorite = favorite;
                            }

                            if (resp.data.group_modified) commit("UPDATE_GROUP", resp.data.group_modified[0]);
                            resolve(!!resp.data);
                        })
                        .catch(err => reject(err))
                })
            } else if (getters.MOBILE) {
                await this.$store.dispatch("CLOSE_PANELS")
                await router.push(`${getters.LOCALE.base}/auth`)
            } else {
                await router.push({name: 'Favorites', params: {locale: getters.LOCALE.route_code}})
            }
        },
        async DELETE_FAVORITES({state, commit, rootState}, data) {
            await axios.delete(Vue.prototype.$api_url + "/favorites/delete", {data: {ids: data.ids}})
                .then(resp => {
                    commit('REMOVE_TRANSLATE_FROM_FAVORITE', data.id);
                    commit('REMOVE_MINI_TRANSLATE_FROM_FAVORITE', data.id);
                    commit('REMOVE_SENTENCE_FROM_FAVORITE', data.id);
                    rootState.detail_word_page.translate_info['favorite'] = null;

                    if (router.app._route.name === 'Favorites' || state.favorites_groups.length) {
                        data.ids.forEach(id => {
                            let index = state.favorites_in_group.findIndex(item => item.id === id);
                            if (~index) state.favorites_in_group.splice(index, 1);
                        });
                        if (resp.data.group_modified) commit("UPDATE_GROUP", resp.data.group_modified[0]);
                        commit("CHANGE_FAVORITE_PANEL", false);
                        commit("CHANGE_FAVORITE_CHECKED_STATUS", false);
                    }
                })
        },
        async COPY_FAVORITES({commit}, data) {
            await axios
                .post(Vue.prototype.$api_url + "/favorites/copy", {"ids": data.ids, "favorite_group_id": data.groupId})
                .then(response => {
                    if (response.data.group_modified) commit("UPDATE_GROUP", response.data.group_modified[0]);
                    commit("ALERT_SHOW", {name: 'favorite', content: 'copied'});
                })
        },
        async MOVE_FAVORITES({state, commit}, data) {
            await axios
                .post(Vue.prototype.$api_url + "/favorites/move", {"ids": data.ids, "favorite_group_id": data.groupId})
                .then(response => {
                    data.ids.forEach(id => {
                        let index = state.favorites_in_group.findIndex(group => group.id === id);
                        if (index >= 0) state.favorites_in_group.splice(index, 1);
                    });

                    if (response.data.group_modified) {
                        commit('UPDATE_GROUP', response.data.group_modified[0]);
                        if (response.data.group_modified[1]) commit('UPDATE_GROUP', response.data.group_modified[1]);
                    }

                    if (!data.not_alert) commit("ALERT_SHOW", {name: 'favorite', content: 'movied'});
                })
                .catch(err => {
                    throw new Error(err)
                })
        },
        async CHANGE_COMMENT({state}, data) {
            await axios
                .put(`${Vue.prototype.$api_url}/favorites/${data.id}`, {comment: data.comment})
                .then(newFavorite => {
                    let index = state.favorites_in_group.findIndex(favorite => favorite.id === data.id)
                    state.favorites_in_group.splice(index, 1, newFavorite.data.data)
                })
        },
        async DELETE_COMMENT({state}, id) {
            await axios
                .delete(`${Vue.prototype.$api_url}/favorites/${id}/comment`)
                .then(newFavorite => {
                    let index = state.favorites_in_group.findIndex(favorite => favorite.id === id)
                    state.favorites_in_group.splice(index, 1, newFavorite.data.data)
                })
        },
        async ADD_RAW_FAVORITE({state, getters, dispatch, commit, rootState}, data) {
            if (getters.isLoggedIn) {
                if (!state.favorite_folder_main.id) await dispatch("LOAD_FAVORITES_GROUPS", 'update')
                return new Promise((resolve, reject) => {
                    axios
                        .post(Vue.prototype.$api_url + "/favorites/translation/raw", {
                            "word_from": data.word_from,
                            "word_to": data.word_to,
                            "lang_from": data.lang_from,
                            "lang_to": data.lang_to,
                            "favorite_group_id": data.groupId || state.favorite_folder_main.id
                        })
                        .then(resp => {
                            commit("SAVE_LAST_FAVORITE", resp.data.data);
                            const favorite = {comment: resp.data.data.comment, id: resp.data.data.id};

                            if (data.type === 'translation') {
                                let translate = rootState.dictionary.translates.find(transl => transl.id === data.id);
                                if (translate) translate.favorite = favorite;
                                rootState.detail_word_page.translate_info.favorite = favorite;

                                let mini_translate = rootState.mini_dictionary.mini_translates.find(transl => transl.id === data.id);
                                if (mini_translate) mini_translate.favorite = favorite;
                            } else {
                                let sentence = rootState.detail_word_page.sentences.find(sentence => sentence.word_sentence.id === data.id),
                                    word_sentence = rootState.detail_word_page.word_sentences.find(sentence => sentence.word_sentence.id === data.id);
                                if (sentence) sentence.word_sentence.favorite = favorite;
                                if (word_sentence) word_sentence.word_sentence.favorite = favorite;
                            }

                            if (resp.data.group_modified) commit("UPDATE_GROUP", resp.data.group_modified[0]);
                            resolve(!!resp.data);
                        })
                        .catch(err => reject(err))
                })
            } else if (getters.MOBILE) {
                await this.$store.dispatch("CLOSE_PANELS")
                await router.push(`${getters.LOCALE.base}/auth`)
            } else {
                await router.push({name: 'Favorites', params: {locale: getters.LOCALE.route_code}})
            }
        },
        // FAVORITES GROUP
        async LOAD_FAVORITES_GROUPS({state, commit}, type) {
            const haveGroups = state.favorites_groups.length > 0,
                maxLength = state.favorites_folders_total_count || 1,
                groupsAllLoaded = state.favorites_groups.length >= maxLength,
                limit = 15;

            if (!groupsAllLoaded || type) {
                let url = Vue.prototype.$api_url + '/favorites/group?limit=' + limit;

                if (haveGroups && type === 'load_up') url += '&offset=' + state.favorites_groups.length;

                await axios
                    .get(url)
                    .then(response => {
                        let data = response.data
                        commit("SAVE_FAVORITES_FOLDERS_TOTAL_COUNT", data.folders_total_count)
                        commit("SAVE_FAVORITES_TRANSLATIONS_TOTAL_COUNT", data.translations_total_count)
                        commit("SAVE_FAVORITES_SENTENCES_TOTAL_COUNT", data.sentences_total_count)
                        if (data.data.length) {
                            if (type === 'update') commit('SAVE_FAVORITES_GROUPS', data.data)
                            else commit("SUPPLEMENT_FAVORITES_GROUPS", data.data)
                            if (!state.favorite_folder_main.id || type === 'update') {
                                let main = data.data.find(group => group.is_main)
                                if (main) commit("SAVE_FAVORITE_FOLDER_MAIN", main)
                            }
                        }
                    })
                    .catch(err => {
                        throw new Error(err)
                    })
            }
        },
        async SEARCH_FAVORITES_GROUPS({commit, dispatch}, search) {
            if (search) {
                await dispatch('GET_FAVORITES_GROUPS', search)
                    .then(folders => commit("SAVE_FAVORITES_GROUPS_TOOLTIPS", folders))
                    .catch(err => err)
            } else await commit("SAVE_FAVORITES_GROUPS_TOOLTIPS", [])
        },
        GET_FAVORITES_GROUPS({commit}, search) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/favorites/group?limit=20&offset=0&search=${search}`)
                    .then(response => resolve(response.data.data))
                    .catch(err => reject(err))
                    .finally(() => commit)
            })
        },
        ADD_FAVORITES_GROUP({commit}, groupName) {
            return new Promise((resolve, reject) => {
                axios
                    .post(Vue.prototype.$api_url + "/favorites/group", {name: groupName})
                    .then(newGroup => {
                        commit('SAVE_NEW_GROUP', newGroup.data.data)
                        resolve(newGroup.data.data)
                    })
                    .catch(() => reject())
            })
        },
        async CHANGE_FAVORITE_GROUP_NAME({state}, data) {
            await axios
                .put(
                    `${Vue.prototype.$api_url}/favorites/group/${data.id}`,
                    {"name": data.groupName}
                )
                .then(newFolder => {
                    let arr = state.favorites_groups
                    let index = arr.findIndex(element => element.id === data.id)
                    arr.splice(index, 1, newFolder.data.data)
                });
        },
        async CHANGE_FAVORITE_GROUP_MAIN({dispatch}, id) {
            await axios
                .post(`${Vue.prototype.$api_url}/favorites/group/${id}/main`)
                .then(() => dispatch('LOAD_FAVORITES_GROUPS', 'update'));
        },
        async DELETE_FAVORITES_GROUP({commit}, groupId) {
            await axios
                .delete(Vue.prototype.$api_url + `/favorites/group/${groupId}`)
                .then(() => {
                    commit('DELETE_ONE_FAVORITES_GROUPS', groupId)
                })
                .catch((err) => {
                    throw new Error(err)
                })
        },
        CHANGE_ORDER({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${Vue.prototype.$api_url}/favorites/group/change-order`, data)
                    .then(resp => resolve(resp))
                    .catch(err => reject(err))
                    .finally(() => commit)
            })
        }
    },
    mutations: {
        SAVE_FAVORITE_FOLDER: (state, folder) => state.favorite_folder = folder,
        SAVE_FAVORITE_FOLDER_MAIN: (state, folder) => state.favorite_folder_main = folder,
        SAVE_FAVORITES_IN_GROUP: (state, favorites) => state.favorites_in_group = favorites,
        SAVE_FAVORITES_IN_GROUP_MORE: (state, favorites) => state.favorites_in_group = state.favorites_in_group.concat(favorites),
        SAVE_FAVORITES_GROUPS: (state, groups) => state.favorites_groups = groups,
        SAVE_LAST_FAVORITE: (state, favorite) => state.last_favorite = favorite,
        SAVE_FAVORITE_POPUP: (state, boolean) => state.favorite_popup = boolean,
        SUPPLEMENT_FAVORITES_GROUPS: (state, groups) => state.favorites_groups = state.favorites_groups.concat(groups),
        UPDATE_GROUP: (state, group) => {
            let index = state.favorites_groups.findIndex(item => item.id === group.id)
            if (~index) {
                if (state.favorites_groups[index].translations_count !== group.translations_count) {
                    state.favorites_translations_total_count += group.translations_count - state.favorites_groups[index].translations_count
                } else if (state.favorites_groups[index].sentences_count !== group.sentences_count) {
                    state.favorites_sentences_total_count += group.sentences_count - state.favorites_groups[index].sentences_count
                }
                state.favorites_groups.splice(index, 1, group)
            }
        },
        DELETE_AUDIO_FROM_FAVORITES(state, id) {
            state.favorites_in_group.forEach(f => {
                if (f.entity_type === 'translation') {
                    if (f.entity.primary_word.audios) {
                        let p_a = f.entity.primary_word.audios.findIndex(a => a.id === id);
                        if (~p_a) f.entity.primary_word.audios.splice(p_a, 1);
                    }
                    if (f.entity.translation_word.audios) {
                        let t_a = f.entity.translation_word.audios.findIndex(a => a.id === id);
                        if (~t_a) f.entity.translation_word.audios.splice(t_a, 1);
                    }
                }
            })
        },
        SAVE_FAVORITES_FOLDERS_TOTAL_COUNT: (state, number) => state.favorites_folders_total_count = number,
        SAVE_FAVORITES_TRANSLATIONS_TOTAL_COUNT: (state, number) => state.favorites_translations_total_count = number,
        SAVE_FAVORITES_SENTENCES_TOTAL_COUNT: (state, number) => state.favorites_sentences_total_count = number,
        SAVE_FAVORITES_TOOLTIPS: (state, tooltips) => state.favorites_tooltips = tooltips,
        SAVE_FAVORITES_FOUND_ITEMS: (state, favorites) => state.favorites_found_items = favorites,
        SAVE_FAVORITES_GROUPS_TOOLTIPS: (state, tooltips) => state.favorites_groups_tooltips = tooltips,
        SAVE_FAVORITE_FOUND_FOLDER: (state, folder) => state.favorite_found_folder = folder,
        SAVE_FAVORITE_FOUND_WORD: (state, word) => state.favorite_found_word = word,
        SAVE_NEW_GROUP: (state, group) => {
            state.favorites_groups.push(group)
            state.favorites_folders_total_count += 1
        },
        CHANGE_FAVORITE_CHECKED_STATUS: (state, boolean) => state.favorites_checked_status = boolean,
        CHANGE_FAVORITE_COPYING_STATUS: (state, boolean) => state.favorites_copying_status = boolean,
        CHANGE_FAVORITE_MOVING_STATUS: (state, boolean) => state.favorites_moving_status = boolean,
        CLOSE_FAVORITE_ALL_STATUS: (state) => {
            state.favorites_moving_status = state.favorites_copying_status = state.favorites_checked_status = false
        },
        SAVE_FAVORITE_SELECTED_SORT: (state, sort) => state.favorite_selected_sort = sort ? sort : {
            name: 'sort_by_date_new_old',
            value: 'new-old',
            sort_by: 'date',
            sort_order: 'desc'
        },
        DELETE_ONE_FAVORITES_GROUPS: (state, groupId) => {
            let index = state.favorites_groups.findIndex(el => el.id === groupId)
            state.favorites_groups.splice(index, 1)
            state.favorites_folders_total_count -= 1
            for (let i = index; i <= (state.favorites_groups.length - 1); i++) state.favorites_groups[i].order -= 1
        },
        CLEAR_USER_FAVORITES: (state) => Object.assign(state, getDefaultState())
    }
}
