/* eslint-disable no-console */

import {register} from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.info('WOLA: App is being served from cache by a service worker.')
        },
        registered() {
            console.info('WOLA: Service worker has been registered.')
        },
        cached() {
            console.info('WOLA: Content has been cached for offline use.')
        },
        updatefound() {
            console.info('WOLA: New content is downloading.')
        },
        updated() {
            console.info('WOLA: New content is available; please refresh.')
        },
        offline() {
            console.info('WOLA: No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('WOLA: Error during service worker registration:', error)
        }
    })
}
