<template>
    <transition name="bounce" mode="out-in" appear>
        <button class="arrow_back pointer semi-bold none-select" @click.prevent="goBack">
            <div class="arrow_back-icon icon">
                <w-svg-icon folder-name="dictionary" icon-name="iconArrowBack"/>
            </div>
            <div v-if="show_text" class="arrow_back-text semi-bold">
                {{ $t('btn_back') }}
            </div>
        </button>
    </transition>
</template>

<script>
    export default {
        name: "arrow_back",
        props: {
            custom: {
                type: Boolean,
                default: false
            },
            show_text: {
                type: Boolean,
                default: true
            },
        },
        methods: {
            goBack() {
                if ((this.$route.name === 'TrainingSelection') && this.$store.state.other.is_first_page) {
                    this.$router.replace(this.$store.getters.LOCALE.base + '/feed');
                    this.$store.commit("CHANGE_IS_FIRST_PAGE", false);
                    return;
                } else if ((this.$route.name === 'TrainingVideo') && this.$store.state.other.is_first_page) {
                    this.$router.replace(this.$store.getters.LOCALE.base + '/feed');
                    this.$store.commit("CHANGE_IS_FIRST_PAGE", false);
                    return;
                } else if (this.custom) {
                    this.$emit('click');
                    return;
                }
                this.$store.dispatch('CLOSE_PAGE_BACK', 'Dictionary');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .arrow_back {
        position: fixed;
        top: 48px;
        left: 48px;
        display: flex;
        align-items: center;
        z-index: 20;

        &-icon {
            width: 32px;
            height: 32px;
            margin-right: 12px;
            border-radius: 50%;
            background-color: var(--w_color_white);
        }

        &-text {
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: var(--w_color_grey6);
        }
    }

    @media (max-width: 1440px) and (min-width: 981px) {
        .arrow_back {
            top: 32px;
            left: 32px;

            &-icon {
                width: 22px;
                height: 22px;
                margin-right: 8px;

                svg {
                    width: 12px;
                }
            }

            &-text {
                font-size: 9px;
                line-height: 14px;
            }
        }
    }

    @media screen and (max-width: 980px) {
        .arrow_back {
            top: 16px;
            left: 16px;

            &-text {
                display: none;
            }
        }

        @supports (-webkit-touch-callout: none) {
            @media screen and (display-mode: standalone) {
                .arrow_back {
                    top: 56px;
                }
            }
        }
    }

    [data-theme='dark'] {
        .arrow_back {
            &-icon {
                background-color: var(--w_color_grey7);
            }

            &-text {
                color: var(--w_color_grey4);
            }
        }
    }
</style>
