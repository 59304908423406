import axios from "axios";
import Vue from "vue";

const getDefaultState = () => {
    return {
        ratings: [],
        last_week_winners: [],
        this_week_chart: [],
        refresh_date: null
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        LOAD_RATINGS_GENERAL({commit, rootState}) {
            return new Promise((resolve, reject) => {
                commit('CHANGE_LOADING_STATISTICS_STATUS', true)
                setTimeout(() => {
                    axios
                        .get(Vue.prototype.$api_url + '/user/weekly-chart')
                        .then(resp => {
                            commit("SAVE_RATINGS_LAST_WEEK_WINNERS", resp.data.data.last_week_winners)
                            commit("SAVE_RATINGS_THIS_WEEK_CHART", resp.data.data.this_week_chart)
                            commit("SAVE_RATINGS_REFRESH_DATE", resp.data.data.refresh_date)
                            resolve()
                        })
                        .catch((err) => {
                            reject(err)
                        })
                        .finally(() => commit('CHANGE_LOADING_STATISTICS_STATUS', false))
                }, rootState.profile.profile_update_timeout)
            })
        },
    },
    mutations: {
        SAVE_RATINGS_LAST_WEEK_WINNERS: (state, users) => state.last_week_winners = users,
        SAVE_RATINGS_THIS_WEEK_CHART: (state, users) => state.this_week_chart = users,
        SAVE_RATINGS_REFRESH_DATE: (state, date) => state.refresh_date = date,
        CLEAR_RATINGS: (state) => Object.assign(state, getDefaultState())
    }
}
