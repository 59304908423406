import Vue from "vue";
import axios from "axios";
import Echo from "laravel-echo";
import router from "../../router";
import store from "../index";

let uuid = "",
    filter = "";

const getDefaultState = () => {
    return {
        notifications: [],
        notifications_filter: 'all',
        notifications_new_count: 0,
        notifications_load_length: 15,
        notifications_panel: false,
        notifications_settings_panel: false,
        notifications_all_loaded: false,
        push_messages: [],
        push_supported_types: [
            'achievement_reached',                  // получение новой ачивки
            'new_level_reached',                    // достижение нового уровня
            'push_for_chat',                        // новое сообщение из чата
            'user_can_be_moderator',                // приглашение в модераторы
            'user_become_moderator',                // поздравление с модераторством
            'moderator_performed_task',             // модератор принял/отклонил вашу жалобу || отклонил ваше оспаривание
            'friend_request',                       // с тобой хотят дружить
            'friendship_accepted',                  // теперь вы дружите с пользователем
            'like_user',                            // получение лайка
            'verification_succeed',                 // пользователи подтвердили ваш материал
            'verification_failed',                  // пользователи отклонили ваш материал
            'verification_changed_for_participant', // модератор принял/отклонил ваш ответ
            'verification_changed_for_author',      // модератор подтвердил/отклонил ваш материал
            'wrong_answer',                         // ошиблись выполняя задание
            'entity_commented',                     // новый комментарий к вашему материалу
            'task_entity_commented',                // новый комментарий к вашему материалу
            'entity_comment_replied',               // ответили на ваш комментарий к заданию
            'entity_select_action',                 // выбрать действие по прокомментированному материалу
            'entity_edited_by_commenter',           // пользователь изменил ваш материал
            'entity_edited',                        // пользователь изменил прокомментированный вами материал
            'we_miss_you',                          // мы скучаем
            'new_tasks',                            // новые задания для вас
            'no_activity_in_chat',                  // вас давно не было в чате
            'new_user_who_can_become_interlocutor', // приглашение от пользователя
            'end_daily_activity_warning',           // не пропустите ежедневную активность
            'new_education_selection_comment',      // пользователь оставил комментарий к вашей подборке
            'new_education_selection_comment_reply',// пользователь ответил на ваш комментарий к подборке
            'selection_became_popular',             // подборка стала популярной
            'like_education_selection',             // лайк подборке
            'new_subscriber',                       // новый подписчик
            'end_beat_tempo_warning_notification',  // ударный темп
            'new_published_selection',              // твоя подписка опубликовала новую подборку
            'video_became_popular',                 // видео стало популярным
            'like_education_video',                 // лайк видео
            'new_education_video',                  // твоя подписка опубликовала новое видео
            'new_education_video_comment',          // пользователь оставил комментарий к вашему видео
            'new_education_video_comment_reply',    // пользователь ответил на ваш комментарий к видео
            'new_post',                             // пользователь опубликовал новый пост
            'new_post_comment',                     // пользователь написал комментарий к посту
            'new_post_comment_reply',               // пользователь ответил на ваш комментарий к посту
            'like_post',                            // лайк посту
            'new_article',                          // пользователь опубликовал новую статью
            'new_article_comment',                  // пользователь написал комментарий к статье
            'new_article_comment_reply',            // пользователь ответил на ваш комментарий к статье
            'like_article',                         // лайк статье
            'new_question',                         // пользователь опубликовал новый вопрос
            'like_question',                        // лайк вопросу
            'new_question_answer',                  // новый ответ к вопросу
            'new_question_answer_comment',          // новый комментарий к ответу на вопрос
            'new_question_answer_comment_reply',    // ответили на ваш ответ на вопрос
            'user_reprimand',                       // предупреждение за несоблюдение правил
            'geolocation_data_updated',             // геолокация обновилась
            'general_cancelled_push'                // отмена пуша
        ],
        notifications_unsupported_types: [
            'push_for_chat',                        // новое сообщение из чата
            'we_miss_you',                          // мы скучаем
            'new_tasks',                            // новые задания для вас
            'no_activity_in_chat',                  // вас давно не было в чате
            'end_daily_activity_warning',           // не пропустите ежедневную активность
            'end_beat_tempo_warning_notification',  // ударный темп
        ],
        notifications_for_need_action: [],
        notifications_for_need_action_all_loaded: false
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_NOTIFICATIONS({state, commit}, type) {
            if (state.notifications_filter !== filter) {
                await commit("REMOVE_NOTIFICATIONS");
                state.notifications_all_loaded = false;
            }

            const haveNotifications = !!state.notifications.length,
                notificationsAllLoaded = state.notifications_all_loaded;

            if (!state.notifications.length || (state.notifications_filter !== filter) || state.notifications_new_count > 0 || (!notificationsAllLoaded && type)) {
                let url = Vue.prototype.$api_url + '/user/notifications/' + state.notifications_filter;
                let offset;

                if (state.notifications_new_count > 0 && !type) commit("REMOVE_NOTIFICATIONS")
                else if (haveNotifications) offset = state.notifications.length;

                await axios
                    .get(url, {
                        params: {
                            limit: state.notifications_load_length,
                            offset
                        }
                    })
                    .then(resp => {
                        let data = resp.data.data;

                        if (data.length) commit("SAVE_NOTIFICATIONS", data);
                        else if (!type && state.notifications_filter === 'unread') commit("SAVE_NOTIFICATIONS_NEW_COUNT", 0);

                        if (data.length < state.notifications_load_length) state.notifications_all_loaded = true;
                    })
                    .catch((e) => {
                        throw new Error(e)
                    })
                    .finally(() => {
                        filter = state.notifications_filter
                    })
            }
        },
        LOAD_NOTIFICATIONS_SETTINGS({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(Vue.prototype.$api_url + '/user/notifications/settings')
                    .then(resp => {
                        commit
                        resolve(resp.data.data.settings)
                    })
                    .catch((e) => {
                        reject(e)
                        throw new Error(e)
                    })
            })
        },
        SEND_NOTIFICATIONS_SETTINGS({commit}, settings) {
            axios.put(Vue.prototype.$api_url + '/user/notifications/settings', {settings})
            commit
        },
        async LOAD_NEW_NOTIFICATIONS_COUNT({commit}) {
            await axios
                .get(Vue.prototype.$api_url + '/user/notifications/unread/count')
                .then(response => commit("SAVE_NOTIFICATIONS_NEW_COUNT", response.data.data.count));
        },
        async READ_NOTIFICATIONS({state, commit}, uuids) {
            await axios
                .post(Vue.prototype.$api_url + '/user/notifications/mark-as-read-multiple', {uuids})
                .then(response => commit("SAVE_NOTIFICATIONS_NEW_COUNT", response.data.data.unread_notifications_count))
                .then(() => {
                    uuids.forEach(id => {
                        let item = state.notifications.find(notification => notification.id === id)
                        if (item) item.read_at = Date.now().toString()
                    })
                })
        },
        async READ_ALL_NOTIFICATIONS({state, commit}) {
            await axios
                .post(Vue.prototype.$api_url + '/user/notifications/mark-as-read-all')
                .then(async () => {
                    await state.notifications.forEach(n => n.read_at = Date.now().toString());
                    await commit("SAVE_NOTIFICATIONS_NEW_COUNT", 0);
                })
        },
        async SOCKET_PUSH_CONNECT({state, commit, dispatch, rootState}) {
            if (Vue.prototype.$echo?.connector) Vue.prototype.$echo.connector.pusher.disconnect();

            uuid = rootState.profile.user_info.uuid;

            window.Pusher = require('pusher-js');

            let host = ''

            if (store.state.other.dev_host) {
                let u = new URL(store.state.cache.api)
                host = u.host;
                URL.revokeObjectURL(u);
            } else host = 'wola.io';

            Vue.prototype.$echo = await new Echo({
                broadcaster: 'pusher',
                key: 'fakepusherkey',
                secret: 'fakepushersecret',
                cluster: 'eu',
                forceTLS: true,
                encrypted: true,
                wsHost: host,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],

                authEndpoint: Vue.prototype.$api_url + "/broadcast",
                auth: {
                    headers: {
                        Authorization: rootState.auth.token_type + ' ' + rootState.auth.token
                    }
                }
            });

            let connectPusherChannel = () => {
                Vue.prototype.$echo
                    .private(`notifications.${uuid}`)
                    .listen('NewNotificationEvent', (e) => {
                        let current_route = router.app._route,
                            uniq_notification,
                            notification_type = e.notification.notification_type;

                        if (rootState.other.dev_host) console.info('WOLA: New push message.', e.notification);

                        if (state.push_supported_types.includes(notification_type)) {
                            if (e.notification.cancellation_uuid) {
                                uniq_notification = state.push_messages.findIndex(notification => {
                                    return !!notification.cancellation_uuid && (notification.cancellation_uuid !== e.notification.cancellation_uuid)
                                })
                            } else if (notification_type === 'new_level_reached') {
                                commit("CHANGE_LEVEL_POPUP", e.notification.level);
                                dispatch("READ_NOTIFICATIONS", [e.notification.uuid]);
                                commit("DICTIONARY_RATING_UPDATE", true);
                                dispatch("LOAD_USER_RATING_DICTIONARY");
                                return
                            } else if (notification_type === 'achievement_reached') {
                                dispatch("READ_NOTIFICATIONS", [e.notification.uuid]);
                                commit("ACHIEVEMENTS_UPDATE");
                                dispatch("LOAD_USER_ACHIEVEMENTS");
                                let lang_code = null;
                                if (e.notification.additional) {
                                    let additional = JSON.parse(e.notification.additional);
                                    if (additional.lang_code) lang_code = additional.lang_code;
                                }
                                commit("CHANGE_ACHIEVEMENT_POPUP", {
                                    "this_user": true,
                                    "congratulation": true,
                                    "prize": {
                                        "type": e.notification.type,
                                        "level": e.notification.level,
                                        "lang_code": lang_code
                                    }
                                });
                                return;
                            } else if ((notification_type === 'user_become_moderator') && ((current_route.name === 'ModeratorsGreeting') || rootState.panels.accept_moderation_popup)) {
                                dispatch("READ_NOTIFICATIONS", [e.notification.uuid]);
                                return;
                            } else if (notification_type === 'geolocation_data_updated') {
                                commit("UPDATE_USER_GEO_POSITION", e.notification);
                                commit("CHANGE_UPDATED_PROFILE_DATA", e.notification);
                                return;
                            } else {
                                uniq_notification = state.push_messages.findIndex(notification => notification.uuid && notification.uuid === e.notification.uuid);
                            }

                            if (~uniq_notification) return;
                            else {
                                if (notification_type === 'general_cancelled_push') {
                                    const delete_push_type = state.push_messages.find(push => push.uuid === e.notification.cancellation_uuid)?.notification_type;

                                    if (delete_push_type) {
                                        if (delete_push_type === 'friend_request') {
                                            let c1 = current_route.name === 'ProfilePage',
                                                c2 = current_route.params.nickname === e.notification.nickname;

                                            if (c1 && c2) router.go(0)
                                        }
                                    } else if (delete_push_type === 'like_user') {
                                        commit("CHANGE_USER_LIKES_COUNT", -1);
                                    }

                                    commit("DELETE_PUSH_MESSAGE", e.notification.cancellation_uuid);
                                    commit("SAVE_NOTIFICATIONS_NEW_COUNT", state.notifications_new_count - 1);
                                } else {
                                    commit("SAVE_NEW_PUSH", e.notification);

                                    if (notification_type === 'push_for_chat') commit("UPDATE_UNREAD_MESSAGES_STATUS", true);
                                    else if (!state.notifications_unsupported_types.includes(notification_type)) commit("SAVE_NOTIFICATIONS_NEW_COUNT", state.notifications_new_count + 1);

                                    if (notification_type === 'friendship_accepted') {
                                        commit("CHANGE_UPDATED_PROFILE_DATA", {
                                            user_uuid: e.notification.user_uuid,
                                            friendship_status: 'accepted'
                                        })

                                        if (current_route.name === 'Chat') {
                                            commit("UPDATE_COMPANION_FRIENDSHIP_STATUS", e.notification.user_uuid);
                                        }
                                    } else if (notification_type === 'friend_request') {
                                        commit("CHANGE_UPDATED_PROFILE_DATA", {
                                            user_uuid: e.notification.user_uuid,
                                            friendship_status: 'request_receiver'
                                        })
                                    } else if (notification_type === 'user_reprimand') {
                                        commit("SAVE_USER_REPRIMAND", {reason: e.notification.reason, until_date: e.notification.until_date, comment: e.notification.comment});
                                    } else if (notification_type === 'user_can_be_moderator') {
                                        commit("CHANGE_USER_MODERATION_STATUS", {status: "invited", role: "user"});
                                    } else if (notification_type === 'like_user') {
                                        commit("CHANGE_USER_LIKES_COUNT", 1);
                                    }
                                }
                            }
                        }
                    })
            }

            await Vue.prototype.$echo.connector.pusher.connection.bind('connected', () => console.info('WOLA: System pusher connected'));
            await Vue.prototype.$echo.connector.pusher.connection.bind('disconnected', () => {
                console.info('WOLA: System pusher disconnected');
                connectPusherChannel();
            });

            await connectPusherChannel()
        },
        async LOAD_NEW_NOTIFICATIONS_FOR_NEED_ACTION({state, commit}) {
            await axios
                .get(Vue.prototype.$api_url + '/user/notifications/unread', {
                    params: {
                        limit: state.notifications_load_length
                    }
                })
                .then(resp => {
                    let data = resp.data.data;

                    if (resp.data.data.length < state.notifications_load_length) commit("CHANGE_NOTIFICATIONS_FOR_NEED_ACTION_ALL_LOADED", true);

                    data = data.filter(e => {
                        let c1 = !['new_level_reached', 'achievement_reached', 'friend_request', 'friendship_accepted'].includes(e.type);
                        let c2 = e.data.entity_business_type_slug && e.data.entity_id
                        return c1 && c2
                    })

                    commit("SAVE_NOTIFICATIONS_FOR_NEED_ACTION", data.map(e => {
                        return {
                            entity_id: e.data.entity_id,
                            entity_type_slug: e.data.entity_business_type_slug,
                            notification_id: e.id
                        }
                    }))
                })
                .catch((e) => {
                    throw new Error(e)
                })
                .finally(() => {
                    filter = state.notifications_filter
                })
        },
        OPEN_NEXT_NEED_ACTION_TASK_FROM_NOTIFICATION({state, dispatch, commit, rootState}) {
            let task_index = state.notifications_for_need_action.findIndex(t => t.notification_id === rootState.need_action.need_action_task_details.notification_id);
            if (~task_index) state.notifications_for_need_action.splice(task_index, 1);
            let task = state.notifications_for_need_action[0];
            if (task) {
                task['type'] = 'dictionary';
                dispatch("READ_NOTIFICATIONS", [task.notification_id]);
                commit("CHANGE_NEED_ACTION_TASK_DETAILS", task);
            } else {
                commit("CHANGE_NEED_ACTION_TASK_DETAILS", null);
            }
        },
    },
    mutations: {
        SAVE_NOTIFICATIONS: (state, notifications) => state.notifications = state.notifications.concat(notifications),
        SAVE_NOTIFICATIONS_FILTER: (state, filter) => state.notifications_filter = filter,
        REMOVE_NOTIFICATIONS: (state) => state.notifications = [],
        SAVE_NOTIFICATIONS_NEW_COUNT: (state, count) => state.notifications_new_count = count >= 0 ? count : 0,
        CHANGE_NOTIFICATIONS_PANEL: (state) => {
            if (state.notifications_panel === false) state.push_messages = []
            state.notifications_panel = !state.notifications_panel
        },
        CLOSE_NOTIFICATIONS_PANEL: (state) => {
            let timeout = 0;

            if (state.notifications_settings_panel) {
                state.notifications_settings_panel = false;
                timeout = 300;
            }

            setTimeout(() => {
                state.notifications_panel = false;
                state.notifications = [];
            }, timeout)

            state.notifications_for_need_action = [];
            state.notifications_all_loaded = false;
        },
        CHANGE_NOTIFICATIONS_SETTINGS_PANEL: (state, boolean) => state.notifications_settings_panel = boolean,
        CHANGE_NOTIFICATIONS_FOR_NEED_ACTION_ALL_LOADED: (state, boolean) => state.notifications_for_need_action_all_loaded = boolean,
        DELETE_USER_MESSAGES_NOTICE: (state, user_uuid) => {
            state.push_messages = state.push_messages.filter(push => {
                if (push.notification_type !== 'push_for_chat') return;
                else return push.user_uuid !== user_uuid;
            })
        },
        DELETE_PUSH_MESSAGE: (state, id) => {
            let pushIndex = state.push_messages.findIndex(push => push.uuid === id)
            if (~pushIndex) state.push_messages.splice(pushIndex, 1)
        },
        CLEAR_PUSH_MESSAGE: (state) => state.push_messages = [],
        SAVE_NOTIFICATIONS_FOR_NEED_ACTION: (state, data) => state.notifications_for_need_action = data,
        SAVE_NEW_PUSH: (state, push) => {
            state.push_messages.push(push)
            if (state.push_messages.length > 3) state.push_messages.shift();
        },
        CLEAR_NOTIFICATIONS: (state) => {
            if (Vue.prototype.$echo?.connector) Vue.prototype.$echo.connector.pusher.disconnect();
            Object.assign(state, getDefaultState());
        },
    },
}
