export const SUPPORTED_LOCALES = [
    {
        code: 'en',
        base: '',
        route_code: undefined,
        dictionary_code: 'eng',
        translations: '/src/localizations/en.json'
    },
    {
        code: 'de',
        base: '/de',
        route_code: 'de',
        dictionary_code: 'ger',
        translations: '/src/localizations/de.json'
    },
    {
        code: 'es',
        base: '/es',
        route_code: 'es',
        dictionary_code: 'spa',
        translations: '/src/localizations/es.json'
    },
    {
        code: 'fr',
        base: '/fr',
        route_code: 'fr',
        dictionary_code: 'fre',
        translations: '/src/localizations/fr.json'
    },
    {
        code: 'nl',
        base: '/nl',
        route_code: 'nl',
        dictionary_code: 'dut',
        translations: '/src/localizations/nl.json'
    },
    {
        code: 'pl',
        base: '/pl',
        route_code: 'pl',
        dictionary_code: 'pol',
        translations: '/src/localizations/pl.json'
    },
    {
        code: 'ru',
        base: '/ru',
        route_code: 'ru',
        dictionary_code: 'rus',
        translations: '/src/localizations/ru.json'
    },
    {
        code: 'uk',
        base: '/uk',
        route_code: 'uk',
        dictionary_code: 'ukr',
        translations: '/src/localizations/uk.json'
    }
]
