import Vue from "vue";
import axios from "axios";
import router from "../../router";
import UNFULFILLED_ACTIONS from "@/assets/js/unfulfilled_actions";
import moment from 'moment';
import Cookies from 'js-cookie';

const getDefaultState = () => {
    return {
        briefcase_version: localStorage.getItem('ls-version') || '1.0.0',
        site_load: true,
        internet_connection: true,
        social_windows: false,
        exist_version: '1.0.0',
        windowWidth: 1600,
        page_key: 1,
        redirect_link: null,
        before_auth_link: '',
        data_theme: 'light',
        before_edit_link: null,
        locale: {},
        scroll_position: {},
        call_to_auth: null,
        language_setup_popup: null,
        language_setup_popup_data: null,
        document_visible: true,
        dedicated_translation_data: null,
        plus_benefit_data: null,
        is_first_page: false,
        google_adsense_status: true,
        dev_host: location.host !== 'wola.io',
        local_host: location.origin.includes('http://localhost:')
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        MOBILE: state => state.windowWidth < 980,
        LOCALE: state => state.locale
    },
    actions: {
        APP_START({dispatch, getters}) {
            if (getters.isLoggedIn) {
                if (!getters.USER.nickname) dispatch('LOAD_USER_INFO')
                if (UNFULFILLED_ACTIONS.size() > 0) dispatch('RETRY_FAILED_ACTIONS')

                dispatch('LOAD_NEW_NOTIFICATIONS_COUNT')

                if (!getters.MOBILE) {
                    dispatch('SOCKET_PUSH_CONNECT')
                    dispatch('GET_UNREAD_MESSAGES_COUNT')
                }
            }
        },
        async CHECK_WEB_APP_VERSION({state, commit}) {
            await axios
                .get(Vue.prototype.$api_url + '/client-applications/web')
                .then(resp => {
                    if (state.exist_version) {
                        const
                            new_version = Number(resp.data.data.maxExistVersion.replace('.', '')),
                            old_version = Number(state.exist_version)

                        if (new_version > old_version) setTimeout(() => {
                            commit('CLEAR_USER');
                            commit('CLEAR_STATISTICS');
                            commit('CLEAR_RATINGS');
                            commit('CLEAR_ATTRIBUTES');
                            commit('CLEAR_LANGUAGES');
                            commit('CLEAR_TASKS');
                            commit('CLEAR_NEED_ACTION_TASKS');
                            commit('CLEAR_MODERATION_STORE');
                            commit('CLEAR_CHAT');
                            commit('CLEAR_CACHE');
                            commit('CLEAR_HISTORY')

                            Vue.$vlf.removeItem('chatDB');

                            localStorage.clear()

                            location.reload(true)
                        }, 200)
                    }

                    state.exist_version = String(resp.data.data.maxExistVersion)
                })
        },
        async SEND_REPORT({dispatch, commit}, payload) {
            await axios
                .post(Vue.prototype.$api_url + '/reviews', payload)
                .then(() => {
                    commit("ALERT_SHOW", {
                        name: 'feed_complain',
                        content: 'review_success_message'
                    })
                })
                .catch(err => {
                    dispatch('ERROR_SHOW', {
                        name: 'system',
                        content: 'server_error_title'
                    });
                    throw Error(err);
                })
        },
        async RETRY_FAILED_ACTIONS({dispatch, commit}) {
            await commit("CHANGE_LOADING_INTERNET_CONNECTION_STATUS", true)

            let a = 0,
                action_list = UNFULFILLED_ACTIONS.list(),
                size = UNFULFILLED_ACTIONS.size(),
                finish = async (count, name) => {
                    if (count === size) {
                        await commit("SAVE_INTERNET_CONNECTION", true);
                        await commit("CHANGE_LOADING_INTERNET_CONNECTION_STATUS", false);
                        await UNFULFILLED_ACTIONS.clear();
                    } else {
                        UNFULFILLED_ACTIONS.delete(name)
                    }
                };

            await action_list.forEach((payload, name) => {
                dispatch(name, payload)
                    .then(() => finish(++a, name))
                    .catch(() => commit("CHANGE_LOADING_INTERNET_CONNECTION_STATUS", false))
            })

            await commit("CHANGE_LOADING_INTERNET_CONNECTION_STATUS", false)
        },
        UPDATE_BRIEFCASE_VERSION({commit, dispatch}, version) {
            const
                ls_version = localStorage.getItem('ls-version'),
                ls_version_correct = ls_version ? Number(ls_version.replaceAll('.', '')) : 0,
                new_version_correct = Number(version.replaceAll('.', ''));

            commit('CHANGE_BRIEFCASE_VERSION', version);
            dispatch('CLEAN_UP_OLD_API_DATA');

            if (!ls_version_correct || (ls_version_correct !== new_version_correct)) {
                if (ls_version_correct < 394) {
                    let cookies = Object.keys(Cookies.get());
                    for (let i = 0; i < cookies.length; i++) Cookies.remove(cookies[i])
                }

                commit('CLEAR_USER');
                commit('CLEAR_STATISTICS');
                commit('CLEAR_RATINGS');
                commit('CLEAR_ATTRIBUTES');
                commit('CLEAR_LANGUAGES');
                commit('CLEAR_TASKS');
                commit('CLEAR_NEED_ACTION_TASKS');
                commit('CLEAR_MODERATION_STORE');
                commit('CLEAR_CHAT');
                commit('CLEAR_CACHE');
                commit('CLEAR_NEWS');

                Vue.$vlf.removeItem('chatDB');
                indexedDB.deleteDatabase('chatDB');

                localStorage.clear();
                location.reload(true);
            }
        },
        CLOSE_PAGE_BACK({state, getters}, route_name) {
            if (window.history.length > 1) router.back()
            else if (route_name) router.replace({name: route_name, params: {locale: getters.LOCALE.route_code}})
            else router.replace(state.before_auth_link || state.redirect_link || (getters.LOCALE.base + '/feed'))
        },
        CLOSE_ADDITION_PAGE_BACK({state}) {
            if (router.app._route.params.prev_url?.toLowerCase().includes('addition')) router.go(-2)
            else router.go(-1)
            state
        },
        GO_TO_PAGE({getters}, route_name) {
            router.push({name: route_name, params: {locale: getters.LOCALE.route_code}})
        },
        CLEAN_UP_OLD_API_DATA({state, commit}) {
            if (Number(state.briefcase_version.replaceAll('.', '')) > 327) {
                commit('CLEAR_ONE_USER_SETTING', 'tasks_setting_types')
            }
        }
    },
    mutations: {
        SAVE_WINDOW_WIDTH: (state, width) => state.windowWidth = width,
        SAVE_INTERNET_CONNECTION: (state, boolean) => state.internet_connection = boolean,
        SAVE_SOCIAL_WINDOWS: (state, value) => state.social_windows = value,
        SAVE_BEFORE_AUTH_LINK: (state, link) => state.before_auth_link = link,
        SAVE_BEFORE_EDIT_LINK: (state, link) => state.before_edit_link = link,
        SAVE_REDIRECT_LINK: (state, link) => state.redirect_link = link,
        SAVE_SCROLL: (state, position) => state.scroll_position[position.name] = position.position,
        CLEAR_SCROLL: (state) => state.scroll_position = {},
        LOAD_SITE: (state, boolean) => state.site_load = boolean,
        CHANGE_DOCUMENT_VISIBLE: (state, boolean) => state.document_visible = boolean,
        CHANGE_CALL_TO_AUTH: (state, value) => state.call_to_auth = value,
        CHANGE_LANGUAGE_SETUP_POPUP: (state, data) => state.language_setup_popup = data,
        CHANGE_LANGUAGE_SETUP_POPUP_DATA: (state, data) => state.language_setup_popup_data = data,
        CHANGE_PLUS_BENEFIT_DATA: (state, data) => state.plus_benefit_data = data,
        SAVE_LOCALE: (state, value) => {
            state.locale = value;
            moment.locale(value.code);
        },
        CHANGE_SITE_THEME_PREFERS(state, data) {
            if (data.theme.trim() === 'dark' && state.windowWidth < 980) return;

            let root = document.documentElement;
            if (data.animation) root.classList.add('toggle-animation');
            root.setAttribute('data-theme', data.theme.trim());
            state.data_theme = data.theme.trim();
            setTimeout(() => {
                root.classList.remove('toggle-animation');
                root.style.backgroundColor = null;
            }, 500);
        },
        UPDATE_PAGE: (state) => state.page_key += 1,
        CHANGE_DEDICATED_TRANSLATION_DATA: (state, data) => state.dedicated_translation_data = data,
        CHANGE_IS_FIRST_PAGE: (state, bool) => state.is_first_page = bool,
        GOOGLE_ADSENSE_DISCONNECT: (state) => state.google_adsense_status = false,
        async CHANGE_BRIEFCASE_VERSION(state, version) {
            await localStorage.removeItem('briefcase')
            state.briefcase_version = version
            await localStorage.setItem('ls-version', version)
        }
    },
}
