import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import store from '@/store';
import platform from '@/assets/js/platform';
import bus from "@/assets/js/helper.js";
import Main from "@/views/Main";
import i18n from "@/plugins/i18n.js";
import {SUPPORTED_LOCALES} from "@/assets/js/locales.js";

Vue.use(VueRouter);
Vue.use(Meta);

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
    if (typeof location === 'object') {
        if (location.params) location.params['locale'] = store.getters.LOCALE.route_code
        else location['params'] = {'locale': store.getters.LOCALE.route_code}
    }
    return originalPush.call(this, location).catch(err => {
        if (err.name === 'NavigationDuplicated') console.info('WOLA: Navigation duplicated')
        else if (err.message.includes('Redirected when going from')) console.info('WOLA: Redirected')
        else throw new Error(err)
    });
};
VueRouter.prototype.replace = function replace(location) {
    if (typeof location === 'object') {
        if (location.params) location.params['locale'] = store.getters.LOCALE.route_code
        else location['params'] = {'locale': store.getters.LOCALE.route_code}
    }
    return originalReplace.call(this, location).catch(err => {
        if (err.name === 'NavigationDuplicated') console.info('WOLA: Navigation duplicated')
        else throw new Error(err)
    });
};

// auth
const Auth = () => import('../views/auth/Auth');
const ForkPage = () => import('../views/auth/ForkPage');
const Login = () => import('../views/auth/login/Login');
const SignUp = () => import('../views/auth/registration/SignUp');
// dictionary
const Dictionary = () => import(/* webpackChunkName: "chunk-dictionary-page" */ '../views/dictionary/Dictionary');
const DictionaryPage = () => import(/* webpackChunkName: "chunk-dictionary-page" */ '../views/dictionary/DictionaryPage');
const DetailWordPage = () => import('../components/panels/panel_translation_word');
const SentencesPage = () => import('../components/panels/panel_translation_sentences');
// entity
const EntityPage = () => import('../views/EntityPage');
// complain
const ComplainPage = () => import('../components/panels/panel_complain_entity');
// history
const panel_history_requests = () => import("../components/panels/panel_history_requests");
// favorites
const Favorites = () => {
    if (store.getters.MOBILE) return import('../views/FullVersion');
    else return import('../views/favorites/Favorites');
}
// profile
const Profile = () => {
    if (store.getters.MOBILE) return import('../views/FullVersion');
    else return import(/* webpackChunkName: "chunk-profile-page" */ '../views/profile/Profile');
}
const ProfilePage = () => import(/* webpackChunkName: "chunk-profile-page" */ '../views/profile/ProfilePage');
const EditPage = () => import('../views/profile/EditPage');
const EditAvatar = () => import('../views/profile/EditAvatar');
const LearningMaterials = () => import('../views/training/LearningMaterials');
const EditPassword = () => import('../views/profile/EditPassword');
const LogoutPage = () => import('../views/profile/LogoutPage');
const DeleteAccountPage = () => import('../views/profile/DeleteAccountPage');
// addition
const Addition = () => import('../views/addition/Addition');
const AdditionFork = () => import('../views/addition/AdditionFork');
const EditionTranslate = () => import('../views/addition/EditionTranslate');
const AdditionTranslate = () => import('../views/addition/AdditionTranslate');
const AdditionSentences = () => import('../views/addition/AdditionSentences');
const AdditionMeaning = () => import('../views/addition/AdditionMeaning');
const AdditionSynonym = () => import('../views/addition/AdditionSynonym');
// chat
const Chat = () => {
    if (store.getters.MOBILE) return import('../views/FullVersion');
    else return import('../views/chat/ChatPage');
}
const fill_info_for_chat = () => import('../components/elements/chat/fill_info_for_chat');
// training
const Training = () => {
    if (store.getters.MOBILE) return import('../views/FullVersion');
    else return import('../views/training/Training');
}
const TrainingHome = () => {
    if (store.getters.MOBILE) return import('../views/FullVersion');
    else return import('../views/training/TrainingHome');
}
const CreateSelection = () => import('../views/training/CreateSelection');
const CreateVideo = () => import('../views/training/CreateVideo');
const TrainingSelection = () => import('../views/training/TrainingSelection');
const TrainingAllSelections = () => import('../views/training/TrainingAllSelections');
const TrainingVideo = () => import('../views/training/TrainingVideo');
const TrainingLesson = () => import('../views/training/TrainingLesson.vue');
// about
const About = () => import('../views/About');
// notifications
const Notifications = () => import("../views/notifications/Notifications");
// feed
const FeedPage = () => {
    if (store.getters.MOBILE) return import('../views/FullVersion');
    else return import("../views/feed/FeedPage.vue");
}
const CreatePost = () => import("../views/feed/CreatePost.vue");
const CreateArticle = () => import("../views/feed/CreateArticle.vue");
const CreateQuestion = () => import("../views/feed/CreateQuestion.vue");
const PostPage = () => import('../views/feed/PostPage.vue');
const ArticlePage = () => import('../views/feed/ArticlePage.vue');
const QuestionPage = () => import('../views/feed/QuestionPage.vue');
// tasks
const Tasks = () => {
    if (store.getters.MOBILE) return import('../views/FullVersion');
    else return import('../views/tasks/Tasks');
}
const TasksPage = () => import('../views/tasks/TasksPage');
const TasksCards = () => import('../views/tasks/TasksCards');
const TasksSettings = () => import('../views/tasks/TasksSettings');
const TasksNeedAction = () => import('../views/tasks/TasksNeedAction');
// moderators
const Moderation = () => import('../views/moderation/Moderation');
const ModerationPage = () => {
    if (store.getters.MOBILE) return import('../views/FullVersion');
    else return import('../views/moderation/ModerationPage');
}
const ModeratorsInfo = () => import('../views/moderation/ModeratorsInfo');
const ModeratorsGreeting = () => import('../views/moderation/ModeratorsGreeting');
const ModeratorsRules = () => import('../views/moderation/ModeratorsRules');
const ModerationSettings = () => import('../views/moderation/ModerationSettings');
const CommentsAndSuggestions = () => import('../views/moderation/CommentsAndSuggestions.vue');
// privacy policy
const PrivacyPolicy = () => import('../views/PrivacyPolicy');
// terms & conditions
const TermsAndConditions = () => import('../views/TermsAndConditions');
// community guidelines
const CommunityGuidelines = () => import('../views/CommunityGuidelines');
// reset password
const ResetPassword = () => import('../views/ResetPassword');
// reset password
const EmailVerification = () => import('../views/EmailVerification');
// News
const News = () => import('../views/News');
// Support
const Support = () => import('../views/Support');
// MobileSettings
const MobileSettings = () => import('../views/MobileSettings');
// 404
const NoPage = () => import('../views/NoPage');
// console
const ConsolePage = () => import('../views/ConsolePage');
// plus payment
const PaymentPage = () => import('../views/PaymentPage.vue');

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next();
    } else {
        store.commit("SAVE_REDIRECT_LINK", to.fullPath)
        if (to.fullPath.toLowerCase().includes('addition')) store.commit('CHANGE_CALL_TO_AUTH', 'addition')
        else next(store.getters.LOCALE.base + '/auth')
    }
};

const ifUnauthenticated = (to, from, next) => {
    if (!store.getters.isLoggedIn) {
        if (from.name) {
            if (!['Auth', 'AuthLogin', 'AuthSignUp', 'AuthCompleteSocialProfile', 'AuthAddNativeLang'].includes(from.name)) store.commit("SAVE_BEFORE_AUTH_LINK", from.fullPath)
        } else store.commit("SAVE_BEFORE_AUTH_LINK", store.getters.LOCALE.base + '/feed')
        next();
    } else next('/');
};

const ifAuthenticatedFromSocial = (to, from, next) => {
    if (store.getters.isLoggedIn && (store.state.profile.user_info.need_to_set_nickname || !store.state.profile.user_info.nickname || !store.state.profile.native_lang)) {
        next();
    }
};

const ifMobile = (to, from, next) => {
    if (store.getters.MOBILE) next();
    else next('/')
};

const ifModerator = (to, from, next) => {
    if (store.getters.IS_MODERATOR) next();
    else next('/')
};

const ifAdmin = (to, from, next) => {
    if (store.getters.IS_ADMIN) next();
    else next('/')
};

const ifMobileDetailPage = (to, from, next) => {
    if (store.getters.MOBILE) next();
    else {
        sessionStorage.setItem('detail_word_translation_id', to.params['id'])
        let new_route = {
            'name': 'DictionaryPage',
            'params': to.params
        }
        delete new_route.params['id']
        next(new_route)
    }
};

const ifDevelopmentHost = (to, from, next) => {
    if (store.state.other.dev_host) next();
    else next('/')
};

// const ifLocalHost = (to, from, next) => {
//     if (store.state.other.local_host) next();
//     else next('/')
// };

function getLocaleRegex() {
    let reg = '';

    SUPPORTED_LOCALES.forEach((locale, index) => {
        reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`;
    });
    return `(${reg})`;
}

const routes = [
    {
        path: `/:locale${getLocaleRegex()}?`,
        name: 'Main',
        component: Main,
        children: [
            {
                path: '',
                redirect: 'feed'
            },
            {
                path: 'dictionary',
                name: 'Dictionary',
                component: Dictionary,
                children: [
                    {
                        path: ':lang/:lang_t',
                        name: 'DictionaryCopy',
                    },
                    {
                        path: ':lang/:lang_t/:search',
                        name: 'DictionaryPage',
                        component: DictionaryPage
                    },
                ]
            },
            {
                path: 'feed',
                name: 'FeedPage',
                component: FeedPage
            },
            {
                path: 'feed/create_post',
                name: 'CreatePost',
                component: CreatePost,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'feed/edit_post',
                name: 'EditPost',
                component: CreatePost,
                beforeEnter: ifAuthenticated,
                props: {
                    edit_page: true
                }
            },
            {
                path: 'feed/create_article',
                name: 'CreateArticle',
                component: CreateArticle,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'feed/edit_article',
                name: 'EditArticle',
                component: CreateArticle,
                beforeEnter: ifAuthenticated,
                props: {
                    edit_page: true
                }
            },
            {
                path: 'feed/create_question',
                name: 'CreateQuestion',
                component: CreateQuestion,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'feed/edit_question',
                name: 'EditQuestion',
                component: CreateQuestion,
                beforeEnter: ifAuthenticated,
                props: {
                    edit_page: true
                }
            },
            {
                path: 'post',
                name: 'PostPage',
                component: PostPage
            },
            {
                path: 'article',
                name: 'ArticlePage',
                component: ArticlePage
            },
            {
                path: 'question',
                name: 'QuestionPage',
                component: QuestionPage
            },
            {
                path: 'dictionary/:lang/:lang_t/:search/:id',
                name: 'DetailWordPage',
                component: DetailWordPage,
                beforeEnter: ifMobileDetailPage
            },
            {
                path: 'entity',
                name: 'EntityPage',
                component: EntityPage,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'auth',
                component: Auth,
                children: [
                    {
                        path: '',
                        name: 'Auth',
                        component: ForkPage,
                        beforeEnter: ifUnauthenticated,
                    },
                    {
                        path: 'login/:type',
                        name: 'AuthLogin',
                        component: Login,
                        beforeEnter: ifUnauthenticated,
                    },
                    {
                        path: 'sign_up',
                        redirect: '/auth/sign_up/email'
                    },
                    {
                        path: 'sign_up/:step',
                        name: 'AuthSignUp',
                        component: SignUp,
                        beforeEnter: ifUnauthenticated,
                    },
                    {
                        path: 'complete_profile/:step',
                        name: 'AuthCompleteSocialProfile',
                        component: SignUp,
                        beforeEnter: ifAuthenticatedFromSocial
                    },
                    {
                        path: 'add_native_lang',
                        name: 'AuthAddNativeLang',
                        component: SignUp,
                    },
                ]
            },
            {
                path: 'sentences',
                name: 'SentencesPage',
                component: SentencesPage,
                beforeEnter: ifMobile
            },
            {
                path: 'word_sentences',
                name: 'WordSentencesPage',
                component: SentencesPage,
                props: {
                    word_sentences: true
                },
                beforeEnter: ifMobile
            },
            {
                path: 'complain',
                name: 'ComplainPage',
                component: ComplainPage,
                beforeEnter: ifMobile
            },
            {
                path: ':page_type(addition|editing)?',
                component: Addition,
                beforeEnter: ifAuthenticated,
                children: [
                    {
                        path: '',
                        name: 'AdditionFork',
                        component: AdditionFork
                    },
                    {
                        path: 'translation',
                        name: 'EditionTranslate',
                        component: EditionTranslate
                    },
                    {
                        path: 'translation',
                        name: 'AdditionTranslate',
                        component: AdditionTranslate
                    },
                    {
                        path: 'sentences-:lang/step::step',
                        name: 'AdditionSentences',
                        component: AdditionSentences
                    },
                    {
                        path: 'meanings-:lang',
                        name: 'AdditionMeaning',
                        component: AdditionMeaning,
                    },
                    {
                        path: 'synonym-:lang',
                        name: 'AdditionSynonym',
                        component: AdditionSynonym,
                    },
                    {
                        path: 'antonym-:lang',
                        name: 'AdditionAntonym',
                        component: AdditionSynonym,
                    }
                ]
            },
            {
                path: 'training',
                name: 'Training',
                component: TrainingHome
            },
            {
                path: 'training',
                component: Training,
                children: [
                    {
                        path: 'selection',
                        name: 'TrainingSelection',
                        component: TrainingSelection
                    },
                    {
                        path: 'all_selections',
                        name: 'TrainingAllSelections',
                        component: TrainingAllSelections
                    },
                    {
                        path: 'video',
                        name: 'TrainingVideo',
                        component: TrainingVideo
                    },
                    {
                        path: 'all_videos',
                        name: 'TrainingAllVideos',
                        component: TrainingAllSelections,
                        props: {
                            video_type: true
                        }
                    },
                    {
                        path: 'lesson',
                        name: 'TrainingLesson',
                        component: TrainingLesson,
                        beforeEnter: ifAuthenticated
                    },
                ]
            },
            {
                path: 'training/selection/create',
                name: 'CreateSelection',
                component: CreateSelection,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'training/selection/edit',
                name: 'EditSelection',
                component: CreateSelection,
                beforeEnter: ifAuthenticated,
                props: {edit_page: true}
            },
            {
                path: 'training/video/create',
                name: 'CreateVideo',
                component: CreateVideo,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'training/video/edit',
                name: 'EditVideo',
                component: CreateVideo,
                beforeEnter: ifAuthenticated,
                props: {edit_page: true}
            },
            {
                path: 'moderation',
                component: Moderation,
                children: [
                    {
                        path: '',
                        name: 'Moderation',
                        component: ModerationPage,
                        beforeEnter: ifModerator
                    },
                    {
                        path: 'settings',
                        name: 'ModerationSettings',
                        component: ModerationSettings,
                        beforeEnter: ifModerator
                    },
                    {
                        path: 'suggestions',
                        name: 'CommentsAndSuggestions',
                        component: CommentsAndSuggestions,
                        beforeEnter: ifAdmin
                    },
                    {
                        path: 'greeting',
                        name: 'ModeratorsGreeting',
                        component: ModeratorsGreeting,
                    },
                    {
                        path: 'info',
                        name: 'ModeratorsInfo',
                        component: ModeratorsInfo,
                    },
                    {
                        path: 'rules',
                        name: 'ModeratorsRules',
                        component: ModeratorsRules,
                    }
                ]
            },
            {
                path: 'tasks',
                component: Tasks,
                children: [
                    {
                        path: '',
                        name: 'TasksPage',
                        component: TasksPage,
                    },
                    {
                        path: 'cards',
                        name: 'TasksCards',
                        component: TasksCards,
                        beforeEnter: ifAuthenticated,
                    },
                    {
                        path: 'cards/settings',
                        name: 'TasksSettings',
                        component: TasksSettings,
                        beforeEnter: ifAuthenticated,
                    },
                    {
                        path: 'need_action',
                        name: 'TasksNeedAction',
                        component: TasksNeedAction,
                        beforeEnter: ifAuthenticated,
                    },
                ]
            },
            {
                path: 'profile/:nickname',
                component: Profile,
                children: [
                    {
                        path: '',
                        name: 'ProfilePage',
                        component: ProfilePage
                    },
                    {
                        path: 'edit_avatar',
                        name: 'EditAvatar',
                        beforeEnter: ifAuthenticated,
                        component: EditAvatar
                    },
                    {
                        path: 'edit_info',
                        name: 'EditInfo',
                        beforeEnter: ifAuthenticated,
                        component: EditPage
                    },
                    {
                        path: 'password-update',
                        name: 'EditPassword',
                        beforeEnter: ifAuthenticated,
                        component: EditPassword
                    },
                    {
                        path: 'logout',
                        name: 'LogoutPage',
                        beforeEnter: ifAuthenticated,
                        component: LogoutPage
                    }
                ]
            },
            {
                path: 'profile/:nickname/deleting',
                name: 'DeleteAccountPage',
                beforeEnter: ifAuthenticated,
                component: DeleteAccountPage
            },
            {
                path: 'profile/:nickname/learning_materials',
                name: 'LearningMaterials',
                beforeEnter: ifAuthenticated,
                component: LearningMaterials
            },
            {
                path: 'password-reset',
                query: {token: ''},
                name: 'ResetPassword',
                component: ResetPassword,
            },
            {
                path: 'email-verification',
                query: {
                    id: Number,
                    hash: String
                },
                name: 'EmailVerification',
                component: EmailVerification,
            },
            {
                path: 'chat',
                name: 'Chat',
                component: Chat,
            },
            {
                path: 'profile/fill_info/:step',
                name: 'fillInfoForChatPage',
                beforeEnter: ifAuthenticated,
                component: fill_info_for_chat
            },
            {
                path: 'history',
                name: 'History',
                component: panel_history_requests,
                beforeEnter: ifMobile
            },
            {
                path: 'about',
                name: 'About',
                component: About
            },
            {
                path: 'privacy',
                name: 'PrivacyPolicy',
                component: PrivacyPolicy
            },
            {
                path: 'terms',
                name: 'TermsAndConditions',
                component: TermsAndConditions
            },
            {
                path: 'community-guidelines',
                name: 'CommunityGuidelines',
                component: CommunityGuidelines
            },
            {
                path: 'privacy_policy',
                redirect: {name: 'PrivacyPolicy'}
            },
            {
                path: 'terms&conditions',
                redirect: {name: 'TermsAndConditions'}
            },
            {
                path: 'favorites',
                name: 'Favorites',
                component: Favorites
            },
            {
                path: 'settings',
                name: 'MobileSettings',
                component: MobileSettings,
                beforeEnter: ifMobile
            },
            {
                path: 'notifications',
                name: 'Notifications',
                component: Notifications,
                beforeEnter: ifMobile
            },
            {
                path: 'news',
                name: 'News',
                component: News
            },
            {
                path: 'support',
                name: 'Support',
                component: Support
            },
            {
                path: '404',
                name: 'NoPage',
                component: NoPage
            },
            {
                path: 'console',
                name: 'ConsolePage',
                component: ConsolePage,
                beforeEnter: ifDevelopmentHost
            },
            {
                path: 'wola-plus-payment',
                redirect: 'wola-plus-payment/year'
            },
            {
                path: 'wola-plus-payment/:period(month|year|verification)?',
                name: 'PaymentPage',
                component: PaymentPage
            },
            {
                path: '*',
                name: 'NoFound',
                component: NoPage,
                props: {
                    with_code: true
                }
            }
        ],
        meta: {
            title: 'WOLA',
            metaTags: [
                {
                    name: 'description',
                    content: i18n.t('hello_dictionary_description')
                }
            ]
        },
        beforeEnter(to, from, next) {
            if (to.params.locale !== store.getters.LOCALE.route_code) {
                let route = to
                route.params.locale = store.getters.LOCALE.route_code
                router.replace(route)
            }
            next()
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        let position,
            duration = 0;

        if (store.getters.MOBILE && platform.platform.isApple) duration = 0
        else if (['page-bounce-front', 'bounce', 'long-bounce'].includes(Vue.prototype.$page_transition.name)) duration = 250

        if (to.hash) position = {selector: to.hash}
        else if (savedPosition) position = savedPosition
        else position = {x: 0, y: 0}

        return new Promise((resolve) => {
            setTimeout(() => resolve(position), duration)
        })
    },
    routes
});

router.onError((error) => {
    const
        pattern1 = /Loading chunk (\d)+ failed/g,
        pattern2 = /ChunkLoadError:.*failed./i,
        pattern3 = /Loading.*chunk.*failed./i,
        isChunkLoadFailed1 = error.message.match(pattern1),
        isChunkLoadFailed2 = pattern2.test(error.message),
        isChunkLoadFailed3 = pattern3.test(error.message);

    if (isChunkLoadFailed1 || isChunkLoadFailed2 || isChunkLoadFailed3) window.location.reload(true);
});

router.beforeEach((to, from, next) => {
    if (store.state.other.call_to_auth) {
        store.commit("CHANGE_CALL_TO_AUTH", null);
        store.commit("SAVE_REDIRECT_LINK", null);
        next(false);
    } else {
        if (store.state.tutorials.tutorial_screen) store.commit("CLEAR_TUTORIALS");
        if (Vue.prototype.$analytics) bus.pageEventForAnalytic(to.name);
        if (from.name) to.params['prev_url'] = from.name;
        next();
    }
});

export default router;
