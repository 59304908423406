<template>
    <transition name="loading-transition">
        <div class="loading_page">
            <w-svg-icon class="loading_page_logo" folder-name="logo" icon-name="logoCircle" fill="#48c68f"/>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "loading_page"
    }
</script>

<style lang="scss" scoped>
    .loading_page {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E9EBF4;
        z-index: 9999;

        &_logo {
            width: 120px;
            height: 120px;
            animation: circle-logo 1000ms ease infinite;
            will-change: transform;
            transition: opacity 300ms ease;
        }

        @keyframes circle-logo {
            0% {
                transform: rotate(0) scale(1)
            }
            40% {
                transform: rotate(360deg) scale(1.1)
            }
            100% {
                transform: rotate(360deg)
            }
        }
    }

    .loading-transition {
        &-leave-active {
            transition: opacity 400ms 300ms ease-out;

            .loading_page_logo {
                opacity: 0;
            }
        }

        &-leave-to {
            opacity: 0;
            pointer-events: none;
        }
    }

    @media (max-width: 1440px) and (min-width: 981px) {
        .loading_page_logo {
            width: 70px;
            height: 70px;
        }
    }

    @media screen and (max-width: 980px) {
        .loading_page {
            display: none !important;
        }

        /*@supports (-webkit-touch-callout: none) {*/
        /*    @media (display-mode: standalone) {*/
        /*        .loading_page {*/
        /*            @media (device-width: 320px) and (device-height: 568px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/iphone5_splash.png");*/
        /*            }*/
        /*            @media (device-width: 375px) and (device-height: 667px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/iphone6_splash.png");*/
        /*            }*/
        /*            @media (device-width: 621px) and (device-height: 1104px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/iphoneplus_splash.png");*/
        /*            }*/
        /*            @media (device-width: 375px) and (device-height: 812px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/iphonex_splash.png");*/
        /*            }*/
        /*            @media (device-width: 414px) and (device-height: 896px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/iphonexr_splash.png");*/
        /*            }*/
        /*            @media (device-width: 414px) and (device-height: 896px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/iphonexsmax_splash.png");*/
        /*            }*/
        /*            @media (device-width: 390px) and (device-height: 844px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/iphone12_splash.png");*/
        /*            }*/
        /*            @media (device-width: 375px) and (device-height: 812px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/iphone12mini_splash.png");*/
        /*            }*/
        /*            @media (device-width: 428px) and (device-height: 926px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/iphone12max_splash.png");*/
        /*            }*/
        /*            @media (device-width: 768px) and (device-height: 1024px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/ipad_splash.png");*/
        /*            }*/
        /*            @media (device-width: 834px) and (device-height: 1112px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/ipadpro1_splash.png");*/
        /*            }*/
        /*            @media (device-width: 834px) and (device-height: 1194px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/ipadpro3_splash.png");*/
        /*            }*/
        /*            @media (device-width: 1024px) and (device-height: 1366px) {*/
        /*                background-image: url("../../../public/icons/splashscreens/ipadpro2_splash.png");*/
        /*            }*/
        /*        }*/
        /*    }*/
        /*}*/
    }

    [data-theme='dark'] {
        .loading_page {
            background-color: var(--w_color_grey2);

            &_logo::v-deep path {
                fill: var(--w_color_white);
            }
        }
    }
</style>
