let UNFULFILLED_ACTIONS = new Map([]);

if (sessionStorage.unfulfilled_actions) UNFULFILLED_ACTIONS = new Map(JSON.parse(sessionStorage.unfulfilled_actions));

export default {
    set(key, value) {
        UNFULFILLED_ACTIONS.set(key, value)
        sessionStorage.unfulfilled_actions = JSON.stringify(Array.from(UNFULFILLED_ACTIONS.entries()))
    },
    delete(key) {
        UNFULFILLED_ACTIONS.delete(key)
        sessionStorage.unfulfilled_actions = JSON.stringify(Array.from(UNFULFILLED_ACTIONS.entries()))
    },
    clear() {
        UNFULFILLED_ACTIONS.clear()
        sessionStorage.removeItem('unfulfilled_actions')
    },
    size() {
        return UNFULFILLED_ACTIONS.size
    },
    list() {
        return UNFULFILLED_ACTIONS
    }
}
