<template>
    <main class="content_wrapper" :class="mainClass">
        <transition appear :name="$page_transition.name" :mode="$page_transition.mode">
            <router-view class="view" :key="$store.state.other.page_key"/>
        </transition>
    </main>
</template>

<script>
    export default {
        name: "Main",
        data() {
            return {
                regExpAuth: new RegExp('(.*)/auth/(.*)'),
                regExpAdding: new RegExp('(.*)/addition/(.*)'),
                regExpEdition: new RegExp('(.*)/editing/(.*)'),
                fullScreenPagesLevel3: [
                    'EditSelection',
                    'CreateSelection',
                    'EditVideo',
                    'CreateVideo',
                    'NoPage',
                    'NoFound',
                    'EditAvatar',
                    'EditInfo',
                    'EditPassword',
                    'LogoutPage',
                    'DeleteAccountPage',
                    'ModerationSettings',
                    'ModeratorsGreeting',
                    'ModeratorsInfo',
                    'ModeratorsRules',
                    'TasksSettings',
                    'AdditionTranslate',
                    'EditionTranslate',
                    'AdditionSentences',
                    'AdditionMeaning',
                    'AdditionSynonym',
                    'AdditionAntonym',
                    'CreatePost',
                    'EditPost',
                    'CreateArticle',
                    'EditArticle',
                    'CreateQuestion',
                    'EditQuestion',
                    'CommentsAndSuggestions',
                ],
            }
        },
        computed: {
            mainClass() {
                if (this.$store.getters.MOBILE) return false
                else return {'auth': this.$route.name.includes('Auth')}
            },
            fullScreenPagesLevel1() {
                return this.fullScreenPagesLevel3.concat([
                    'ArticlePage',
                    'TasksCards',
                    'TasksNeedAction',
                    'TrainingLesson',
                    'TrainingSelection',
                    'TrainingVideo',
                    'LearningMaterials',
                    'TrainingAllSelections',
                    'TrainingAllVideos',
                    'CommentsAndSuggestions',
                ])
            },
            fullScreenPagesLevel2() {
                return this.fullScreenPagesLevel3.concat([
                    'TrainingLesson'
                ])
            }
        },
        watch: {
            $route(to, from) {
                if (this.$store.getters.MOBILE) {
                    this.$page_transition.setParams(this.$platform.isApple ? '' : 'bounce', 'out-in')
                } else if (to.path.match(this.regExpAuth) || from.path.match(this.regExpAuth)) {
                    this.$page_transition.setParams('long-bounce', 'out-in')
                } else if (this.fullScreenPagesLevel1.includes(from.name) && this.fullScreenPagesLevel1.includes(to.name)) {
                    let front_type = from.fullPath.split('/').length < to.fullPath.split('/').length;
                    if (this.fullScreenPagesLevel3.includes(to.name) && this.fullScreenPagesLevel2.includes(from.name)) front_type = true;
                    else if (this.fullScreenPagesLevel3.includes(from.name) && this.fullScreenPagesLevel2.includes(to.name)) front_type = false;
                    else if (this.fullScreenPagesLevel3.includes(to.name)) front_type = true;
                    else if (this.fullScreenPagesLevel2.includes(to.name)) front_type = true;
                    else if (to.name.toLowerCase().includes('create')) front_type = true;
                    else if (to.name.toLowerCase().includes('lesson')) front_type = true;
                    else if (to.name === 'TrainingSelection' && from.name === 'LearningMaterials') front_type = true;
                    else if (from.name === 'TrainingSelection' && to.name === 'LearningMaterials') front_type = true;
                    else if (to.name === 'TrainingVideo' && from.name === 'LearningMaterials') front_type = true;
                    else if (from.name === 'TrainingVideo' && to.name === 'LearningMaterials') front_type = true;
                    this.$page_transition.setParams(front_type ? 'page-bounce-front' : 'page-bounce-back', 'in-out')
                } else if (to.name.toLowerCase().includes('addition') || this.fullScreenPagesLevel1.includes(to.name)) {
                    this.$page_transition.setParams('page-bounce-front', 'in-out')
                } else if (from.name.toLowerCase().includes('addition') || this.fullScreenPagesLevel1.includes(from.name)) {
                    this.$page_transition.setParams('page-bounce-back', 'in-out')
                } else if (
                    ['EmailVerification'].includes(to.name) ||
                    to.path.match(this.regExpEdition) ||
                    to.path.match(this.regExpAdding) ||
                    ['EmailVerification'].includes(from.name) ||
                    from.path.match(this.regExpEdition) ||
                    from.path.match(this.regExpAdding) ||
                    to.name === 'fillInfoForChatPage' ||
                    from.name === 'fillInfoForChatPage'
                ) {
                    this.$page_transition.setParams('long-bounce', 'in-out')
                } else {
                    this.$page_transition.setParams('bounce', 'out-in')
                }
            }
        }
    }
</script>
