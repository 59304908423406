import axios from "axios";
import Vue from "vue";
import router from "../../router";

const getDefaultState = () => {
    return {
        need_action_tasks: [],
        need_action_tasks_all_loaded: false,
        need_action_task_count: 0,
        need_action_task_id: null,
        need_action_task_delete_index: 0,
        need_action_task_edit_index: 0,
        need_action_task_details: null,
        need_action_task_comments: [],
        need_action_task_comments_all_loaded: false,
        need_action_task_actions: [],
        need_action_task_actions_id: null,
        need_action_vocabulary_panel: false,
        need_action_audio_data: false,
        deleted_entity_data: null,
        redirect_after_delete: null,
        dispute_parent_material_popup: null,
        comment_translation_language: {}
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_NEED_ACTION_TASKS({state, commit}, payload) {
            if (!state.need_action_tasks_all_loaded) {
                commit('CHANGE_LOADING_NEED_ACTION_TASKS_STATUS', true);

                let URL = `${Vue.prototype.$api_url}/tasks/need-action-list`,
                    limit = 20,
                    params = {
                        limit
                    };

                if (payload.type === "load_up") params['pagination_id'] = payload.id;

                await axios
                    .get(URL, {params})
                    .then(async response => {
                        let arr = response.data.data;

                        arr.forEach(e => e['done'] = false);

                        let data = {
                            tasks: arr,
                            type: payload.type
                        };

                        await commit("SAVE_NEED_ACTION_TASKS", data);
                        if (response.data.data.length < limit) state.need_action_tasks_all_loaded = true;
                        if (state.need_action_tasks.length === 0) state.need_action_task_count = 0;
                    })
                    .catch(() => {
                        throw new Error()
                    })
                    .finally(() => {
                        commit('CHANGE_LOADING_NEED_ACTION_TASKS_STATUS', false)
                    })
            }
        },
        async LOAD_NEED_ACTION_TASKS_COUNT({commit}) {
            await axios
                .get(`${Vue.prototype.$api_url}/tasks/need-action-list/count`)
                .then(r => commit('UPDATE_NEED_ACTION_TASKS_COUNT', r.data.data.count))
                .catch(() => {
                    throw new Error()
                })
        },
        async LOAD_NEED_ACTION_TASK_DETAILS({state, commit}, payload) {
            return new Promise((resolve, reject) => {
                let entity_type_slug, entity_id;

                if (payload) {
                    entity_type_slug = payload.entity_type_slug;
                    entity_id = payload.entity_id
                } else {
                    entity_type_slug = state.need_action_task_details.entity_type_slug;
                    entity_id = state.need_action_task_details.entity_id
                }

                axios
                    .get(`${Vue.prototype.$api_url}/dictionary/entity`, {
                        params: {
                            'entity_type_slug': entity_type_slug,
                            'entity_id': entity_id
                        }
                    })
                    .then(r => {
                        if (payload) resolve(r.data.data)
                        else {
                            resolve()
                            commit('UPDATE_NEED_ACTION_TASK_ENTITY', r.data.data)
                        }
                    })
                    .catch((e) => {
                        reject(e)
                        throw new Error(e)
                    })
            })
        },
        async LOAD_NEED_ACTION_TASK_ACTIONS({commit}, payload) {
            await axios
                .get(`${Vue.prototype.$api_url}/tasks/${payload.type}/${payload.id}/available-actions`)
                .then(r => {
                    commit("SAVE_NEED_ACTION_TASK_ACTIONS", r.data.data.actions)
                    commit("SAVE_NEED_ACTION_TASK_ACTIONS_ID", r.data.data.task_id)
                })
                .catch(() => {
                    throw new Error()
                })
                .finally(() => {
                    commit('CHANGE_LOADING_NEED_ACTION_TASKS_STATUS', false)
                })
        },
        async DELETE_WORD({commit, dispatch}, word) {
            await commit("CHANGE_LOADING_DELETE_STATUS", true)
            await axios
                .delete(`${Vue.prototype.$api_url}/translations/words/${word.id}`)
                .then(() => {
                    commit("ALERT_SHOW", {name: 'need_action', content: 'notice_material_removed'});
                })
                .catch((err) => {
                    dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'})
                    throw new Error(err)
                })
                .finally(() => {
                    commit("CHANGE_LOADING_DELETE_STATUS", false)
                    commit('CHANGE_LOADING_NEED_ACTION_TASKS_STATUS', false)
                })
        },
        async DELETE_ENTITY({state, getters, dispatch, commit, rootState}) {
            let entity_data = state.deleted_entity_data;

            await commit("CHANGE_LOADING_DELETE_STATUS", true)
            await axios
                .delete(`${Vue.prototype.$api_url}/dictionary/entity`, {params: entity_data})
                .then(async () => {
                    let duration = 100, deleted_task_id;

                    if (rootState.statistics.statistics_detail_panel) {
                        duration = 200;
                        await commit("SAVE_STATISTICS_DELETED_ENTITY", {
                            id: entity_data.entity_id,
                            type: entity_data.entity_type_slug
                        });
                    }

                    if (rootState.complaints.complain_task_id && rootState.tasks.tasks[0]) {
                        duration = 300;
                        deleted_task_id = rootState.complaints.complain_task_id;
                        await commit("SAVE_COMPLAIN_TASK_ID", null);
                    }

                    if (entity_data.entity_type_slug === 'synonym' && (rootState.detail_word_page.word_synonyms[0].length || rootState.detail_word_page.word_synonyms[1].length)) {
                        await commit('DELETE_SYNONYM_FROM_DETAIL_PAGE', entity_data.entity_id);
                    } else if (entity_data.entity_type_slug === 'antonym' && (rootState.detail_word_page.word_antonyms[0].length || rootState.detail_word_page.word_antonyms[1].length)) {
                        await commit('DELETE_ANTONYM_FROM_DETAIL_PAGE', entity_data.entity_id);
                    } else if (entity_data.entity_type_slug === 'translation-meaning' && (rootState.detail_word_page.word_meanings[0].length || rootState.detail_word_page.word_meanings[1].length)) {
                        await commit('DELETE_MEANING_FROM_DETAIL_PAGE', entity_data.entity_id);
                        await commit('DELETE_MEANING_FROM_TRANSLATION', entity_data.entity_id);
                    } else if (['translation', 'translation-union'].includes(entity_data.entity_type_slug) && rootState.dictionary.translates.length) {
                        await commit('DELETE_DICTIONARY_TRANSLATE', entity_data.entity_id);
                        await commit("DO_NOT_CLEAR_OFF");
                        await dispatch("CLOSE_WORD_PANEL");
                    } else if (entity_data.entity_type_slug === 'word-sentence') {
                        await dispatch('DELETE_SENTENCE_FROM_DETAIL_PAGE', entity_data.entity_id);
                    } else if (entity_data.entity_type_slug === 'sentence-translation') {
                        await dispatch('DELETE_SENTENCE_TRANSLATIONS_FROM_DETAIL_PAGE', entity_data.entity_id);
                    } else if (entity_data.entity_type_slug === 'word-audio') {
                        await commit('DELETE_AUDIO_FROM_TRANSLATIONS', entity_data.entity_id);
                        await commit('DELETE_AUDIO_FROM_DETAIL_WORD', entity_data.entity_id);
                        await commit('DELETE_AUDIO_FROM_FAVORITES', entity_data.entity_id);
                    }

                    setTimeout(async () => {
                        if (state.need_action_task_details) {
                            await commit("UPDATE_NEED_ACTION_TASK_INDEX", "delete");

                            if ((state.need_action_task_details.entity_type_slug === 'sentence-audio') && ['word-sentence', 'sentence-translation'].includes(entity_data.entity_type_slug)) {
                                await commit("CHANGE_NEED_ACTION_TASK_DETAILS", null);
                            }
                        }

                        if (['translation', 'translation-union'].includes(entity_data.entity_type_slug)) {
                            await commit("CLEAR_DETAIL_WORD_PAGE");
                        }

                        if (entity_data.out_of_place === 'selection') {
                            await commit("CLOSE_LESSON_WORDS", entity_data.entity_type_slug);
                        }

                        if (state.redirect_after_delete) {
                            await router.push(getters.LOCALE.base + (rootState.dictionary.last_translate_link || '/'));
                            await commit("CHANGE_REDIRECT_AFTER_DELETE", false);
                        }

                        await commit("CHANGE_LOADING_DELETE_STATUS", false);
                        await commit("SAVE_DELETED_ENTITY_DATA", null);
                        await commit("ALERT_SHOW", {name: 'need_action', content: 'notice_material_removed'});

                        if (deleted_task_id) await commit("SAVE_TASK_CONTINUE_ID", deleted_task_id);
                    }, duration)
                })
                .catch((err) => {
                    commit("CHANGE_LOADING_DELETE_STATUS", false)
                    dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'})
                    throw new Error(err)
                })
        },
        OPEN_NEXT_NEED_ACTION_TASK({state, commit}) {
            let task,
                task_index = state.need_action_tasks.findIndex(t => {
                    return (t.entity_id === state.need_action_task_details.entity_id) && (t.entity_type_slug === state.need_action_task_details.entity_type_slug)
                });

            if (~task_index) {
                let next_task = state.need_action_tasks[task_index + 1]
                if (next_task) task = next_task
                else if (state.need_action_tasks[0]) task = state.need_action_tasks[0]
            } else if (state.need_action_tasks[0]) {
                task = state.need_action_tasks[0]
            }

            if (task) {
                task["type"] = 'dictionary';
                commit("CHANGE_NEED_ACTION_TASK_DETAILS", task);
            } else {
                commit("CHANGE_NEED_ACTION_TASK_DETAILS", null);
            }
        },
        CLEAR_NEED_ACTION_TASK_PANEL({dispatch, commit}, next) {
            commit("DELETE_NEED_ACTION_TASK_PANEL_INFO")

            if (next === 'need_action') dispatch("OPEN_NEXT_NEED_ACTION_TASK");
            else if (next === 'moderation') dispatch("OPEN_NEXT_MODERATION_TASK");
            else if (next === 'notification') dispatch("OPEN_NEXT_NEED_ACTION_TASK_FROM_NOTIFICATION");
            else if (next === 'statistics') dispatch("OPEN_NEXT_NEED_ACTION_TASK_FROM_STATISTICS");
            else commit("CHANGE_NEED_ACTION_TASK_DETAILS", null);
        },
        ADD_NEW_NEED_ACTION_TASK_COMMENT({getters, commit}, payload) {
            let new_comment = {
                'created_at': new Date().toISOString(),
                'id': Date.now(),
                'type': payload["type"],
                'new_comment': true,
                'user_full_name': "",
                'user_mini_avatar': getters.USER.mini_avatar,
                'user_nickname': getters.USER.nickname,
                'user_uuid': getters.USER.uuid
            };

            if (payload.type === 'task_comment') {
                new_comment['comment'] = payload['comment']
                new_comment['task_comment_uuid'] = payload['task_comment_uuid']
            } else if (payload.type === 'complain_added_log') {
                new_comment['comment'] = payload['comment']
                // ENTITY, MATHERIAL, RESOLUTION
                new_comment['target'] = payload['target']
            } else if (payload.type === 'entity_system') {
                // update_entity, add_entity, delete_entity
                new_comment['action'] = payload['action']
            } else if (payload.type === 'complain_resolved_log') {
                // KEEP, DELETE
                new_comment['comment'] = payload['comment']
                // KEEP, DELETE, EDIT
                new_comment['action'] = payload['action']
                // ENTITY, MATHERIAL, RESOLUTION
                new_comment['target'] = payload['target']
            }

            if (payload.resolve) return new_comment
            else {
                commit("PUSH_NEED_ACTION_TASK_COMMENT", new_comment)
                commit("PUSH_COMPLAIN_ENTITY_COMMENT", new_comment)
            }
        },
    },
    mutations: {
        SAVE_NEED_ACTION_TASKS(state, data) {
            if (!data.tasks) {
                state.need_action_tasks = []
            } else if (data.type === 'load_up') {
                state.need_action_tasks = state.need_action_tasks.concat(data.tasks)
            } else {
                state.need_action_tasks = data.tasks
            }
        },
        UPDATE_NEED_ACTION_TASKS_COUNT: (state, count) => state.need_action_task_count = count,
        SAVE_NEED_ACTION_TASK_ID: (state, id) => state.need_action_task_id = id,
        SAVE_NEED_ACTION_TASK_DETAILS(state, data) {
            let duration = 0;
            if (state.need_action_task_details) {
                duration = 300;
                state.need_action_task_id = null;
                state.need_action_task_details = null;
                state.need_action_task_comments_all_loaded = false;
                state.need_action_task_comments = [];
                state.need_action_task_actions = [];
                state.need_action_task_actions_id = null;
            }
            setTimeout(() => state.need_action_task_details = data, duration)
        },
        CHANGE_NEED_ACTION_TASK_DETAILS: (state, data) => state.need_action_task_details = data,
        SAVE_NEED_ACTION_TASK_COMMENTS: (state, comments) => state.need_action_task_comments = comments,
        CONCAT_NEED_ACTION_TASK_COMMENTS: (state, comments) => state.need_action_task_comments = state.need_action_task_comments.concat(comments),
        PUSH_NEED_ACTION_TASK_COMMENT: (state, comment) => state.need_action_task_comments.unshift(comment),
        EDIT_NEED_ACTION_COMMENT: (state, comment) => {
            let index = state.need_action_task_comments.findIndex(c => c.task_comment_uuid === comment.task_comment_uuid);
            if (~index) state.need_action_task_comments.splice(index, 1, comment);
        },
        DELETE_NEED_ACTION_COMMENT: (state, uuid) => {
            state.need_action_task_comments = state.need_action_task_comments.filter(c => c.task_comment_uuid !== uuid);
        },
        SAVE_NEED_ACTION_TASK_ACTIONS: (state, actions) => state.need_action_task_actions = actions,
        SAVE_NEED_ACTION_TASK_ACTIONS_ID: (state, id) => state.need_action_task_actions_id = id,
        CHANGE_NEED_ACTION_TASK_COMMENTS_ALL_LOADED: (state, boolean) => state.need_action_task_comments_all_loaded = boolean,
        UPDATE_NEED_ACTION_TASK_ENTITY(state, entity) {
            if (state.need_action_task_details) state.need_action_task_details['entity'] = entity
        },
        DELETE_NEED_ACTION_TASK_PANEL_INFO(state) {
            state.need_action_task_comments_all_loaded = false;
            state.need_action_task_comments = [];
            state.need_action_task_actions = [];
            state.need_action_task_actions_id = null;
            state.need_action_task_id = null;
        },
        CHANGE_NEED_ACTION_VOCABULARY_PANEL: (state, data) => state.need_action_vocabulary_panel = data,
        CHANGE_NEED_ACTION_AUDIO_DATA: (state, data) => state.need_action_audio_data = data,
        CHANGE_REDIRECT_AFTER_DELETE: (state, boolean) => state.redirect_after_delete = boolean,
        SAVE_DELETED_ENTITY_DATA: (state, data) => state.deleted_entity_data = data,
        SAVE_DISPUTE_PARENT_MATERIAL_POPUP: (state, data) => state.dispute_parent_material_popup = data,
        SAVE_NEED_ACTION_TASK_VERIFY: (state, value) => state.need_action_task_details.entity['verification_action_by_current_user'] = value,
        SAVE_COMMENT_TRANSLATION_LANGUAGE: (state, value) => state.comment_translation_language = value || {},
        UPDATE_NEED_ACTION_TASK_INDEX(state, type) {
            if (type === 'delete') state.need_action_task_delete_index += 1;
            else if (type === 'edit') state.need_action_task_edit_index += 1;
        },
        REMOVE_NEED_ACTION_TASK(state, id) {
            let index = state.need_action_tasks.findIndex(t => t.task_id == id);
            if (~index) {
                state.need_action_tasks[index]['done'] = true;
                setTimeout(() => state.need_action_tasks?.splice(index, 1), 1000);
            }
        },
        UPDATE_NEED_ACTION_TASKS_PAGE(state) {
            state.need_action_tasks = [];
            state.need_action_tasks_all_loaded = false;
            state.need_action_task_id = null;
            state.need_action_task_details = null;
            state.need_action_task_comments = [];
            state.need_action_task_comments_all_loaded = false;
            state.need_action_task_actions = [];
            state.need_action_task_actions_id = null;
        },
        CLEAR_NEED_ACTION_TASKS: (state) => Object.assign(state, getDefaultState())
    },
}
