import axios from "axios";
import Vue from "vue";
import router from "../../router";

const getDefaultState = () => {
    return {
        moderators_list: [],
        moderators_list_all_loaded: false,
        administrators_list: [],
        update_moderation_setting_languages: true,
        moderation_tasks: [],
        moderation_tasks_date_of_receiving: null,
        moderation_mode_notice: false
    }
}

let moderation_mode_notice_timeout;

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_MODERATION_TASKS({commit}) {
            await commit('CHANGE_LOADING_MODERATION_TASKS_STATUS', true);

            await axios
                .get(`${Vue.prototype.$api_url_v2}/moderation/tasks?limit=10`)
                .then(async response => {
                    if (response) {
                        let arr = response.data.data,
                            date_of_receiving = Date.now();

                        if (arr.length) arr.forEach(e => {
                            e['done'] = false;
                            e['date_of_receiving'] = date_of_receiving;
                        });

                        await commit("SAVE_MODERATION_TASKS", arr);
                    }
                })
                .catch((e) => {
                    commit("SAVE_MODERATION_TASKS", []);
                    throw new Error(e)
                })
                .finally(() => {
                    setTimeout(() => commit('CHANGE_LOADING_MODERATION_TASKS_STATUS', false), 100);
                })
        },
        SOLVING_FROM_MODERATOR_DICTIONARY({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url_v2}/moderation/tasks/${payload.id}/dictionary`, {
                        "action": payload.action,
                        "comment": payload.comment
                    })
                    .then(() => {
                        commit;
                        resolve();
                    })
                    .catch((err) => reject(err))
            })
        },
        SOLVING_FROM_MODERATOR_USER({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url_v2}/moderation/tasks/${payload.id}/users`, {
                        "action": payload.action,
                        "period": payload.period,
                        "reason": payload.reason,
                        "comment": payload.comment
                    })
                    .then(() => {
                        commit;
                        resolve();
                    })
                    .catch((err) => reject(err))
            })
        },
        SOLVING_FROM_MODERATOR_USER_ACTIONS({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url_v2}/moderation/tasks/${payload.id}/actions`, {
                        "action": payload.action,
                    })
                    .then(() => {
                        commit;
                        resolve();
                    })
                    .catch((err) => reject(err))
            })
        },
        SOLVING_FROM_MODERATOR_FEED({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url_v2}/moderation/tasks/${payload.id}/feed`, {
                        "action": payload.action,
                    })
                    .then(() => {
                        commit;
                        resolve();
                    })
                    .catch((err) => reject(err))
            })
        },
        ANSWER_INVITE_MODERATION_STATUS({commit, getters}, payload) {
            return new Promise((resolve, reject) => {
                let headers = {}
                if (payload.authorization) headers['authorization'] = payload.authorization

                axios
                    .post(`${Vue.prototype.$api_url}/user/moderation/answer-invite?accept=${payload.answer}`, {}, {headers})
                    .then(async () => {
                        if (payload.answer === 1) {
                            commit("CHANGE_USER_MODERATION_STATUS", {status: "accepted", role: "moderator"})
                        } else if (getters.USER.invited_to_moderation_status === 'invited') {
                            commit("CHANGE_USER_MODERATION_STATUS", {status: "declined", role: "user"})
                        }
                        resolve()
                    })
                    .catch(() => {
                        reject()
                        throw new Error()
                    })
            })
        },
        async CHANGE_USER_MODERATOR_STATUS({commit}, payload) {
            let method = payload.type === 'invite' ? 'POST' : 'DELETE',
                url = `${Vue.prototype.$api_url}/user/${payload.uuid}/moderation`;

            await axios({url, method})
                .catch((err) => {
                    throw new Error(err)
                })
                .finally(() => commit)
        },
        // END_MODERATION_STATUS({commit}) {
        //     axios
        //         .post(`${Vue.prototype.$api_url}/user/moderation/end-moderation`)
        //         .then(() => commit("CHANGE_USER_MODERATION_STATUS", {status: "declined", role: "user"}))
        //         .catch(() => {
        //             commit("CHANGE_USER_MODERATION_STATUS", {status: "invited", role: "user"})
        //             throw new Error()
        //         })
        // },
        GET_MODERATORS_LIST({state, commit}, payload) {
            return new Promise((resolve, reject) => {
                let limit = 12, URL = `${Vue.prototype.$api_url}/users/moderation?limit=${limit}`;

                if (payload.search) {
                    URL += '&search=' + payload.search
                } else if (payload.type === 'load_up') {
                    if (state.moderators_list.length) URL += '&offset=' + state.moderators_list.length
                } else {
                    commit("CHANGE_MODERATORS_LIST_ALL_LOADED", false)
                }

                axios
                    .get(URL)
                    .then((r) => {
                        if (r) {
                            if (payload.search) resolve(r.data.data)
                            else {
                                if (r.data.data.length < limit) commit("CHANGE_MODERATORS_LIST_ALL_LOADED", true)

                                if (payload.type === 'load_up') commit("CONCAT_LIST_MODERATORS", r.data.data)
                                else commit("SAVE_LIST_MODERATORS", r.data.data)
                                resolve()
                            }
                        }
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        GET_ADMINISTRATORS_LIST({commit}) {
            axios
                .get(`${Vue.prototype.$api_url}/users/administration`)
                .then((r) => {
                    if (r) commit("SAVE_LIST_ADMINISTRATORS", r.data.data)
                })
                .catch((e) => {
                    throw new Error(e)
                })
        },
        async UPDATE_MODERATION_SETTING_LANGUAGES({state, commit, dispatch}) {
            if (state.update_moderation_setting_languages) {
                return new Promise((resolve, reject) => {
                    axios
                        .get(Vue.prototype.$api_url + '/moderation/filters')
                        .then(async response => {
                            await dispatch('UPDATE_USER_CONFIG', {
                                key: 'moderation_setting_languages',
                                value: response.data.data.languages.map(l => l.code)
                            })
                            await commit('CHANGE_UPDATE_MODERATION_SETTING_LANGUAGES', false)
                            await resolve(response.data.data.languages)
                        })
                        .catch(err => reject(err))
                })
            }
        },
        async UPDATE_MODERATION_SETTINGS_FILTER({getters, dispatch}, payload) {
            if (payload.languages) {
                await axios.post(Vue.prototype.$api_url + '/moderation/filters', {'lang_codes': payload.languages})
                await dispatch('UPDATE_USER_CONFIG', {key: 'moderation_setting_languages', value: payload.languages})
            }

            await dispatch("LOAD_MODERATION_TASKS")
                .then(() => router.push({name: 'Moderation', params: {locale: getters.LOCALE.route_code}}));
        },
        OPEN_NEXT_MODERATION_TASK({state, commit, rootState}) {
            let task,
                task_index = state.moderation_tasks.findIndex(t => t.id === rootState.need_action.need_action_task_details.moderation_task_id);

            if (~task_index) {
                let next_task = state.moderation_tasks[task_index + 1];

                if (next_task) task = next_task
                else if (state.moderation_tasks[0]) task = state.moderation_tasks[0]
            } else if (state.moderation_tasks[0]) {
                task = state.moderation_tasks[0]
            }

            if (task) {
                if (task.type === 'dictionary') {
                    commit("CHANGE_NEED_ACTION_TASK_DETAILS", {
                        "type": 'dictionary',
                        "entity_id": task.detail_data.entity.id,
                        "entity_type_slug": task.detail_data.entity_type_slug,
                        "target": task.detail_data.target,
                        "moderation_task_id": task.id,
                        "date_of_receiving": task.date_of_receiving,
                    });
                } else if (task.type === 'user') {
                    commit("CHANGE_NEED_ACTION_TASK_DETAILS", {
                        "type": 'user',
                        "detail_data": task.detail_data,
                        "moderation_task_id": task.id,
                        "date_of_receiving": task.date_of_receiving,
                    });
                } else if (task.type === 'user_action') {
                    commit("CHANGE_NEED_ACTION_TASK_DETAILS", {
                        "type": 'user_action',
                        "detail_data": task.detail_data,
                        "moderation_task_id": task.id,
                        "date_of_receiving": task.date_of_receiving,
                    });
                } else if (task.type === 'feed') {
                    commit("CHANGE_NEED_ACTION_TASK_DETAILS", {
                        "type": 'feed',
                        "detail_data": task.detail_data,
                        "moderation_task_id": task.id,
                        "date_of_receiving": task.date_of_receiving,
                    });
                }
            } else {
                commit("CHANGE_NEED_ACTION_TASK_DETAILS", null);
            }
        },
        LOAD_ACTIVE_USERS({commit}, payload) {
            return new Promise((resolve, reject) => {
                let URL = `${Vue.prototype.$api_url}/users/can-become-moderators-search?limit=20`

                if (payload.pagination_user_uuid) URL += '&pagination_user_uuid=' + payload.pagination_user_uuid
                if (payload.native_lang_code) URL += '&native_lang_code=' + payload.native_lang_code
                if (payload.learn_lang_code) URL += '&learn_lang_code=' + payload.learn_lang_code

                axios
                    .get(URL)
                    .then(response => {
                        commit;
                        resolve(response.data.data);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        LOAD_USERS_REVIEWS({commit}, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/reviews`, {params})
                    .then(response => {
                        commit;
                        resolve(response.data.data);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        LOAD_TARGET_ACTIONS({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url_v2}/moderation/actions/${payload.target_type}/${payload.target_uuid}`, {params: payload.params})
                    .then(response => {
                        commit;
                        resolve(response.data.data);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },
    mutations: {
        SAVE_MODERATION_TASKS: (state, data) => {
            state.moderation_tasks = data;
            if (data[0]) state.moderation_tasks_date_of_receiving = data[0].date_of_receiving;
            else state.moderation_tasks_date_of_receiving = null;
        },
        SAVE_LIST_MODERATORS: (state, list) => state.moderators_list = list,
        CHANGE_UPDATE_MODERATION_SETTING_LANGUAGES: (state, boolean) => state.update_moderation_setting_languages = boolean,
        CHANGE_MODERATION_MODE_NOTICE(state, boolean) {
            clearTimeout(moderation_mode_notice_timeout);

            if (boolean === false) state.moderation_mode_notice = false;
            else {
                if (!localStorage.getItem('moderation_mode_notice')) {
                    setTimeout(() => {
                        state.moderation_mode_notice = true;
                        localStorage.setItem('moderation_mode_notice', 1);
                    }, 500)
                    moderation_mode_notice_timeout = setTimeout(() => state.moderation_mode_notice = false, 3500);
                }
            }
        },
        CONCAT_LIST_MODERATORS: (state, list) => state.moderators_list = state.moderators_list.concat(list),
        SAVE_LIST_ADMINISTRATORS: (state, list) => state.administrators_list = list,
        CHANGE_MODERATORS_LIST_ALL_LOADED: (state, boolean) => state.moderators_list_all_loaded = boolean,
        REMOVE_MODERATION_TASK: (state, id) => {
            let index = state.moderation_tasks.findIndex(t => t.id == id);
            if (~index) {
                state.moderation_tasks[index]['done'] = true;
                setTimeout(() => state.moderation_tasks.splice(index, 1), 1000);
            }
        },
        CLEAR_MODERATION_TASKS: (state) => {
            state.moderation_tasks = [];
            state.moderation_tasks_date_of_receiving = null;
        },
        CLEAR_MODERATION_STORE: (state) => Object.assign(state, getDefaultState())
    },
}
