<template>
    <div id="wola">
        <component :is="MOBILE ? 'wm-header' : 'w-header'" class="wola_header"/>

        <router-view/>

        <w-alert v-if="appAlerts"
                 :error="!!ERROR_SYSTEM"
                 :key="keyAlerts"
                 :black="!!ALERT_NEED_ACTION || !!ALERT_FEED_COMPLAIN"
        >
            <template v-if="ALERT_SYSTEM">{{ $t(ALERT_SYSTEM) }}</template>
            <template v-else-if="ERROR_SYSTEM">{{ $t(ERROR_SYSTEM) }}</template>
            <div v-else-if="ALERT_ADDITION" class="wola_alert">
                <div class="wola_alert__icon icon">
                    <w-svg-icon class="wola_alert__icon-icon"
                                folder-name="need_action" icon-name="iconDone"
                    />
                </div>
                <div>{{ $t(ALERT_ADDITION) }}</div>
            </div>
            <div v-else-if="ALERT_FEED_COMPLAIN" class="wola_alert">
                <div class="wola_alert__icon icon">
                    <w-svg-icon class="wola_alert__icon-icon"
                                folder-name="need_action" icon-name="iconDone"
                    />
                </div>
                <div>{{ $t(ALERT_FEED_COMPLAIN) }}</div>
            </div>
            <div v-else-if="ALERT_NEED_ACTION" class="wola_alert">
                <div class="wola_alert__icon icon">
                    <w-svg-icon class="wola_alert__icon-icon"
                                folder-name="need_action" icon-name="iconDone"
                    />
                </div>
                <div>{{ $t(ALERT_NEED_ACTION) }}</div>
            </div>
        </w-alert>

        <cookies_alert v-else-if="cookies_alert" @close="closeCookiesAlert"/>

        <w-blackout v-if="showBlackout" @click="closePanel"/>

        <w-popup v-if="show_internet_connection_popup || (!!ERROR_SERVER && $store.state.other.dev_host)"
                 close_in
                 @closePopup="closePopup"
        >
            <error_internet_connect v-if="show_internet_connection_popup"/>
            <error_server v-else-if="!!ERROR_SERVER && $store.state.other.dev_host"/>
        </w-popup>

        <template v-if="MOBILE">
            <smart-banner v-if="show_smart_banner" @close="show_smart_banner = false"/>
            <wola-nav-mobile-panel/>
        </template>
        <template v-else>
            <loading_page v-if="SITE_LOAD && !$is_webview && ($route.name !== 'PaymentPage')"/>

            <router-link class="logo" :to="{name: 'FeedPage', params: {locale: LOCALE.route_code}}">
                <w-svg-icon class="logo-circle" folder-name="logo" icon-name="logoCircle"/>
                <w-svg-icon class="logo-name" folder-name="logo" icon-name="logoName"/>
            </router-link>

            <app-panels ref="app_panels" @closePanel="closePanel"/>

            <popup-news v-if="NEWS_POPUP"/>

            <popup-vocabularies
                    v-if="NEED_ACTION_VOCABULARY_PANEL"
                    improve need_action
                    :cache="NEED_ACTION_VOCABULARY_PANEL.cache"
                    :all_vocabularies="NEED_ACTION_VOCABULARY_PANEL.all_vocabularies"
                    :translation="NEED_ACTION_VOCABULARY_PANEL.translation"
                    :edited_entity_id="NEED_ACTION_VOCABULARY_PANEL.edited_entity_id"
                    :moderation_task_id="NEED_ACTION_VOCABULARY_PANEL.moderation_task_id"
            />

            <popup-user-lang-delete v-if="REMOVABLE_LANG"/>
            <popup-add-new-lang v-else-if="ADD_LANG_POPUP"/>

            <popup-audio-record
                    v-if="!!NEED_ACTION_AUDIO_DATA"
                    need_action
                    :word="NEED_ACTION_AUDIO_DATA.text"
                    :lang_code="NEED_ACTION_AUDIO_DATA.lang_code"
            />

            <popup-dispute-parent-material
                    v-if="Boolean(DISPUTE_PARENT_MATERIAL_POPUP)"
                    :data="DISPUTE_PARENT_MATERIAL_POPUP"
            />

            <popup-language-setup v-if="!!LANGUAGE_SETUP_POPUP"/>

            <template v-if="isLoggedIn">
                <push-messages/>
                <audio preload muted id="audio-companion" volume="0.2">
                    <source :src="require('@/assets/audios/audioMsgCompanion.mp3')" type="audio/mp3">
                </audio>
            </template>

            <tutorial-screen v-if="TUTORIAL_SCREEN" :type="TUTORIAL_SCREEN"/>

            <dedicated_translation v-if="DEDICATED_TRANSLATION_DATA"/>
        </template>
        <dictionary-alerts/>
        <template v-if="isLoggedIn">
            <popup-user-ban v-if="BAN_POPUP && (BAN_POPUP.target === 'user')"/>
            <popup-entity-delete v-else-if="DELETED_ENTITY_DATA"/>
            <popup-post-delete v-else-if="DELETED_POST_DATA"/>
            <popup-entity-complain v-else-if="COMPLAIN_ENTITY_DATA"/>
            <popup-selection-delete v-else-if="DELETED_SELECTION_DATA"/>
            <popup-email-verified v-else-if="!USER_VERIFIED && USER_VERIFIED_POPUP"/>
            <popup-forward-message v-else-if="FORWARD_MESSAGES_POPUP_STATUS"/>

            <popup-wola-plus v-if="WOLA_PLUS_POPUP"/>

            <plus_benefit v-if="PLUS_BENEFIT_DATA"/>
        </template>
        <call-for-registration-page v-else-if="CALL_TO_AUTH"/>
        <popup-report v-if="REPORT_POPUP"/>
        <popup-new-level v-else-if="LEVEL_POPUP"/>
        <popup-new-achievement v-else-if="ACHIEVEMENT_POPUP" :key="ACHIEVEMENT_POPUP.prize.type"/>
        <popup-accept-moderation v-else-if="ACCEPT_MODERATION_POPUP"/>
        <popup-clear-history v-else-if="CLEAR_HISTORY_POPUP"/>
        <popup-sentence-translations v-else-if="SENTENCE_TRANSLATIONS_POPUP"/>
        <popup-word-translations v-else-if="WORD_TRANSLATIONS_POPUP"/>
        <popupImage v-if="IMAGE_POPUP" :object_data="IMAGE_POPUP"/>
    </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';
    import error_internet_connect from "./components/elements/errors/error_internet_connect";
    import loading_page from "./components/Main/loading_page";
    import store from "./store";
    import Bowser from "bowser";
    import Cookies from "js-cookie";
    import dictionaryAlerts from "./components/Main/dictionaryAlerts";
    import appPanels from "./components/Main/desktop/appPanels";
    import UNFULFILLED_ACTIONS from "@/assets/js/unfulfilled_actions";
    import dedicated_translation from "@/components/elements/dedicated_translation.vue";
    import PopupWolaPlus from "@/components/popups/popupWolaPlus.vue";

    export default {
        name: 'App',
        components: {
            PopupWolaPlus,
            dedicated_translation,
            dictionaryAlerts,
            loading_page,
            error_internet_connect,
            appPanels,
            plus_benefit: () => import("@/components/elements/plus/plus_benefit.vue"),
            error_server: () => import("./components/elements/errors/error_server"),
            PushMessages: () => import("./views/notifications/PushMessages"),
            wHeader: () => import("./components/Main/desktop/wHeader"),
            wmHeader: () => import("./components/Main/mobile/wmHeader"),
            cookies_alert: () => import("./components/elements/cookies_alert"),
            wolaNavMobilePanel: () => import("./components/Main/mobile/wola_nav_mobile_panel"),
            TutorialScreen: () => import("./components/elements/tutorials/TutorialScreen"),
            CallForRegistrationPage: () => import("./views/dictionary/CallForRegistrationPage"),
            smartBanner: () => import("./components/Main/smartBanner"),
            popupImage: () => import("./components/popups/popupImage"),
            popupEntityDelete: () => import("./components/popups/popupEntityDelete"),
            popupPostDelete: () => import("./components/popups/popupPostDelete"),
            popupNews: () => import("./components/popups/popupNews"),
            popupLanguageSetup: () => import("./components/popups/popupLanguageSetup"),
            popupNewAchievement: () => import("./components/popups/popupNewAchievement"),
            popupNewLevel: () => import("./components/popups/popupNewLevel"),
            popupEmailVerified: () => import("./components/popups/popupEmailVerified"),
            popupAcceptModeration: () => import("./components/popups/popupAcceptModeration"),
            popupVocabularies: () => import("./components/popups/popupVocabularies"),
            popupAudioRecord: () => import("./components/popups/popupAudioRecord"),
            popupDisputeParentMaterial: () => import("./components/popups/popupDisputeParentMaterial"),
            popupForwardMessage: () => import("./components/popups/popupForwardMessage"),
            popupClearHistory: () => import("./components/popups/popupClearHistory"),
            popupSentenceTranslations: () => import("./components/popups/popupSentenceTranslations"),
            popupWordTranslations: () => import("./components/popups/popupWordTranslations"),
            popupAddNewLang: () => import("@/components/popups/popupAddNewLang.vue"),
            popupUserLangDelete: () => import("@/components/popups/popupUserLangDelete.vue"),
            popupUserBan: () => import("@/components/popups/popupUserBan.vue"),
            popupSelectionDelete: () => import("@/components/popups/popupSelectionDelete.vue"),
            popupEntityComplain: () => import("@/components/popups/popupEntityComplain.vue"),
            popupReport: () => import("@/components/popups/popupReport.vue"),
        },
        data() {
            return {
                cookies_alert: false,
                show_smart_banner: false,
                show_internet_connection_popup: false
            }
        },
        computed: {
            ...mapGetters(['MOBILE', 'isLoggedIn', 'USER', 'LOCALE']),
            ...mapState({
                'SITE_LOAD': state => state.other.site_load,
                'INTERNET_CONNECTION': state => state.other.internet_connection,
                'NOTIFICATIONS_SETTINGS_PANEL': state => state.notifications.notifications_settings_panel,
                'NOTIFICATIONS_PANEL': state => state.notifications.notifications_panel,
                'ERROR_SYSTEM': state => state.errors.error_system,
                'ERROR_SERVER': state => state.errors.error_server,
                'SOCIAL_WINDOWS': state => state.other.social_windows,
                'ALERT_SYSTEM': state => state.alerts.alert_system,
                'ALERT_ADDITION': state => state.alerts.alert_addition,
                'ALERT_NEED_ACTION': state => state.alerts.alert_need_action,
                'ALERT_FEED_COMPLAIN': state => state.alerts.alert_feed_complain,
                'USER_VERIFIED': state => state.profile.user_info.is_email_verified,
                'USER_VERIFIED_POPUP': state => state.profile.user_verified_popup,
                'ACHIEVEMENT_POPUP': state => state.panels.achievement_popup,
                'ACCEPT_MODERATION_POPUP': state => state.panels.accept_moderation_popup,
                'LEVEL_POPUP': state => state.panels.level_popup,
                'MOBILE_NAV_PANEL': state => state.panels.mobile_nav_panel,
                'TUTORIAL_SCREEN': state => state.tutorials.tutorial_screen,
                'CALL_TO_AUTH': state => state.other.call_to_auth,
                'PLUS_BENEFIT_DATA': state => state.other.plus_benefit_data,
                'WORD_PANEL': state => state.panels.word_panel,
                'WORD_SENTENCES_PANEL': state => state.panels.word_sentences_panel,
                'SENTENCES_PANEL': state => state.panels.sentences_panel,
                'COMPLAIN_PANEL': state => state.panels.complaint_panel,
                'FAVORITE_PANEL': state => state.panels.favorite_panel,
                'BLOCKED_PANEL': state => state.panels.blocked_panel,
                'BLOCKED_HISTORY_PANEL': state => state.panels.blocked_history_panel,
                'WOLA_USER_PANEL_NICKNAME': state => state.panels.wola_user_panel_nickname,
                'IMAGE_POPUP': state => state.panels.image_popup,
                'NEED_ACTION_TASK_DETAILS': state => state.need_action.need_action_task_details,
                'NEED_ACTION_VOCABULARY_PANEL': state => state.need_action.need_action_vocabulary_panel,
                'NEED_ACTION_AUDIO_DATA': state => state.need_action.need_action_audio_data,
                'DISPUTE_PARENT_MATERIAL_POPUP': state => state.need_action.dispute_parent_material_popup,
                'NEWS_POPUP': state => state.panels.news_popup,
                'ALL_NEWS': state => state.news.all_news,
                'STATISTICS_GENERAL_PANEL': state => state.statistics.statistics_general_panel,
                'STATISTICS_DETAIL_PANEL': state => state.statistics.statistics_detail_panel,
                'CLEAR_HISTORY_POPUP': state => state.panels.clear_history_popup,
                'LANGUAGE_SETUP_POPUP': state => state.other.language_setup_popup,
                'WOLA_PLUS_POPUP': state => state.panels.wola_plus_popup,
                'SENTENCE_TRANSLATIONS_POPUP': state => state.panels.sentence_translations_popup,
                'WORD_TRANSLATIONS_POPUP': state => state.panels.word_translations_popup,
                'BAN_POPUP': state => state.panels.ban_popup,
                'DELETED_ENTITY_DATA': state => state.need_action.deleted_entity_data,
                'DELETED_POST_DATA': state => state.feed.deleted_post_data,
                'DELETED_SELECTION_DATA': state => state.learning.deleted_selection_data,
                'NATIVE_LANG': state => state.profile.native_lang,
                'FORWARD_MESSAGES_POPUP_STATUS': state => state.chat.forward_messages_popup_status,
                'SELECTION_COMMENTS_DETAIL': state => state.learning.selection_comments_detail,
                'REMOVABLE_LANG': state => state.profile.removable_lang,
                'ADD_LANG_POPUP': state => state.panels.add_lang_popup,
                'REPORT_POPUP': state => state.panels.report_popup,
                'DEDICATED_TRANSLATION_DATA': state => state.other.dedicated_translation_data,
                'POST_DETAILS': state => state.feed.post_details,
                'QUESTION_DETAILS': state => state.feed.question_details,
                'COMPLAIN_ENTITY_DATA': state => state.complaints.complain_entity_data,
            }),
            appAlerts() {
                return this.ALERT_SYSTEM || this.ERROR_SYSTEM || this.ALERT_ADDITION || this.ALERT_NEED_ACTION || this.ALERT_FEED_COMPLAIN
            },
            computedThemeColor() {
                let green_theme = ['Auth', 'DetailWordPage', 'SentencesPage', 'Dictionary', 'DictionaryPage', 'WordSentencesPage', 'ProfilePage'],
                    grey_theme = ['AdditionFork', 'EditionTranslate', 'AdditionTranslate', 'AdditionSentences', 'AdditionMeaning', 'AdditionSynonym', 'AdditionAntonym'],
                    white_theme = ['MobileSettings', 'History', 'AuthLogin', 'AuthSignUp', 'AdditionFork', 'AdditionTranslate', 'AdditionSentences', 'AdditionMeaning', 'AdditionSynonym', 'AdditionAntonym', 'NoPage', 'NoFound'];

                if (this.SITE_LOAD) return '#E9EBF4'
                else if (green_theme.includes(this.$route.name)) return '#48c68f'
                else if (grey_theme.includes(this.$route.name)) return '#f5f6fa'
                else if (white_theme.includes(this.$route.name)) return '#ffffff'
                else return '#f5f6fa'
            },
            keyAlerts() {
                if (this.ALERT_SYSTEM) return this.ALERT_SYSTEM
                else if (this.ERROR_SYSTEM) return this.ERROR_SYSTEM
                else if (this.ALERT_ADDITION) return this.ALERT_ADDITION
                else if (this.ALERT_NEED_ACTION) return this.ALERT_NEED_ACTION
                else if (this.ALERT_FEED_COMPLAIN) return this.ALERT_FEED_COMPLAIN
                else return 1
            },
            showBlackout() {
                return !!this.SOCIAL_WINDOWS ||
                    this.WORD_PANEL ||
                    this.WORD_SENTENCES_PANEL ||
                    this.SENTENCES_PANEL ||
                    this.COMPLAIN_PANEL ||
                    this.FAVORITE_PANEL ||
                    this.BLOCKED_PANEL ||
                    this.BLOCKED_HISTORY_PANEL ||
                    this.NOTIFICATIONS_SETTINGS_PANEL ||
                    this.NOTIFICATIONS_PANEL ||
                    this.MOBILE_NAV_PANEL ||
                    this.WOLA_USER_PANEL_NICKNAME ||
                    this.NEED_ACTION_TASK_DETAILS ||
                    this.POST_DETAILS ||
                    this.QUESTION_DETAILS ||
                    this.STATISTICS_DETAIL_PANEL ||
                    this.STATISTICS_GENERAL_PANEL ||
                    this.SELECTION_COMMENTS_DETAIL
            }
        },
        watch: {
            INTERNET_CONNECTION(val, oldVal) {
                if ((oldVal === false) && (val === true) && (UNFULFILLED_ACTIONS.size() > 0)) {
                    store.dispatch("RETRY_FAILED_ACTIONS")
                }
            }
        },
        created() {
            this.setSomeSettings();
        },
        mounted() {
            // this.$store.state.languages.languages.forEach(lang => console.log(lang.code_two_signs + ': ' + lang.name))
            if (!this.$is_webview) {
                this.startApp();
                this.showCookiesAlert();
                this.showVerifiedPopup();
                this.showSmartBanner();
                this.checkWebVersion();

                window.addEventListener('online',  this.updateOnlineStatus);
                window.addEventListener('offline', this.updateOnlineStatus);
            }
        },
        destroyed() {
            document.removeEventListener("visibilitychange", this.toggleVisibilityChangeListening);
        },
        methods: {
            checkWebVersion() {
                let cookieNewsVersion = Cookies.get('news_version');

                if (!cookieNewsVersion) this.showNewsPopup();
                else {
                    let cookie_v = Number(cookieNewsVersion.replaceAll('.', '')),
                        storage_v = Number(this.$web_version.replaceAll('.', ''));
                    if (storage_v > cookie_v) this.showNewsPopup();
                }
            },
            clearTranslationIdFromRoute() {
                let new_query = this.$route
                delete new_query.params['id']
                return new_query
            },
            closeCookiesAlert() {
                this.cookies_alert = false;
                Cookies.set('cookies_permission', 'accept', {
                    secure: true,
                    expires: Date.now() + Number(3154e+10)
                })
                localStorage.setItem('cookies_permission', 1)
            },
            closePanel() {
                if (this.BLOCKED_HISTORY_PANEL) store.commit("CHANGE_BLOCKED_HISTORY_PANEL", null);
                else if (this.WOLA_USER_PANEL_NICKNAME) store.commit("CHANGE_WOLA_USER_PANEL_NICKNAME", null);
                else if (this.BLOCKED_PANEL) store.commit("CHANGE_BLOCKED_PANEL", false);
                else if (this.COMPLAIN_PANEL) store.commit("CHANGE_COMPLAIN_PANEL", false);
                else if (this.SENTENCES_PANEL) store.commit("CHANGE_SENTENCES_PANEL", false);
                else if (this.WORD_SENTENCES_PANEL) store.commit("CHANGE_WORD_SENTENCES_PANEL", false);
                else if (this.WORD_PANEL || this.FAVORITE_PANEL) {
                    store.dispatch("CLOSE_WORD_PANEL", this.$route.name !== 'DictionaryPage');
                    store.commit("DO_NOT_CLEAR_OFF");
                    if (this.$route.params.id) this.$router.replace(this.clearTranslationIdFromRoute());
                }
                else if (this.POST_DETAILS) store.commit("SAVE_POST_DETAILS", null);
                else if (this.QUESTION_DETAILS) store.commit("SAVE_QUESTION_DETAILS", null);
                else if (this.NEED_ACTION_TASK_DETAILS) store.dispatch("CLEAR_NEED_ACTION_TASK_PANEL");
                else if (this.SELECTION_COMMENTS_DETAIL) store.commit("SAVE_SELECTION_COMMENTS_DETAIL", null);
                else if (this.STATISTICS_DETAIL_PANEL) store.commit('CHANGE_STATISTICS_DETAIL_PANEL', false);
                else if (this.STATISTICS_GENERAL_PANEL) store.commit('CHANGE_STATISTICS_GENERAL_PANEL', false);
                else if (this.NOTIFICATIONS_SETTINGS_PANEL) store.commit("CHANGE_NOTIFICATIONS_SETTINGS_PANEL", false);
                else if (this.NOTIFICATIONS_PANEL) store.commit("CLOSE_NOTIFICATIONS_PANEL");
                else if (this.MOBILE_NAV_PANEL) store.commit("CHANGE_MOBILE_NAV_PANEL", false);
                else if (this.SOCIAL_WINDOWS) store.commit("SAVE_SOCIAL_WINDOWS", false);
            },
            closePopup() {
                if (this.show_internet_connection_popup) this.show_internet_connection_popup = false
                else if (this.ERROR_SERVER) this.$store.commit("CLOSE_ERROR", 'server')
                else this.$store.commit('CLOSE_ALL_ERRORS')
            },
            saveDeviceWidth() {
                store.commit("SAVE_WINDOW_WIDTH", document.documentElement.clientWidth);
                let root = document.documentElement,
                    dataTheme = root.getAttribute('data-theme'),
                    storageTheme = localStorage.getItem('user_theme');
                if (window.innerWidth < 980) {
                    if (dataTheme != 'light') root.setAttribute('data-theme', 'light')
                } else {
                    if (storageTheme && (dataTheme != storageTheme)) root.setAttribute('data-theme', storageTheme)
                }
            },
            setCookies() {
                let props = {
                    secure: true,
                    expires: Date.now() + Number(3154e+10)
                }
                Cookies.set('news_version', this.$web_version, props);
                if (this.ALL_NEWS[0]) Cookies.set('last_news_id', this.ALL_NEWS[0].id, props);
            },
            setSomeSettings() {
                if (this.isLoggedIn) {
                    if (this.USER.need_to_set_nickname) {
                        this.$router.replace({name: 'AuthCompleteSocialProfile', params: {locale: this.LOCALE.route_code, step: 'nickname'}});
                    } else if (!this.NATIVE_LANG) {
                        this.$store.dispatch('LOAD_USER_LANG').then(() => {
                            if (!this.NATIVE_LANG) this.$router.replace({name: 'AuthAddNativeLang'});
                        })
                    } else {
                        this.$store.dispatch("LOAD_USER_INFO")
                    }

                    let ImagesDB = this.$vlf.createInstance({
                        name: 'chatDB',
                        storeName: 'images',
                    })

                    let VoicesDB = this.$vlf.createInstance({
                        name: 'chatDB',
                        storeName: 'voices',
                    })

                    ImagesDB.iterate((value, key) => {
                        this.$chat_images.set(key, value)
                    })

                    VoicesDB.iterate((value, key) => {
                        this.$chat_voices.set(key, value)
                    })
                }
            },
            showCookiesAlert() {
                setTimeout(() => {
                    let condition1 = !Cookies.get('cookies_permission'),
                        condition2 = !localStorage.getItem('cookies_permission');

                    if (!condition1 && condition2) localStorage.setItem('cookies_permission', 1);

                    this.cookies_alert = condition1 && condition2;
                }, 2000)
            },
            showNewsPopup() {
                this.$store.dispatch("GET_ALL_NEWS").then(() => {
                    if (this.ALL_NEWS.length) {
                        let lastNewsId = Cookies.get('last_news_id');
                        let cookieNewsVersion = Cookies.get('news_version');

                        if (!lastNewsId && !cookieNewsVersion) {
                            this.setCookies();
                            return;
                        }
                        else if (!lastNewsId) store.dispatch('CHANGE_UNREAD_NEWS_STATUS', true);
                        else if (Number(lastNewsId) < Number(this.ALL_NEWS[0].id)) {
                            store.dispatch('CHANGE_UNREAD_NEWS_STATUS', true);
                        }
                    }

                    Cookies.set('news_version', this.$web_version, {
                        secure: true,
                        expires: Date.now() + Number(3154e+10)
                    });
                })
            },
            showSmartBanner() {
                if (this.MOBILE && !Bowser.getParser(window.navigator.userAgent).getBrowser().name.toLowerCase().includes('safari')) this.show_smart_banner = true
            },
            showVerifiedPopup() {
                store.dispatch('CHECK_USER_EMAIL_VERIFICATION', true);
            },
            startApp() {
                window.onresize = () => this.saveDeviceWidth();
                document.addEventListener("visibilitychange", this.toggleVisibilityChangeListening);

                store.dispatch('LOAD_LANGUAGES');
                store.dispatch('LOAD_ALL_ATTRIBUTES');
                store.dispatch('APP_START');
                store.dispatch('CHECK_WEB_APP_VERSION');
            },
            toggleVisibilityChangeListening() {
                store.commit("CHANGE_DOCUMENT_VISIBLE", !document.hidden)

                if (store.state.other.dev_host) {
                    if (document.hidden) console.info('WOLA: Вкладка не активна');
                    else console.info('WOLA: Вкладка активна');
                }
            },
            async updateOnlineStatus() {
                this.show_internet_connection_popup = !navigator.onLine;
                store.commit("SAVE_INTERNET_CONNECTION", navigator.onLine);
            },
        },
        metaInfo() {
            return {
                title: 'WOLA',
                meta: [
                    {vmid: 'description', name: 'description', content: this.$t('hello_dictionary_description'),},
                    {name: 'theme-color', content: this.MOBILE ? this.computedThemeColor : '',},
                    {name: 'viewport', content: this.MOBILE ? 'width=device-width,viewport-fit=cover,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0' : 'width=1920',},
                    {property: 'og:title', content: 'WOLA'},
                    {property: 'og:type', content: 'website'},
                    {property: 'og:url', content: 'https://wola.io'},
                    {property: 'og:description', content: this.$t('hello_dictionary_description')},
                    {property: 'og:image', content: 'https://wola.io/images/og_image.png'},
                    {property: 'twitter:image:src', content: 'https://wola.io/images/og_image.png'},
                    {property: 'og:image:width', content: '1200'},
                    {property: 'og:image:height', content: '630'},
                    {property: 'og:site_name', content: this.$t('wola_name')}
                ]
            }
        }
    }
</script>

<style lang="scss">
    @import "assets/styles/main";

    @media (min-width: 981px) {
        @import "assets/styles/desktop/normalize";
    }

    @media screen and (max-width: 980px) {
        @import "assets/styles/mobile/normalize";
    }
</style>
