<template>
    <transition :name="(MOBILE && image) ? 'modal-image' : 'modal'" appear>
        <div class="w_popup_mask"
             :class="{'dark_mask': image || video}"
             tabindex="0"
             @mousedown.self="clickClosePopup"
             @click.self="mobileClosePopup"
             @keydown.esc.stop="closePopup"
             @keydown.enter.stop="$emit('keyEnter')"
             @keydown.stop="$emit('keydown', $event)"
             @touchmove.stop.self.prevent="blockAction"
        >
            <div class="w_popup_content"
                 ref="popup"
                 :class="{audio, addition_popup, attributes_popup, transparent, image, video, overflow}"
            >
                <div v-if="MOBILE && !image"
                     class="w_popup_content-dash icon"
                     @touchstart.stop.prevent.self="swipeStart"
                     @touchmove.stop.prevent.self="swipeMove"
                     @touchend.stop.prevent.self="returnPosition"
                >
                    <w-svg-icon class="w_popup_content-dash--icon"
                                folder-name="mobile"
                                icon-name="mobilePopupDash"
                                width="40" height="2"
                    />
                </div>
                <slot>popup</slot>
                <div v-if="close_in"
                     class="w_popup_close icon"
                     :class="{'ease': image}"
                     @click.stop="closePopup"
                >
                    <w-svg-icon folder-name="dictionary" icon-name="iconClose" fill="#949EAE"/>
                </div>
            </div>
            <div v-if="close_out"
                 class="w_popup_close icon"
                 :class="{'ease': image}"
                 @click.stop="closePopup"
            >
                <w-svg-icon folder-name="dictionary" icon-name="iconClose" fill="#949EAE"/>
            </div>
            <div v-if="image && !MOBILE"
                 class="w_popup_menu"
                 @mouseenter="menuStatus(true)"
                 @mouseleave="menuStatus(false)"
            >
                <div class="w_popup_menu__icon icon">
                    <w-svg-icon folder-name="dictionary" icon-name="iconActions"/>
                </div>

                <transition name="bounce-scale">
                    <div v-if="show_menu" class="w_popup_menu__content">
                        <div v-if="$store.state.chat.opened_image_info.image"
                             class="w_popup_menu__content-item"
                             @click="replyImage"
                        >
                            <div class="w_popup_menu__content-item-icon icon">
                                <w-svg-icon class="w_popup_menu__content-item-icon-svg"
                                            folder-name="chat" icon-name="iconMessageReply"
                                            stroke="#ffffff"
                                />
                            </div>
                            {{ $t('action_reply') }}
                        </div>
<!--                        <div class="w_popup_menu__content-item"-->
<!--                             @click="downloadImage"-->
<!--                        >-->
<!--                            <div class="w_popup_menu__content-item-icon icon">-->
<!--                                <w-svg-icon class="w_popup_menu__content-item-icon-svg"-->
<!--                                            folder-name="chat"-->
<!--                                            icon-name="iconImageSave"-->
<!--                                            stroke="#ffffff"-->
<!--                                />-->
<!--                            </div>-->
<!--                            {{ $t('action_download_on_device') }}-->
<!--                        </div>-->
<!--                        <div class="w_popup_menu__content-item"-->
<!--                             @click="copyImage"-->
<!--                        >-->
<!--                            <w-svg-icon class="w_popup_menu__content-item-icon"-->
<!--                                        folder-name="chat"-->
<!--                                        icon-name="iconMessageCopy"-->
<!--                                        stroke="#ffffff"-->
<!--                            />-->
<!--                            {{ $t('copy_image') }}-->
<!--                        </div>-->
                        <div class="w_popup_menu__content-item"
                             @click="$emit('turnImage', $event)"
                        >
                            <w-svg-icon class="w_popup_menu__content-item-icon"
                                        folder-name="chat"
                                        icon-name="iconImageTurn"
                                        fill="#ffffff"
                            />
                            {{ $t('action_turn') }}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
    import CANCEL_TOKENS from "../../assets/js/cancel_tokens";
    import {mapGetters} from "vuex";

    export default {
        name: "w_popup",
        props: {
            addition_popup: {
                type: Boolean,
                default: false
            },
            attributes_popup: {
                type: Boolean,
                default: false
            },
            audio: {
                type: Boolean,
                default: false
            },
            close_in: {
                type: Boolean,
                default: false
            },
            close_out: {
                type: Boolean,
                default: false
            },
            confirm: {
                type: Boolean,
                default: false
            },
            image: {
                type: Boolean,
                default: false
            },
            overflow: {
                type: Boolean,
                default: false
            },
            transparent: {
                type: Boolean,
                default: false
            },
            video: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                eventStartPosition: null,
                limit: null,
                popupNode: null,
                hiddenMenuTimeout: null,
                show_menu: false,
                scale_value: 1
            }
        },
        computed: {
            ...mapGetters(['MOBILE'])
        },
        mounted() {
            this.$el.focus()
            if (this.MOBILE) {
                this.popupNode = this.$refs['popup']
                if (this.popupNode) this.limit = this.popupNode.clientHeight < 150 ? 50 : (this.popupNode.clientHeight / 2)
            }
        },
        destroyed() {
            if (this.confirm) {
                CANCEL_TOKENS.forEach(source => source.token?.cancel('Request canceled from popup'))
            }
        },
        methods: {
            blockAction() {
                return false
            },
            clickClosePopup(e) {
                if (!this.MOBILE) {
                    e.stopPropagation();
                    e.preventDefault();
                    if (this.confirm) return false
                    else this.closePopup()
                }
            },
            closePopup() {
                this.$emit('closePopup')
                if (this.confirm) {
                    CANCEL_TOKENS.forEach(source => source.token.cancel('Request canceled from popup'))
                }
            },
            copyImage() {
                this.$emit('copyImage')
                this.menuStatus(false, 0)
            },
            downloadImage() {
                this.$emit('downloadImage')
                this.menuStatus(false, 0)
            },
            menuStatus(boolean, timeout = 300) {
                if (boolean) {
                    clearTimeout(this.hiddenMenuTimeout);
                    this.show_menu = true;
                } else this.hiddenMenuTimeout = setTimeout(() => this.show_menu = false, timeout);
            },
            mobileClosePopup(e) {
                if (this.MOBILE) {
                    e.stopPropagation();
                    e.preventDefault();
                    if (this.confirm) return false
                    else this.closePopup()
                }
            },
            replyImage() {
                this.$emit('replyImage')
                this.menuStatus(false, 0)
            },
            returnPosition() {
                if (this.popupNode) {
                    this.popupNode.style.transition = 'transform 150ms ease'
                    this.popupNode.style.transform = ''
                    setTimeout(() => {
                        this.popupNode.style.transition = ''
                    }, 200)
                }
            },
            scaleImage(e) {
                if (this.image && e.ctrlKey) {
                    e.stopPropagation();
                    e.preventDefault();

                    let old_scale_value = this.scale_value,
                        popup = this.$refs['popup'],
                        width = Math.floor(popup.getBoundingClientRect().width),
                        height = Math.floor(popup.getBoundingClientRect().height),
                        can_scale_width = width < window.innerWidth * 0.9,
                        can_scale_height = height < window.innerHeight * 0.9,
                        transform = popup.style.transform,
                        scaleMatch = transform.match(/scale\((.*)\)/);

                    if (scaleMatch && scaleMatch[1]) this.scale_value = Number(scaleMatch[1])

                    if (e.wheelDelta > 0 && can_scale_width && can_scale_height) this.scale_value += 0.1
                    else if (e.wheelDelta < 0 && this.scale_value > 0.3) this.scale_value -= 0.1

                    if (old_scale_value !== this.scale_value) {
                        if (transform.includes('scale')) transform = transform.replace(/scale(.*)/, `scale(${this.scale_value})`)
                        else transform += ` scale(${this.scale_value})`

                        popup.style.transform = transform;
                    }
                }
            },
            swipeMove(e) {
                if (this.popupNode) {
                    let bottom = e.targetTouches[0].clientY - this.eventStartPosition

                    if (bottom < 0) this.popupNode.style.transform = ''
                    else if (bottom > this.limit) this.closePopup()
                    else {
                        this.popupNode.style['-webkit-transform'] = `translateY(${Math.abs(bottom)}px)`
                        this.popupNode.style['transform'] = `translateY(${Math.abs(bottom)}px)`
                    }
                }
            },
            swipeStart(e) {
                this.eventStartPosition = e.targetTouches[0].clientY
            }
        }
    }
</script>

<style lang="scss" scoped>
    .w_popup {
        &_mask {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3000;

            &.dark_mask {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }

        &_close {
            position: absolute;
            top: 28px;
            right: 28px;
            width: 32px;
            height: 32px;
            cursor: pointer;
            border-radius: 50%;
            background-color: var(--w_color_grey2);
            z-index: 200;

            svg {
                width: 12px;
                height: 12px;
            }

            &.ease {
                width: 40px;
                height: 40px;
                background-color: transparent !important;

                svg {
                    width: 16px;
                    height: 16px;

                    &::v-deep path {
                        fill: var(--w_color_white);
                    }
                }
            }
        }

        &_menu {
            position: absolute;
            top: 80px;
            right: 28px;
            color: var(--w_color_white);
            z-index: 200;
            user-select: none;

            &__icon {
                width: 40px;
                height: 40px;

                svg {
                    width: 16px;
                    height: 16px;

                    &::v-deep path {
                        fill: var(--w_color_white);
                    }
                }
            }

            &__content {
                position: absolute;
                right: 0;
                top: 100%;
                background-color: #1c1b21;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                border: 1px solid rgba(0, 0, 0, 0.05);
                padding: 12px 6px;
                border-radius: 16px;
                transform-origin: top right;

                &-item {
                    display: flex;
                    align-items: center;
                    padding: 8px 48px 8px 16px;
                    border-radius: 8px;
                    white-space: nowrap;
                    transition: background-color 200ms;
                    cursor: pointer;
                    position: relative;

                    &-icon {
                        width: 24px;
                        height: 24px;
                        min-width: 24px;
                        margin-right: 12px;

                        &-svg {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    &:hover {
                        background-color: #192939;
                    }
                }
            }
        }

        &_content {
            min-height: 14vh;
            min-width: 20vh;
            background-color: var(--w_color_white);
            box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            position: fixed;
            z-index: 130;

            &.close_payment {
                animation: close-payment 300ms ease;
            }

            &.audio {
                padding: 44px 160px;
            }

            &.addition_popup {
                padding: 32px 135px;
            }

            &.attributes_popup {
                padding: 0 135px;
            }

            &.transparent {
                background-color: transparent !important;
            }

            &.overflow {
                overflow: hidden;
            }

            &.image {
                border-radius: 8px;
                overflow: hidden;
                display: flex;
                box-shadow: none !important;
                pointer-events: none;
                min-height: auto;
                min-width: auto;
            }

            &.video {
                width: 52vw;
                height: 29.25vw;
                min-height: auto;
                min-width: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .modal {
        &-enter, &-leave-to {
            opacity: 0;

            .w_popup_content {
                transform: scale(.8);
            }
        }

        &-enter-active, &-leave-active {
            transition: opacity 300ms ease;

            .w_popup_content {
                transition: transform 300ms ease;
            }
        }
    }

    @keyframes close-payment {
        0%, 100% {
            transform: translateX(0);
        }
        25%, 75% {
            transform: translateX(5px);
        }
        50% {
            transform: translateX(-5px);
        }
    }

    @media (max-width: 1440px) and (min-width: 981px) {
        .w_popup {
            &_close {
                top: 20px;
                right: 20px;
                width: 22px;
                height: 22px;

                svg {
                    width: 8px;
                    height: 8px;
                }

                &.ease {
                    width: 28px;
                    height: 28px;

                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            &_menu {
                top: 60px;
                right: 20px;

                &__icon {
                    width: 28px;
                    height: 28px;

                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }

                &__content {
                    padding: 8px 4px;
                    border-radius: 12px;

                    &-item {
                        padding: 6px 32px 6px 12px;
                        border-radius: 6px;
                        font-size: 12px;
                        line-height: 14px;

                        &-icon {
                            width: 16px;
                            height: 16px;
                            min-width: 16px;
                            margin-right: 8px;

                            &-svg {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }

            &_content {
                border-radius: 14px;

                &.audio {
                    padding: 30px 110px;
                }

                &.addition_popup {
                    padding: 22px 96px;
                }

                &.attributes_popup {
                    padding: 0 96px;
                }
            }
        }
    }

    @media screen and (max-width: 980px) {
        .w_popup {
            &_mask {
                z-index: 200;

                &.dark_mask {
                    background-color: rgba(0, 0, 0, 1);

                    .w_popup_close {
                        display: flex;
                        top: 20px;
                        right: 12px;
                    }
                }
            }

            &_close {
                display: none;
            }

            &_menu {
                display: none;
            }

            &_content {
                width: 100%;
                max-width: 100vw;
                min-height: 100px;
                background-color: var(--w_color_white);
                box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.06);
                border-radius: 20px 20px 0 0;
                position: fixed;
                bottom: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                will-change: transform;
                transition: transform 50ms linear;

                &-dash {
                    width: 100%;
                    height: 25px;
                    min-height: 25px;
                    padding: 12px 0 42px;
                    margin-bottom: -30px;
                    z-index: 10;
                }

                &.audio {
                    padding: 0 20px 40px;
                    user-select: none;
                }

                &.addition_popup, &.attributes_popup {
                    padding: 0 0 32px;
                }

                &.transparent {
                    .w_popup_content-dash {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        z-index: 50;
                    }
                }

                &.image {
                    bottom: auto;
                    border-radius: 0 !important;
                }
            }
        }

        .modal {
            &-enter, &-leave-to {
                opacity: 1;
                background-color: rgba(0, 0, 0, 0) !important;

                .w_popup_content {
                    transform: translateY(110%) !important;
                }
            }

            &-enter-active, &-leave-active {
                transition: background-color 400ms ease;

                .w_popup_content {
                    transition: transform 400ms ease;
                }
            }
        }

        .modal-image {
            &-enter, &-leave-to {
                opacity: 0;
                background-color: rgba(0, 0, 0, 0) !important;

                .w_popup_content {
                    transform: none;
                }
            }

            &-enter-active, &-leave-active {
                transition: opacity 400ms ease;
            }
        }

        @supports (-webkit-touch-callout: none) {
            @media (display-mode: standalone) {
                .w_popup {
                    &_content {
                        padding-bottom: 40px !important;
                    }

                    &_close {
                        top: 58px !important;
                    }
                }
            }
        }
    }

    [data-theme='dark'] {
        .w_popup {
            &_mask {
                background-color: rgba(0, 0, 0, 0.6);

                &.dark_mask {
                    background-color: rgba(0, 0, 0, 0.75);
                }
            }

            &_content {
                background-color: var(--w_color_grey7);
                box-shadow: 0 -8px 20px rgba(0, 0, 0, 0.5);
            }

            &_menu__content {
                background-color: var(--w_color_grey2);
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
                border: 1px solid rgba(0, 0, 0, 0.1);

                &-item:hover {
                    background-color: var(--w_color_grey7);
                }
            }
        }
    }
</style>
