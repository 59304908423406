import Vue from "vue";
import axios from "axios";

const getDefaultState = () => {
    return {
        all_news: [],
        all_news_loaded: false
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async GET_ALL_NEWS({state, commit, rootState}, load_up) {
            if (!state.all_news.length || load_up) {
                if (!state.all_news_loaded) {
                    let limit = 10,
                        pagination_id,
                        v = rootState.other.briefcase_version.match(/[\d.]/g).join('');

                    if (load_up) pagination_id = state.all_news[state.all_news.length - 1].pagination_id;

                    await axios
                        .get(Vue.prototype.$api_url + '/client-applications/web/feature-news/' + v, {
                            params: {
                                lang_code: rootState.other.locale.dictionary_code || 'eng',
                                limit,
                                pagination_id
                            }
                        })
                        .then(resp => {
                            commit("SAVE_ALL_NEWS", resp.data.data)
                            if (resp.data.data.length < limit) commit("SAVE_ALL_NEWS_LOADED", true)
                        })
                }
            }
        },
        CHANGE_UNREAD_NEWS_STATUS({state, commit}, boolean) {
            commit("SAVE_UNREAD_NEWS_STATUS", boolean)
            if (boolean === true) {
                if (!sessionStorage.getItem('news_popup')) {
                    let i = state.all_news.findIndex(n => Vue.prototype.$web_version == n.min_suitable_version)
                    if (~i) setTimeout(() => commit('CHANGE_NEWS_POPUP', true), 1500)
                }
            } else commit('CHANGE_NEWS_POPUP', false)
        }
    },
    mutations: {
        SAVE_ALL_NEWS: (state, news) => {
            if (state.all_news.length) state.all_news = state.all_news.concat(news)
            else state.all_news = news
        },
        SAVE_ALL_NEWS_LOADED: (state, boolean) => state.all_news_loaded = boolean,
        CLEAR_NEWS: (state) => Object.assign(state, getDefaultState())
    },
}
