let userDeviceArray = [
    {device: 'Android', platform: /Android/, os: 'android', isApple: false},
    {device: 'iPhone', platform: /iPhone/, os: 'iOS', isApple: true},
    {device: 'iPad', platform: /iPad/, os: 'iOS', isApple: true},
    {device: 'iPod', platform: /iPod/, os: 'iOS', isApple: true},
    {device: 'Symbian', platform: /Symbian/, os: '?', isApple: false},
    {device: 'Windows Phone', platform: /Windows Phone/, os: 'Windows', isApple: false},
    {device: 'Tablet OS', platform: /Tablet OS/, os: '?', isApple: true},
    {device: 'Linux', platform: /Linux/, os: 'Linux', isApple: false},
    {device: 'Windows', platform: /Windows NT/, os: 'Windows', isApple: false},
    {device: 'Macintosh', platform: /Macintosh/, os: 'Macintosh', isApple: true}
];

let userAgent = window.navigator.userAgent;
let isRobotAgents = /YandexBot|YandexAccessibilityBot|YandexMobileBot|YandexDirectDyn|YandexScreenshotBot|YandexImages|YandexVideo|YandexVideoParser|YandexMedia|YandexBlogs|YandexFavicons|YandexWebmaster|YandexPagechecker|YandexImageResizer|YandexAdNet|YandexDirect|YaDirectFetcher|YandexCalendar|YandexSitelinks|YandexMetrika|YandexNews|YandexNewslinks|YandexCatalog|YandexAntivirus|YandexMarket|YandexVertis|YandexForDomain|YandexSpravBot|YandexSearchShop|YandexMedianaBot|YandexOntoDB|YandexOntoDBAPI|YandexTurbo|YandexVerticals|Googlebot|Googlebot-Image|Mediapartners-Google|AdsBot-Google|APIs-Google|AdsBot-Google-Mobile|AdsBot-Google-Mobile|Googlebot-News|Googlebot-Video|AdsBot-Google-Mobile-Apps|Mail.RU_Bot|bingbot|Accoona|ia_archiver|Ask Jeeves|OmniExplorer_Bot|W3C_Validator|WebAlta|YahooFeedSeeker|Yahoo!|Ezooms|Tourlentabot|MJ12bot|AhrefsBot|SearchBot|SiteStatus|Nigma.ru|Baiduspider|Statsbot|SISTRIX|AcoonBot|findlinks|proximic|OpenindexSpider|statdom.ru|Exabot|Spider|SeznamBot|oBot|C-T bot|Updownerbot|Snoopy|heritrix|Yeti|DomainVader|DCPbot|PaperLiBot|StackRambler|msnbot|msnbot-media|msnbot-news/i.test(userAgent)
let isWebView = /webview/i.test(userAgent.toLowerCase())

function getPlatform() {
    for (let i in userDeviceArray) {
        if (userDeviceArray[i].platform.test(userAgent)) {
            console.info('WOLA: Your device: ' + userDeviceArray[i].device + ', ' + userDeviceArray[i].os);
            return {
                platform: userDeviceArray[i],
                is_robot: isRobotAgents,
                is_webview: isWebView,
                random(min, max) {
                    let rand = min + Math.random() * (max + 1 - min);
                    return Math.floor(rand);
                }
            };
        }
    }
    console.info('WOLA: Your device: undefined! ' + userAgent);
    return {
        platform: {},
        is_robot: isRobotAgents,
        is_webview: isWebView
    };
}

export default getPlatform()
