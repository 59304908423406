const getDefaultState = () => {
    return {
        loading_auth: false,
        loading_dictionary: false,
        loading_google_translate: false,
        loading_details: false,
        loading_statistics: false,
        loading_addition: false,
        loading_delete: false,
        loading_action: false,
        loading_favorites: false,
        loading_internet_connection: false,
        loading_tasks: false,
        loading_need_action_tasks: false,
        loading_moderation_tasks: false,
        loading_chat_messages: false,
        loading_chat_page: false,
        loading_chat_list: false,
        loading_refresh_token: false,
        loading_user_achievements: false,
        loading_selection: false,
        loading_selection_edit: false,
        loading_selection_ids: false,
        loading_google_adsense_script: false,
    }
}

export default {
    state: () => getDefaultState(),
    mutations: {
        CHANGE_LOADING_AUTH_STATUS: (state, boolean) => state.loading_auth = boolean,
        CHANGE_LOADING_DICTIONARY_STATUS: (state, boolean) => state.loading_dictionary = boolean,
        CHANGE_LOADING_GOOGLE_TRANSLATE_STATUS: (state, boolean) => state.loading_google_translate = boolean,
        CHANGE_LOADING_DETAILS_STATUS: (state, boolean) => state.loading_details = boolean,
        CHANGE_LOADING_STATISTICS_STATUS: (state, boolean) => state.loading_statistics = boolean,
        CHANGE_LOADING_ADDITION_STATUS: (state, boolean) => state.loading_addition = boolean,
        CHANGE_LOADING_DELETE_STATUS: (state, boolean) => state.loading_delete = boolean,
        CHANGE_LOADING_ACTION_STATUS: (state, boolean) => state.loading_action = boolean,
        CHANGE_LOADING_FAVORITES_STATUS: (state, boolean) => state.loading_favorites = boolean,
        CHANGE_LOADING_INTERNET_CONNECTION_STATUS: (state, boolean) => state.loading_internet_connection = boolean,
        CHANGE_LOADING_TASKS_STATUS: (state, boolean) => state.loading_tasks = boolean,
        CHANGE_LOADING_NEED_ACTION_TASKS_STATUS: (state, boolean) => state.loading_need_action_tasks = boolean,
        CHANGE_LOADING_MODERATION_TASKS_STATUS: (state, boolean) => state.loading_moderation_tasks = boolean,
        CHANGE_LOADING_CHAT_MESSAGES_STATUS: (state, boolean) => state.loading_chat_messages = boolean,
        CHANGE_LOADING_CHAT_PAGE_STATUS: (state, boolean) => state.loading_chat_page = boolean,
        CHANGE_LOADING_CHAT_LIST_STATUS: (state, boolean) => state.loading_chat_list = boolean,
        CHANGE_LOADING_REFRESH_TOKEN_STATUS: (state, boolean) => state.loading_refresh_token = boolean,
        CHANGE_LOADING_USER_ACHIEVEMENTS: (state, boolean) => state.loading_user_achievements = boolean,
        CHANGE_LOADING_SELECTION: (state, boolean) => state.loading_selection = boolean,
        CHANGE_LOADING_SELECTION_EDIT: (state, boolean) => state.loading_selection_edit = boolean,
        CHANGE_LOADING_SELECTION_IDS: (state, boolean) => state.loading_selection_ids = boolean,
        CHANGE_LOADING_GOOGLE_ADSENSE_SCRIPT: (state, boolean) => state.loading_google_adsense_script = boolean
    },
}
