<template>
    <transition name="bounce">
        <div class="dedicated_translation"
             :class="{'hidden': !translation, top}"
             :style="computedStyle"
             v-click-outside="closePopup"
        >
            <div class="dedicated_translation__word">
                <w-flag class="dedicated_translation__word-lang" :code="DEDICATED_TRANSLATION_DATA.lang_code"/>
                <div class="dedicated_translation__word-text">{{ DEDICATED_TRANSLATION_DATA.text }}</div>
                <div v-if="show_favorite_btn" class="dedicated_translation__word-favorite icon" @click="addToFavorite">
                    <w-svg-icon folder-name="addition" icon-name="iconStar"/>
                </div>
            </div>
            <div class="dedicated_translation__word">
                <w-flag class="dedicated_translation__word-lang" :code="LEARNING_KNOW_LANGUAGE"/>
                <div class="dedicated_translation__word-text">{{ translation }}</div>
            </div>
            <div class="dedicated_translation__line"/>
            <div class="dedicated_translation__else" @click="openMiniDictionary">
                {{ $t('more_translations') }}
                <w-svg-icon folder-name="dictionary" icon-name="iconArrowTo"/>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "dedicated_translation",
    props: {
        position: {
            type: Object
        }
    },
    data() {
        return {
            top: false,
            show_favorite_btn: true,
            translation: ""
        }
    },
    computed: {
        ...mapGetters(['LEARNING_KNOW_LANGUAGE']),
        ...mapState({
            'DEDICATED_TRANSLATION_DATA': state => state.other.dedicated_translation_data,
            'MINI_DICTIONARY': state => state.history.mini_dictionary,
            'NATIVE_LANG': state => state.profile.native_lang,
        }),
        computedStyle() {
            let screen_height = document.body.clientHeight;

            if (this.top) {
                return {
                    left: (this.DEDICATED_TRANSLATION_DATA.position.left + (this.DEDICATED_TRANSLATION_DATA.position.width / 2)) + 'px',
                    bottom: (screen_height - this.DEDICATED_TRANSLATION_DATA.position.top + 4) + 'px',
                }
            } else {
                return {
                    left: (this.DEDICATED_TRANSLATION_DATA.position.left + (this.DEDICATED_TRANSLATION_DATA.position.width / 2)) + 'px',
                    top: (this.DEDICATED_TRANSLATION_DATA.position.bottom + 6) + 'px',
                }
            }
        }
    },
    created() {
        this.$store
            .dispatch("LOAD_GOOGLE_TRANSLATE", {
                lang_t: this.LEARNING_KNOW_LANGUAGE,
                search: this.DEDICATED_TRANSLATION_DATA.text,
                important: true
            })
            .then(r => {
                this.translation = r.translate;
                if (r.sl === this.NATIVE_LANG) this.show_favorite_btn = false;
                if (r.translate.trim() === this.DEDICATED_TRANSLATION_DATA.text.trim()) this.show_favorite_btn = false;
                this.setTopPosition();
            })
            .finally(() => {
                // this.loading_input_translate = false;
            })
    },
    methods: {
        openMiniDictionary() {
            if (!this.MINI_DICTIONARY) this.$store.commit("CHANGE_MINI_DICTIONARY", true);
            else {
                this.$store.commit("CHANGE_MINI_DICTIONARY", false);
                setTimeout(() => this.$store.commit("CHANGE_MINI_DICTIONARY", true), 200);
            }
        },
        setTopPosition() {
            if ((this.DEDICATED_TRANSLATION_DATA.position.bottom + 50 + this.$el.clientHeight) > document.body.clientHeight) this.top = true;
        },
        closePopup() {
            this.$store.commit("CHANGE_DEDICATED_TRANSLATION_DATA", null)
        },
        addToFavorite() {
            this.$store.dispatch("ADD_RAW_FAVORITE", {
                "word_from": this.DEDICATED_TRANSLATION_DATA.text,
                "word_to": this.translation,
                "lang_from": this.DEDICATED_TRANSLATION_DATA.lang_code,
                "lang_to": this.LEARNING_KNOW_LANGUAGE,
            });
            this.closePopup();
        }
    },
}
</script>

<style lang="scss" scoped>
.dedicated_translation {
    position: fixed;
    padding-top: 18px;
    z-index: 5000;
    min-width: 300px;
    max-width: 25vw;
    border-radius: 20px;
    transform: translateX(-50%);
    transition: opacity 300ms 300ms ease;
    filter: drop-shadow(0 8px 24px rgba(0, 0, 0, 0.12));
    background-color: var(--w_color_white);

    &::before {
        content: " ";
        width: 18px;
        height: 18px;
        position: absolute;
        left: calc(50% - 9px);
        top: -8px;
        border-radius: 2px;
        z-index: -1;
        transform: rotate(45deg);
        background-color: var(--w_color_white);
    }

    &.hidden {
        opacity: 0;
    }

    &.top::before {
        top: auto !important;
        bottom: -8px;
    }

    &__word {
        display: flex;
        align-items: flex-start;
        padding: 6px 24px;

        &-lang {
            width: 20px;
            height: 20px;
            min-width: 20px !important;
            margin: 4px 8px 4px 0;
        }

        &-text {
            font-size: 20px;
            line-height: 28px;
            margin-right: auto;
        }

        &-favorite {
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin: 2px 0 2px 6px;

            &:hover svg::v-deep path {
                fill: var(--w_color_orange);
                stroke: var(--w_color_orange);
            }

            svg {
                height: 84%;
            }
        }
    }

    &__line {
        width: 100%;
        height: 1px;
        margin: 10px 0 0;
        background-color: var(--w_color_grey10);
    }

    &__else {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
        padding: 16px 24px 24px;
        cursor: pointer;
        border-bottom-right-radius: inherit;
        border-bottom-left-radius: inherit;
        transition: background-color 100ms ease;

        &:hover {
            background-color: var(--w_color_grey2);
        }

        svg {
            width: 18px;
            height: 18px;
            margin-right: 3px;
            margin-left: auto;
        }
    }
}

@media (max-width: 1440px) and (min-width: 981px) {
    .dedicated_translation {
        padding-top: 12px;
        min-width: 200px;
        border-radius: 14px;

        &::before {
            width: 12px;
            height: 12px;
            position: absolute;
            left: calc(50% - 6px);
            top: -5px;
        }

        &.top::before {
            bottom: -5px;
        }

        &__word {
            padding: 4px 16px;

            &-lang {
                width: 14px;
                height: 14px;
                min-width: 14px !important;
                margin: 3px 5px 3px 0;
            }

            &-text {
                font-size: 14px;
                line-height: 18px;
            }

            &-favorite {
                width: 16px;
                min-width: 16px;
                height: 16px;
                margin: 1px 0 1px 4px;
            }
        }

        &__line {
            margin: 7px 0 0;
        }

        &__else {
            font-size: 11px;
            line-height: 14px;
            padding: 11px 16px 16px;

            svg {
                width: 12px;
                height: 12px;
                margin-right: 2px;
            }
        }
    }
}

[data-theme='dark'] {
    .dedicated_translation {
        filter: drop-shadow(0 8px 24px rgba(0, 0, 0, 0.3));
        background-color: var(--w_color_grey7);

        &::before {
            background-color: var(--w_color_grey7);
        }

        &__line {
            background-color: var(--w_color_grey5);
        }

        &__else:hover {
            background-color: var(--w_color_grey3);
        }
    }
}
</style>