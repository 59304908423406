import axios from "axios";
import router from "../../router";
import Vue from "vue";
import countriesAndTimezones from "countries-and-timezones";

const getDefaultState = () => {
    return {
        user_info: {},
        user_verified_popup: false,
        user_languages: [],
        user_ratings: [],
        user_achievements: [],
        user_subscription_counters: {},
        user_materials: {},
        native_lang: null,
        removable_lang: null,
        user_friends: [],
        user_friends_requests: false,
        user_friends_total: 0,
        user_friends_requests_list: [],
        user_friends_requests_total: 0,
        profile_update_timeout: 0,
        updated_profile_data: null,
        deleted_account_loading: false,
        supported_achievements_types: [
            'king_of_the_vocabulary',
            'all_entity_types_checked_dictionary',
            'all_entity_types_added_dictionary',
            'translation_union_dictionary',
            'daily_activity',
            'translation_attribute_dictionary',
            'translation_child_attribute_dictionary',
            'sentence_dictionary',
            'audio_dictionary',
            'translation_meaning_dictionary',
            'translation_vocabulary_dictionary',
            'synonym_dictionary',
            'antonym_dictionary',
            'user_likes',
            'user_friends',
            'learned_words',
            'compilations_added',
            'selected_compilations',
            'videos_added',
            'videos_watched',
            'subscriptions'
        ]
    }
}

let loading_user = false,
    profile_update = true,
    achievements_update = true,
    materials_update = true,
    subscribers_update = true,
    rating_dictionary_update = true,
    rating_update = true,
    profile_update_timeout_function = null

export default {
    state: () => getDefaultState(),
    getters: {
        USER: state => state.user_info,
        IS_MODERATOR: state => ['moderator', 'admin'].includes(state.user_info.role),
        IS_ADMIN: state => state.user_info.role === 'admin',
        LEARNING_KNOW_LANGUAGE: (state, getters) => getters.LEARNING_KNOW_LEARNING_LANG || state.native_lang
    },
    actions: {
        LOAD_USER({dispatch}) {
            return new Promise((resolve) => {
                if (!loading_user && profile_update) {
                    loading_user = true
                    profile_update = false

                    let a = 0, stop = function (count) {
                        if (count === 6) {
                            loading_user = false
                            resolve()
                        }
                    }

                    dispatch("LOAD_USER_INFO").then(() => stop(++a))
                    dispatch("LOAD_USER_LANG").finally(() => stop(++a))
                    dispatch("LOAD_USER_FRIENDS", {type: "refresh"}).finally(() => stop(++a))
                    dispatch("LOAD_USER_RATINGS").finally(() => stop(++a))
                    dispatch("LOAD_USER_ACHIEVEMENTS").finally(() => stop(++a))
                    dispatch("LOAD_USER_SUBSCRIPTION_COUNTERS").finally(() => stop(++a))
                } else resolve()
            })
        },
        async LOAD_ANOTHER_USER({dispatch}, nickname) {
            return new Promise((resolve, reject) => {
                let a = 0,
                    info = {},
                    ratings = [],
                    languages = [],
                    friends = [],
                    friends_total = 0,
                    achievements = [],
                    subscription_counters = {},
                    finish = (count) => {
                        if (count === 5) resolve({info, ratings, languages, friends, friends_total, achievements, subscription_counters})
                    };

                axios
                    .get(`${Vue.prototype.$api_url}/user/${nickname}/profile`)
                    .then(response => {
                        info = response.data.data
                        languages = response.data.data.languages
                        finish(++a)
                    })
                    .catch(err => reject(err))

                axios
                    .get(`${Vue.prototype.$api_url}/user/${nickname}/ratings`)
                    .then(response => ratings = response.data.data)
                    .finally(() => finish(++a))

                dispatch("LOAD_USER_FRIENDS", {nickname: nickname, type: 'refresh'})
                    .then(response => {
                        friends = response.data.data.friends
                        friends_total = response.data.data.total
                    })
                    .finally(() => finish(++a))

                dispatch("LOAD_USER_ACHIEVEMENTS", nickname)
                    .then(response => achievements = response)
                    .finally(() => finish(++a))

                dispatch("LOAD_USER_SUBSCRIPTION_COUNTERS", nickname)
                    .then(response => subscription_counters = response)
                    .finally(() => finish(++a))
            })
        },
        async LOAD_USER_INFO({commit, dispatch}) {
            await axios
                .get(Vue.prototype.$api_url + "/user/profile")
                .then(user_info => {
                    commit("SAVE_USER", user_info.data.data)
                    commit('SAVE_USER_IN_HISTORY', user_info.data.data.nickname)

                    let current_tz = countriesAndTimezones.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone),
                        user_tz = user_info.data.data.timezone;

                    if (!user_tz || ((user_tz !== current_tz.name) && (user_tz !== current_tz.utcOffsetStr))) {
                        let send_tz, user_tz_data = countriesAndTimezones.getTimezone(user_tz);

                        if (!current_tz.name) send_tz = current_tz.utcOffsetStr;
                        else send_tz = current_tz.name.includes("Etc/GMT") ? current_tz.utcOffsetStr : current_tz.name;

                        if (send_tz) {
                            if (user_tz_data) {
                                if (user_tz_data.utcOffsetStr !== current_tz.utcOffsetStr) dispatch("PATCH_USER_TIMEZONE", send_tz);
                            } else dispatch("PATCH_USER_TIMEZONE", send_tz);
                        }
                    }
                });
        },

        async LOAD_USER_LANG({state, getters, dispatch, commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(Vue.prototype.$api_url + "/user/languages")
                    .then(async response => {
                        await commit("SAVE_USER_LANG", response.data.data);
                        if (state.user_languages.length) {
                            let c1 = getters.USER_LEARNING_LANG;
                            let c2 = !state.user_languages.map(l => l.lang.code).includes(getters.USER_LEARNING_LANG);
                            if (c1 && c2) await dispatch("UPDATE_USER_CONFIG", {key: 'learning_lang', value: state.user_languages[1].lang.code});
                        }
                        await resolve();
                    })
                    .catch((err) => reject(err))
            })
        },
        async LOAD_USER_RATINGS({commit, dispatch}) {
            if (rating_update) {
                await axios
                    .get(Vue.prototype.$api_url + "/user/ratings")
                    .then(response => commit("SAVE_USER_RATINGS", response.data.data))
                    .then(() => rating_update = false)
            } else if (rating_dictionary_update) await dispatch("LOAD_USER_RATING_DICTIONARY")
        },
        LOAD_USER_ACHIEVEMENTS({state, commit}, nickname) {
            return new Promise((resolve, reject) => {
                let URL = Vue.prototype.$api_url + '/user';

                if (nickname) URL += `/${nickname}/achievements`;
                else URL += '/achievements'

                if ((achievements_update || !state.user_achievements.length) || nickname) {
                    commit("CHANGE_LOADING_USER_ACHIEVEMENTS", true);
                    setTimeout(() => {
                        axios
                            .get(URL)
                            .then(user_achievements => {
                                let achievements = user_achievements.data.data.filter(a => state.supported_achievements_types.includes(a?.type));
                                if (!nickname) commit("SAVE_USER_ACHIEVEMENTS", achievements);
                                resolve(achievements);
                                achievements_update = false;
                            })
                            .catch(err => reject(err))
                            .finally(() => commit("CHANGE_LOADING_USER_ACHIEVEMENTS", false))
                    }, state.profile_update_timeout)
                } else resolve(state.user_achievements)
            })
        },
        LOAD_USER_MATERIALS({state, commit}, nickname) {
            return new Promise((resolve, reject) => {
                let URL = `${Vue.prototype.$api_url}/education/users/counters`;
                if (nickname) URL += `/${nickname}`;

                if (materials_update || nickname) {
                    commit("CHANGE_LOADING_USER_ACHIEVEMENTS", true);
                    setTimeout(() => {
                        axios
                            .get(URL)
                            .then(user_materials => {
                                if (nickname) resolve(user_materials.data.data)
                                else {
                                    commit("SAVE_USER_MATERIALS", user_materials.data.data)
                                    resolve(user_materials.data.data)
                                }
                                materials_update = false
                            })
                            .catch(err => reject(err))
                            .finally(() => commit("CHANGE_LOADING_USER_ACHIEVEMENTS", false))
                    }, state.profile_update_timeout)
                } else resolve(state.user_materials)
            })
        },
        LOAD_USER_SUBSCRIPTION_COUNTERS({state, commit}, nickname) {
            return new Promise((resolve, reject) => {
                let user_uuid = nickname || state.user_info.nickname,
                    URL = `${Vue.prototype.$api_url}/subscription/${user_uuid}/counters`;

                if (user_uuid && (subscribers_update || nickname)) {
                    setTimeout(() => {
                        axios
                            .get(URL)
                            .then(user_materials => {
                                if (nickname) resolve(user_materials.data.data)
                                else {
                                    commit("SAVE_USER_SUBSCRIPTION_COUNTERS", user_materials.data.data)
                                    resolve(user_materials.data.data)
                                }
                                subscribers_update = false
                            })
                            .catch(err => reject(err))
                    }, state.profile_update_timeout)
                } else resolve(state.user_materials)
            })
        },
        async LOAD_USER_RATING_DICTIONARY({state, commit}) {
            if (rating_dictionary_update) {
                return new Promise(resolve => {
                    setTimeout(() => {
                        axios
                            .get(Vue.prototype.$api_url + '/user/ratings/DICTIONARY_RATING')
                            .then(response => {
                                commit("SAVE_USER_DICTIONARY_RATING", response.data.data)
                                rating_dictionary_update = false
                                resolve(response.data.data)
                            })
                    }, state.profile_update_timeout)
                })
            }
        },
        async SET_USER_NATIVE_LANG({state, commit}, lang) {
            let native_lang_id = state.user_languages.find(l => l.level === 'NATIVE')?.id;

            if (!native_lang_id) return;

            await axios
                .put(Vue.prototype.$api_url + "/user/languages/" + native_lang_id, {
                    "lang_code": lang.code,
                    "level": "NATIVE"
                })
                .then(resp => {
                    commit("SAVE_USER_LANG", resp.data.data)
                    commit("CLEAR_TASKS")
                    commit("CLEAR_MODERATION_TASKS")
                    commit("SAVE_LAST_LANGUAGES", lang)
                })
        },
        CHANGE_USER_NICKNAME({commit}, nickname) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(Vue.prototype.$api_url + "/user/profile/nickname", {nickname})
                    .then(() => {
                        commit("SAVE_USER_NICKNAME", nickname)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        async ADD_USER_LANG({commit}, payload) {
            await axios
                .post(Vue.prototype.$api_url + "/user/languages", {
                    "languages": [{
                            "lang_code": payload.lang.code,
                            "level": payload.level
                    }]
                })
                .then(resp => {
                    commit("SAVE_LAST_LANGUAGES", payload.lang)
                    commit("SAVE_USER_LANG", resp.data.data)
                    commit("CHANGE_UPDATE_SETTING_LANGUAGES", true)
                    commit("CLEAR_TASKS")
                    commit("CLEAR_MODERATION_TASKS")
                })
        },
        async DELETE_USER_LANG({state, getters, dispatch, commit}) {
            await commit("CHANGE_LOADING_DELETE_STATUS", true);
            await axios
                .delete(Vue.prototype.$api_url + "/user/languages/" + state.removable_lang.id)
                .then(resp => {
                    commit("SAVE_USER_LANG", resp.data.data);
                    commit("CHANGE_UPDATE_SETTING_LANGUAGES", true);
                    commit("CLEAR_TASKS");
                    commit("CLEAR_MODERATION_TASKS");

                    if (getters.USER_LEARNING_LANG === state.removable_lang.lang.code) {
                        if (state.user_languages[1]) dispatch("UPDATE_USER_CONFIG", {key: 'learning_lang', value: state.user_languages[1].lang.code});
                        else dispatch("UPDATE_USER_CONFIG", {key: 'learning_lang'});
                    } else if (getters.LEARNING_KNOW_LEARNING_LANG === state.removable_lang.lang.code) {
                        commit("SAVE_LEARNING_KNOW_LANGUAGE", null);
                    }

                    if (('tasks_setting_languages' in getters.USER_HISTORY) && getters.USER_HISTORY['tasks_setting_languages'].includes(state.removable_lang.lang.code)) {
                        dispatch('UPDATE_USER_CONFIG', {
                            key: 'tasks_setting_languages',
                            value: getters.USER_HISTORY['tasks_setting_languages'].filter(c => c !== state.removable_lang.lang.code)
                        })
                    }
                    commit("CHANGE_LOADING_DELETE_STATUS", false);
                    commit("SAVE_REMOVABLE_LANG", null);
                })
                .catch(() => commit("CHANGE_LOADING_DELETE_STATUS", false))
        },
        async DELETE_USER_AVATAR({commit}) {
            commit("CHANGE_LOADING_DICTIONARY_STATUS", true)
            await axios
                .delete(Vue.prototype.$api_url + "/user/avatar")
                .then(() => {
                    commit("DELETE_AVATAR");
                    commit("CHANGE_LOADING_DICTIONARY_STATUS", false)
                })
                .catch(() => commit("CHANGE_LOADING_DICTIONARY_STATUS", false))
        },
        async PUT_USER_LANG_LEVEL({state, commit}, payload) {
            let langID = state.user_languages.find(lang => lang.lang.code === payload.flag_code).id
            await axios
                .put(`${Vue.prototype.$api_url}/user/languages/${langID}`, {level: payload.level})
                .then(response => {
                    let data = Object.keys(response.data.data).map(value => response.data.data[value])
                    commit("SAVE_USER_LANG", data)
                })
        },
        CHANGE_LANGUAGE_AVAILABLE({state, commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put(Vue.prototype.$api_url + `/user/languages/${data.id}/update-available-to-chat-status`, {"is_available_to_chat": data.value})
                    .then(() => {
                        let languages = state.user_languages,
                            index = languages.findIndex(lang => lang.id === data.id)

                        if (~index) languages[index].is_available_to_chat = data.value

                        commit("SAVE_USER_LANG", languages)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
        EDIT_USER_PROFILE({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(Vue.prototype.$api_url + "/user/profile", data)
                    .then(response => {
                        commit("SAVE_USER", response.data.data)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        async CHANGE_USER_GEOLOCATION({dispatch}, payload) {
            await axios
                .patch(Vue.prototype.$api_url + "/user/profile/geolocation", payload)
                .then(() => {
                    dispatch('UPDATE_USER_CONFIG', {
                        key: 'geolocation',
                        value: payload
                    })
                    profile_update = true;
                })
                .catch(err => {
                    if (err.message === 'USER_NEW_LOCATION_EQUAL_WITH_OLD_LOCATION') {
                        dispatch('UPDATE_USER_CONFIG', {
                            key: 'geolocation',
                            value: payload
                        })
                    } else throw Error(err.message)
                })
        },
        async UPDATE_PASSWORD({commit}, data) {
            await axios
                .post(`${Vue.prototype.$api_url}/user/password/update`, data)
                .then(response => {
                    const token = response.data['access_token'], token_type = response.data['token_type'];
                    commit('SAVE_TOKEN', token);
                    commit('SAVE_TOKEN_TYPE', token_type);
                    axios.defaults.headers.common['authorization'] = token_type + ' ' + token;
                })
        },
        async EMAIL_VERIFICATION_POST({commit}) {
            await axios
                .post(`${Vue.prototype.$api_url}/email-verification/resend`)
                .then(() => commit("SAVE_USER_VERIFIED_POPUP", false))
        },
        EMAIL_VERIFICATION_GET({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/email-verification/verify/${data.id}/${data.hash}`)
                    .then(response => resolve(response.data.data))
                    .catch(error => reject(error))
                    .finally(() => commit)
            })
        },
        async IDENTIFY_LANGUAGES({state, getters, commit, rootState}) {
            let lang_one,
                lang_two,
                user_languages = state.user_languages,
                user_lang_first = rootState.languages.languages.find(lang => lang.code === user_languages[0].lang.code) || {};

            if (user_languages.length > 1) {
                let has_one = false,
                    has_two = false,
                    user_lang_second = rootState.languages.languages.find(lang => lang.code === user_languages[1].lang.code) || {};

                if (getters.USER_HISTORY && getters.USER_HISTORY.length) {
                    let last_query = getters.USER_HISTORY[0];

                    user_languages.forEach(lang => {
                        if (lang.lang.code === last_query.lang) has_one = true;
                        else if (lang.lang.code === last_query.lang_t) has_two = true;
                    })

                    if (has_one && has_two) {
                        lang_one = last_query.lang;
                        lang_two = last_query.lang_t;
                    } else {
                        lang_one = user_lang_first;
                        lang_two = user_lang_second;
                    }
                } else {
                    lang_one = user_lang_first;
                    lang_two = user_lang_second;
                }
            } else {
                let lang_eng = rootState.languages.languages.find(lang => lang.code === 'eng') || {};

                if (state.native_lang === 'eng') {
                    lang_one = lang_eng;
                    lang_two = rootState.languages.languages.find(lang => lang.code === 'spa') || {};
                } else {
                    lang_one = user_lang_first;
                    lang_two = lang_eng;
                }
            }

            if (lang_one && lang_two) {
                await commit("SAVE_LANGUAGE", lang_one);
                await commit("SAVE_LANGUAGE_T", lang_two);
            }
        },
        async PATCH_USER_TIMEZONE({commit}, timezone) {
            await axios
                .patch(Vue.prototype.$api_url + "/user/timezone", {timezone})
                .then(() => commit("SAVE_USER_TIMEZONE", timezone))
        },
        CHANGE_ANOTHER_USER_LIKE({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url}/user/${payload.uuid}/${payload.type}`)
                    .then((resp) => resolve(resp.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        CHANGE_ANOTHER_USER_SUBSCRIBE({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${Vue.prototype.$api_url}/subscription/${payload.uuid}/${payload.type}`)
                    .then(() => {
                        commit("CHANGE_USER_SUBSCRIPTION_COUNTERS", payload.type === 'subscribe')
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        LOAD_USER_FRIENDS({commit, state}, data) {
            let url = Vue.prototype.$api_url,
                pagination_id = null

            if (data.nickname) {
                url += `/user/${data.nickname}/friends`
                pagination_id = data.pagination_id
            } else {
                url += '/user/friends';
                if (data.type === 'refresh') commit("CLEAR_USER_FRIENDS")
                else if (data.type === 'search') pagination_id = data.pagination_id
                else if (state.user_friends.length) pagination_id = state.user_friends[state.user_friends.length - 1].friendship_id
            }

            url += '?limit=20'

            if (data.type === 'search') {
                url += `&search=${data.search}`
                if (pagination_id) url += `&pagination_id=${pagination_id}`
            } else if (data.type !== 'refresh' && pagination_id) url += `&pagination_id=${pagination_id}`

            return new Promise((resolve, reject) => {
                axios
                    .get(url)
                    .then(async response => {
                        if (data.type === 'search') await resolve(response.data.data)
                        else if (data.nickname) await resolve(response)
                        else if (data.type === 'get_requests') await resolve(response.data.data.has_requests)
                        else {
                            await commit("SAVE_USER_FRIENDS", response.data.data.friends)
                            await commit("SAVE_USER_FRIENDS_TOTAL", response.data.data.total)
                            await commit("SAVE_USER_FRIENDS_REQUESTS", response.data.data.has_requests)
                            await commit("SAVE_USER_FRIENDS_REQUESTS_TOTAL", response.data.requests_count)
                            await resolve(response)
                        }
                    })
                    .catch((err) => reject(err))
            })
        },
        LOAD_USER_BLOCK_LIST({commit}, data) {
            return new Promise((resolve, reject) => {
                let params = {limit: data.limit};
                if (data.pagination_uuid) params['pagination_uuid'] = data.pagination_uuid;

                axios
                    .get(`${Vue.prototype.$api_url}/users/block-list`, {params})
                    .then(async response => resolve(response.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        REQUEST_FRIENDSHIP({state, dispatch, commit, rootState}, request) {
            return new Promise((resolve, reject) => {
                axios({
                    method: request.method,
                    url: Vue.prototype.$api_url + '/user/friends/request',
                    data: {"uuid": request.uuid}
                })
                    .then((resp) => {
                        if (router.app._route.name === 'Chat') {
                            commit("CHANGE_COMPANION_FRIENDSHIP_STATUS", {
                                'uuid': resp.data.data.uuid,
                                'status': resp.data.data.friendship_status
                            })
                        }
                        resolve(resp)
                    })
                    .catch((err) => {
                        if (err.message === 'FRIENDS_REQUEST_NOT_FOUND_EXCEPTION') {
                            let nickname = router.app._route.params.nickname
                            router.replace({name: 'ProfilePage', params: {nickname: state.user_info.nickname}})
                            router.replace({name: 'ProfilePage', params: {nickname: nickname}})
                        } else if (err.message === 'USERS_HAVE_RELATION') {
                            if ((router.app._route.name === 'Chat') && rootState.chat.chat_id) reject(err.message)
                        } else {
                            dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'})
                            reject(err)
                        }
                    })
            })
        },
        ANSWER_FRIENDSHIP_REQUEST({state, commit}, request) {
            return new Promise((resolve, reject) => {
                axios
                    .put(Vue.prototype.$api_url + '/user/friends/request', request)
                    .then((resp) => {
                        let new_friend = resp.data.data
                        state.user_friends_requests_total = state.user_friends_requests_total - 1
                        commit("SAVE_USER_FRIENDS_REQUESTS", state.user_friends_requests_total > 0)

                        if (request.action === 'accept') {
                            commit("CHANGE_USER_FRIENDS", {'type': 'add', 'friend': new_friend})
                        } else {
                            commit("CHANGE_USER_FRIENDS", {'type': 'delete', 'friend': new_friend})
                        }

                        if (router.app._route.name === 'Chat') {
                            commit("CHANGE_COMPANION_FRIENDSHIP_STATUS", {
                                'uuid': new_friend.uuid,
                                'status': new_friend.friendship_status
                            })
                        }

                        resolve()
                    })
                    .catch((err) => reject(err.message))
            })
        },
        DELETE_FRIEND({state, commit}, uuid) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(Vue.prototype.$api_url + '/user/friends', {data: {uuid}})
                    .then(() => {
                        let userIndex = state.user_friends.findIndex(friend => friend.uuid === uuid)
                        if (~userIndex) {
                            state.user_friends.splice(userIndex, 1)
                            state.user_friends_total = state.user_friends_total - 1
                        }
                        resolve()
                    })
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        LOAD_FRIENDS_REQUESTS({state, commit}, type) {
            let url = Vue.prototype.$api_url + '/user/friends/requests?limit=20',
                pagination_id = null

            if (type === 'refresh') {
                state.user_friends_requests_list = []
            } else if (state.user_friends_requests_list.length) {
                pagination_id = state.user_friends_requests_list[state.user_friends_requests_list.length - 1].friendship_id
            }

            if (type !== 'refresh' && ~pagination_id) url += `&pagination_id=${pagination_id}`

            return new Promise((resolve, reject) => {
                axios
                    .get(url)
                    .then((resp) => {
                        commit("SAVE_USER_FRIENDS_REQUESTS_LIST", resp.data.data.requests)
                        if (~resp.data.data.total) {
                            commit("SAVE_USER_FRIENDS_REQUESTS_TOTAL", resp.data.data.total)
                            commit("SAVE_USER_FRIENDS_REQUESTS", resp.data.data.total > 0)
                        }
                        resolve(resp)
                    })
                    .catch((err) => reject(err))
            })
        },
        CHANGE_USER_ACCESS_STATUS({commit}, data) {
            this.loading_unblocking = true;

            let method = '',
                url = Vue.prototype.$api_url + '/user/' + data.uuid;

            if (data.type === 'unblock') {
                method = 'PUT';
                url += '/unblock';
            } else {
                method = 'POST';
                url += '/block';
            }

            return new Promise((resolve, reject) => {
                axios({url, method})
                    .then((resp) => resolve(resp.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        CHECK_USER_EMAIL_VERIFICATION({getters, commit}, need_to_request) {
            function sendEmailChecking() {
                if (need_to_request) {
                    axios
                        .post(Vue.prototype.$api_url + '/user/email/is-verified')
                        .then(resp => {
                            let is_verified = resp.data.data.is_verified;
                            commit("SAVE_USER_VERIFIED", is_verified);
                            localStorage.removeItem('verified_popup');
                            if (!is_verified) setTimeout(() => commit("SAVE_USER_VERIFIED_POPUP", true), 800);
                        })
                } else {
                    localStorage.removeItem('verified_popup');
                    setTimeout(() => commit("SAVE_USER_VERIFIED_POPUP", true), 800);
                }
            }

            if (getters.isLoggedIn) {
                if (getters.USER.is_email_verified) {
                    localStorage.removeItem('verified_popup');
                } else {
                    if (!getters.USER.email?.includes('@wola.test')) {
                        let ls_date = localStorage.getItem('verified_popup');

                        if (ls_date) {
                            if (Math.floor((Date.now() - ls_date) / 1000) > 86400) sendEmailChecking()
                        } else sendEmailChecking()
                    }
                }
            }
        },

        LOAD_USER_SUBSCRIPTIONS({commit}, data) {
            return new Promise((resolve, reject) => {
                let params = {limit: data.limit};
                if (data.previous_uuid) params['previous_uuid'] = data.previous_uuid;

                axios
                    .get(`${Vue.prototype.$api_url}/subscription/${data.uuid}/subscriptions`, {params})
                    .then(async response => resolve(response.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        LOAD_USER_SUBSCRIBERS({commit}, data) {
            return new Promise((resolve, reject) => {
                let params = {limit: data.limit};
                if (data.previous_uuid) params['previous_uuid'] = data.previous_uuid;

                axios
                    .get(`${Vue.prototype.$api_url}/subscription/${data.uuid}/subscribers`, {params})
                    .then(async response => resolve(response.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        BAN_USER({commit}, data) {
            return new Promise((resolve, reject) => {
                let params = {
                    reason: data.reason,
                    period: data.period,
                };

                if (data.comment) params['comment'] = data.comment;

                axios
                    .post(`${Vue.prototype.$api_url}/user/${data.uuid}/set-block`, params)
                    .then(response => resolve(response.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        REPRIMAND_USER({commit}, data) {
            return new Promise((resolve, reject) => {
                let params = {
                    reason: data.reason,
                    period: data.period,
                };

                if (data.comment) params['comment'] = data.comment;

                axios
                    .post(`${Vue.prototype.$api_url}/user/${data.uuid}/reprimand`, params)
                    .then(response => resolve(response.data.data))
                    .catch((err) => reject(err))
                    .finally(() => commit)
            })
        },
        REMOVE_USER_PROPERTY({commit}, data) {
            axios
                .delete(`${Vue.prototype.$api_url}/user/${data.uuid}/${data.type}`)
                .finally(() => commit)
        },
        async CANCEL_BAN_USER({dispatch}, uuid) {
            await axios
                .delete(`${Vue.prototype.$api_url}/user/${uuid}/erase-block`)
                .catch(() => dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'}))
        },
        async CANCEL_REPRIMAND_USER({dispatch}, uuid) {
            await axios
                .delete(`${Vue.prototype.$api_url}/user/${uuid}/reprimand`)
                .catch(() => dispatch('ERROR_SHOW', {name: 'system', content: 'server_error_title'}))
        },
        GET_BLOCKED_COMMENTS({getters}, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/user/${getters.USER.uuid}/violation-history`, {params, 'dont_show_error': true})
                    .then(resp => resolve(resp.data.data))
                    .catch(err => reject(err))
            })
        },
        GET_BLOCKED_HISTORY({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/user/${payload.uuid}/violation-history`, {params: payload.params, 'dont_show_error': true})
                    .then(resp => resolve(resp.data.data))
                    .catch(err => reject(err))
                commit
            })
        },
        async TOGGLE_TRUST_PUBLISHER({commit}, payload) {
            await axios({
                method: payload.status ? 'POST' : 'DELETE',
                url: `${Vue.prototype.$api_url}/user/${payload.uuid}/trust-publisher`
            })
                .then(() => {
                    commit("CHANGE_UPDATED_PROFILE_DATA", {
                        user_uuid: payload.uuid,
                        is_trust_publisher: payload.status
                    })
                })
                .catch(err => {
                    throw new Error(err)
                })
        },
        GET_STRIPE_SESSION_ID({commit}, period) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${Vue.prototype.$api_url}/stripe/session-id?period=${period}`)
                    .then(resp => resolve(resp.data.data.session_id))
                    .catch(err => reject(err))
                commit
            })
        },
        async CHECK_PREMIUM_STATUS({getters, commit}, sessionId) {
            await axios
                .get(`${Vue.prototype.$api_url}/stripe/session-status?sessionId=${sessionId}`)
                .then(async resp => {
                    if (resp.data.data.status) {
                        await axios.post(`${Vue.prototype.$api_url}/payments/subscriptions/finalize`)
                        await commit("CHANGE_UPDATED_PROFILE_DATA", {
                            user_uuid: getters.USER.uuid,
                            premium: true
                        })
                        await commit("SAVE_USER_PREMIUM", true)
                    }
                })
                .catch(err => {
                    throw Error(err)
                })
        },
        async CANCEL_WOLA_PLUS_SUBSCRIPTION({getters, commit}) {
            await axios
                .patch(`${Vue.prototype.$api_url}/stripe/cancel-subscription`)
                .then(() => {
                    commit("CHANGE_UPDATED_PROFILE_DATA", {
                        user_uuid: getters.USER.uuid,
                        premium: false
                    })
                    commit("SAVE_USER_PREMIUM", false)
                })
                .catch(err => {
                    throw Error(err)
                })
        },
        GET_WOLA_PLUS_SUBSCRIPTION_INFO({commit}) {
            return new Promise(resolve => {
                axios
                    .get(`${Vue.prototype.$api_url}/payments/subscriptions`)
                    .then(resp => {
                        commit
                        resolve(resp.data.data)
                    })
                    .catch(err => {
                        throw Error(err)
                    })
            })
        },
    },
    mutations: {
        SAVE_USER: (state, user) => state.user_info = user,
        SAVE_USER_NICKNAME: (state, nickname) => state.user_info.nickname = nickname,
        SAVE_USER_VERIFIED: (state, boolean) => state.user_info.is_email_verified = boolean,
        SAVE_USER_VERIFIED_POPUP: (state, boolean) => state.user_verified_popup = boolean,
        SAVE_USER_TIMEZONE: (state, timezone) => state.user_info.timezone = timezone,
        SAVE_USER_PREMIUM: (state, boolean) => state.user_info.premium = boolean,
        SAVE_USER_LANG: (state, user_languages) => {
            let order = ['NATIVE', 'EXPERT', 'ADVANCED', 'BASE', 'ELEMENTARY']
            state.user_languages = user_languages.sort((a, b) => order.indexOf(a.level) - order.indexOf(b.level))

            let native_lang = user_languages.find(lang => lang.level === "NATIVE")
            if (native_lang) state.native_lang = native_lang.lang.code;
        },
        SAVE_USER_RATINGS: (state, user_ratings) => state.user_ratings = user_ratings,
        SAVE_USER_ACHIEVEMENTS: (state, user_achievements) => state.user_achievements = user_achievements,
        SAVE_USER_MATERIALS: (state, user_materials) => state.user_materials = user_materials,
        SAVE_USER_SUBSCRIPTION_COUNTERS: (state, counters) => state.user_subscription_counters = counters,
        SAVE_USER_DICTIONARY_RATING: (state, rating) => {
            let dictionary_rating_index = state.user_ratings.findIndex(rating => rating.name === 'DICTIONARY_RATING');
            if (dictionary_rating_index > -1) state.user_ratings.splice(dictionary_rating_index, 1, rating)
            else state.user_ratings = [rating]
        },
        SAVE_USER_REPRIMAND: (state, reprimand) => state.user_info.reprimand = reprimand,
        RATING_UPDATE: () => rating_update = true,
        DICTIONARY_RATING_UPDATE: () => rating_dictionary_update = true,
        ACHIEVEMENTS_UPDATE: () => achievements_update = true,
        MATERIALS_UPDATE: () => materials_update = true,
        DELETE_AVATAR: (state) => {
            state.user_info['avatar'] = '';
            state.user_info['mini_avatar'] = '';
            state.user_info['medium_avatar'] = '';
        },
        SAVE_REMOVABLE_LANG: (state, lang) => state.removable_lang = lang,
        CLEAR_USER_FRIENDS: (state) => state.user_friends = [],
        CLEAR_USER_FRIENDS_REQUESTS: (state) => {
            state.user_friends_requests_list = []
            state.user_friends_requests_total = 0
            state.user_friends_requests = false
        },
        SAVE_USER_FRIENDS: (state, friends) => state.user_friends = state.user_friends.concat(friends),
        SAVE_USER_FRIENDS_REQUESTS: (state, boolean) => state.user_friends_requests = boolean,
        SAVE_USER_FRIENDS_TOTAL: (state, count) => state.user_friends_total = count,
        SAVE_USER_FRIENDS_REQUESTS_LIST: (state, friends) => state.user_friends_requests_list = state.user_friends_requests_list.concat(friends),
        SAVE_USER_FRIENDS_REQUESTS_TOTAL: (state, count) => state.user_friends_requests_total = count,
        CHANGE_USER_SUBSCRIPTION_COUNTERS: (state, boolean) => {
            if (boolean) {
                state.user_subscription_counters.subscriptions_count += 1
            } else if (state.user_subscription_counters.subscribers_count > 0) {
                state.user_subscription_counters.subscriptions_count -= 1
            }
        },
        CHANGE_USER_LIKES_COUNT: (state, count) => state.user_info.likes_count = state.user_info.likes_count += count,
        CHANGE_USER_MODERATION_STATUS: (state, payload) => {
            state.user_info["invited_to_moderation_status"] = payload.status;
            state.user_info["role"] = payload.role;
        },
        UPDATE_USER_GEO_POSITION: (state, payload) => {
            state.user_info["country"] = payload.country;
            state.user_info["city"] = payload.city;
        },
        CHANGE_USER_FRIENDS: (state, request) => {
            if (request.type === 'add') {
                state.user_friends.unshift(request.friend)
                state.user_friends_total = state.user_friends_total + 1
            } else {
                let index = state.user_friends.findIndex(friend => friend.nickname === request.friend.nickname)
                if (~index) {
                    if (request.type === 'delete') {
                        state.user_friends.splice(index, 1)
                        state.user_friends_total = state.user_friends_total - 1
                    } else state.user_friends.splice(index, 1, request.friend)
                }
            }

            let indexNew = state.user_friends_requests_list.findIndex(friend => friend.nickname === request.friend.nickname)
            if (~indexNew) state.user_friends_requests_list.splice(indexNew, 1, request.friend)
        },
        SET_PROFILE_UPDATE_TIMEOUT: (state) => {
            clearTimeout(profile_update_timeout_function)
            state.profile_update_timeout = 1500
            profile_update_timeout_function = setTimeout(() => state.profile_update_timeout = 0, 1500)
        },
        CHANGE_DELETED_ACCOUNT_LOADING: (state, boolean) => state.deleted_account_loading = boolean,
        CHANGE_UPDATED_PROFILE_DATA: (state, data) => state.updated_profile_data = data,
        CLEAR_USER: (state) => {
            Object.assign(state, getDefaultState());
            loading_user = false;
            profile_update_timeout_function = null;
            profile_update = rating_update = rating_dictionary_update = achievements_update = materials_update = true;
        }
    }
}
